import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import Popup from 'reactjs-popup';
import { ReactComponent as CloseIco } from '../assets/close.svg';
import useBetterTranslate from '../utils/translation-utils';
import { ButtonAccent, ButtonPrimary } from './button';
import styles from './delete-popup.module.scss';

export function DeleteBtn(props: PropsWithChildren<{ className?: string; onClick: () => void }>) {
  const { _t } = useBetterTranslate('delete-popup');
  return (
    <ButtonPrimary onClick={props.onClick} className={classNames(styles.delete, props.className)} dataCy='btnDelete'>
      {props.children || _t('Löschen')}
    </ButtonPrimary>
  );
}

export function CancelBtn(props: PropsWithChildren<{ className?: string; onClick: () => void }>) {
  const { _t } = useBetterTranslate('delete-popup');
  return (
    <ButtonAccent onClick={props.onClick} className={classNames(styles.cancel, props.className)} dataCy='btnCancel'>
      {props.children || _t('Abbrechen')}
    </ButtonAccent>
  );
}

export default function DeletePopup(
  props: PropsWithChildren<{
    className?: string;
    open: boolean;
    onOpen?: () => void;
    onClose: () => void;
    title?: ReactNode;
    cancelText?: string;
    bodyClassName?: string;
    actionsClassName?: string;
    onDelete?: () => void;
    deleteText?: string;
    additionalFooterContent?: ReactNode;
    additionalFooterContentClassName?: string;
    dataCy?: string;
  }>
) {
  return (
    <Popup
      closeOnDocumentClick={false}
      closeOnEscape={false}
      className={`delete-popup`}
      lockScroll={true}
      nested={true}
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}
      position='right center'
    >
      <div className={classNames(styles.root, props.className)} data-cy={props.dataCy}>
        <div className={styles.header}>
          {props.title && (
            <span className={classNames(styles.title)} data-cy='popTitle'>
              {props.title}
            </span>
          )}
          <button type='button' onClick={props.onClose} data-cy={'btnClose'}>
            <CloseIco />
          </button>
        </div>
        <div className={classNames(styles.body, props.bodyClassName)}>{props.children}</div>
        <div className={styles.footer}>
          {props.additionalFooterContent && <div className={classNames(styles.additionalContent, props.additionalFooterContentClassName)}>{props.additionalFooterContent}</div>}
          <div className={classNames(styles.actions, props.actionsClassName)}>
            <CancelBtn onClick={props.onClose}>{props.cancelText}</CancelBtn>

            {props.onDelete && <DeleteBtn onClick={props.onDelete}>{props.deleteText}</DeleteBtn>}
          </div>
        </div>
      </div>
    </Popup>
  );
}

export function DeletePopupRow(
  props: PropsWithChildren<{
    className?: string;
    controlCassName?: string;
  }>
) {
  return <div className={props.className}>{props.children}</div>;
}
