import { useEffect, useState } from 'react';
import { isHttpResponse } from '../../services/api';
import { ApiError, HttpResponse, SharedClientDto } from '../../services/api-client/csp-api';
import useBetterTranslate from '../../utils/translation-utils';
import EditPoup from '../edit-popup';
import SingleSelect from '../select/single-select';
import styles from './select-client-popup.module.scss';

export default function SelectClientPopup(props: {
  open: boolean;
  client?: { code: string; title: string };
  onCancel: () => void;
  searchClients: (search: string) => Promise<SharedClientDto[] | HttpResponse<SharedClientDto[], ApiError>>;
  onClientSelected: (client: SharedClientDto | null) => void;
}) {
  const { _t } = useBetterTranslate('org-select-client-popup');
  const [selectedClient, setSelectedClient] = useState<SharedClientDto | null>(null);

  useEffect(() => {
    if (props.open && props.client) {
      setSelectedClient({ code: props.client.code, title: props.client.title, hierarchy: [] });
    }
  }, [props.open, props.client]);

  return (
    <EditPoup
      dataCy={{
        buttonSave: 'client_pick_btn_apply',
        buttonCancel: 'client_pick_btn_cancel',
        buttonClose: 'client_pick_close',
      }}
      open={props.open}
      className={styles.popup}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onClose={props.onCancel}
      onSave={() => {
        if (selectedClient && selectedClient?.code !== props.client?.code) {
          setTimeout(() => {
            props.onClientSelected(selectedClient);
          }, 100);
        } else {
          props.onCancel();
        }
      }}
      showCancel={true}
      saveText={_t('Übernehmen')}
      title={_t('Wählen Sie eine Organisation')}
    >
      <SingleSelect<{ id: string; dto: SharedClientDto }>
        className={styles.select}
        dataCy='client-pick-list'
        createOption={(opt) => {
          return (
            <div data-cy={'el_client_pick'} data-cy-id={opt.id} className={styles.clientOpt}>
              <b>{opt.dto.title}</b>
              <i>{opt.id}</i>
            </div>
          );
        }}
        maxMenuHeight={250}
        fetchOptions={async (search) => {
          const res = await props.searchClients(search);
          if (isHttpResponse(res)) {
            return res.data.map((r) => ({ id: r.code, dto: r }));
          } else {
            return res.map((r) => ({ id: r.code, dto: r }));
          }
        }}
        options={true}
        renderDisplayValue={(v) => <>{v.dto.title}</>}
        selectedValue={selectedClient ? { id: selectedClient?.code, dto: selectedClient } : null}
        onChanged={(val) => {
          if (!val) return;
          setSelectedClient(val.dto);
        }}
      />
    </EditPoup>
  );
}
