import useBetterTranslate from '../../utils/translation-utils';
import Pill from '../pill';
import SearchInput from '../search-input';
import SingleSelect from '../select/single-select';
import colorStyles from '../style-utils';
import { StationFilterParameters } from './filter-area-parameters.dto';
import styles from './station-charge-point-filter-area.module.scss';

const chargePointTypeOptions = [
  { name: 'AC', id: 'ac' },
  { name: 'DC', id: 'dc' },
  { name: 'AC/DC', id: 'acdc' },
];

export default function StationFilterArea(props: {
  searchTerm: string | undefined;
  updateSearchTerm: (newSearchTerm: string) => void;
  filterParams: StationFilterParameters;
  setFilterParams: (params: StationFilterParameters) => void;
}) {
  const { searchTerm, updateSearchTerm, filterParams, setFilterParams } = props;
  const { _t } = useBetterTranslate('stations-filter-area');

  return (
    <div className={styles.filterArea}>
      <div className={styles.filterContainer1}>
        <SearchInput dataCy='search_input' maxLength={120} onChange={updateSearchTerm} value={searchTerm} className={styles.searchInput} placeholder={_t('Suchen')} />
        <div>
          <SingleSelect<{ name: string; id: string }>
            className=''
            placeholder={_t('Stromart (AC/DC)')}
            options={chargePointTypeOptions}
            createOption={(opt) => <div>{opt.name}</div>}
            renderDisplayValue={(val) => val.name}
            selectedValue={null}
            fetchOptions={async (txt) => {
              if (!txt) return chargePointTypeOptions || [];
              const results = (chargePointTypeOptions || []).filter((item) => item.name.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
              return results;
            }}
            onChanged={(selected) => {
              if (selected?.id === 'ac') {
                setFilterParams({ ac: '1' });
              } else if (selected?.id === 'dc') {
                setFilterParams({ dc: '1' });
              } else if (selected?.id === 'acdc') {
                setFilterParams({ acdc: '1' });
              }
            }}
            isClearable={true}
          />
        </div>
      </div>

      <div className={styles.filterContainer2}>
        <Pill
          dataCy='pills_public'
          selectedClass={colorStyles.components.rateType.public}
          unselectedClass={colorStyles.components.rateTypeInvert.public}
          selected={filterParams.public === '1'}
          onClick={() => setFilterParams({ public: filterParams.public === '1' ? undefined : '1' })}
        >
          {_t('Public Service')}
        </Pill>

        <Pill
          dataCy='pills_online'
          selectedClass={colorStyles.components.stationStatus.online.active}
          unselectedClass={colorStyles.components.stationStatus.online.inactive}
          selected={filterParams.online === '1'}
          onClick={() => (filterParams.online === '1' ? setFilterParams({ online: undefined }) : setFilterParams({ online: '1' }))}
        >
          {_t('Online')}
        </Pill>

        <Pill
          dataCy='pills_offline'
          selectedClass={colorStyles.components.stationStatus.offline.active}
          unselectedClass={colorStyles.components.stationStatus.offline.inactive}
          selected={filterParams.offline === '1'}
          onClick={() => (filterParams.offline === '1' ? setFilterParams({ offline: undefined }) : setFilterParams({ offline: '1' }))}
        >
          {_t('Offline')}
        </Pill>

        <Pill
          dataCy='pills_defective'
          selectedClass={colorStyles.components.stationStatus.failure.active}
          unselectedClass={colorStyles.components.stationStatus.failure.inactive}
          selected={filterParams.failure === '1'}
          onClick={() => (filterParams.failure === '1' ? setFilterParams({ failure: undefined }) : setFilterParams({ failure: '1' }))}
        >
          {_t('Fehlerhaft')}
        </Pill>
        <Pill
          dataCy='pills_notInOp'
          selectedClass={colorStyles.components.stationStatus.notInOperation.active}
          unselectedClass={colorStyles.components.stationStatus.notInOperation.inactive}
          selected={filterParams.notInOperation === '1'}
          onClick={() => (filterParams.notInOperation === '1' ? setFilterParams({ notInOperation: undefined }) : setFilterParams({ notInOperation: '1' }))}
        >
          {_t('Nicht in Betrieb')}
        </Pill>
      </div>
    </div>
  );
}
