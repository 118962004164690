import classNames from 'classnames';
import { Fragment, ReactNode, useCallback, useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { ApiBasedContent, PageBody, PageLayout, PageTitle } from '../../../components/page-layout';
import { useApi } from '../../../hooks/useApi';
import api from '../../../services/api';
import styles from './deployment-overview.page.module.scss';

interface ProjectUiItem {
  id: number;
  title: string;
  devPipelineStatus: string;
  masterPipelineStatus: string;
  devToMasterDiff: number;
  masterToDevDiff: number;
  loadingDetails: boolean;
}
export default function DeploymentOverviewPage() {
  const [projectItems, setProjectItems] = useState<ProjectUiItem[]>([]);
  const [fetchedProjects, fetching, fetchErr] = useApi({
    call: async () => {
      const resp = api.devTools.listDeploymentProjects();
      return resp;
    },
    map: (resp) => {
      if (!resp) return;
      const mapped = resp
        .map((item): ProjectUiItem => {
          return { devToMasterDiff: -1, id: item.projectId, title: item.title, loadingDetails: true, masterToDevDiff: -1, devPipelineStatus: '', masterPipelineStatus: '' };
        })
        .sort((a, b) => a.title.localeCompare(b.title));
      setProjectItems(mapped);
      return resp;
    },
  });

  const updateProjectItems = useCallback(() => {
    console.log('update projects');
    projectItems.forEach(async (item) => {
      const details = await api.devTools.listDeploymentProjectDetail(item.id);
      setProjectItems((current) => {
        current = current.filter((item) => item.id !== details.data.projectId);
        const dto = details.data;
        current.push({
          devToMasterDiff: dto.developToMasterChanges,
          masterToDevDiff: dto.masterToDevelopChanges,
          title: dto.title,
          id: dto.projectId,
          loadingDetails: false,
          devPipelineStatus: dto.devPipelineStatus,
          masterPipelineStatus: dto.masterPipelineStatus,
        });

        current = current.sort((a, b) => a.title.localeCompare(b.title));
        // localStorage.setItem('CACHED_VM', JSON.stringify(current));
        return current;
      });
    });
  }, [fetchedProjects]);

  useEffect(() => {
    updateProjectItems();
  }, [updateProjectItems]);

  useInterval(updateProjectItems, 10000);

  return (
    <PageLayout className={styles.root}>
      <PageTitle>GitLab Projects</PageTitle>
      <ApiBasedContent customErrorMsg={(e) => e.message} fetching={fetching} resp={projectItems} err={fetchErr}>
        {() => (
          <>
            <PageBody>
              <div className={styles.projectGrid}>
                {projectItems.map((item) => {
                  return (
                    <div key={item.id} className={styles.gridItem}>
                      <h4>{item.title}</h4>
                      <div className={styles.branchesRow}>
                        {item.loadingDetails && <i>loading ...</i>}
                        {!item.loadingDetails && (
                          <>
                            {(() => {
                              const createStat = (changes: number, pipelineStatus: string, from: string, to: string) => {
                                let rows: ReactNode[] = [];
                                let status = styles.branchStatusUnknown;
                                if (changes === -1) {
                                  rows.push(<span>could not get branch information</span>);
                                } else if (changes === 0 && pipelineStatus === 'success') {
                                  rows.push(
                                    <span>
                                      <b>{changes}</b> changes behind <i>{to}</i>
                                    </span>
                                  );
                                  rows.push(
                                    <span>
                                      pipeline <b className={styles[pipelineStatus]}>{pipelineStatus}</b>
                                    </span>
                                  );
                                  status = styles.branchStatusSuccess;
                                } else if (changes === 0 && pipelineStatus !== 'success') {
                                  rows.push(
                                    <span>
                                      <b>{changes}</b> changes behind <i>{to}</i>
                                    </span>
                                  );
                                  rows.push(
                                    <span>
                                      pipeline <b className={styles[pipelineStatus]}>{pipelineStatus}</b>
                                    </span>
                                  );
                                  status = styles.branchStatusWarn;
                                } else if (changes !== 0 && pipelineStatus) {
                                  rows.push(
                                    <span>
                                      <b>{changes}</b> changes behind <i>{to}</i>
                                    </span>
                                  );
                                  rows.push(
                                    <span>
                                      pipeline <b className={styles[pipelineStatus]}>{pipelineStatus}</b>
                                    </span>
                                  );
                                  status = styles.branchStatusAlert;
                                }

                                return (
                                  <div className={classNames(styles.statusRow, status)}>
                                    <div className={styles.branch}>{from}</div>
                                    <div className={styles.stats}>
                                      {rows.map((r, i) => (
                                        <Fragment key={i}>{r}</Fragment>
                                      ))}
                                      {/* <span>{changes} changes behind <b>{to}</b></span> */}
                                      {/* <span>current commit deployed</span> */}
                                    </div>
                                  </div>
                                );
                              };

                              // const mapStat = (changes: number, from: string, to: string) => {
                              //   let apperance: StateApperances = 'undef';
                              //   let displayText = 'could not get branch information'
                              //   if (changes > 0) {
                              //     apperance = 'alert'
                              //     displayText = `${from} => ${to} ${changes} changes`;
                              //   } else if (changes === 0) {
                              //     apperance = 'success';
                              //     displayText = `${from} => ${to} ${changes} changes`;
                              //   }
                              //   return { apperance, displayText };
                              // }
                              const devToMaster = createStat(item.devToMasterDiff, item.devPipelineStatus, 'develop', 'master');
                              const masterToDev = createStat(item.masterToDevDiff, item.masterPipelineStatus, 'master', 'develop');

                              return (
                                <>
                                  {devToMaster}
                                  {masterToDev}
                                </>
                              );
                            })()}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </PageBody>
          </>
        )}
      </ApiBasedContent>
    </PageLayout>
  );
}
