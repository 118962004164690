import classNames from 'classnames';
import styles from './info.module.scss';

import { ReactNode, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { ReactComponent as InfoIco } from '../assets/info.svg';

export default function Info(props: { content: ReactNode; dataCy?: string }) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <>
      <Popover
        isOpen={popoverOpen}
        onClickOutside={() => setPopoverOpen(false)}
        containerStyle={{ zIndex: '1000' }}
        align={'center'}
        positions={['right', 'left', 'bottom', 'top']} // preferred positions by priority
        padding={5}
        content={() => {
          return (
            <div className={classNames(styles.content)} data-cy={props.dataCy}>
              {props.content}
            </div>
          );
        }}
      >
        <div
          className={styles.icon}
          onClick={(e) => {
            e.preventDefault();
            setPopoverOpen(!popoverOpen);
          }}
          data-cy='infAdditionalInfo'
        >
          <InfoIco />
        </div>
      </Popover>
    </>
  );
}

export function LabelWithInfo(label: string, info: ReactNode, dataCy?: string) {
  return (
    <div>
      {label}
      <Info content={info} dataCy={dataCy}></Info>
    </div>
  );
}
