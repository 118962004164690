import EditPoup, { EditPopupRow } from '../../../../components/edit-popup';
import Input from '../../../../components/input';
import useFormState from '../../../../hooks/useFormState';
import { ClientHierarchyNodeTypeDto, ClientStructureDto } from '../../../../services/api-client/csp-api';
import useEffectAsync from '../../../../utils/useEffectAsync';
import useBetterTranslate from '../../../../utils/translation-utils';
import styles from './structure-edit-node-popup.module.scss';
import { NodeInfo } from '../structure-edit-hierarchy-popup/structure-edit-hierarchy-popup';
import { ReactComponent as DeleteRoleIco } from '../../../../assets/trash.svg';
import api from '../../../../services/api';

export default function StructureEditNodePopup(props: {
  open: boolean;
  org?: ClientStructureDto;
  node?: NodeInfo;
  onCancel: () => void;
  onSubmit: (node: NodeInfo) => Promise<void>;
  onDelete: () => void;
  autoCreated: boolean;
}) {
  const { _t } = useBetterTranslate('structure-edit-node-popup');

  // Form state
  const formState = useFormState();
  const resetForm = formState.reset;

  const isLocationOrRegion = props.node?.type === ClientHierarchyNodeTypeDto.Region || props.node?.type === ClientHierarchyNodeTypeDto.Location;

  const nodeTypeTitle =
    props.node?.type === ClientHierarchyNodeTypeDto.Region
      ? _t('Gebiet')
      : props.node?.type === ClientHierarchyNodeTypeDto.Location
      ? _t('Standort')
      : props.node?.type === ClientHierarchyNodeTypeDto.Area
      ? _t('Bereich')
      : '';

  const nodeTypeTitleWithArticle =
    props.node?.type === ClientHierarchyNodeTypeDto.Region
      ? _t('das Gebiet')
      : props.node?.type === ClientHierarchyNodeTypeDto.Location
      ? _t('den Standort')
      : props.node?.type === ClientHierarchyNodeTypeDto.Area
      ? _t('den Bereich')
      : '';

  //title
  const [titleField, setTitle] = formState.useInputState({
    label: '',
    placeholder: _t('Namen eintippen'),
    maxLength: 1000,
    initialValue: '',
    validate: async (val) => {
      if (!val) return _t('Bitte geben Sie eine Bezeichnung für {{nodeTypeTitleWithArticle}} ein.', { nodeTypeTitleWithArticle });
      val = val.trim();
      if (val.length === 0) {
        return _t('Bitte geben Sie eine Bezeichnung für {{nodeTypeTitleWithArticle}} ein.', { nodeTypeTitleWithArticle });
      }
      if (val.length > 30) {
        return _t('Die Bereichsbezeichnung darf maximal 30 Zeichen lang sein.');
      }
      if (props.org) {
        const nodes = [...props.org?.accessableNodes, ...props.org?.forbiddenParents];
        const duplicatedNode = nodes.find(
          (node) => node.title === val && node.code !== props.node?.code && node.parentCode === props.node?.parentCode && node.type === props.node?.type
        );
        if (duplicatedNode) {
          return _t('Diese Bezeichnung ist in diesem Knoten bereits vergeben.');
        }
      }
    },
  });

  //cpmsId
  const [cpmsIdField, setCpmsId] = formState.useInputState({
    label: '',
    placeholder: _t('Entity-ID'),
    maxLength: 1000,

    initialValue: '',
    validate: async (val) => {
      if (!isLocationOrRegion) {
        return;
      }
      if (!val) return _t('Es muss eine Entity-ID angegeben werden');
      val = val.trim();
      if (val.length === 0) {
        return _t('Es muss eine Entity-ID angegeben werden');
      }
      if (props.org) {
        const exists = await (await api.orgStructure.cpmsIdExists(props.org.code, { cpmsId: val, nodeCodeToExclude: props.node?.code || '' })).data;
        if (exists) {
          return _t('Diese Entity-ID ist bereits vergeben.');
        }
      }
    },
  });

  useEffectAsync(async () => {
    if (props.open) {
      resetForm();
      if (props.node?.code) {
        //edit mode
        setTitle(props.autoCreated ? '' : props.node.title);
        setCpmsId(props.node.cpmsId || '');
      }
    }
  }, [props.open]);

  //submission
  formState.handleSubmit(async () => {
    if (!props.node) return; //should never happen
    props.node.title = titleField.value.trim();
    if (isLocationOrRegion) {
      props.node.cpmsId = cpmsIdField.value.trim();
    } else {
      props.node.cpmsId = undefined;
    }
    await props.onSubmit(props.node);
  });

  return (
    <EditPoup
      open={props.open}
      className={styles.popup}
      onClose={props.onCancel}
      onSave={async () => await formState.submit()}
      additionalFooterContentClassName={styles.submitError}
      additionalFooterContent={formState.submitError}
      bodyClassName={styles.body}
      title={props.node?.code ? _t('{{nodeTypeTitle}} bearbeiten', { nodeTypeTitle }) : _t('{{nodeTypeTitle}} erstellen', { nodeTypeTitle })}
    >
      <EditPopupRow label={titleField.label}>
        <Input {...titleField} />
      </EditPopupRow>
      {isLocationOrRegion && (
        <EditPopupRow label={cpmsIdField.label}>
          <Input {...cpmsIdField} />
        </EditPopupRow>
      )}
      {props.node?.code && !props.autoCreated && (
        <div className={styles.deleteNode} onClick={props.onDelete}>
          <DeleteRoleIco />
          <span>{_t('Knoten löschen')}</span>
        </div>
      )}
    </EditPoup>
  );
}
