import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../components/button';
import { mapItems } from '../../../../components/org-hierarchy/org-diagramm-utils';
import OrgHierarchyDiagramm, { OrgHierarchyDiagrammSettings, useOrgHiearchyDiagramm } from '../../../../components/org-hierarchy/org-hierarchy-diagramm';
import { ApiBasedContent, PageBody, PageDesc, PageFooter, PageLayout, PageTitle } from '../../../../components/page-layout';
import { useApi } from '../../../../hooks/useApi';
import { useLocationState } from '../../../../hooks/useBetterNavigate';
import api from '../../../../services/api';
import { ClientDto, ClientHierarchyNodeTypeDto } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import styles from './preview-client-structure.page.module.scss';
// import { useLocation } from 'react-use';
// import { useLocation } from 'react-router-dom';

export interface PreviewClientStructurePageProps {
  className?: string;
}

export default function PreviewClientStructurePage(props: PreviewClientStructurePageProps) {
  const { _t } = useBetterTranslate(`preview-client-structure-page`);
  const navigate = useNavigate();
  const { affiliateCode } = useParams();

  // could be the case that the state is empty
  // for example when the user get redirected to gigya
  const clientDto = useLocationState<ClientDto>('client');

  // than we are just going pack to the client list page
  useEffect(() => {
    if (!clientDto) {
      navigate(`/administration/clients/${affiliateCode}`);
    }
  }, [clientDto, affiliateCode, navigate]);

  const [preview, fetching, err] = useApi(
    {
      call: async (clientDto?: ClientDto) => {
        if (!clientDto) return;
        const res = await api.client.createHiearchyPreview({
          affiliateCode: clientDto.affiliateCode,
          clientCode: clientDto.code,
          cpmsId: clientDto.cpmsId!,
          clientTitle: clientDto.title,
        });
        return res;
      },
      map: (data) => data,
    },
    clientDto
  );

  const diagSettings = useMemo(() => {
    const settings: OrgHierarchyDiagrammSettings = {
      diagramProps: {
        readonly: true,
        className: styles.diagram,
        nodesSelectable: false,
      },
      clientRootChangable: false,
    };

    return settings;
  }, []);

  const [diagProps, diagCtx] = useOrgHiearchyDiagramm(diagSettings);

  const onStartImportClick = () => {
    if (!clientDto) return;
    if (!preview) return;

    navigate(`/administration/clients/${clientDto.affiliateCode}/create`, { state: { client: clientDto } });
  };

  useEffect(() => {
    if (!preview) return;
    if (!diagCtx) return;

    const mapped = mapItems(preview.hierarchy, preview.hierarchy.find((p) => p.type === ClientHierarchyNodeTypeDto.ClientRoot)!.code, true);
    diagCtx.setItems(mapped);
    diagCtx.fitToView(100);
  }, [diagCtx, preview]);

  return (
    <PageLayout className={classNames(styles.root, props.className)}>
      <PageTitle>{_t(`Import client hierarchy`)}</PageTitle>
      <PageDesc>{_t(`Please validate the imported hierarchy.`)}</PageDesc>
      <ApiBasedContent resp={preview} err={err} fetching={fetching}>
        {(resp) => (
          <>
            <PageBody>
              <OrgHierarchyDiagramm {...diagProps} />
            </PageBody>
            <PageFooter>
              <Button onClick={() => navigate(-1)} kind='accent'>
                {_t(`Cancel`)}
              </Button>
              <Button onClick={() => onStartImportClick()} kind='primary'>
                {_t(`Continue`)}
              </Button>
            </PageFooter>
          </>
        )}
      </ApiBasedContent>
    </PageLayout>
  );
}
