import classNames from 'classnames';
import { WorkloadLevel } from '../../services/api-client/csp-api';
import ReactTooltip from 'react-tooltip';
import colorStyles from '../style-utils';
import { translate } from '../../i18n';

function getWorkloadStatusColorStyle(level: WorkloadLevel) {
  return level === WorkloadLevel.High
    ? colorStyles.components.workloadStatus.high.active
    : level === WorkloadLevel.Medium
    ? colorStyles.components.workloadStatus.medium.active
    : level === WorkloadLevel.Low
    ? colorStyles.components.workloadStatus.low.active
    : colorStyles.components.rateType.unknown;
}

export default function ClusterWorkLoadBox(props: { workloadLevel: WorkloadLevel; className?: string }) {
  const { workloadLevel, className } = props;

  const _t = translate('cluster-workload-box');
  const getTooltipText = () =>
    `${_t('Low: Keine Einschränkung der Ladeleistung')} <br />\
     ${_t('Medium: Aktuelle Ladeleistung nahe am gesetzten Limit')} <br />\
     ${_t('High: Ladeleistung von Ladevorgängen reduziert')}`;

  return workloadLevel ? (
    <div>
      <ReactTooltip id={'workload-tooltip-id'} place={'left'} type={'light'} html={true} />
      <div
        className={classNames(className, getWorkloadStatusColorStyle(workloadLevel as WorkloadLevel))}
        data-id='workload-tooltip-id'
        data-for='workload-tooltip-id'
        data-tip={getTooltipText()}
      >
        {workloadLevel.toLocaleUpperCase()}
      </div>
    </div>
  ) : (
    <div></div>
  );
}
