import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as InformationIco } from '../../../../assets/information.svg';
import EditPoup, { EditPopupRow } from '../../../../components/edit-popup';
import Input from '../../../../components/input';
import SingleSelect from '../../../../components/select/single-select';
import useFormState from '../../../../hooks/useFormState';
import api from '../../../../services/api';
import { AdminDto, SystemAdminUserType } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import useEffectAsync from '../../../../utils/useEffectAsync';
import styles from './admin-create-popup.module.scss';

export default function AdminCreatePopup(props: { open: boolean; mail?: string; close: () => void; onSubmit: () => void }) {
  const { _t } = useBetterTranslate('admin-create-popup');

  const [userOfCurrentMailAddress, setUserOfCurrentMailAddress] = useState<AdminDto>();

  // Form state
  const formState = useFormState();
  const resetForm = formState.reset;

  // User
  const [orgUser, setOrgUser] = useState<AdminDto>();

  //first name
  const [firstNameField, setFirstName] = formState.useInputState({
    label: _t('Vorname'),
    placeholder: 'Max',
    maxLength: 128,

    initialValue: '',
    validate: async (val) => {
      if (!val) return _t('Dieses Feld darf nicht leer sein.');
      val = val.trim();
      if (val.length === 0) {
        return _t('Dieses Feld darf nicht leer sein.');
      }
      if (val.length > 128) {
        return _t('Vorname darf nicht länger als 128 Zeichen sein.');
      }
    },
  });

  //last name
  const [lastNameField, setLastName] = formState.useInputState({
    label: _t('Nachname'),
    placeholder: 'Mustermann',
    maxLength: 128,

    initialValue: '',
    validate: async (val) => {
      if (!val) return _t('Dieses Feld darf nicht leer sein.');
      val = val.trim();
      if (val.length === 0) {
        return _t('Dieses Feld darf nicht leer sein.');
      }
      if (val.length > 128) {
        return _t('Nachname darf nicht länger als 128 Zeichen sein.');
      }
    },
  });

  const languages = useMemo(
    () => [
      { name: _t('English'), id: 'en' },
      { name: _t('German'), id: 'de' },
      { name: _t('French'), id: 'fr' },
    ],
    [_t]
  );
  const [langField, setLangField] = formState.useFieldState({
    initialValue: languages[0],
    label: _t('Language'),
    dataCy: 'language',
  });

  //mail
  const [mailField, setMail] = formState.useInputState({
    label: _t('E-Mail'),
    placeholder: 'Max.Mustermann@beispielmail.com',
    type: 'email',
    maxLength: 254,

    initialValue: '',
    validate: async (val) => {
      if (!val) {
        if (!!userOfCurrentMailAddress) {
          setUserOfCurrentMailAddress(undefined);
          resetForm();
        } else {
          return _t('Dieses Feld darf nicht leer sein.');
        }
      }

      val = val.trim();
      if (val.length > 254) {
        return _t('E-Mail darf nicht länger als 254 Zeichen sein.');
      }

      if (!/^[\S._%+-]+@[\S.-]+\.[A-Za-z]{2,}$/i.test(val)) {
        return _t('Ungültige E-Mail-Adresse.');
      }

      if (orgUser && orgUser.mail === val) {
        return;
      }

      const existingUser = await api.systemAdmins.getUserWithType(mailField.value);
      if (existingUser.data.userType === SystemAdminUserType.SYSTEM_ADMIN) return _t('E-Mail ist bereits vergeben.');
      else if (existingUser.data.userType === SystemAdminUserType.NO_SYSTEM_ADMIN) {
        if (existingUser.data.admin) {
          setFirstName(existingUser.data.admin.firstName);
          setLastName(existingUser.data.admin.lastName);
          setLangField(languages.find((l) => l.id === existingUser?.data?.admin?.defaultLanguageCode) || languages[0]);
          setUserOfCurrentMailAddress(existingUser.data.admin);
        }
      }
    },
  });

  //load data before open
  const [showPopup, setShowPopup] = useState(props.open);
  const onOpen = useCallback(async () => {
    resetForm();
    if (props.mail) {
      //edit mode
      const user = (await api.systemAdmins.getUser(props.mail)).data;
      setOrgUser(user);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setMail(user.mail);
      setLangField(languages.find((l) => l.id === user.defaultLanguageCode) || languages[0]);
    } else {
      setOrgUser(undefined);
    }
  }, [resetForm, setFirstName, setLastName, setLangField, languages, setMail, props.mail]);

  useEffectAsync(async () => {
    if (props.open) {
      await onOpen();
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [props.open]);

  //submission
  formState.handleSubmit(async () => {
    let user: AdminDto;
    if (!!userOfCurrentMailAddress) {
      user = {
        firstName: userOfCurrentMailAddress.firstName,
        lastName: userOfCurrentMailAddress.lastName,
        defaultLanguageCode: userOfCurrentMailAddress.defaultLanguageCode,
        mail: userOfCurrentMailAddress.mail,
      };
    } else {
      user = {
        firstName: firstNameField.value.trim(),
        lastName: lastNameField.value.trim(),
        mail: mailField.value.trim(),
        defaultLanguageCode: langField.value.id,
      };
    }

    if (!!userOfCurrentMailAddress) {
      await api.systemAdmins.makeToAdmin(user.mail);
    } else if (props.mail) {
      await api.systemAdmins.updateUser(user);
    } else {
      await api.systemAdmins.addUser(user);
    }
    props.onSubmit();
    props.close();
  });

  return (
    <EditPoup
      open={showPopup}
      className={styles.popup}
      onClose={props.close}
      onSave={async () => await formState.submit()}
      additionalFooterContentClassName={styles.submitError}
      additionalFooterContent={formState.submitError}
      bodyClassName={styles.popupBody}
      title={props.mail ? _t('Administrator Editieren') : _t('Administrator Erstellen')}
      saveText={props.mail ? _t('Speichern') : _t('Erstellen')}
    >
      <EditPopupRow label={mailField.label} controlCassName={props.mail ? styles.editEmail : ''}>
        {!props.mail && <Input {...mailField} label='' onBlur={() => mailField.validate()} />}
        {props.mail && <div>{props.mail}</div>}
      </EditPopupRow>

      <EditPopupRow label={firstNameField.label}>
        <Input {...firstNameField} label='' onBlur={() => firstNameField.validate()} />
      </EditPopupRow>

      <EditPopupRow label={lastNameField.label}>
        <Input {...lastNameField} label='' onBlur={() => lastNameField.validate()} />
      </EditPopupRow>

      <EditPopupRow label={langField.label} className={styles.langRow} validationErr={langField.validationErr}>
        <SingleSelect
          className={styles.inputField}
          options={languages}
          fetchOptions={async (v) => {
            return languages.filter((l) => l.name.toLowerCase().includes(v.toLowerCase()));
          }}
          createOption={(opt) => <div>{opt.name}</div>}
          renderDisplayValue={(v) => v.name}
          selectedValue={langField.value}
          onChanged={(v) => setLangField(v || languages[0])}

          // createOption={() => }
        />
      </EditPopupRow>

      <EditPopupRow label=' ' className={styles.passwordRow}>
        {!props.mail && (
          <div className={styles.info}>
            <InformationIco />
            <span>
              {!!userOfCurrentMailAddress
                ? _t('Der Benutzer existiert bereits und erhält Administratorrechte. Um den Namen des Benutzers zu ändern, nutzen Sie die Editierfunktion.')
                : _t('Nach dem Erstellen, erhält der Benutzer eine automatisierte E-mail zur Passwortvergabe.')}
            </span>{' '}
          </div>
        )}
      </EditPopupRow>
    </EditPoup>
  );
}
