import classNames from 'classnames';
import Switch from 'react-switch';
import styles from './switch-toggle.module.scss';

export function SwitchToggle(props: { className?: string; onChange?: (val: boolean) => void; checked: boolean; disabled?: boolean }) {
  let innerProps = { ...props };
  innerProps.className = classNames(innerProps.className, styles.root, props.checked ? styles.check : styles.uncheck);

  return (
    <Switch
      {...innerProps}
      onChange={innerProps.onChange || (() => {})}
      uncheckedIcon={false}
      checkedIcon={false}
      handleDiameter={16}
      height={12}
      width={28}
      // size={10}
      activeBoxShadow={`#df5c5c 0px 0px 2px 3px`}
      title={'foo'}
    />
  );
}
