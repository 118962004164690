import classNames from 'classnames';
import { useState } from 'react';
import Button from '../../components/button';
import DataTable from '../../components/data-table/data-table';
import { FormCard, FormCardBody } from '../../components/form-card';
import Page, { ListingCardPlaceholder } from '../../components/page';
import { useApi } from '../../hooks/useApi';
import api from '../../services/api';
import { MsvcRecord } from '../../services/api-client/csp-api';
import styles from './dev-versions-page.module.scss';

interface MsvcCompareRecords {
  msvc: string;
  leftEnv: string;
  rightEnv: string;
  leftVersion: string;
  rightVersion: string;

  /***
   * true if left is newer than right
   */
  newer: boolean;
}

export default function DevVersionsPage() {
  // const [readyFile, setReadyFile] = useState();
  // const [errorData, setErrorData] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [compareModel, setCompareModel] = useState<MsvcCompareRecords[] | null>(null);

  // var [myCounterValue, setCounterValue] = useState(0);

  /**
   * Get health check records from the microservices.
   */
  const [record, devFetching, devErr] = useApi({
    call: () => api.devTools.listVersions(),
    map: (res) => res?.records,
  });

  /**
   * Copy Data from Table as JSON
   */
  const copyDataTable = async () => {
    if (!record) return;

    await navigator.clipboard.writeText(JSON.stringify(record));
    setIsCopied(true);
  };

  const onUserResetClicked = () => {
    setCompareModel(null);
    setIsCopied(false);
  };

  /**
   * Paste Data from Table as JSON
   */
  const pasteDataTable = async () => {
    if (!record) return;

    const text = await navigator.clipboard.readText();
    if (!text) return;

    const rightSide = JSON.parse(text) as MsvcRecord[];
    const leftSide = [...record];

    const result: MsvcCompareRecords[] = [];
    for (const left of leftSide) {
      let msvc = result.find((m) => m.msvc === left.msvc);
      if (!msvc) {
        msvc = { leftEnv: left.environment, leftVersion: left.version, msvc: left.msvc, newer: true, rightEnv: '?', rightVersion: '?' };
        result.push(msvc);
      }

      const right = rightSide.find((r) => r.msvc === left.msvc);
      if (right) {
        msvc.rightEnv = right.environment;
        msvc.rightVersion = right.version;
        msvc.newer = left.version.localeCompare(right.version) >= 0;
      }
    }

    // only if not exist on right side
    for (const right of rightSide) {
      let msvc = result.find((m) => m.msvc === right.msvc);
      if (!msvc) {
        msvc = { rightEnv: right.environment, rightVersion: right.version, msvc: right.msvc, newer: false, leftEnv: '?', leftVersion: '?' };
        result.push(msvc);
      }
    }

    setCompareModel(result);
  };

  return (
    <Page
      breadCrumb={[{ title: 'DevTools', href: '/dev-tools', active: true }]}
      fetching={devFetching}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={devErr}
    >
      <>
        <div className={styles.titleValue}>
          <h4>Microservice Overview</h4>
        </div>

        <FormCard className={styles.filterCard} tabletSize='full'>
          <FormCardBody className={styles.toolbar}>
            {compareModel === null && (
              <>
                <Button kind='primary' onClick={copyDataTable}>
                  {isCopied ? 'Copied!' : 'Copy Model'}
                </Button>
                <Button kind='accent' onClick={pasteDataTable}>
                  Paste Model
                </Button>
              </>
            )}
            {compareModel !== null && (
              <Button kind='danger' onClick={onUserResetClicked}>
                Reset
              </Button>
            )}
          </FormCardBody>
        </FormCard>

        <FormCard tabletSize='full'>
          <FormCardBody className={styles.gridCardBody}>
            {compareModel ? (
              <DataTable
                sticky={true}
                records={compareModel}
                renderer={{
                  cols: [
                    {
                      headerCol: () => <div>Microservice Name</div>,
                      name: 'msvc',
                      headerClass: styles.statusCol,
                      sumClass: styles.statusCol,
                      col: (record) => <div className={classNames(styles.statusCol, record.newer ? styles.inactiveCol : styles.activeCol)}>{record.msvc}</div>,
                      colClass: (record) => classNames(styles.statusCol, record.newer ? styles.inactiveRow : styles.activeRow),
                      width: '30%',
                    },
                    {
                      headerCol: () => <div className='asd asdasd'>Left Env</div>,
                      name: 'left-env',
                      col: (item) => item.leftEnv,
                      width: '10%',
                    },
                    {
                      headerCol: () => <div>Left Version</div>,
                      name: 'left-version',
                      col: (item) => item.leftVersion,
                      width: '25%',
                    },
                    {
                      headerCol: () => <div>Right Env</div>,
                      name: 'right-env',
                      col: (item) => item.rightEnv,
                      width: '10%',
                    },
                    {
                      headerCol: () => <div>Right Version</div>,
                      name: 'right-version',
                      col: (item) => item.rightVersion,
                      width: '25%',
                    },
                    // {
                    //   headerCol: () => <div>Environment</div>,
                    //   name: 'environment',
                    //   col: (readyFile) => readyFile.environment,
                    //   width: '33%',
                    // },
                  ],
                }}
              />
            ) : (
              <DataTable
                sticky={true}
                records={record || []}
                renderer={{
                  cols: [
                    {
                      headerCol: () => <div>Microservice Name</div>,
                      name: 'msvc',
                      col: (record) => record.msvc,
                      width: '30%',
                    },
                    {
                      headerCol: () => <div>Version</div>,
                      name: 'version',
                      col: (record) => record.version,
                      width: '30%',
                    },
                    {
                      headerCol: () => <div>Environment</div>,
                      name: 'environment',
                      col: (record) => record.environment,
                      width: '15%',
                    },
                    {
                      headerCol: () => <div>Msg Count</div>,
                      name: 'msgCount',
                      col: (record) => (record.msgCount === -1 ? 'No Queue' : record.msgCount),
                      width: '10%',
                    },
                    {
                      headerCol: () => <div>Consumer Count</div>,
                      name: 'consumerCount',
                      col: (record) => (record.consumerCount === -1 ? 'No Queue' : record.consumerCount),
                      width: '10%',
                    },
                  ],
                }}
              />
            )}
          </FormCardBody>
        </FormCard>
      </>
    </Page>
  );
}
