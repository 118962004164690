import useBetterTranslate from '../utils/translation-utils';
import styles from './role-permissions-popup.module.scss';
import EditPopup, { EditPopupRowUsingDiv } from './edit-popup';
import { PermissionsGroup } from '../pages/administration/roles/role-create-popup/permissions-groups';
import { RoleType } from '../services/api-client/csp-api';
import colorStyles from './style-utils';
import NavigatableCategories, { NavigatableCategory } from '../components-v2/navigatable-categories';

export default function RolePermissionsPopup(props: {
  open: boolean;
  onClose: () => void;
  permissionsGroups: PermissionsGroup[];
  roleType?: RoleType | undefined;
  roleName?: string;
}) {
  const { _t } = useBetterTranslate('role-permissions-popup');

  const getRoleTypeText = (type: RoleType | undefined) => {
    if (type === RoleType.Root) return _t('Root');
    if (type === RoleType.Standard) return _t('Standard');
    if (type === RoleType.Individual) return _t('Individual');
    return type;
  };

  const getRoleTypeColorStyle = (type: RoleType) => {
    if (type === RoleType.Root) return colorStyles.components.rateType.public;
    if (type === RoleType.Standard) return colorStyles.components.rateType.work;
    if (type === RoleType.Individual) return colorStyles.components.rateType.employee;
    return colorStyles.components.rateType.unknown;
  };

  const mapPermissionGroupToCategory = (group: PermissionsGroup): NavigatableCategory => {
    return {
      id: group.code,
      title: group.title,
      items: group.items.map((i) => ({ id: i.value, title: i.title })),
    };
  };

  return (
    <EditPopup
      title={
        <>
          <div className={styles.titleArea}>
            <div className={styles.titleText}>{_t('Granted permissions with this role')}</div>
            <div className={getRoleTypeColorStyle(props.roleType as RoleType)}>{getRoleTypeText(props.roleType)}</div>
          </div>
          <div className={styles.roleName}>
            <span>{props.roleName}</span>
          </div>
        </>
      }
      showCancel={false}
      open={props.open}
      onClose={props.onClose}
      // closeOnDocumentClick={true}
    >
      <EditPopupRowUsingDiv>
        <NavigatableCategories className={styles.content} categories={props.permissionsGroups.map((g) => mapPermissionGroupToCategory(g))}></NavigatableCategories>
      </EditPopupRowUsingDiv>
    </EditPopup>
  );
}
