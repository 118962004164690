import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { ReactComponent as BackButton } from '../../assets/back.svg';
import { ReactComponent as StationIco } from '../../assets/charging-station-blue.svg';
import { ReactComponent as PlugIco } from '../../assets/plug-blue.svg';
import Pagingation from '../../components-v2/pagination/pagination';
import Accordion from '../../components/accordion/accordion';
import { TIME_FILTER_KEYS, TimeFilterKey } from '../../components/chart-filter';
import ClusterDiagramsPanel from '../../components/cluster/cluster-diagrams-panel';
import ClusterWorkLoadBox from '../../components/cluster/cluster-workload-box';
import { FormCard, FormCardBody } from '../../components/form-card';
import IconExplanationSection from '../../components/icon-explanation-section';
import ChargePointAppliedTags from '../../components/infrastructure/chargepoint-applied-tags';
import ChargepointFilterArea from '../../components/infrastructure/chargepoint-filter-area';
import ChargepointListItem from '../../components/infrastructure/chargepoint-list-item';
import { ChargePointFilterParameters } from '../../components/infrastructure/filter-area-parameters.dto';
import { getPlugTypeOptions } from '../../components/outlet-type-icon';
import Page, { ListingCardPlaceholder } from '../../components/page';
import StationStatusSummaryPanel from '../../components/station-status-summary-panel/station-status-summary-panel';
import TabPanel, { Tab, Tabs } from '../../components/tab-panel';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { createQueryString, usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import { WorkloadLevel } from '../../services/api-client/csp-api';
import { dateRangeValidator, validateString } from '../../utils/queryParamValidators';
import useBetterTranslate from '../../utils/translation-utils';
import ChargePointRemoteActions, { useChargePointRemoteActions } from '../stations/chargepoint-remote-actions';
import styles from './clusters-detail-page.module.scss';
import { getTimeRange } from './clusters-util';

export default function ClusterChargePointsDetailPage(props: {}) {
  const { _t } = useBetterTranslate('clusters-detail-stations-page');
  const navigate = useBetterNavigate();

  const MIN_DATE = useMemo(() => moment().subtract(6, 'month').startOf('day'), []);
  const MAX_DATE = useMemo(() => moment().endOf('day'), []);

  type FilterParameters = {
    from?: string;
    to?: string;
    skip?: number | null;
    limit?: number | null;
    clientCode?: string;
    nodes?: string[];
    selectedTimeFilter?: TimeFilterKey;
  } & ChargePointFilterParameters;
  const setFilterParams = (filter: FilterParameters) => {
    const { ...params } = filter;
    _setInnerFilterParams({ ...params });
  };
  const setNewFilterParams = (filter: FilterParameters) => {
    // Set all filter params to undefined except the passed ones
    let property: keyof typeof filterParams;
    const undefinedFilterParams: FilterParameters = {};
    for (property in filterParams) {
      undefinedFilterParams[property] = undefined;
    }
    _setInnerFilterParams({
      ...undefinedFilterParams,
      ...filter,
    });
  };
  const { clusterId = '' } = useParams();
  const validators = { ...dateRangeValidator(MIN_DATE, MAX_DATE), selectedTimeFilter: validateString(TIME_FILTER_KEYS) };

  const defaultFrom = moment().subtract(1, 'day').startOf('minute').toISOString();
  const defaultTo = moment().startOf('minute').toISOString();
  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>(
    {
      from: defaultFrom,
      to: defaultTo,
      selectedTimeFilter: '24h',
    },
    validators
  );
  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const updateSearchTerm = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };
  const [hasExternalHardware, setHasExternalHardware] = useState(false);
  const [hasPublicStations, setHasPublicStations] = useState(false);

  const [clusterResp, , clusterApiErr] = useApi(
    {
      call: async (clientCode?: string, clusterId?: string, from?: string, to?: string, selectedTimeFilter?: TimeFilterKey) => {
        const timeRange = getTimeRange(selectedTimeFilter || '24h', from, to);
        const res = await api.clusterDetail.getClusterDetails({
          clientCode: clientCode || '',
          clusterId: clusterId || '',
          from: timeRange.from,
          to: timeRange.to,
        });
        return res;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    clusterId,
    filterParams.from,
    filterParams.to,
    filterParams.selectedTimeFilter
  );

  const [summaryResp, , summaryApiErr] = useApi(
    {
      call: async (clientCode?: string, clusterId?: string) => {
        if (!clientCode) return undefined;
        return await api.clusterDetail.getSummary({
          client: clientCode,
          hierarchyNodeCodes: [clientCode],
          clusterId: clusterId,
        });
      },
      map: (data) => {
        if (!data) return data;
        const stationsTotal = data.summary.stationsOnline + data.summary.stationsOffline + data.summary.stationsFailure + data.summary.stationsNotInOperation;
        const connectorsTotal =
          data.summary.connectorsAvailable +
          data.summary.connectorsCharging +
          data.summary.connectorsUnavailable +
          data.summary.connectorsUnknown +
          data.summary.connectorsFailure +
          data.summary.connectorsOccupied;
        const totalEnergyConsumption = data.summary.stationsAssumedEnergy;
        return {
          clientContext: data.clientContext,
          totalEnergyConsumption: totalEnergyConsumption,
          stationsSeries: {
            online: data.summary.stationsOnline,
            offline: data.summary.stationsOffline,
            failures: data.summary.stationsFailure,
            notInOperation: data.summary.stationsNotInOperation,
            total: stationsTotal,
          },
          connectorsSeries: {
            available: data.summary.connectorsAvailable,
            charging: data.summary.connectorsCharging,
            unavailable: data.summary.connectorsUnavailable,
            unknown: data.summary.connectorsUnknown,
            occupied: data.summary.connectorsOccupied,
            failure: data.summary.connectorsFailure,
            total: connectorsTotal,
          },
        };
      },
    },
    filterParams.clientCode,
    clusterId
  );

  const [chargePointsResp, chargePointsFetching, chargePointsApiErr, chargePointsRefresh] = useApi(
    {
      call: async (
        clientCode?: string,
        skip?: number | null,
        limit?: number | null,
        plugTypes?: string[],
        ac?: string,
        dc?: string,
        available?: string,
        charging?: string,
        occupied?: string,
        failure?: string,
        unavailable?: string,
        unknown?: string,
        query?: string,
        clusterId?: string,
        isPublic?: string
      ) => {
        if (!clientCode) return undefined;

        const res = await api.clusterDetail.listClusterChargepoints({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          client: clientCode,
          outletTypes:
            plugTypes?.filter((type) =>
              getPlugTypeOptions()
                .map((option) => option.name)
                .includes(type)
            ) || [],
          ac: ac === '1',
          dc: dc === '1',
          available: available === '1',
          charging: charging === '1',
          occupied: occupied === '1',
          failure: failure === '1',
          unavailable: unavailable === '1',
          unknown: unknown === '1',
          searchText: query || undefined,
          hideNotInOperation: true,
          clusterId: clusterId,
          isPublic: isPublic === '1' ? true : undefined,
        });
        setHasExternalHardware(!!res.data.chargePoints.find((c) => c.isExternalHardware));
        setHasPublicStations(!!res.data.chargePoints.find((c) => c.isPublic));
        return res;
      },
      map: (data) => {
        if (!data) return data;
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.skip,
    filterParams.limit,
    filterParams.plugTypes,
    filterParams.ac,
    filterParams.dc,
    filterParams.available,
    filterParams.charging,
    filterParams.occupied,
    filterParams.failure,
    filterParams.unavailable,
    filterParams.unknown,
    filterParams.search,
    clusterId,
    filterParams.public
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const remoteActions = useChargePointRemoteActions({
    refreshRequested: () => chargePointsRefresh(),
    remoteStop: {
      do: async (cp) => (await api.clusterDetail.remoteStopSession({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
    },
    remoteStart: {
      do: async (cp, card) =>
        (await api.clusterDetail.remoteStartSession({ cardExtId: card.extId, cardNumber: card.id, chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
      searchCards: async (q) => (await api.clusterDetail.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteReserve: {
      do: async (cp, card, minutes) =>
        (await api.clusterDetail.remoteReserve({ cardExtId: card.extId, cardNumber: card.id, chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId, minutes: minutes })).data,
      searchCards: async (q) => (await api.clusterDetail.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteCancelReserve: {
      do: async (cp) => (await api.clusterDetail.remoteReserveCancel({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
      searchCards: async (q) => (await api.clusterDetail.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteUnlock: {
      do: async (cp) => (await api.clusterDetail.remoteUnlock({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
    },
    remoteChangeAvailability: {
      do: async (cp, av) => (await api.clusterDetail.remoteChangeChargePointAvailability({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId, type: av })).data,
    },
  });

  const navigateToClusterListPage = (filter: {}) => {
    const link = `/loadmanagement/${filterParams.clientCode}`;
    navigate(link);
  };

  const navigateToClusterDetailStationsPage = (filter: { online?: number; offline?: number; failure?: number; notInOperation?: number }) => {
    const link = `/loadmanagement/${filterParams.clientCode}/cluster-stations/${clusterId}`;
    navigate(link, filter);
  };

  const [expandEnergyPanel, setExpandEnergyPanel] = useState<boolean>(true);
  const [expandEnergyGraph, setExpandEnergyGraph] = useState<boolean>(true);

  return (
    <Page
      breadCrumb={[{ title: 'LASTMANAGEMENT', href: '/loadmanagement', active: true }]}
      fetching={chargePointsFetching}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={clusterApiErr || summaryApiErr || chargePointsApiErr}
      outOfPlaceHolder={
        <>
          {/* Context popups */}
          <ChargePointRemoteActions {...remoteActions.chargePointRemoteActionsProps} />
          {clusterResp && clusterResp.clusterCount > 1 && (
            <div className={classNames(styles.clusterOverviewLink, styles.textLink)} onClick={navigateToClusterListPage}>
              <BackButton /> <span>{_t('Zur Cluster Übersicht')}</span>
            </div>
          )}
          {summaryResp && (
            <>
              <FormCard tabletSize='full'>
                <Accordion
                  expanded={expandEnergyPanel}
                  onExpand={() => setExpandEnergyPanel(!expandEnergyPanel)}
                  headline={
                    clusterResp &&
                    clusterResp.cluster && (
                      <div className={styles.headline}>
                        <div className={styles.label}>{`${_t('Lastmanagement')} ${clusterResp.cluster.displayName}`}</div>
                        <ClusterWorkLoadBox workloadLevel={clusterResp.cluster.workload as WorkloadLevel} className={styles.workload} />
                      </div>
                    )
                  }
                >
                  <StationStatusSummaryPanel
                    connectors={{
                      overallTotal: summaryResp.connectorsSeries.total,
                      total: summaryResp.connectorsSeries.total,
                      available: summaryResp.connectorsSeries.available,
                      charging: summaryResp.connectorsSeries.charging,
                      failure: summaryResp.connectorsSeries.failure,
                      occupied: summaryResp.connectorsSeries.occupied,
                      unavailable: summaryResp.connectorsSeries.unavailable,
                      unknown: summaryResp.connectorsSeries.unknown,
                      onAvailableClicked: () => setNewFilterParams({ available: '1' }),
                      onChargingClicked: () => setNewFilterParams({ charging: '1' }),
                      onOccupiedClicked: () => setNewFilterParams({ occupied: '1' }),
                      onUnavailableClicked: () => setNewFilterParams({ unavailable: '1' }),
                      onUnknownClicked: () => setNewFilterParams({ unknown: '1' }),
                      onFailureClicked: () => setNewFilterParams({ failure: '1' }),
                    }}
                    stations={{
                      overallTotal: summaryResp.stationsSeries.total,
                      total: summaryResp.stationsSeries.total,
                      failures: summaryResp.stationsSeries.failures,
                      offline: summaryResp.stationsSeries.offline,
                      online: summaryResp.stationsSeries.online,
                      notInOperation: summaryResp.stationsSeries.notInOperation,
                      onOnlineClicked: () => navigateToClusterDetailStationsPage({ online: 1 }),
                      onOfflineClicked: () => navigateToClusterDetailStationsPage({ offline: 1 }),
                      onFailureClicked: () => navigateToClusterDetailStationsPage({ failure: 1 }),
                      onNotInOperationClicked: () => navigateToClusterDetailStationsPage({ notInOperation: 1 }),
                    }}
                  />
                </Accordion>
              </FormCard>
            </>
          )}
          {clusterResp && clusterResp.cluster && (
            <FormCard tabletSize='full'>
              <ClusterDiagramsPanel
                expanded={expandEnergyGraph}
                cluster={clusterResp.cluster}
                historicalData={clusterResp.historicalData}
                onExpand={() => setExpandEnergyGraph(!expandEnergyGraph)}
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
                onChangeDate={_setInnerFilterParams}
                selectedTimeFilter={filterParams.selectedTimeFilter}
              />
            </FormCard>
          )}
          {(hasExternalHardware || hasPublicStations) && (
            <FormCard phoneSize='full'>
              <FormCardBody>
                <IconExplanationSection showExternalHardware={hasExternalHardware} showPublicStations={hasPublicStations} />
              </FormCardBody>
            </FormCard>
          )}
          <FormCard phoneSize='full'>
            <TabPanel>
              <Tabs>
                <Tab txt={_t('Ladestationen')} onClick={() => navigateToClusterDetailStationsPage({})} ico={<StationIco />}></Tab>
                <Tab active={true} txt={_t('Ladepunkte')} ico={<PlugIco />}></Tab>
              </Tabs>
            </TabPanel>
          </FormCard>
          <FormCard phoneSize='full' className={classNames(styles.tabContent)}>
            <ChargepointFilterArea
              filterParams={filterParams}
              searchTerm={searchTerm}
              setFilterParams={setFilterParams}
              updateSearchTerm={updateSearchTerm}
            ></ChargepointFilterArea>
          </FormCard>
          <FormCard phoneSize='full' className={classNames(styles.selectedTags, styles.tabContent)}>
            <ChargePointAppliedTags plugTypes={filterParams.plugTypes || []} setFilterParams={setFilterParams} show={{ ac: !!filterParams.ac, dc: !!filterParams.dc }} />
          </FormCard>
        </>
      }
    >
      <div className={classNames(styles.stationListContainer, styles.tabContent)}>
        {chargePointsResp &&
          chargePointsResp.chargePoints.map((cp, i) => {
            const sessionsQuery = createQueryString({ entity: [{ id: cp.chargeBoxId, type: 'station' }] });
            const sessionsLink = `/${chargePointsResp?.sessionsPagePrefix}-charging-sessions/${filterParams.clientCode}?${sessionsQuery}`;
            const stationsPath = `/loadmanagement/${filterParams.clientCode}/cluster-stations/${cp.clusterId}`;
            const hasRemoteActions = remoteActions.hasChargePointRemoteActions(cp);
            return (
              <ChargepointListItem
                key={i}
                item={cp}
                allNodes={[...(chargePointsResp?.clientContext.accessableNodes || []), ...(chargePointsResp?.clientContext.forbiddenParents || [])]}
                sessionsLink={sessionsLink}
                stationsPath={stationsPath}
                clientCode={filterParams.clientCode}
                loadManagementLinkActive={true}
                hasContextIcons={hasRemoteActions}
                onClickRemoteActionButton={(ev) => remoteActions.showChargePointRemoteActionContextMenu(ev, cp)}
                hasChangeCustomNamePopup={false}
                onClickChangeCustomName={() => {}}
              ></ChargepointListItem>
            );
          })}
      </div>

      {chargePointsResp && (
        <Pagingation
          className={classNames(styles.pagination, styles.tabContent)}
          skip={chargePointsResp.skip}
          limit={chargePointsResp.limit}
          total={chargePointsResp.total}
          onChange={(arg) => {
            setFilterParams({
              skip: arg.skip <= 0 ? null : arg.skip,
              limit: arg.limit,
            });
          }}
        />
      )}
    </Page>
  );
}
