import classNames from 'classnames';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InfoIco } from '../../../../assets/info.svg';
import Badge from '../../../../components-v2/badge/badge';
import { roleTypeColor } from '../../../../components-v2/business-components/domain-mappings';
import ButtonV2, { ButtonCancel, ButtonV2Primary } from '../../../../components-v2/button';
import Ico from '../../../../components-v2/ico';
import InputV2, { FormContainerV2 } from '../../../../components-v2/input';
import { Page, PageContent, PageHeader, PageTitle } from '../../../../components-v2/page/page';
import Box, { ContentBox, Divider } from '../../../../components-v2/utils';
import CheckBox from '../../../../components/input-check-box';
import RolePermissionsPopup from '../../../../components/role-permissions-popup';
import { replaceHistoryState, useLocationState } from '../../../../hooks/useBetterNavigate';
import useFormState, { formValidator } from '../../../../hooks/useFormState';
import { usePageParams } from '../../../../hooks/usePageParams';
import api from '../../../../services/api';
import { ClientCpmsIdCheck, ClientDto, ClientIndividualRoleDto, RoleType } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import useEffectAsync from '../../../../utils/useEffectAsync';
import { PermissionsGroupsOfRole, getPermissionGroupStructure } from '../../roles/role-create-popup/permissions-groups';
import styles from './manage-client.page.module.scss';

interface RoleItem {
  id: string;
  name: string;
  selected: boolean;
  type: RoleType;
}
export interface ManageClientPageProps {
  className?: string;
}

type FilterParameters = {
  clientCode?: string;
  affiliateCode?: string;
};

export default function ManageClientPage(props: ManageClientPageProps) {
  const { _t } = useBetterTranslate(`manage-client-page`);
  const navigate = useNavigate();
  const clientFromState = useLocationState<ClientDto>('client', { default: undefined });

  const [filterParams] = usePageParams<FilterParameters>({}, {});

  const emptyClient: ClientDto = { affiliateCode: filterParams.affiliateCode || '', code: '', standardRoleIds: [], title: '', cpmsId: '', individualRoles: [] };
  const [clientDto, setClientDto] = useState<ClientDto>(clientFromState || emptyClient);

  const { affiliateCode, clientCode } = filterParams;

  const isNew = filterParams.clientCode === undefined;

  const formState = useFormState();
  const [titleField, setTitle] = formState.useInputState({
    label: _t('Kundenname'),
    placeholder: _t('Firmennamen hinzufügen'),
    maxLength: 100,

    initialValue: '',
    validate: async (val) => {
      let err: string | undefined = '';
      if ((err = formValidator.notEmpty(val, _t('Dieses Feld darf nicht leer sein.')))) return err;
      if ((err = formValidator.maxLen(100, val, _t('Kundenname darf nicht länger als 100 Zeichen sein.')))) return err;
      if (clientDto.title === val) return;

      const exist = await api.client.titleExists(filterParams.affiliateCode || '', { title: val });
      if (exist.data) return _t(`Kundenname ist bereits vergeben.`);
    },
  });
  const [codeField, setCode] = formState.useInputState({
    label: _t('Code'),
    labelInfo: _t('Der Code wird als eindeutige Kennung im System verwendet.'),
    dataCy: 'infCode',
    placeholder: _t('Code'),
    maxLength: 10,

    initialValue: '',
    validate: async (val) => {
      if (!isNew) return;
      let err: string | undefined = '';
      if ((err = formValidator.notEmpty(val, _t('Dieses Feld darf nicht leer sein.')))) return err;
      if ((err = formValidator.maxLen(100, val, _t('Code darf nicht länger als 10 Zeichen sein.')))) return err;
      if (val === 'ROOT') return _t(`Code ist bereits vergeben.`); //reserved word

      const code = `${affiliateCode}-${val}`;
      const exist = await api.client.codeExists(affiliateCode!, { code });
      if (exist.data) return _t(`Code ist bereits vergeben.`);
    },
    transform: (val) => {
      if (!val) return val;
      return val.replace(/[^a-z0-9]/gi, '').toUpperCase();
    },
  });

  const [cpmsField, setCpms] = formState.useInputState({
    label: _t('Unternehmen-ID'),
    labelInfo: _t('Unternehmen-ID muss die korrekte Firmen-ID von HTB oder die Org-Id von EVP sein, um die richtige Hierarchie zu übertragen.'),
    dataCy: 'infCompanyId',
    placeholder: _t('Unternehmen-ID'),
    maxLength: 50,

    initialValue: '',
    validate: async (val) => {
      let err: string | undefined = '';
      if ((err = formValidator.notEmpty(val, _t('Dieses Feld darf nicht leer sein.')))) return err;
      const response = await api.client.cpmsIdValidate(affiliateCode!, { cpmsId: val });
      if (response.data.result === ClientCpmsIdCheck.ALREADY_USED_BY_ANOTHER_CLIENT) {
        return _t(`Unternehmen-ID ist bereits vergeben.`);
      } else if (response.data.result === ClientCpmsIdCheck.NOT_FOUND_AT_ANY_CPMS) {
        return _t(`Unternehmen-ID ist weder in HTB noch in EVP vorhanden.`);
      }
    },
    transform: (val) => {
      if (!val) return val;
      return val.trim();
    },
  });

  //roles
  const [stdRolesField, setStdRoles] = formState.useFieldState<RoleItem[]>({
    label: _t('Rollen'),
    labelInfo: _t('Wählen Sie die Rollen, die Sie diesem Unternehmen hinzufügen möchten.'),
    dataCy: 'infRoles',
    initialValue: [],
    validate: async (val) => {
      if (indRoles.length === 0 && !val.find((item) => item.selected)) {
        if (!isNew) {
          return _t('Um die Änderung am Unternehmen zu speichern, müssen Sie mindestens eine Rolle auswählen.');
        }
        return _t('Um das Unternehmen zu erstellen, müssen Sie mindestens eine Rolle auswählen.');
      }
    },
  });
  const [unselectedStdRolesField, setUnselectedStdRolesField] = formState.useFieldState<string[]>({
    label: '',
    initialValue: [],
  });

  const [indRoles, setIndRoles] = useState<ClientIndividualRoleDto[]>([]);

  const toggleRoleItem = (role: RoleItem) => {
    role.selected = !role.selected;
    if (role.selected) {
      setUnselectedStdRolesField(unselectedStdRolesField.value.filter((item) => item !== role.id));
    } else if (clientDto.standardRoleIds.find((r) => r === role.id)) {
      setUnselectedStdRolesField([...unselectedStdRolesField.value, role.id]);
    }

    stdRolesField.onChange([...stdRolesField.value]);
  };

  useEffect(() => {
    if (!clientDto) return;
    setTitle(clientDto.title);
    let code = clientDto.code || '';
    // remove affiliate from code
    if (code.indexOf(`-`) >= 0) {
      code = code.split(`-`)[1];
    }
    setCode(code);
    setCpms(clientDto.cpmsId || '');
    setIndRoles(clientDto.individualRoles || []);

    // setIndRoles(clientObj.individualRoles || []);
  }, [clientDto, setTitle, setCode, setCpms]);

  useEffectAsync(async () => {
    if (!affiliateCode) return;

    const res = await api.client.listRoles({ type: RoleType.Standard, affiliateCode: affiliateCode });
    const roleItems: RoleItem[] = res.data.map((role) => {
      return { id: role.id || '', name: role.name, selected: false, type: role.type };
    });

    let client = clientDto;
    if (clientCode) {
      const clientObj = (await api.client.get(affiliateCode!, clientCode)).data;
      client = clientObj;
      setClientDto(clientObj);
    }

    for (const roleItem of roleItems) {
      if (client.standardRoleIds.includes(roleItem.id)) {
        roleItem.selected = true;
      }
    }

    setStdRoles(roleItems);
  }, [affiliateCode, clientCode]);

  const [currentPermissionGroupOfRole, setCurrentPermissionGroupOfRole] = useState<PermissionsGroupsOfRole>();
  const onRolesPermissionsClick = async (role: RoleItem) => {
    const res = await api.client.getPermissionsOfRole(filterParams.affiliateCode || '', role.id);
    const permissionGroups = getPermissionGroupStructure(res.data.permissions);
    setCurrentPermissionGroupOfRole({ roleId: role.id || '', roleName: role.name || '', roleType: role.type as RoleType, permissionsGroups: permissionGroups });
  };

  formState.handleSubmit(async () => {
    if (!affiliateCode) return;

    const standardRoleIds: string[] = [];
    stdRolesField.value?.forEach((item) => {
      if (item.selected) {
        standardRoleIds.push(item.id);
      }
    });
    const code = clientCode || `${affiliateCode}-${codeField.value}`;
    let client = {
      title: titleField.value.trim(),
      code,
      affiliateCode: affiliateCode,
      cpmsId: cpmsField.value,
      standardRoleIds,
      individualRoles: indRoles,
    };
    if (clientCode) {
      // await api.client.update(client);
    } else {
      // store in state
      replaceHistoryState({ client });
      // navigate than
      navigate(`/administration/clients/${affiliateCode}/structure-import`, { state: { client } });
    }

    // console.log('SUBMIT')
  });

  // if (err) return <>ERROR</>;

  return (
    <Page className={classNames(styles.root, props.className)}>
      {/* <ApiBasedContent resp={{}} err={err} fetching={fetching}> */}
      {/* {() => ( */}
      {/* <> */}
      <PageHeader>
        <PageTitle>{isNew ? _t(`Create Client`) : _t(`Edit Client`)}</PageTitle>
      </PageHeader>

      {/* <PageDesc>{isNew ? _t(`Please type in all information to create the client.`) : _t(`Change this Client`)}</PageDesc> */}
      <PageContent>
        <Box kind={'vflex'} gap='m'>
          <Box kind={'hflex'} justify='flex-end' gap='l'>
            <ButtonCancel size='s' onClick={() => navigate(-1)} />
            {isNew && (
              <ButtonV2Primary size='s' onClick={() => formState.submit()}>
                <Box pad={['0', '400']}>{_t('Create client')}</Box>
              </ButtonV2Primary>
            )}
            {!isNew && <ButtonV2>{_t(`Save Client`)}</ButtonV2>}
          </Box>
          <ContentBox kind={'vflex'} gap='l'>
            <Box fw='700' fs={'l'}>
              {_t('1 / Client information')}
            </Box>
            <Divider kind='h' />
            <Box kind={'hgrid'} gap='l'>
              <FormContainerV2 {...titleField} renderMode='label'>
                <InputV2 {...titleField} />
              </FormContainerV2>

              {isNew && (
                <FormContainerV2 {...codeField} label={codeField.label} renderMode='label'>
                  <InputV2 {...codeField} prefix={<Box fw='700'>{affiliateCode} -</Box>} />
                </FormContainerV2>
              )}
              {!isNew && (
                <FormContainerV2 {...codeField} label={codeField.label} renderMode='label'>
                  <InputV2 {...codeField} disabled={true} prefix={<Box fw='700'>{affiliateCode} -</Box>} />
                </FormContainerV2>
              )}

              <FormContainerV2 {...cpmsField} renderMode='label'>
                <InputV2 {...cpmsField} />
              </FormContainerV2>
            </Box>
          </ContentBox>
          <ContentBox kind={'vflex'} gap='l' h='100%'>
            <Box kind={'hflex'} gap='s' align='center'>
              <Box fw='700' fs={'l'}>
                {_t('2 / Roles & Permissions')}
              </Box>
              <Divider />
              <Box fg='primary-neutral-100'>
                {stdRolesField.value.filter((r) => r.selected).length} {_t('Selected')}
              </Box>
            </Box>
            <Divider kind='h' />
            <Box kind={'vflex'} gap='m'>
              {_.chunk(stdRolesField.value, 2).map((batch, bidx) => {
                return (
                  <Box kind={'hgrid'} key={`batch_${bidx}`} gap='xl'>
                    <>
                      {batch.map((role, idx) => {
                        return (
                          <Box key={`item_${idx}`} kind={'vflex'} gap='s'>
                            <Box kind={'hflex'} gap='m' align='center'>
                              <Box fw='700' flexGrow='1'>
                                <CheckBox label={role.name} isSelected={role.selected} onChange={() => toggleRoleItem(role)} />
                              </Box>
                              <Badge fg='primary-white' bg={roleTypeColor(role.type)}>
                                {role.type}
                              </Badge>
                              <Divider kind={'v'} />
                              <Box onClick={() => onRolesPermissionsClick(role)}>
                                <Ico size='14px' fill='primary-500' file={<InfoIco />} />
                              </Box>
                            </Box>
                            <Divider kind='h' />
                          </Box>
                        );
                      })}
                    </>
                    {batch.length === 1 && <Box></Box>}
                  </Box>
                );
              })}
            </Box>
          </ContentBox>
        </Box>
      </PageContent>

      {/* </> */}
      {/* )} */}
      {/* </ApiBasedContent> */}

      {/* if (fetching) return <LoadingSpinner />;
        return (
          <>

          </>
        ); */}
      <RolePermissionsPopup
        open={!!currentPermissionGroupOfRole?.roleId}
        permissionsGroups={currentPermissionGroupOfRole?.permissionsGroups || []}
        onClose={() => {
          setCurrentPermissionGroupOfRole(undefined);
        }}
        roleType={currentPermissionGroupOfRole?.roleType}
        roleName={currentPermissionGroupOfRole?.roleName}
      />
    </Page>
  );
}
