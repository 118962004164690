import { StringMap } from 'i18next';
import { useTranslation } from 'react-i18next';
import i18n, { isLOLalizeModeEnabled } from '../i18n';

// T returns a func with 16 possible overloads
// this makes it hard

/**
 * 't' returned by has over 16 overloads, which makes it hard to use in async functions
 * because some of them return (by type def) string|null and some only strings
 * this confuse TS sometimes and some functions where we use "t('translate')"
 * produce TS errors that string|null is not expected where only "string" should be returned
 *
 * Also: searching for "t" in code is hard if we are searching for a wrong translation
 * so we use the constant _t to translate texts
 *
 * @example:
 * const {_t} = useBetterTranslate('login-page')
 * const translated = _t('passwort');
 * const translated2 = _t('used energy is {{energy}}', {energy: 123.321} );
 *
 * @param ns namespace to be used (file name in public/locales folder)
 * @returns
 */
export default function useBetterTranslate(ns: string): { _t: (val: string, args?: StringMap) => string } {
  const { t } = useTranslation(ns);

  if (isLOLalizeModeEnabled()) {
    return { _t: (val: string, args?: StringMap) => 'LOL' };
  }
  return { _t: t };
}

export function getSupportedLanaguages() {
  return ['en', 'de', 'fr'];
}

// export async function setUiLanguage(profileLanguage: string) {
//   i18n.changeLanguage(profileLanguage);
// }

export function getBrowserLanguageCode() {
  const lang = i18n.language;
  if (!lang) return 'en';
  if (lang.length < 2) return 'en';
  return lang.substring(0, 2);
}
