import classNames from 'classnames';
import { SharedClientHierarchyNodeDto } from '../../services/api-client/csp-api';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './node-label.module.scss';

export default function NodeLabel(props: { className?: string; code: string; allNodes: SharedClientHierarchyNodeDto[] }) {
  const { _t } = useBetterTranslate('node-hierarchy-label');

  const item = props.allNodes.find((n) => n.code === props.code);
  if (!item) {
    console.error(`could not find node w. code ${props.code} in all nodes prop`);
    return <></>;
  }

  const title = item.autoCreated ? _t('Standardbereich') : item.title;

  return <span className={classNames(styles.root, props.className)}>{title}</span>;
}
