import Accordion from '../accordion/accordion';
import { ReactComponent as LmStatusIcon } from '../../assets/settings-backup-restore.svg';
import { ReactComponent as ClusterLimitIcon } from '../../assets/do-disturb-on.svg';
import { ReactComponent as StrategyIcon } from '../../assets/lightbulb.svg';
import { formatter } from '../../utils/localized-types';
import styles from './cluster-diagrams-panel.module.scss';
import useBetterTranslate from '../../utils/translation-utils';
import { ClusterDetailDto, HistoricalClusterData } from '../../services/api-client/csp-api';
import classNames from 'classnames';
import Chart from 'react-apexcharts';
import moment from 'moment';
import ChartFilterBar, { ChartTimeFilterItem, TimeFilterKey } from '../chart-filter';
import { ButtonPrimary } from '../button';
import { useState } from 'react';
import { useAuth } from '../../utils/AuthProvider';

export default function ClusterDiagramsPanel(props: {
  expanded: boolean;
  onExpand?: (expanded: boolean) => void;
  cluster: ClusterDetailDto;
  historicalData?: HistoricalClusterData;
  minDate?: moment.Moment | undefined;
  maxDate?: moment.Moment | undefined;
  onChangeDate: (val: { from?: string | undefined; to?: string | undefined; selectedTimeFilter?: TimeFilterKey }) => void;
  selectedTimeFilter?: TimeFilterKey;
}) {
  const { _t } = useBetterTranslate('cluster-diagrams-panel');
  const { expanded, onExpand, cluster, historicalData, minDate, maxDate, onChangeDate, selectedTimeFilter } = props;

  const { user } = useAuth();

  type DataPoint = [string, number];

  const [showAllPhasesView, setShowAllPhasesView] = useState<boolean>(false);

  const dataPoints: DataPoint[] =
    historicalData?.clusterLoad.values.map((item) => {
      return [item.timestamp, item.value];
    }) || [];

  const limitDataPoints: DataPoint[] =
    historicalData?.clusterLoadLimit.values.map((item) => {
      return [item.timestamp, item.value];
    }) || [];

  const locationLoadDataPointsL0: DataPoint[] =
    historicalData?.locationLoad?.L0?.values.map((item) => {
      return [item.timestamp, item.value];
    }) || [];

  const locationLoadDataPointsL1: DataPoint[] =
    historicalData?.locationLoad?.L1?.values.map((item) => {
      return [item.timestamp, item.value];
    }) || [];

  const locationLoadDataPointsL2: DataPoint[] =
    historicalData?.locationLoad?.L2?.values.map((item) => {
      return [item.timestamp, item.value];
    }) || [];

  const locationLoadDataPointsL3: DataPoint[] =
    historicalData?.locationLoad?.L3?.values.map((item) => {
      return [item.timestamp, item.value];
    }) || [];

  const locationLimitDataPointsL0: DataPoint[] =
    historicalData?.locationLoadLimit?.L0.values.map((item) => {
      return [item.timestamp, item.value];
    }) || [];

  // No need to get L2 and L3 here because they are always equal to L1
  const locationLimitDataPointsL1: DataPoint[] =
    historicalData?.locationLoadLimit?.L1.values.map((item) => {
      return [item.timestamp, item.value];
    }) || [];

  return (
    <Accordion
      className={styles.diagram}
      expanded={expanded}
      headline={
        <div className={styles.clusterConfig}>
          <div className={styles.label}>{_t('Konfiguration und Diagramme')}</div>
          <div className={styles.issue}>
            <div className={styles.icon}>
              <LmStatusIcon />
            </div>
            <div className={styles.content}>
              <div className={styles.lmStatus}>{cluster.loadManagementStatus === 'active' ? _t('AKTIVIERT') : _t('DEAKTIVIERT')}</div>
              <div className={styles.description}>{_t('Lastmanagement Status')}</div>
            </div>
          </div>
          <div className={styles.issue}>
            <div className={styles.icon}>
              <ClusterLimitIcon />
            </div>
            <div className={styles.content}>
              <div className={styles.clusterLimit}>{`${formatter.formatNumber(cluster.loadLimit?.value, user?.preferences.languageCode, 0, 0)} ${cluster.loadLimit?.unit}`}</div>
              <div className={styles.description}>{_t('Cluster Limit')}</div>
            </div>
          </div>
          <div className={styles.issue}>
            <div className={styles.icon}>
              <StrategyIcon />
            </div>
            <div className={styles.content}>
              <div className={styles.strategy}>{cluster.loadManagementStrategyGroup === 'static' ? _t('STATISCH') : _t('DYNAMISCH')}</div>
              <div className={styles.description}>{_t('Lastmanagement Strategie')}</div>
            </div>
          </div>
          {cluster.loadManagementStrategyGroup === 'dynamic' && cluster.meter && (
            <>
              <div className={styles.issue}>
                <div className={classNames(styles.icon, styles.meterData)}>
                  <ClusterLimitIcon />
                </div>
                <div className={styles.content}>
                  <div className={styles.meterData}>{`${formatter.formatNumber(cluster.meter.definedMaxSupplyLimitSum?.value, user?.preferences.languageCode, 0, 0)} ${
                    cluster.meter.definedMaxSupplyLimitSum?.unit
                  }`}</div>
                  <div className={styles.description}>{_t('Lastspitze')}</div>
                </div>
              </div>

              <div className={styles.issue}>
                <div className={classNames(styles.icon, styles.meterData)}>
                  <ClusterLimitIcon />
                </div>
                <div className={styles.content}>
                  <div>{`${formatter.formatNumber(cluster.meter.physicalSupplyLimit?.value, user?.preferences.languageCode, 0, 0)} ${
                    cluster.meter.physicalSupplyLimit?.unit
                  }`}</div>
                  <div className={styles.description}>{_t('Standort Limit')}</div>
                </div>
              </div>
            </>
          )}
        </div>
      }
      onExpand={onExpand}
    >
      <div className={styles.diagram}>
        <div className={styles.diagramTop}>
          <div>
            <div className={styles.diagramHeadline}>{_t('Leistungsverlauf Ladeinfrastruktur')}</div>
          </div>
          <ChartFilterBar className={styles.datePicker}>
            <ChartTimeFilterItem onChange={(range) => onChangeDate({ ...range })} minDate={minDate} maxDate={maxDate} selectedTimeFilter={selectedTimeFilter} />
          </ChartFilterBar>
        </div>
        {dataPoints?.length > 0 || limitDataPoints?.length > 0 ? (
          <Chart
            options={{
              chart: { type: 'line', toolbar: { show: false }, selection: { enabled: false }, zoom: { enabled: false } },
              xaxis: {
                type: 'datetime',
                labels: {
                  formatter: function (value) {
                    const date = new Date(value);
                    return formatter.formatDateTime(date, user?.preferences.languageCode);
                  },
                },
                offsetX: 25, // First date is always cutted without offset
              },
              yaxis: {
                forceNiceScale: true,
                min: 0,
                decimalsInFloat: 2,
                labels: {
                  formatter: function (value) {
                    return `${formatter.formatNumber(value, user?.preferences.languageCode, 2, 2)} kW`;
                  },
                },
              },
              stroke: { curve: 'stepline', width: 2 },
            }}
            series={[
              {
                data: dataPoints.map(([timestamp, value]): [number, number | null] => [new Date(timestamp).getTime(), value]),
                color: '#0D6EFD',
                name: _t('Cluster Auslastung'),
              },
              {
                data: limitDataPoints.map(([timestamp, value]): [number, number | null] => [new Date(timestamp).getTime(), value]),
                color: '#DDAA4D',
                name: _t('Cluster Limit'),
              },
            ]}
            type='line'
            height={400}
          />
        ) : (
          _t('Keine Werte im ausgewählten Zeitraum vorhanden')
        )}
      </div>

      {(locationLimitDataPointsL1?.length > 0 || locationLoadDataPointsL1?.length > 0) && (
        <div className={styles.diagram}>
          <div className={styles.diagramTop}>
            <div className={styles.diagramHeadline}>{_t('Leistungsverlauf Standort')}</div>
            <div>
              <ButtonPrimary onClick={() => setShowAllPhasesView(!showAllPhasesView)} className={styles.switchViewButton}>
                {showAllPhasesView ? _t('Wechsel zur Ansicht pro Phase') : _t('Wechsel zur Ansicht der Summe aller Phasen')}
              </ButtonPrimary>
            </div>
          </div>
          <Chart
            options={{
              chart: { type: 'line', toolbar: { show: false }, selection: { enabled: false }, zoom: { enabled: false } },
              xaxis: {
                type: 'datetime',
                labels: {
                  formatter: function (value) {
                    const date = new Date(value);
                    return formatter.formatDateTime(date, user?.preferences.languageCode);
                  },
                },
                offsetX: 25, // First date is always cutted without offset
              },
              yaxis: {
                forceNiceScale: true,
                min: 0,
                decimalsInFloat: 2,
                labels: {
                  formatter: function (value) {
                    return `${formatter.formatNumber(value, user?.preferences.languageCode, 2, 2)} kW`;
                  },
                },
              },
              stroke: { curve: 'stepline', width: 2 },
            }}
            series={
              showAllPhasesView
                ? [
                    {
                      data: locationLoadDataPointsL0.map(([timestamp, value]): [number, number | null] => [new Date(timestamp).getTime(), value]),
                      color: '#0D6EFD',
                      name: _t('Standort Auslastung Summe aller Phasen'),
                    },
                    {
                      data: locationLimitDataPointsL0.map(([timestamp, value]): [number, number | null] => [new Date(timestamp).getTime(), value]),
                      color: '#DDAA4D',
                      name: _t('Standort Limit Summe aller Phasen'),
                    },
                  ]
                : [
                    {
                      data: locationLoadDataPointsL1.map(([timestamp, value]): [number, number | null] => [new Date(timestamp).getTime(), value]),
                      color: '#825E1D',
                      name: _t('Standort Auslastung L1'),
                    },
                    {
                      data: locationLoadDataPointsL2.map(([timestamp, value]): [number, number | null] => [new Date(timestamp).getTime(), value]),
                      color: '#09C8A5',
                      name: _t('Standort Auslastung L2'),
                    },
                    {
                      data: locationLoadDataPointsL3.map(([timestamp, value]): [number, number | null] => [new Date(timestamp).getTime(), value]),
                      color: '#7646B2',
                      name: _t('Standort Auslastung L3'),
                    },
                    {
                      data: locationLimitDataPointsL1.map(([timestamp, value]): [number, number | null] => [new Date(timestamp).getTime(), value]),
                      color: '#DDAA4D',
                      name: _t('Standort Limit'),
                    },
                  ]
            }
            type='line'
            height={400}
          />
        </div>
      )}
    </Accordion>
  );
}
