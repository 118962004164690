import classNames from 'classnames';
import { Fragment, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '../assets/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../assets/chevron-right.svg';
import useBetterTranslate from '../utils/translation-utils';
import styles from './breadcrumb.module.scss';

export interface BreadCrumbEntry {
  title: string;
  href: string;
  active?: boolean;
  onClick?: () => boolean;
}

export function BreadCrumb(props: { className?: string; items: BreadCrumbEntry[]; noBack?: boolean; onBack?: () => void }) {
  const navigate = useNavigate();
  const { _t } = useBetterTranslate('breadcrumb');

  const onItemClicked = (item: BreadCrumbEntry, ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (item.active) return;
    if (!item.href) return;
    if (item.onClick && item.onClick()) return;

    navigate(item.href);
  };

  return (
    <div className={classNames(styles.root, props.className)}>
      {!props.noBack && (
        <Fragment>
          <a
            className={styles.back}
            href={'#'}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              if (props.onBack) props.onBack();
              else navigate(-1);
            }}
          >
            <ChevronLeftIcon />
            {_t('Back')}
          </a>
          <span className={styles.backSep}>|</span>
        </Fragment>
      )}
      {props.items.map((item, i) => {
        return (
          <Fragment key={i}>
            {i > 0 && (
              <span className={classNames(styles.separator)}>
                <ChevronRightIcon />
              </span>
            )}
            <a
              href={item.active && item.href ? item.href : '#'}
              onClick={(e) => onItemClicked(item, e)}
              key={i}
              className={classNames(styles.step, item.active ? styles.active : '')}
            >
              <span>{item.title}</span>
            </a>
          </Fragment>
        );
      })}
    </div>
  );
}
