export function percentageSave(val: number, total: number) {
  if (total === 0) return 0;
  return (val * 100) / total;
}

export function round(val: number) {
  return Math.round(val);
}

export function convertToK(val: number) {
  return val / 1000.0;
}
