import classNames from 'classnames';
import md5 from 'md5';
import Avatar from 'react-avatar';
import styles from './user-avatar.module.scss';

export default function UserAvatar(props: { className?: string; round?: boolean; mail?: string; name: string; size?: string }) {
  const hash = props.mail ? md5(props.mail) : undefined;
  const name = props.name || props.mail || '?';

  return (
    <div className={classNames(styles.root, props.className, props.size ? '' : styles.autoSize)}>
      <Avatar round={props.round} size={props.size || '80px'} md5Email={hash} name={name}></Avatar>
    </div>
  );
}
