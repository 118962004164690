import styles from './style-utils.module.scss';

const colorStyles = {
  components: {
    rateType: {
      all: `${styles.compStyle} ${styles.rateType} ${styles.all}`,
      employee: `${styles.compStyle} ${styles.rateType} ${styles.employee}`,
      home: `${styles.compStyle} ${styles.rateType} ${styles.home}`,
      work: `${styles.compStyle} ${styles.rateType} ${styles.work}`,
      unknown: `${styles.compStyle} ${styles.rateType} ${styles.unknown}`,
      public: `${styles.compStyle} ${styles.rateType} ${styles.public}`,
    },
    roleKind: {
      root: {
        base: `${styles.compStyle} ${styles.roleKind} ${styles.root}`,
        light: `${styles.compStyle} ${styles.roleKind} ${styles.root} ${styles.light}`,
      },
      client: {
        base: `${styles.compStyle} ${styles.roleKind} ${styles.client}`,
        light: `${styles.compStyle} ${styles.roleKind} ${styles.client} ${styles.light}`,
      },
    },
    rateTypeInvert: {
      employee: `${styles.invert} ${styles.compStyle} ${styles.rateType} ${styles.employee}`,
      home: `${styles.invert} ${styles.compStyle} ${styles.rateType} ${styles.home}`,
      work: `${styles.invert} ${styles.compStyle} ${styles.rateType} ${styles.work}`,
      unknown: `${styles.invert} ${styles.compStyle} ${styles.rateType} ${styles.unknown}`,
      public: `${styles.invert} ${styles.compStyle} ${styles.rateType} ${styles.public}`,
    },
    activeInactive: {
      active: `${styles.compStyle} ${styles.activeInactive} ${styles.active}`,
      inactive: `${styles.compStyle} ${styles.activeInactive} ${styles.inactive}`,
    },
    activeInactiveInvert: {
      active: `${styles.invert} ${styles.compStyle} ${styles.activeInactive} ${styles.active}`,
      inactive: `${styles.invert} ${styles.compStyle} ${styles.activeInactive} ${styles.inactive}`,
    },
    stationStatus: {
      bg: {
        total: `${styles.compStyle} ${styles.stationStatus} ${styles.total}`,
        online: `${styles.compStyle} ${styles.stationStatus} ${styles.online}`,
        offline: `${styles.compStyle} ${styles.stationStatus} ${styles.offline}`,
        failure: `${styles.compStyle} ${styles.stationStatus} ${styles.failure}`,
        notInOperation: `${styles.compStyle} ${styles.stationStatus} ${styles.notInOperation}`,
        transparent: `${styles.compStyle} ${styles.stationStatus} ${styles.transparent}`,
      },
      online: {
        active: `${styles.compStyle} ${styles.stationStatus} ${styles.online} ${styles.active}`,
        inactive: `${styles.compStyle} ${styles.stationStatus} ${styles.online} ${styles.inactive}`,
      },
      offline: {
        active: `${styles.compStyle} ${styles.stationStatus} ${styles.offline} ${styles.active}`,
        inactive: `${styles.compStyle} ${styles.stationStatus} ${styles.offline} ${styles.inactive}`,
      },
      failure: {
        active: `${styles.compStyle} ${styles.stationStatus} ${styles.failure} ${styles.active}`,
        inactive: `${styles.compStyle} ${styles.stationStatus} ${styles.failure} ${styles.inactive}`,
      },
      notInOperation: {
        active: `${styles.compStyle} ${styles.stationStatus} ${styles.notInOperation} ${styles.active}`,
        inactive: `${styles.compStyle} ${styles.stationStatus} ${styles.notInOperation} ${styles.inactive}`,
      },
      colors: {
        __define: `${styles.compStyle} ${styles.stationStatus} ${styles.define}`,
        total: `var(--total)`,
        online: `var(--online)`,
        offline: `var(--offline)`,
        failure: `var(--failure)`,
        notInOperation: `var(--notInOperation)`,
        transparent: `var(--transparent)`,
      },
    },

    connectorsStatus: {
      bg: {
        total: `${styles.compStyle} ${styles.connectorsStatus} ${styles.total}`,
        available: `${styles.compStyle} ${styles.connectorsStatus} ${styles.available}`,
        charging: `${styles.compStyle} ${styles.connectorsStatus} ${styles.charging}`,
        unavailable: `${styles.compStyle} ${styles.connectorsStatus} ${styles.unavailable}`,
        unknown: `${styles.compStyle} ${styles.connectorsStatus} ${styles.unknown}`,
        occupied: `${styles.compStyle} ${styles.connectorsStatus} ${styles.occupied}`,
        failure: `${styles.compStyle} ${styles.connectorsStatus} ${styles.failure}`,
      },
      colors: {
        __define: `${styles.compStyle} ${styles.connectorsStatus} ${styles.define}`,
        total: `var(--total)`,
        available: `var(--available)`,
        charging: `var(--charging)`,
        unavailable: `var(--unavailable)`,
        unknown: `var(--unknown)`,
        occupied: `var(--occupied)`,
        failure: `var(--failure)`,
      },
      available: {
        active: `${styles.compStyle} ${styles.connectorsStatus} ${styles.available} ${styles.active}`,
        inactive: `${styles.compStyle} ${styles.connectorsStatus} ${styles.available} ${styles.inactive}`,
        textColor: `${styles.compStyle} ${styles.connectorsStatus} ${styles.available} ${styles.textColor}`,
      },
      charging: {
        active: `${styles.compStyle} ${styles.connectorsStatus} ${styles.charging} ${styles.active}`,
        inactive: `${styles.compStyle} ${styles.connectorsStatus} ${styles.charging} ${styles.inactive}`,
        textColor: `${styles.compStyle} ${styles.connectorsStatus} ${styles.charging} ${styles.textColor}`,
      },
      failure: {
        active: `${styles.compStyle} ${styles.connectorsStatus} ${styles.failure} ${styles.active}`,
        inactive: `${styles.compStyle} ${styles.connectorsStatus} ${styles.failure} ${styles.inactive}`,
        textColor: `${styles.compStyle} ${styles.connectorsStatus} ${styles.failure} ${styles.textColor}`,
      },
      unavailable: {
        active: `${styles.compStyle} ${styles.connectorsStatus} ${styles.unavailable} ${styles.active}`,
        inactive: `${styles.compStyle} ${styles.connectorsStatus} ${styles.unavailable} ${styles.inactive}`,
        textColor: `${styles.compStyle} ${styles.connectorsStatus} ${styles.unavailable} ${styles.textColor}`,
      },
      unknown: {
        active: `${styles.compStyle} ${styles.connectorsStatus} ${styles.unknown} ${styles.active}`,
        inactive: `${styles.compStyle} ${styles.connectorsStatus} ${styles.unknown} ${styles.inactive}`,
        textColor: `${styles.compStyle} ${styles.connectorsStatus} ${styles.unknown} ${styles.textColor}`,
      },
      occupied: {
        active: `${styles.compStyle} ${styles.connectorsStatus} ${styles.occupied} ${styles.active}`,
        inactive: `${styles.compStyle} ${styles.connectorsStatus} ${styles.occupied} ${styles.inactive}`,
        textColor: `${styles.compStyle} ${styles.connectorsStatus} ${styles.occupied} ${styles.textColor}`,
      },
    },

    sessionStatus: {
      charging: `${styles.compStyle} ${styles.sessionStatus} ${styles.charging}`,
      occupied: `${styles.compStyle} ${styles.sessionStatus} ${styles.occupied}`,
      closed: `${styles.compStyle} ${styles.sessionStatus} ${styles.closed}`,
    },
    sessionStatusInvert: {
      charging: `${styles.invert} ${styles.compStyle} ${styles.sessionStatus} ${styles.charging}`,
      occupied: `${styles.invert} ${styles.compStyle} ${styles.sessionStatus} ${styles.occupied}`,
      closed: `${styles.invert} ${styles.compStyle} ${styles.sessionStatus} ${styles.closed}`,
    },
    workloadStatus: {
      high: {
        active: `${styles.invert} ${styles.compStyle} ${styles.workloadStatus} ${styles.high}`,
        inactive: `${styles.compStyle} ${styles.workloadStatus} ${styles.high}`,
      },
      medium: {
        active: `${styles.invert} ${styles.compStyle} ${styles.workloadStatus} ${styles.medium}`,
        inactive: `${styles.compStyle} ${styles.workloadStatus} ${styles.medium}`,
      },
      low: {
        active: `${styles.invert} ${styles.compStyle} ${styles.workloadStatus} ${styles.low}`,
        inactive: `${styles.compStyle} ${styles.workloadStatus} ${styles.low}`,
      },
    },
    infrastructureLevelPill: {
      active: `${styles.invert} ${styles.compStyle} ${styles.infrastructureLevelPill} ${styles.active}`,
      inactive: `${styles.compStyle} ${styles.infrastructureLevelPill} ${styles.inactive}`,
    },
  },
};

export default colorStyles;
