import colorStyles from '../components/style-utils';
import { translate } from '../i18n';

const _t = translate('domain-status');

export function getDomainStatusClassActive(domainStatus: string) {
  return domainStatus === 'available'
    ? colorStyles.components.connectorsStatus.available.active
    : domainStatus === 'charging'
    ? colorStyles.components.connectorsStatus.charging.active
    : domainStatus === 'occupied'
    ? colorStyles.components.connectorsStatus.occupied.active
    : domainStatus === 'failure'
    ? colorStyles.components.connectorsStatus.failure.active
    : domainStatus === 'notAvailable'
    ? colorStyles.components.connectorsStatus.unavailable.active
    : colorStyles.components.connectorsStatus.unknown.active;
}

export function getDomainStatusClassInactive(domainStatus: string) {
  return domainStatus === 'available'
    ? colorStyles.components.connectorsStatus.available.inactive
    : domainStatus === 'charging'
    ? colorStyles.components.connectorsStatus.charging.inactive
    : domainStatus === 'occupied'
    ? colorStyles.components.connectorsStatus.occupied.inactive
    : domainStatus === 'failure'
    ? colorStyles.components.connectorsStatus.failure.inactive
    : domainStatus === 'notAvailable'
    ? colorStyles.components.connectorsStatus.unavailable.inactive
    : colorStyles.components.connectorsStatus.unknown.inactive;
}
// 'online' | 'offline' | 'failure' | 'notInOperation';

export function stationDomainStatusTitle(domainStatus: 'online' | 'offline' | 'failure' | 'notInOperation') {
  if (domainStatus === 'online') return _t('Online');
  if (domainStatus === 'offline') return _t('Offline');
  if (domainStatus === 'failure') return _t('Failure');
  if (domainStatus === 'notInOperation') return _t('Not in operation');
  return _t('Unbekannt');
}

export function stationDomainStatusClassActive(domainStatus: 'online' | 'offline' | 'failure' | 'notInOperation') {
  if (domainStatus === 'online') return colorStyles.components.stationStatus.online.active;
  if (domainStatus === 'offline') return colorStyles.components.stationStatus.offline.active;
  if (domainStatus === 'failure') return colorStyles.components.stationStatus.failure.active;
  if (domainStatus === 'notInOperation') return colorStyles.components.stationStatus.notInOperation.active;
  return colorStyles.components.stationStatus.notInOperation.active;
}

export function getDomainStatusTitle(domainStatus: string) {
  return domainStatus === 'available'
    ? _t('Verfügbar')
    : domainStatus === 'charging'
    ? _t('Laden')
    : domainStatus === 'occupied'
    ? _t('Besetzt')
    : domainStatus === 'failure'
    ? _t('Fehlerhaft')
    : domainStatus === 'notAvailable'
    ? _t('Nicht verfügbar')
    : _t('Unbekannt');
}

export function getDomainStatusColorStyle(domainStatus: string) {
  return domainStatus === 'available'
    ? colorStyles.components.connectorsStatus.colors.available
    : domainStatus === 'charging'
    ? colorStyles.components.connectorsStatus.colors.charging
    : domainStatus === 'occupied'
    ? colorStyles.components.connectorsStatus.colors.occupied
    : domainStatus === 'failure'
    ? colorStyles.components.connectorsStatus.colors.failure
    : domainStatus === 'notAvailable'
    ? colorStyles.components.connectorsStatus.colors.unavailable
    : colorStyles.components.connectorsStatus.colors.unknown;
}

export function getDomainStatusTextColorStyle(domainStatus: string) {
  return domainStatus === 'available'
    ? colorStyles.components.connectorsStatus.available.textColor
    : domainStatus === 'charging'
    ? colorStyles.components.connectorsStatus.charging.textColor
    : domainStatus === 'occupied'
    ? colorStyles.components.connectorsStatus.occupied.textColor
    : domainStatus === 'failure'
    ? colorStyles.components.connectorsStatus.failure.textColor
    : domainStatus === 'notAvailable'
    ? colorStyles.components.connectorsStatus.unavailable.textColor
    : colorStyles.components.connectorsStatus.unknown.textColor;
}
