import classNames from 'classnames';
import _ from 'lodash';
import { FormEvent, PropsWithChildren, ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { GroupBase, OptionProps } from 'react-select';
import Popup from 'reactjs-popup';
import { ReactComponent as CloseIco } from '../assets/close.svg';
import { ReactComponent as ErrorIco } from '../assets/error.svg';
import { ReactComponent as SpinnerIco } from '../assets/spinner.svg';
import { ReactComponent as SuccessIco } from '../assets/success.svg';
import useBetterTranslate from '../utils/translation-utils';
import Button, { ButtonAccent, ButtonPrimary } from './button';
import styles from './edit-popup.module.scss';
import TagSelector, { TagOptionProp } from './tag-selector';

export function SaveBtn(props: PropsWithChildren<{ className?: string; onClick: () => void; disabled?: boolean; dataCy?: string }>) {
  const { _t } = useBetterTranslate('edit-popup');
  return (
    <ButtonPrimary submitSelector={true} autoFocus={true} onClick={props.onClick} disabled={props.disabled} className={classNames(props.className)} dataCy={props.dataCy}>
      {props.children || _t('Speichern')}
    </ButtonPrimary>
  );
}

export function CancelBtn(props: PropsWithChildren<{ className?: string; onClick: () => void; dataCy?: string }>) {
  const { _t } = useBetterTranslate('edit-popup');
  return (
    <ButtonAccent onClick={props.onClick} className={classNames(props.className)} dataCy={props.dataCy}>
      {props.children || _t('Abbrechen')}
    </ButtonAccent>
  );
}

export function EditPopupRow(
  props: PropsWithChildren<{
    className?: string;
    lblClassName?: string;
    controlCassName?: string;
    label?: ReactNode;
    validationErr?: string;
    dataCy?: { label?: string; content?: string };
  }>
) {
  return (
    <label className={classNames(props.className, styles.row)}>
      {props.label && (
        <div className={classNames(props.lblClassName, styles.lbl)} data-cy={props.dataCy?.label}>
          <span>{props.label}</span>
        </div>
      )}
      {/* {props.validationErr &&
        <span className={classNames(styles.lblValidationErr)}>{props.validationErr || ''}</span>
      } */}
      <div className={classNames(props.controlCassName, styles.ctrl)} data-cy={props.dataCy?.content}>
        {props.children}
      </div>
    </label>
  );
}

export function EditPopupRowUsingDiv(
  props: PropsWithChildren<{
    className?: string;
    lblClassName?: string;
    controlCassName?: string;
    label?: ReactNode;
    validationErr?: string;
    dataCy?: { label?: string; content?: string };
  }>
) {
  return (
    <div className={classNames(props.className, styles.row)}>
      {props.label && (
        <div className={classNames(props.lblClassName, styles.lbl)} data-cy={props.dataCy?.label}>
          <span>{props.label}</span>
        </div>
      )}
      {/* {props.validationErr &&
        <span className={classNames(styles.lblValidationErr)}>{props.validationErr || ''}</span>
      } */}
      <div className={classNames(props.controlCassName, styles.ctrl)} data-cy={props.dataCy?.content}>
        {props.children}
      </div>
    </div>
  );
}

export function useEditPopup(): [() => Promise<boolean>, { open: boolean; onClose: () => void; onSave: () => void }] {
  const [show, setShow] = useState(false);
  const [resolveState, setResolveState] = useState<(result: boolean) => void>();

  const trigger = useCallback(() => {
    setShow(true);
    const prom = new Promise<boolean>((resolve) => {
      setResolveState(() => resolve);
    });

    return prom;
  }, []);

  const onClose = () => {
    if (resolveState) resolveState(false);
    setResolveState(undefined);
    setShow(false);
  };
  const onSave = () => {
    if (resolveState) resolveState(true);
    setResolveState(undefined);
    setShow(false);
  };

  const props = { open: show, onClose: onClose, onSave: onSave };
  return [trigger, props];
}

export type EditPopupRef = {
  forceFocus: () => void;
};
export type EditPopupProps = PropsWithChildren<{
  className?: string;
  open: boolean;
  preventScroll?: boolean;
  onOpen?: () => void;
  onClose: () => void;
  title?: ReactNode;
  titleClassName?: string;
  cancelText?: string;
  showFooter?: boolean;
  showCancel?: boolean;
  closeIcon?: ReactNode;
  headerClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
  actionsClassName?: string;
  saveButtonDisabled?: boolean;
  onSave?: () => void;
  saveText?: string;
  onEnterKeyPress?: 'runOnSave' | 'none' | 'submitSelector' | (() => void);

  additionalFooterContent?: ReactNode;
  additionalFooterContentClassName?: string;

  additionalActionsContent?: ReactNode;
  btnClasses?: string;
  outerClassNames?: string;
  skipAutoFocusOnOpen?: boolean;
  // ref?: Ref<() => void>;
  onClickInside?: () => void;
  dataCy?: { root?: string; title?: string; buttonClose?: string; buttonSave?: string; buttonCancel?: string };
  closeOnDocumentClick?: boolean;
}>;
// const EditPoup22 = forwardRef<EditPopupRef, EditPopupProps>((props) => {
//   props.
//   return <></>
// });
const EditPoup = forwardRef<EditPopupRef, EditPopupProps>((props, ref) => {
  const showFooter = props.showFooter || props.showFooter === undefined;
  let showCancel = props.showCancel || props.showCancel === undefined || !!props.cancelText;

  const popupContentRef = useRef<HTMLDivElement>(null);
  const onEnter = props.onEnterKeyPress || 'runOnSave';
  const handleFormSubmit = (ev: FormEvent<any>) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (onEnter === 'none') {
      console.info('popup submit, run nothing');
      return;
    } else if (onEnter === 'runOnSave') {
      console.info('popup submit, run on save');
      props.onSave?.();
    } else if (_.isFunction(onEnter)) {
      console.info('popup submit, run custom actyion');
      onEnter();
    } else if (onEnter === 'submitSelector') {
      if (!popupContentRef.current) return;
      const submentEl = popupContentRef.current.querySelector("*[data-submit-selector='true']");
      if (submentEl && _.isFunction((submentEl as any).click)) {
        (submentEl as any).click();
        return;
      }
    } else {
      console.warn(`unexpected value for 'onEnter'`, onEnter);
    }
  };

  const runAutoFocus = useCallback(() => {
    if (!props.open) return;
    if (props.skipAutoFocusOnOpen) return;

    // try delayed set focus to an element
    setTimeout(() => {
      if (!popupContentRef.current) return;

      // first select the content
      popupContentRef.current?.focus();

      const autoFocusElement = popupContentRef.current.querySelector("*[data-auto-focus='true']");
      if (autoFocusElement && _.isFunction((autoFocusElement as any).focus)) {
        (autoFocusElement as any).focus();
        return;
      }

      const anyFirstInput = popupContentRef.current.querySelector('input');
      if (anyFirstInput) {
        anyFirstInput?.focus();
      }
    }, 300);
  }, [props.open, props.skipAutoFocusOnOpen, popupContentRef]);

  useImperativeHandle(ref, () => ({
    forceFocus: () => {
      runAutoFocus();
    },
  }));

  useLayoutEffect(() => {
    runAutoFocus();
  }, [runAutoFocus]);

  return (
    <Popup
      closeOnDocumentClick={props.closeOnDocumentClick || false}
      closeOnEscape={true}
      className={classNames(`edit-popup`, props.preventScroll ? 'no-scroll' : '')}
      lockScroll={true}
      nested={true}
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}
      position='right center'
    >
      <div className={classNames(styles.root, props.className)} onClick={props.onClickInside} data-cy={props.dataCy?.root}>
        <form onSubmit={handleFormSubmit}>
          <button onSubmit={handleFormSubmit} style={{ display: 'none' }} type='submit'></button>
          <div className={classNames(styles.header, props.headerClassName)}>
            {props.title && (
              <span className={classNames(styles.title, props.titleClassName)} data-cy={props.dataCy?.title || 'popTitle'}>
                {props.title}
              </span>
            )}
            {!props.closeIcon && (
              <button tabIndex={-1} onFocus={(e) => e.target.blur()} onClick={props.onClose} type='button' data-cy={props.dataCy?.buttonClose || 'btnClose'}>
                <CloseIco />
              </button>
            )}
            {props.closeIcon && props.closeIcon}
          </div>
          <div ref={popupContentRef} tabIndex={0} className={classNames(styles.body, props.bodyClassName)}>
            {props.children}
          </div>
          {showFooter && (
            <div className={classNames(styles.footer, props.footerClassName)}>
              {props.additionalFooterContent && <div className={classNames(styles.additionalContent, props.additionalFooterContentClassName)}>{props.additionalFooterContent}</div>}

              <div className={classNames(styles.actions, props.actionsClassName)}>
                {showCancel && (
                  <CancelBtn className={classNames(props.btnClasses)} onClick={props.onClose} dataCy={props.dataCy?.buttonCancel || 'btnCancel'}>
                    {props.cancelText}
                  </CancelBtn>
                )}
                {props.onSave && (
                  <SaveBtn className={classNames(props.btnClasses)} onClick={props.onSave} disabled={props.saveButtonDisabled} dataCy={props.dataCy?.buttonSave || 'btnSave'}>
                    {props.saveText}
                  </SaveBtn>
                )}
              </div>

              {props.additionalActionsContent && props.additionalActionsContent}
            </div>
          )}
        </form>
      </div>
    </Popup>
  );
});

export default EditPoup;

export function Step(props: PropsWithChildren<{ actions: ReactNode; bodyClassName?: string }>) {
  return (
    <div className={classNames(styles.step)}>
      <div className={classNames(styles.body, props.bodyClassName)}>{props.children}</div>
      {props.actions && <div className={classNames(styles.actions)}>{props.actions}</div>}
    </div>
  );
}
export function ProgressStep(props: { onClose?: () => void; onCancel?: () => void }) {
  const { _t } = useBetterTranslate('edit-popup');

  return (
    <Step
      bodyClassName={classNames(styles.progress)}
      actions={
        <>
          {props.onCancel && (
            <Button onClick={props.onCancel} kind='accent'>
              {_t('Abbrechen')}
            </Button>
          )}
          <Button onClick={props.onClose} kind='accent'>
            {_t('Schließen')}
          </Button>
        </>
      }
    >
      <SpinnerIco />
      <label>{_t('Bitte warten...')}</label>
    </Step>
  );
}
export function SuccessStep(props: { onClose?: () => void; text?: string }) {
  const { _t } = useBetterTranslate('edit-popup');

  return (
    <Step
      bodyClassName={classNames(styles.success)}
      actions={
        <>
          <Button submitSelector={true} autoFocus={true} onClick={props.onClose} kind='accent'>
            {_t('Schließen')}
          </Button>
        </>
      }
    >
      <SuccessIco />
      <label>{props.text || _t('Erfolgreich abgeschlossen')}</label>
    </Step>
  );
}

export function PartialSuccessStep(props: { onClose?: () => void; text?: () => ReactNode }) {
  const { _t } = useBetterTranslate('edit-popup');

  return (
    <Step
      bodyClassName={classNames(styles.partial)}
      actions={
        <>
          <Button submitSelector={true} autoFocus={true} onClick={props.onClose} kind='accent'>
            {_t('Schließen')}
          </Button>
        </>
      }
    >
      <SuccessIco />
      {props.text?.() || <label>{_t('Teilweise erfolgreich abgeschlossen')}</label>}
    </Step>
  );
}

export function FailedStep(props: { onClose?: () => void; text?: string }) {
  const { _t } = useBetterTranslate('edit-popup');

  return (
    <Step
      bodyClassName={classNames(styles.failed)}
      actions={
        <>
          <Button submitSelector={true} autoFocus={true} onClick={props.onClose} kind='accent'>
            {_t('Schließen')}
          </Button>
        </>
      }
    >
      <ErrorIco />
      <label>{props.text || _t('Fehler bei der Ausführung')}</label>
    </Step>
  );
}

export function SearchItemStep<TagType extends TagOptionProp>(props: {
  accept?: () => void;
  decline?: () => void;
  text?: string;
  wanText?: string;

  fetchOptions: (input: string) => Promise<TagType[]>;
  createOption: (val: TagType, optProps: OptionProps<TagType, true, GroupBase<TagType>>) => ReactNode;
  onChanged: (values: TagType[]) => void;

  renderSelection?: () => ReactNode;
}) {
  const { _t } = useBetterTranslate('edit-popup');
  const selection = props.renderSelection?.();
  return (
    <Step
      bodyClassName={classNames(styles.search)}
      actions={
        <>
          <Button onClick={props.decline} kind='accent'>
            {_t('Abbrechen')}
          </Button>
          <Button submitSelector={true} autoFocus={true} onClick={props.accept} kind='primary'>
            {_t('Bestätigen')}
          </Button>
        </>
      }
    >
      <TagSelector
        classNames={classNames(styles.dropdown)}
        fetchOptions={props.fetchOptions}
        selectedValues={[]}
        createOption={props.createOption}
        createTag={() => <></>}
        onChanged={props.onChanged}
        displayStyle={'search'}
      />
      {selection && <section className={classNames(styles.selection)}>{selection}</section>}

      <label>{props.text || _t('Mit diesen einstellungen weiter machen??')}</label>
      {props.wanText && <label className={classNames(styles.warn)}>{props.wanText}</label>}
    </Step>
  );
}

export function ConfirmStep(props: {
  accept?: () => void;
  decline?: () => void;
  text?: string;
  wanText?: string;
  controls?: ReactNode;
  acceptText?: string;
  declineText?: string;
}) {
  const { _t } = useBetterTranslate('edit-popup');

  return (
    <Step
      bodyClassName={classNames(styles.confirm)}
      actions={
        <>
          <Button onClick={props.decline} kind='accent'>
            {props.declineText || _t('Abbrechen')}
          </Button>
          <Button autoFocus={true} submitSelector={true} tabindex={1} onClick={props.accept} kind='primary'>
            {props.acceptText || _t('Bestätigen')}
          </Button>
        </>
      }
    >
      <label>{props.text || _t('Sind sie sich sicher dass diese Aktion ausgeführt werden soll?')}</label>
      {props.controls && props.controls}
      {props.wanText && <label className={classNames(styles.warn)}>{props.wanText}</label>}
    </Step>
  );
}

// type DefaultActionHanlderForSteps<Steps extends string> = Partial<Record<Steps, () => void>>;

// defaultActionHandler?: DefaultActionHanlderForSteps<Steps>;
export function useMultistepPopup<Steps extends string>(props: { activeStep: Steps; steps: Steps[]; onClose: () => void }) {
  const [activeStep, goto] = useState(props.activeStep);
  const [allSteps] = useState(props.steps);
  const [open, setOpen] = useState(false);

  const show = useCallback((step: Steps) => {
    goto(step);
    setOpen(true);
  }, []);

  const hide = useCallback(() => {
    setOpen(false);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
    return props.onClose();
  }, [props]);

  const componentProps = useMemo(() => {
    // let onEnterKeyPress: (() => void) | undefined;
    // if (props.defaultActionHandler?.[activeStep]) {
    //   onEnterKeyPress = props.defaultActionHandler[activeStep];
    // }

    return { onClose: onClose, open: open, allSteps: allSteps, activeStep: activeStep };
  }, [onClose, open, allSteps, activeStep]);

  const result = useMemo(() => {
    return { goto, show, hide, componentProps: componentProps, isOpen: open };
  }, [goto, show, hide, componentProps, open]);

  return result;
}

interface MultiStepSteps {
  [key: string]: ReactNode;
}

export function MultistepPopup<StepNames extends MultiStepSteps>(props: {
  className?: string;
  open: boolean;
  onClose: () => void;
  title?: ReactNode;
  titleClassName?: string;
  // onEnterKeyPress?: () => void;

  additionalFooterContent?: ReactNode;
  additionalFooterContentClassName?: string;
  preventScroll?: boolean;

  allSteps: (keyof StepNames)[];
  activeStep?: keyof StepNames;
  steps: StepNames;
}) {
  // const stepPopup = useMultistepPopup({steps: ['one', 'two'], activeStep: 'one' });

  let step: ReactNode = props.activeStep ? props.steps[props.activeStep] : <></>;
  const innerPopup = useRef<EditPopupRef>(null);
  useEffect(() => {
    innerPopup.current?.forceFocus?.();
  }, [props.activeStep]);

  return (
    <EditPoup
      ref={innerPopup}
      // forceAutoFocus={triggerAutoFocus}
      // onEnterKeyPress={props.onEnterKeyPress || 'none'}
      onEnterKeyPress={'submitSelector'}
      className={classNames(props.className)}
      preventScroll={props.preventScroll}
      // outerClassNames={props.preventScroll ? styles.noScroll : ''}
      open={props.open}
      onClose={props.onClose}
      title={props.title}
      titleClassName={props.titleClassName}
      showFooter={false}
      additionalFooterContentClassName={props.additionalFooterContentClassName}
      additionalFooterContent={props.additionalFooterContent}
    >
      {step}
    </EditPoup>
  );
}
