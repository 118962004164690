import { useState } from 'react';
import EditPoup, { EditPopupRow } from '../../../../components/edit-popup';
import useBetterTranslate from '../../../../utils/translation-utils';
import styles from './org-user-delete-popup.module.scss';

export default function OrgUserDeletePopup(props: { open: boolean; onCancel: () => void; onSubmit: () => Promise<void> }) {
  const [submitError, setSubmitError] = useState<string>();
  const { _t } = useBetterTranslate('org-user-delete-popup');

  const onSubmitWrapper = async () => {
    try {
      await props.onSubmit();
    } catch (error) {
      setSubmitError(_t('Ein unerwarteter Fehler ist aufgetreten.'));
    }
  };

  return (
    <EditPoup
      open={props.open}
      className={styles.popup}
      onClose={props.onCancel}
      onSave={async () => await onSubmitWrapper()}
      additionalFooterContentClassName={styles.submitError}
      additionalFooterContent={submitError}
      title={_t('Benutzer löschen')}
      saveText={_t('Löschen')}
    >
      <EditPopupRow className={styles.confirm}>{_t('Sind Sie sicher, dass Sie den Benutzer löschen wollen?')}</EditPopupRow>
      <EditPopupRow className={styles.alert}>{_t('Dieser Benutzer und alle seine Zugänge werden unwiederbringlich gelöscht.')}</EditPopupRow>
    </EditPoup>
  );
}
