import { ApiError, HttpResponse } from '../services/api-client/csp-api';
import { useCallback, useEffect, useState } from 'react';

import { unstable_batchedUpdates } from 'react-dom';
import useEffectAsync from '../utils/useEffectAsync';
import { v4 as uuid } from 'uuid';
import api from '../services/api';

export function useApi<Args extends any[], ApiReturn, MappedReturn>(
  props: {
    call: (...operationParameters: Args) => Promise<HttpResponse<ApiReturn, ApiError> | undefined>;
    map: (apiReturn?: ApiReturn) => MappedReturn | undefined;
  },
  // operation: (...operationParameters: Args) => Promise<HttpResponse<ApiReturn, ApiError>>,
  // mappingFunc: (apiReturn: ApiReturn) => MappedReturn,
  ...parameters: Args
): [MappedReturn | undefined, boolean, ApiError | undefined, () => void] {
  const [fetching, setFetching] = useState(true);
  const [result, setResult] = useState<MappedReturn>();
  const [apiError, setApiError] = useState<ApiError>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callCallback = useCallback(props.call, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataMapCallback = useCallback(props.map, []);

  const refresh = useCallback(async () => {
    setFetching(true);
    try {
      let res = await callCallback(...parameters);

      let mapped: MappedReturn | undefined | ApiReturn = res?.data;
      if (!res?.error) {
        mapped = dataMapCallback(res?.data);
      }

      unstable_batchedUpdates(() => {
        setResult(mapped as MappedReturn);
        setApiError(res?.error);
        setFetching(false);
      });
    } catch (e: any) {
      if (e instanceof DOMException && e.name === 'AbortError') {
        return;
      }
      if (e.error) {
        unstable_batchedUpdates(() => {
          setResult(undefined);
          setApiError(e.error);
          setFetching(false);
        });
      } else {
        throw e;
      }
    }
  }, [callCallback, dataMapCallback, parameters]);

  useEffectAsync(async () => {
    await refresh();
  }, [callCallback, ...parameters]);

  return [result, fetching, apiError, refresh];
}

export function useCancelPerviousRequest() {
  const [perviousRequestId, setPerviousRequestId] = useState<string>();
  const [lastRequestId, setLastRequestId] = useState<string>();

  useEffect(() => {
    if (perviousRequestId === lastRequestId) {
      return;
    }
    if (perviousRequestId) {
      console.log('abort', lastRequestId);
      api.abortRequest(perviousRequestId);
    }
    setPerviousRequestId(perviousRequestId);
  }, [lastRequestId, perviousRequestId]);

  return [setLastRequestId];
}

export function generateRequestId(): string {
  return uuid();
}
