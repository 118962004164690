import moment from 'moment';
import 'moment/dist/locale/de';
import 'moment/dist/locale/en-gb';
// import '../../node_modules/moment/dist/locale/de';
import { NumberParser } from '@internationalized/number';

type Locale = 'de-de' | 'en-gb' | 'fr-fr';
type LanguageCode = 'en' | 'de' | 'fr' | string | undefined;

export function getLocale(languageCode: LanguageCode): Locale {
  return languageCode === 'de' ? 'de-de' : languageCode === 'fr' ? 'fr-fr' : 'en-gb';
}

export const parser = {
  parseNumber: (val: string, languageCode: string) => {
    if (val === undefined) {
      return NaN;
    }
    const locale = getLocale(languageCode);
    const parser = new NumberParser(locale);
    const result = parser.parse(val);
    return result;
  },
  parseDate: (val: string, languageCode: string, isUtc: boolean = false) => {
    if (isUtc) {
      const result = moment.utc(val, 'L', languageCode);
      return result.toDate();
    } else {
      const result = moment(val, 'L', languageCode);
      return result.toDate();
    }
  },
  parseDateTime: (val: string) => {
    throw new Error('not implemented yet, specify a format for moment');
  },
};

export const formatter = {
  formatNumber: (val: number | undefined, languageCode?: string, minimumFractionDigits?: number, maximumFractionDigits?: number): string => {
    return typeof val === 'number' ? val.toLocaleString(getLocale(languageCode), { minimumFractionDigits, maximumFractionDigits }) : 'NaN';
  },

  formatDate: (date: Date, languageCode?: string, options?: Intl.DateTimeFormatOptions) => {
    return date.toLocaleDateString(getLocale(languageCode), options);
  },

  formatDateFromIsoString: (date: string | undefined, languageCode?: string, options?: Intl.DateTimeFormatOptions) => {
    if (!date) return '';
    return formatter.formatDate(new Date(date), languageCode, options);
  },

  formatDateTime: (date: Date, languageCode?: string, options?: Intl.DateTimeFormatOptions) => {
    return date.toLocaleString(getLocale(languageCode), options);
  },

  formatDateTimeFromIsoString: (date: string | undefined, languageCode?: string, options?: Intl.DateTimeFormatOptions) => {
    if (!date) return '';
    return formatter.formatDateTime(new Date(date), languageCode, options);
  },
};
