import classNames from 'classnames';
import { Fragment, ReactNode } from 'react';
import Box, { Divider, Spaces } from './utils';
import styles from './vertical-list.module.scss';

export interface VerticalListProps {
  className?: string;
  items: (ReactNode | false)[];
  gap?: Spaces;
  divider?: boolean;
}

export default function VerticalList(props: VerticalListProps) {
  let items = props.items.filter((item) => item !== false && item !== undefined).map((item) => item as ReactNode);
  if (props.divider) {
    items = items.flatMap((item, idx) => (idx !== items.length - 1 ? [item, <Divider kind='v' />] : [item]));
  }

  return (
    <Box align='center' kind={'hflex'} gap={props.gap} className={classNames(styles.root, props.className)}>
      {items.map((item, i) => (
        <Fragment key={i}>{item}</Fragment>
      ))}
    </Box>
  );
}
