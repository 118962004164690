import { translate } from '../../../../i18n';
import { Permission, RoleType } from '../../../../services/api-client/csp-api';
export interface PermissionItem {
  value: Permission;
  title: string;
  dependencies?: Permission[];
  description?: string;
  selected: boolean;
}

export interface PermissionsGroup {
  items: PermissionItem[];
  title: string;
  code: string;
  selectedCount: number;
  selected: boolean;
  dataCy?: string;
}

export interface PermissionsGroupsOfRole {
  roleId: string;
  roleType: RoleType;
  roleName: string;
  permissionsGroups: PermissionsGroup[];
}

export const getPermissionGroupStructure = (permissions: Permission[]): PermissionsGroup[] => {
  const allGroups = getAllPermissionsGroups();
  const affectedGroups = allGroups.filter((group) => group.items.map((item) => item.value).some((permission) => permissions.includes(permission)));
  affectedGroups.forEach((group) => {
    group.items = group.items.filter((i) => permissions.includes(i.value));
  });
  return affectedGroups;
};

export const ADMIN_PERMISSION_CODE = 'admin';
export const OPERATION_PERMISSION_CODE = 'ops';
export const CARDS_PERMISSION_CODE = 'cards';
export const ROOT_ONLY_PERMISSIONS_CODES = [ADMIN_PERMISSION_CODE, OPERATION_PERMISSION_CODE];

export const getAllPermissionsGroups = (): PermissionsGroup[] => {
  const allGroups = [
    getAdminPermissions(),
    getOperationPermissions(),
    getLoadMgmtPermissions(),
    getStationsPermissions(),
    getCardsPermissions(),
    getSessionsPermissions(),
    getOrgPermissions(),
  ];
  return allGroups;
};

export const getAllPermissionsGroupsExceptRoot = (): PermissionsGroup[] => {
  const allGroups = [getLoadMgmtPermissions(), getStationsPermissions(), getCardsPermissions(), getSessionsPermissions(), getOrgPermissions()];
  return allGroups;
};

export const getRootOnlyPermissionsGroups = (): PermissionsGroup[] => {
  return [getAdminPermissions(), getOperationPermissions()];
};

export const getDependentPermissions = (item: PermissionItem, groups: PermissionsGroup[]): PermissionItem[] => {
  if (!item.dependencies || item.dependencies.length === 0) {
    return [];
  }
  const dependencies: PermissionItem[] = [];
  groups.forEach((group) =>
    group.items.forEach((other) => {
      if (item.dependencies?.includes(other.value)) {
        dependencies.push(other);
      }
    })
  );
  return dependencies;
};

export const getPermissionItem = (permission: Permission, groups: PermissionsGroup[]) => {
  for (const group of groups) {
    const item = group.items.find((item) => item.value === permission);
    if (item) {
      return item;
    }
  }
};

const _t = translate('permissions-group');

const getStationsPermissions = (): PermissionsGroup => {
  return {
    title: _t('Ladestationen'),
    code: 'stations',
    selectedCount: 0,
    selected: false,
    items: [
      {
        value: Permission.ClientStationStatusView,
        title: _t('Zugang zu den Seiten "Standort" und "Ladevorgänge"'),
        description: _t('Ermöglicht das Einsehen der Menüpunkte "Standort" und "Ladevorgänge".'),
        selected: false,
      },
      {
        value: Permission.ClientStationListView,
        title: _t('Zugang zu der Seite "Ladeinfrastruktur"'),
        description: _t('Ermöglicht das Einsehen des Menüpunkts "Ladeinfrastruktur".'),
        selected: false,
      },
      {
        value: Permission.SupportTicketStationCreate,
        title: _t('Erstellen von Service-Tickets bezüglich Ladeinfrastruktur.'),
        description: _t('Ermöglicht es Service-Tickets über die Seite "Ladeinfrastruktur" zu erstellen.'),
        selected: false,
      },
      {
        value: Permission.ClientStationRemoteReset,
        title: _t(' Neustart einer Ladestation'),
        description: _t('Ermöglicht es auf der Seite "Ladeinfrastruktur" eine Station neu zu starten.'),
        selected: false,
      },
      {
        value: Permission.ClientStationRemoteStart,
        title: _t('Remote-Start eines Ladevorganges'),
        description: _t('Ermöglicht auf der Seite "Ladeinfrastruktur" das Starten eines Ladevorgangs.'),
        selected: false,
      },
      {
        value: Permission.ClientStationRemoteStop,
        title: _t('Remote-Stop eines Ladevorganges'),
        description: _t('Ermöglicht auf der Seite "Ladeinfrastruktur" das Stoppen eines Ladevorgangs.'),
        selected: false,
      },
      {
        value: Permission.ClientStationRemoteUnlock,
        title: _t('Entsperren eines Ladekabels'),
        description: _t('Ermöglicht es auf der Seite "Ladeinfrastruktur" das Ladekabel an einem Ladepunkt zu entsperren.'),
        selected: false,
      },
      {
        value: Permission.ClientStationRemoteReserve,
        title: _t('Reservierung eines Ladepunktes'),
        description: _t('Ermöglicht auf der Seite "Ladeinfrastruktur" einen Ladepunkt für eine bestimmte Karte für einen gewählten Zeitraum zu reservieren.'),
        selected: false,
      },
      {
        value: Permission.ClientStationRemoteChangeavailability,
        title: _t('Ent-/sperren einer Ladepunkten'),
        description: _t('Ermöglicht es auf der Seite "Ladeinfrastruktur" die Ladepunkte einer Station zu sperren/entsperren.'),
        selected: false,
      },
      {
        value: Permission.ClientStationRename,
        title: _t('Change label of charging stations/points'),
        description: _t('Allows to change the custom label of charging stations and charging points on the page "Charging Infrastructure".'),
        selected: false,
      },
      {
        value: Permission.ClientStatuslogView,
        title: _t('Zugang zur Seite "Status Log"'),
        description: _t('Ermöglicht das Einsehen des Menüpunkts "Status Log".'),
        selected: false,
      },
    ],
    dataCy: 'dgdChargingStations',
  };
};

const getCardsPermissions = (): PermissionsGroup => {
  return {
    title: _t('Ladekarten'),
    code: CARDS_PERMISSION_CODE,
    selectedCount: 0,
    selected: false,
    items: [
      {
        value: Permission.ClientCardHomeView,
        title: _t('Ladekarten mit dem Service HOME anzeigen'),
        description: _t(
          'Ladekarten mit dem Service HOME sind im Menü "Ladekarten" sichtbar und können eingesehen werden. Die Ladevorgänge dieser Karten sind im Menü "Ladevorgänge" einsehbar.'
        ),
        selected: false,
      },
      {
        value: Permission.ClientCardEmployeeView,
        title: _t('Ladekarten mit dem Service EMPLOYEE anzeigen'),
        description: _t(
          'Ladekarten mit dem Service EMPLOYEE sind im Menü "Ladekarten" sichtbar und können eingesehen werden. Die Ladevorgänge dieser Karten sind im Menü "Ladevorgänge" einsehbar.'
        ),
        selected: false,
      },
      {
        value: Permission.ClientCardWorkView,
        title: _t('Ladekarten mit dem Service WORK anzeigen'),
        description: _t(
          'Ladekarten mit dem Service WORK sind im Menü "Ladekarten" sichtbar und können eingesehen werden. Die Ladevorgänge dieser Karten sind im Menü "Ladevorgänge" einsehbar.'
        ),
        selected: false,
      },
      {
        value: Permission.ClientCardWorkEdit,
        title: _t('Ladekarten mit dem Service WORK editieren'),
        description: _t('Ermöglicht das Editieren von Ladekarten mit dem Service WORK.'),
        selected: false,
      },
      {
        value: Permission.ClientCardUndefinedView,
        title: _t('Ladekarten mit unbekanntem Service anzeigen'),
        description: _t(
          'Ladekarten mit unbekanntem Service sind im Menü "Ladekarten" sichtbar und können eingesehen werden. Die Ladevorgänge dieser Karten sind im Menü "Ladevorgänge" einsehbar.'
        ),
        selected: false,
      },
    ],
    dataCy: 'dgdChargingBadges',
  };
};

const getSessionsPermissions = (): PermissionsGroup => {
  return {
    title: _t('Ladevorgänge'),
    code: 'sessions',
    selectedCount: 0,
    selected: false,
    items: [
      {
        value: Permission.ClientSessionExportAggregation,
        title: _t('Energiemenge von Ladevorgängen aggregieren'),
        description: _t('Ermöglicht es beim Export von Ladevorgängen die Energiemenge auf Stationen, Karten und Standorte zu aggregieren.'),
        selected: false,
      },
    ],
    dataCy: 'dgdChargingRecords',
  };
};

const getLoadMgmtPermissions = (): PermissionsGroup => {
  return {
    title: _t('Lastmanagement'),
    code: 'loadmgmt',
    selectedCount: 0,
    selected: false,
    items: [
      {
        value: Permission.ClientClusterLoadMgmtView,
        title: _t('Zugang zur Seite "Lastmanagement"'),
        description: _t('Ermöglicht das Einsehen des Menüpunkts "Lastmanagement".'),
        selected: false,
      },
    ],
    dataCy: 'dgdLoadManagement',
  };
};

const getAdminPermissions = (): PermissionsGroup => {
  return {
    title: 'Administration',
    code: ADMIN_PERMISSION_CODE,
    selectedCount: 0,
    selected: false,
    items: [
      {
        value: Permission.AdminRoleView,
        title: _t('Zugang zur Registerkarte "Rollen"'),
        description: _t('Ermöglicht das Einsehen des Menüpunkts "Administration" und des Tabs "Rollen" in diesem Menü.'),
        selected: false,
      },
      {
        value: Permission.AdminRoleRootWrite,
        title: _t('Root-Rolle erstellen und editieren'),
        description: _t('Ermöglicht das Erstellen und Bearbeiten von Rollen des Typs "Root".'),
        dependencies: [Permission.AdminRoleView],
        selected: false,
      },
      {
        value: Permission.AdminRoleRootDelete,
        title: _t('Root-Rolle löschen'),
        description: _t('Ermöglicht das Löschen von Rollen des Typs "Root".'),
        dependencies: [Permission.AdminRoleView, Permission.AdminRoleRootWrite],
        selected: false,
      },
      {
        value: Permission.AdminRoleStandardWrite,
        title: _t('Standard-Rolle erstellen und editieren'),
        description: _t(
          'Ermöglicht das Erstellen und Bearbeiten von Rollen des Typs "Standard". Benutzer mit Standard-Rollen werden auf der Seite "Organisation" des jeweiligen Kunden gepflegt.'
        ),
        dependencies: [Permission.AdminRoleView],
        selected: false,
      },
      {
        value: Permission.AdminRoleStandardDelete,
        title: _t('Standard-Rolle löschen'),
        description: _t('Ermöglicht das Löschen von Rollen des Typs "Standard".'),
        dependencies: [Permission.AdminRoleView, Permission.AdminRoleStandardWrite],
        selected: false,
      },
      {
        value: Permission.AdminRoleIndividualWrite,
        title: _t('Individual-Rolle erstellen und editieren'),
        description: _t(
          'Ermöglicht das Erstellen und Bearbeiten von Rollen des Typs "Individual". Individual-Rollen werden auf der Seite "Organisation" des jeweiligen Kunden angelegt und können nur an Benutzer dieses Kunden vergeben werden.'
        ),
        dependencies: [Permission.AdminRoleView],
        selected: false,
      },
      {
        value: Permission.AdminRoleIndividualDelete,
        title: _t('Individual-Rolle löschen'),
        description: _t('Ermöglicht das Löschen von Rollen des Typs "Individual".'),
        dependencies: [Permission.AdminRoleView, Permission.AdminRoleIndividualWrite],
        selected: false,
      },
      {
        value: Permission.AdminClientView,
        title: _t('Zugang zur Registerkarte "Kunden"'),
        description: _t('Ermöglicht das Einsehen des Menüpunkts "Administration" und des Tabs "Kunden" in diesem Menü.'),
        selected: false,
      },
      {
        value: Permission.AdminClientWrite,
        title: _t('Kunden erstellen und editieren'),
        description: _t('Ermöglicht das Erstellen und Bearbeiten von Kunden.'),
        dependencies: [Permission.AdminClientView],
        selected: false,
      },
      {
        value: Permission.AdminClientDelete,
        title: _t('Kunden löschen'),
        description: _t('Ermöglicht das Löschen von Kunden.'),
        dependencies: [Permission.AdminClientView, Permission.AdminClientWrite],
        selected: false,
      },
      {
        value: Permission.AdminUserView,
        title: _t('Zugang zur Registerkarte "Benutzer"'),
        description: _t('Ermöglicht das Einsehen des Menüpunkts "Administration" und des Tabs "Benutzer" in diesem Menü.'),
        selected: false,
      },
      {
        value: Permission.AdminUserManage,
        title: _t('Benutzer erstellen, editieren und löschen'),
        description: _t('Ermöglicht das Erstellen, Bearbeiten und Löschen von Benutzern im Menü "Administration".'),
        dependencies: [Permission.AdminUserView],
        selected: false,
      },
    ],
    dataCy: 'dgdAdministration',
  };
};

const getOrgPermissions = (): PermissionsGroup => {
  return {
    title: _t('Organisation'),
    code: 'org',
    selectedCount: 0,
    selected: false,
    items: [
      {
        value: Permission.ClientOrgStructureView,
        title: _t('Die Struktur des Kunden ist auf der Seite "Organisation" einsehbar'),
        description: _t('Ermöglicht das Einsehen der Registerkarte "Struktur" auf der "Organisation"-Seite.'),
        selected: false,
      },
      {
        value: Permission.ClientOrgAreaManage,
        title: _t('Bereichs-Knoten unter Standort-Knoten erstellen und editieren'),
        description: _t('Ermöglicht das Erstellen, Bearbeiten und Löschen von Bereichs-Knoten direkt unter Standort-Knoten.'),
        selected: false,
      },
      {
        value: Permission.ClientOrgNodeManage,
        title: _t('Gebiets-/Gesellschafts-und Standort-Knoten erstellen, editieren und löschen sowie direkt angebundene Bereichs-Knoten'),
        description: _t(
          'Ermöglicht das Erstellen, Bearbeiten und Löschen von Gebiets-/Gesellschafts-und Standort-Knoten sowie von Bereichs-Knoten, die direkt an diese Knoten angebunden sind. '
        ),
        selected: false,
      },
      {
        value: Permission.ClientOrgUserManage,
        title: _t('Kunden-Benutzer erstellen und editieren'),
        description: _t('Ermöglicht das Erstellen, Bearbeiten und Löschen von Benutzern im Menü "Organisation".'),
        selected: false,
      },
      {
        value: Permission.ClientOrgCardAssign,
        title: _t('Ladekarten zuordnen'),
        description: _t('Ermöglicht das Zuordnen von Ladekarten zu Hierarchie-Knoten.'),
        selected: false,
      },
      {
        value: Permission.ClientOrgStationAssign,
        title: _t('Ladestationen zuordnen'),
        description: _t('Ermöglicht das Zuordnen von Ladestationen zu Hierarchie-Knoten.'),
        selected: false,
      },
    ],
    dataCy: 'dgdOrganisation',
  };
};

const getOperationPermissions = (): PermissionsGroup => {
  return {
    title: _t('Operations'),
    code: OPERATION_PERMISSION_CODE,
    selectedCount: 0,
    selected: false,
    items: [
      {
        value: Permission.OperationManage,
        title: _t('Zugang zur Seite "Operations"'),
        description: _t('Ermöglicht das Einsehen des Menüpunkts "Operations".'),
        selected: false,
      },
    ],
  };
};
