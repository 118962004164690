import { ReactComponent as DividerIco } from '../assets/divider.svg';
import { ReactComponent as ExternalHardwareIco } from '../assets/exclamation.svg';
import { ReactComponent as PublicStationsIco } from '../assets/public-stations.svg';
import styles from './evse-info-icons.module.scss';

export default function EvseInfoIcon(props: { ico?: React.ReactNode }) {
  return (
    <span className={styles.content}>
      <>
        <span className={styles.divider}>
          <DividerIco />
        </span>
        <span className={styles.icon}>{props.ico}</span>
      </>
    </span>
  );
}

export function ExternalHardwareIcon(props: { isExternalHardware: boolean }) {
  return props.isExternalHardware ? <EvseInfoIcon ico={<ExternalHardwareIco />} /> : <></>;
}

export function PublicStationIcon(props: { isPublic: boolean }) {
  return props.isPublic ? <EvseInfoIcon ico={<PublicStationsIco />} /> : <></>;
}
