import { useCallback, useEffect, useMemo, useState } from 'react';
import { isHttpResponse } from '../../services/api';
import { ApiError, HttpResponse, SharedAffiliateContext, SharedAffiliateDto } from '../../services/api-client/csp-api';
import useBetterTranslate from '../../utils/translation-utils';
import { ButtonV2Primary } from '../button';
import { FormContainerV2 } from '../input';
import { Modal, ModalAcceptButton, ModalCancelButton, ModalContent, ModalFooter, ModalHeader, ModalProps, useModal } from '../modal/modal';
import SingleSelect from '../select/single-select';
import Box, { Divider } from '../utils';
import useEffectAsync from '../../utils/useEffectAsync';

// export type AffiliateChooserProps = {
//   className?: string;
//   affiliate?: SharedAffiliateContext;
//   searchAffiliates: (search: string) => Promise<SharedAffiliateDto[] | HttpResponse<SharedAffiliateDto[], ApiError>>;
//   // onAffiliateSelected: (affiliate: SharedAffiliateDto | null) => void;

// } & ModalProps<SharedAffiliateDto, false, {}>;

interface AffiliateChooserPopupArgs {
  current?: SharedAffiliateContext;
  searchAffiliates: (search: string) => Promise<SharedAffiliateDto[] | HttpResponse<SharedAffiliateDto[], ApiError>>;
}
export function AffiliateChooserPopup(props: ModalProps<string | null, false, AffiliateChooserPopupArgs>) {
  const { _t } = useBetterTranslate('affiliate-chooser');
  const [affilate, setAffiliate] = useState<{ id: string; label: string } | null>(null);
  const [initialList, setInitialList] = useState<{ id: string; label: string }[]>([]);

  const searchAffiliates = props.args?.searchAffiliates;
  useEffectAsync(async () => {
    if (!searchAffiliates) return;
    const res = await searchAffiliates('');
    if (isHttpResponse(res)) setInitialList(res.data.map((r) => ({ id: r.code, label: r.title })));
    else setInitialList(res.map((r) => ({ id: r.code, label: r.title, value: r })));
  }, [searchAffiliates]);

  useEffect(() => {
    setAffiliate(props.args?.current ? { id: props.args.current.code, label: props.args.current.title } : null);
  }, [props.args?.current]);

  return (
    <Modal {...props}>
      {(close, args) => {
        return (
          <>
            <ModalHeader title={_t('Change affiliate')} onClose={() => close(false)} />
            <ModalContent>
              <FormContainerV2 label={_t('Select an affiliate')}>
                <SingleSelect
                  selectedValue={affilate}
                  options={initialList}
                  maxMenuHeight={100}
                  fetchOptions={async (search) => {
                    if (!args) return [];
                    const res = await args.searchAffiliates(search);
                    if (isHttpResponse(res)) return res.data.map((r) => ({ id: r.code, label: r.title }));
                    return res.map((r) => ({ id: r.code, label: r.title, value: r }));
                  }}
                  createOption={(val) => val.label}
                  onChanged={setAffiliate}
                />
              </FormContainerV2>
            </ModalContent>
            <ModalFooter>
              <ModalCancelButton onClick={() => close(false)}>{_t('Cancel and return')}</ModalCancelButton>
              <Divider kind='v' />
              <ModalAcceptButton onClick={() => close(affilate?.id || null)}>{_t('Apply affiliate')}</ModalAcceptButton>
            </ModalFooter>
          </>
        );
      }}
    </Modal>
  );
}

export function AffiliateChooser(props: {
  searchAffiliates: (search: string) => Promise<SharedAffiliateDto[] | HttpResponse<SharedAffiliateDto[], ApiError>>;
  current?: SharedAffiliateContext;
  onAffiliateSelected: (affiliateCode: string) => void;
}) {
  const { _t } = useBetterTranslate('affiliate-chooser');
  const [showModal, modalProps] = useModal<string | null, false, AffiliateChooserPopupArgs>({
    // appearDirection: 'appearTop',
  });

  return (
    <Box kind={'hflex'} gap='l'>
      <ButtonV2Primary
        onClick={async () => {
          const res = await showModal({ searchAffiliates: props.searchAffiliates, current: props.current });
          if (res) props.onAffiliateSelected(res);
        }}
      >
        {_t('Change affiliate')}
      </ButtonV2Primary>
      <Divider kind='v' />
      <Box justify='center' kind={'vflex'} fg='primary-neutral-100' fs={'l'}>
        {props.current?.title}
      </Box>
      <AffiliateChooserPopup {...modalProps} />
    </Box>
  );
}
