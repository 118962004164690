import { ReactComponent as ChademoIco } from '../assets/plug-chademo.svg';
import { ReactComponent as Combo1Ico } from '../assets/plug-combo-1.svg';
import { ReactComponent as Combo2Ico } from '../assets/plug-combo-2.svg';
import { ReactComponent as GbtIco } from '../assets/plug-gbt.svg';
import { ReactComponent as ShukoIco } from '../assets/plug-shuko.svg';
import { ReactComponent as Type1Ico } from '../assets/plug-type-1.svg';
import { ReactComponent as Type2Ico } from '../assets/plug-type-2.svg';
import { ReactComponent as DefaultIco } from '../assets/plug-unknown.svg';
import { translate } from '../i18n';

const _t = translate('outlet-type-icon');

export const PlugTypes = ['Type1', 'Type2', 'Combo1', 'Combo2', 'CHAdeMO', 'GB/T', 'Schuko', 'Unknown'] as const;
export type PlugType = (typeof PlugTypes)[number];

export default function OutletTypeIcon(props: { type: PlugType | undefined; className?: string; color?: string }) {
  if (props.type === 'CHAdeMO') return <ChademoIco className={props.className} fill={props.color} />;
  if (props.type === 'Combo1') return <Combo1Ico className={props.className} stroke={props.color} />;
  if (props.type === 'Combo2') return <Combo2Ico className={props.className} fill={props.color} stroke={props.color} />;
  if (props.type === 'Schuko') return <ShukoIco className={props.className} fill={props.color} />;
  if (props.type === 'Type1') return <Type1Ico className={props.className} fill={props.color} stroke={props.color} />;
  if (props.type === 'Type2') return <Type2Ico className={props.className} fill={props.color} />;
  if (props.type === 'GB/T') return <GbtIco className={props.className} fill={props.color} stroke={props.color} />;
  if (props.type === 'Unknown') return <DefaultIco className={props.className} fill={props.color} stroke={props.color} />;
  return <DefaultIco className={props.className} />;
}
export const localizePlugType = (plugType: string): string => {
  //console.log('plugtype', _t('Unbekannt'));
  return plugType === 'Type1'
    ? _t('Typ 1')
    : plugType === 'Type2'
    ? _t('Typ 2')
    : plugType === 'Combo1'
    ? _t('Combo 1')
    : plugType === 'Combo2'
    ? _t('Combo 2')
    : plugType === 'Unknown'
    ? _t('Unbekannt')
    : plugType;
};

export const getPlugTypeOptions = () => [
  { name: 'Type1', title: localizePlugType('Type1'), id: 'type1' },
  { name: 'Type2', title: localizePlugType('Type2'), id: 'type2' },
  { name: 'Combo1', title: localizePlugType('Combo1'), id: 'combo1' },
  { name: 'Combo2', title: localizePlugType('Combo2'), id: 'combo2' },
  { name: 'CHAdeMO', title: localizePlugType('CHAdeMO'), id: 'chademo' },
  { name: 'GB/T', title: localizePlugType('GB/T'), id: 'gbt' },
  { name: 'Schuko', title: localizePlugType('Schuko'), id: 'schuko' },
  { name: 'Unknown', title: localizePlugType('Unknown'), id: 'unknown' },
];
