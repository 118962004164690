import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createQueryString, ParametersList } from './usePageParams';

export function useBetterNavigate<T extends ParametersList>() {
  const navigate = useNavigate();

  const handler = useCallback(
    (path: string, query?: T) => {
      const search = query ? createQueryString(query) : undefined;
      navigate({ pathname: path, search });
    },
    [navigate]
  );

  return handler;
}
export function replaceHistoryState(obj: any) {
  const history = window.history;
  const reactState = history.state?.usr || {};
  const browserState = history.state || {};

  history.replaceState({ ...browserState, ...{ usr: { ...reactState, ...obj } } }, '');
}
export function useLocationState<T>(key: string, opts?: { default?: T }) {
  const location = useLocation();

  if (location.state && location.state[key]) return location.state[key] as T;

  if (Object.keys(opts || {}).includes(`default`)) return opts?.default;

  if (global?.process?.env?.NODE_ENV === `production`) {
    console.error(`current location state is empty but key ${key} is required`);
    console.error(`this is a fatal error on PRODUCTION, probably better to redirect the user to the home page`);
    window.location.href = `/`;
  } else {
    throw new Error(`current location state is empty but key ${key} is required`);
  }
}
