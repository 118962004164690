import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Item, Menu, TriggerEvent, useContextMenu } from 'react-contexify';
import { ReactComponent as WarnIcon } from '../../assets/warning.svg';
import { SmartSuggest } from '../../components-v2/business-components/suggestions/suggestions';
import { HighlightBox } from '../../components-v2/highlight-box';
import Ico from '../../components-v2/ico';
import { FormContainerV2 } from '../../components-v2/input';
import { CloseFunc, ModalConfirmStep, ModalFailedStep, ModalPendingStep, ModalSuccessStep, MultiStepModal, useMultiStep } from '../../components-v2/modal/modal.multi-step';
import { SelectedTag, TagSuggestion, useTagSuggestion } from '../../components-v2/tag-suggestion/tag-suggestion';
import Box from '../../components-v2/utils';
import RadioButton from '../../components/input-radio-button';
// import SingleSelect from '../../components/select/single-select';
import SingleSelect from '../../components-v2/select/single-select';
import { translate } from '../../i18n';
import { ChangeAvailabilityTypes } from '../../services/api-client/csp-api';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './chargepoint-remote-actions.module.scss';

type CardOption = { id: string; label: string; extId: string };

export type ChargePointRemoteAction = 'reserve' | 'unlock' | 'startTransaction' | 'stopTransaction' | 'changeAvailability' | 'cancelReserve';

export type ChargePointRemoteActionProps = {
  refreshRequested: () => void;
  remoteReserve: {
    do: (cp: ChargePoint, card: CardOption, minutes: number) => Promise<boolean>;
    searchCards: (txt: string) => Promise<CardOption[]>;
  };
  remoteCancelReserve: {
    do: (cp: ChargePoint) => Promise<boolean>;
    searchCards: (txt: string) => Promise<CardOption[]>;
  };
  remoteStop: {
    do: (cp: ChargePoint) => Promise<boolean>;
  };
  remoteStart: {
    do: (cp: ChargePoint, card: CardOption) => Promise<boolean>;
    searchCards: (txt: string) => Promise<CardOption[]>;
  };
  remoteUnlock: {
    do: (cp: ChargePoint) => Promise<boolean>;
  };
  remoteChangeAvailability: {
    do: (cp: ChargePoint, newVal: ChangeAvailabilityTypes) => Promise<boolean>;
  };
};

export interface ChargePoint {
  connectorId: string;
  chargeBoxId: string;
  domainStatus: 'available' | 'charging' | 'notAvailable' | 'failure' | 'unknown' | 'occupied';
  domainStatusOfStation: 'online' | 'offline' | 'failure' | 'notInOperation';
  can: { remoteStartSession: boolean; remoteStopSession: boolean; remoteUnlock: boolean; remoteReserve: boolean; remoteChangeAvailability: boolean };
  evseId?: string;
}

type RemoteActionContextItem = {
  action: () => void;
  title: string;
};

function createChargePointActionList(cp: ChargePoint, trigger: (act: ChargePointRemoteAction) => void): RemoteActionContextItem[] {
  const actions: RemoteActionContextItem[] = [];
  const _t = translate('chargepoint-remote-actions');

  const stationIsConnected = cp.domainStatusOfStation === 'online' || cp.domainStatusOfStation === 'failure';

  if (cp.domainStatus !== 'notAvailable' && stationIsConnected && cp.can.remoteStartSession) {
    actions.push({
      action: () => trigger('startTransaction'),
      title: _t('Ladevorgang starten'),
    });
  }

  if (cp.domainStatus !== 'notAvailable' && stationIsConnected && cp.can.remoteStopSession) {
    actions.push({
      action: () => trigger('stopTransaction'),
      title: _t('Ladevorgang stoppen'),
    });
  }
  if (cp.domainStatus !== 'notAvailable' && stationIsConnected && cp.can.remoteUnlock) {
    actions.push({
      action: () => trigger('unlock'),
      title: _t('Konnektor freigeben'),
    });
  }
  if (cp.domainStatus !== 'notAvailable' && stationIsConnected && cp.can.remoteReserve) {
    actions.push({
      action: () => trigger('reserve'),
      title: _t('Ladepunkt reservieren'),
    });
  }
  if (stationIsConnected && cp.can.remoteReserve) {
    actions.push({
      action: () => trigger('cancelReserve'),
      title: _t('Reservierung stornieren'),
    });
  }

  // cp.domainStatus !== 'notAvailable' &&
  // should probabley not be checked here because otherwise we cannot make a chargePoint available if it is not available
  if (stationIsConnected && cp.can.remoteChangeAvailability) {
    actions.push({
      action: () => trigger('changeAvailability'),
      title: _t('Ladepunkt ent-/sperren'),
    });
  }

  return actions;
}

export function useStopChargingSession(props: ChargePointRemoteActionProps) {
  const { _t } = useBetterTranslate('chargepoint-remote-actions');
  const title = _t('Ladevorgang stoppen');
  const end = (close: CloseFunc<any, any>, result: any) => {
    return () => {
      close(result);
      props.refreshRequested();
    };
  };
  const process = async (cp: ChargePoint) => {
    popup.goTo('pending');
    try {
      const result = await props.remoteStop.do(cp);
      if (result) popup.goTo('success');
      else popup.goTo('failed');
    } catch (err) {
      popup.goTo('failed');
    }
  };
  const [popup, popupProps] = useMultiStep({
    className: styles.multiStepPopup,
    steps: {
      confirm: (close, station: ChargePoint) => {
        return (
          <ModalConfirmStep title={title} cancel={{ onClick: end(close, false) }} accept={{ text: _t('Confirm'), onClick: () => process(station) }}>
            <Box txtAlign='center'>{_t('Sind Sie sicher, dass Sie den Ladevorgang stoppen wollen?')}</Box>
          </ModalConfirmStep>
        );
      },
      pending: (close) => {
        return (
          <ModalPendingStep title={title} cancel={false} accept={false}>
            <Box txtAlign='center'>{_t('Bitte warten')}</Box>
          </ModalPendingStep>
        );
      },
      success: (close) => {
        return (
          <ModalSuccessStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }}>
            <Box txtAlign='center'>{_t('Ladevorgang wurde gestoppt')}</Box>
          </ModalSuccessStep>
        );
      },
      failed: (close) => {
        return (
          <ModalFailedStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }} cancel={false}>
            <Box>{_t('Aktion konnte nicht durchgeführt werden')}</Box>
          </ModalFailedStep>
        );
      },
    },
  });

  return { popup, popupProps };
}

export function useUnlock(props: ChargePointRemoteActionProps) {
  const { _t } = useBetterTranslate('chargepoint-remote-actions');
  const title = _t('Konnektor freigeben');
  const end = (close: CloseFunc<any, any>, result: any) => {
    return () => {
      close(result);
      props.refreshRequested();
    };
  };
  const process = async (cp: ChargePoint) => {
    popup.goTo('pending');
    try {
      const result = await props.remoteUnlock.do(cp);
      if (result) popup.goTo('success');
      else popup.goTo('failed');
    } catch (err) {
      popup.goTo('failed');
    }
  };
  const [popup, popupProps] = useMultiStep({
    className: styles.multiStepPopup,
    steps: {
      confirm: (close, station: ChargePoint) => {
        return (
          <ModalConfirmStep title={title} cancel={{ onClick: end(close, false) }} accept={{ text: _t('Confirm'), onClick: () => process(station) }}>
            <Box kind={'vflex'} gap='l' align='center'>
              <Box txtAlign='center'>{_t('Sind Sie sicher, dass Sie den Konnektor freigeben wollen?')}</Box>
              <HighlightBox>
                <Box kind={'hflex'} gap='s' align='center'>
                  <Ico size='24px' file={<WarnIcon />} stroke='brown-gold-metallic' />
                  <Box fg='brown-gold-metallic'>{_t('Aktive Ladevorgänge, werden durch diese Aktion abgebrochen.')}</Box>
                </Box>
              </HighlightBox>
            </Box>
          </ModalConfirmStep>
        );
      },
      pending: (close) => {
        return (
          <ModalPendingStep title={title} cancel={false} accept={false}>
            <Box txtAlign='center'>{_t('Bitte warten')}</Box>
          </ModalPendingStep>
        );
      },
      success: (close) => {
        return (
          <ModalSuccessStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }}>
            <Box txtAlign='center'>{_t('Der Befehl wurde erfolgreich an die Station gesendet.')}</Box>
          </ModalSuccessStep>
        );
      },
      failed: (close) => {
        return (
          <ModalFailedStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }} cancel={false}>
            <Box>{_t('Aktion konnte nicht durchgeführt werden')}</Box>
          </ModalFailedStep>
        );
      },
    },
  });

  return { popup, popupProps };
}

export function useCancelReserve(props: ChargePointRemoteActionProps) {
  const { _t } = useBetterTranslate('chargepoint-remote-actions');
  const title = _t('Reservierung stornieren');
  const end = (close: CloseFunc<any, any>, result: any) => {
    return () => {
      close(result);
      props.refreshRequested();
    };
  };
  const process = async (cp: ChargePoint) => {
    popup.goTo('pending');
    try {
      const result = await props.remoteCancelReserve.do(cp);
      if (result) popup.goTo('success');
      else popup.goTo('failed');
    } catch (err) {
      popup.goTo('failed');
    }
  };
  const [popup, popupProps] = useMultiStep({
    className: styles.multiStepPopup,
    steps: {
      confirm: (close, station: ChargePoint) => {
        return (
          <ModalConfirmStep title={title} cancel={{ onClick: end(close, false) }} accept={{ text: _t('Confirm'), onClick: () => process(station) }}>
            <Box kind={'vflex'} gap='l' align='center'>
              <Box txtAlign='center'>{_t('Wollen Sie die Reservierung dieses Ladepunkts stornieren?')}</Box>
            </Box>
          </ModalConfirmStep>
        );
      },
      pending: (close) => {
        return (
          <ModalPendingStep title={title} cancel={false} accept={false}>
            <Box txtAlign='center'>{_t('Bitte warten')}</Box>
          </ModalPendingStep>
        );
      },
      success: (close) => {
        return (
          <ModalSuccessStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }}>
            <Box txtAlign='center'>{_t('Reservierung wurde storniert')}</Box>
          </ModalSuccessStep>
        );
      },
      failed: (close) => {
        return (
          <ModalFailedStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }} cancel={false}>
            <Box>{_t('Aktion konnte nicht durchgeführt werden')}</Box>
          </ModalFailedStep>
        );
      },
    },
  });

  return { popup, popupProps };
}

export function useChangeAvailabilityAction(props: ChargePointRemoteActionProps) {
  const { _t } = useBetterTranslate('chargepoint-remote-actions');
  const title = _t('Change Availability');
  const [availabilityMode, setAvailabilityMode] = useState<ChangeAvailabilityTypes>(ChangeAvailabilityTypes.Operative);
  const end = (close: CloseFunc<any, any>, result: any) => {
    return () => {
      setAvailabilityMode(ChangeAvailabilityTypes.Operative);
      close(result);
      props.refreshRequested();
    };
  };
  const process = async (cp: ChargePoint) => {
    popup.goTo('pending');
    try {
      const result = await props.remoteChangeAvailability.do(cp, availabilityMode);
      if (result) popup.goTo('success');
      else popup.goTo('failed');
    } catch (err) {
      popup.goTo('failed');
    }
  };
  const [popup, popupProps] = useMultiStep({
    className: styles.multiStepPopup,
    steps: {
      confirm: (close, station: ChargePoint) => {
        return (
          <ModalConfirmStep title={title} cancel={{ onClick: end(close, false) }} accept={{ text: _t('Confirm'), onClick: () => process(station) }}>
            <Box kind={'vflex'} gap='l' align='center'>
              <Box txtAlign='center'>{_t('Sind Sie sicher, dass Sie den Ladepunkt ent-/sperren wollen?')}</Box>
              <Box kind={'hflex'} gap='xl'>
                <RadioButton
                  disabled={false}
                  label={_t('entsperren')}
                  isSelected={availabilityMode === 'Operative'}
                  onChange={() => setAvailabilityMode(ChangeAvailabilityTypes.Operative)}
                />
                <RadioButton
                  disabled={false}
                  label={_t('sperren')}
                  isSelected={availabilityMode === 'Inoperative'}
                  onChange={() => {
                    setAvailabilityMode(ChangeAvailabilityTypes.Inoperative);
                  }}
                />
              </Box>
              <HighlightBox>
                <Box kind={'hflex'} gap='s' align='center'>
                  <Ico size='24px' file={<WarnIcon />} stroke='brown-gold-metallic' />
                  <Box fg='brown-gold-metallic'>{_t('Aktive Ladevorgänge, werden durch diese Aktion abgebrochen.')}</Box>
                </Box>
              </HighlightBox>
            </Box>
          </ModalConfirmStep>
        );
      },
      pending: (close) => {
        return (
          <ModalPendingStep title={title} cancel={false} accept={false}>
            <Box txtAlign='center'>{_t('Bitte warten')}</Box>
          </ModalPendingStep>
        );
      },
      success: (close) => {
        return (
          <ModalSuccessStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }}>
            <Box txtAlign='center'>{availabilityMode === ChangeAvailabilityTypes.Inoperative ? _t('Ladepunkt wurde) gesperrt') : _t('Ladepunkt wurde entsperrt')}</Box>
          </ModalSuccessStep>
        );
      },
      failed: (close) => {
        return (
          <ModalFailedStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }} cancel={false}>
            <Box>{_t('Aktion konnte nicht durchgeführt werden')}</Box>
          </ModalFailedStep>
        );
      },
    },
  });

  return { popup, popupProps };
}

export function useChargePointRemoteActions(props: ChargePointRemoteActionProps) {
  const [chargePoint, setChargepoint] = useState<ChargePoint | undefined>();
  const [activeAction, setActiveAction] = useState<ChargePointRemoteAction | undefined>();

  // prevent rerenders, drawback: props cannot change from caller
  // should be fine, if not need to consider it
  // is a bit complicated because props use a lot references instead of value types
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const frozenProps = useMemo(() => props, []);

  const done = useCallback(() => {
    setChargepoint(undefined);
    setActiveAction(undefined);
  }, []);

  const { show: showContextMenu } = useContextMenu({
    id: 'remote-action-cp',
  });

  const contextMenuItems = useMemo((): RemoteActionContextItem[] => {
    if (!chargePoint) return [];
    const actions = createChargePointActionList(chargePoint, setActiveAction);
    return actions;
  }, [chargePoint, setActiveAction]);

  const chargePointRemoteActionsProps = useMemo(() => {
    return { ...frozenProps, activeAction, onClose: done, contextMenuItems, chargePoint, actionExecuted: props.refreshRequested };
  }, [frozenProps, activeAction, done, contextMenuItems, chargePoint, props.refreshRequested]);

  const hasRemoteActions = useCallback(
    (cp: ChargePoint) => {
      const actions = createChargePointActionList(cp, setActiveAction);
      return actions.length > 0;
    },
    [setActiveAction]
  );

  const showChargePointRemoteActionContextMenu = useCallback(
    (ev: TriggerEvent, cp: ChargePoint) => {
      setChargepoint(cp);
      showContextMenu(ev);
    },
    [setChargepoint, showContextMenu]
  );

  return { chargePointRemoteActionsProps, showChargePointRemoteActionContextMenu, hasChargePointRemoteActions: hasRemoteActions };
}

export function useStartSessionAction(props: ChargePointRemoteActionProps) {
  const { _t } = useBetterTranslate('chargepoint-remote-actions');
  const title = _t('Ladevorgang Starten');

  const suggestion = useTagSuggestion({
    fetchOptions: async (val) => props.remoteStart.searchCards(val),
    createOption: (val) => <SmartSuggest label={val.label || ''} type={'card'} />,
    filterDuplicates: true,
    provideIdentifier: (val) => val.id,
    createSelectedTag: (val, onRemove) => (
      <SelectedTag className={styles.selectedTag} onRemove={onRemove}>
        {val.label}
      </SelectedTag>
    ),
  });

  const end = (close: CloseFunc<any, any>, result: any) => {
    return () => {
      suggestion.selectedTagAreaProps.removeAll();
      close(result);
      props.refreshRequested();
    };
  };

  const selectedCard = suggestion.selectedItems.length > 0 ? suggestion.selectedItems[0] : undefined;
  const process = async (cp: ChargePoint) => {
    if (!selectedCard) return;
    popup.goTo('pending');
    try {
      const result = await props.remoteStart.do(cp, selectedCard);
      if (result) popup.goTo('success');
      else popup.goTo('failed');
    } catch (err) {
      popup.goTo('failed');
    }
  };
  const [popup, popupProps] = useMultiStep({
    className: styles.multiStepPopup,
    steps: {
      confirm: (close, station: ChargePoint) => {
        return (
          <ModalConfirmStep title={title} cancel={{ onClick: end(close, false) }} accept={{ text: _t('Confirm'), disabled: !selectedCard, onClick: () => process(station) }}>
            {suggestion.selectedItems.length <= 0 && (
              <Box kind={'vflex'} gap='m' align='center'>
                <Box txtAlign='center'>{_t('Authentifizierungsmedium suchen')}</Box>
                <FormContainerV2>
                  <TagSuggestion {...suggestion.suggestorProps} />
                </FormContainerV2>
              </Box>
            )}

            {suggestion.selectedItems.length > 0 && (
              <Box kind={'vflex'} gap='m' align='center'>
                <Box>
                  {suggestion.selectedItems.map((item, idx) => {
                    return <Fragment key={idx}>{suggestion.selectedTagAreaProps.renderItem(item)}</Fragment>;
                  })}
                </Box>
                <Box txtAlign='center'>{_t('Ladevorgang mit diesem Authentifikationsmedium starten')}</Box>
              </Box>
            )}
          </ModalConfirmStep>
        );
      },
      pending: (close) => {
        return (
          <ModalPendingStep title={title} cancel={false} accept={false}>
            <Box txtAlign='center'>{_t('Bitte warten')}</Box>
          </ModalPendingStep>
        );
      },
      success: (close) => {
        return (
          <ModalSuccessStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }}>
            <Box txtAlign='center'>{_t('Ladevorgang wurde gestartet')}</Box>
          </ModalSuccessStep>
        );
      },
      failed: (close) => {
        return (
          <ModalFailedStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }} cancel={false}>
            <Box>{_t('Aktion konnte nicht durchgeführt werden')}</Box>
          </ModalFailedStep>
        );
      },
    },
  });

  return { popup, popupProps };
}

export function useReserveAction(props: ChargePointRemoteActionProps) {
  const { _t } = useBetterTranslate('chargepoint-remote-actions');
  const title = _t('Ladepunkt reservieren');

  const suggestion = useTagSuggestion({
    fetchOptions: async (val) => props.remoteStart.searchCards(val),
    createOption: (val) => <SmartSuggest label={val.label || ''} type={'card'} />,
    filterDuplicates: true,
    provideIdentifier: (val) => val.id,
    createSelectedTag: (val, onRemove) => (
      <SelectedTag className={styles.selectedTag} onRemove={onRemove}>
        {val.label}
      </SelectedTag>
    ),
  });

  const end = (close: CloseFunc<any, any>, result: any) => {
    return () => {
      suggestion.selectedTagAreaProps.removeAll();
      setSelectedTime(reservationItmeValues[0]);
      close(result);
      props.refreshRequested();
    };
  };

  const reservationItmeValues = [
    { id: '15 min', value: 15 },
    { id: '30 min', value: 30 },
    { id: '45 min', value: 45 },
    { id: '1 std', value: 60 },
    { id: '2 std', value: 120 },
  ];
  const [selectedTime, setSelectedTime] = useState<{ id: string; value: number }>(reservationItmeValues[0]);
  const selectedCard = suggestion.selectedItems.length > 0 ? suggestion.selectedItems[0] : undefined;
  const process = async (cp: ChargePoint) => {
    if (!selectedCard) return;
    popup.goTo('pending');
    try {
      const result = await props.remoteReserve.do(cp, selectedCard, selectedTime.value);
      if (result) popup.goTo('success');
      else popup.goTo('failed');
    } catch (err) {
      popup.goTo('failed');
    }
  };
  const [popup, popupProps] = useMultiStep({
    className: styles.multiStepPopup,
    steps: {
      confirm: (close, station: ChargePoint) => {
        return (
          <ModalConfirmStep title={title} cancel={{ onClick: end(close, false) }} accept={{ text: _t('Confirm'), disabled: !selectedCard, onClick: () => process(station) }}>
            {suggestion.selectedItems.length <= 0 && (
              <Box kind={'vflex'} gap='m' align='center'>
                <Box txtAlign='center'>{_t('Authentifizierungsmedium suchen')}</Box>
                <FormContainerV2>
                  <TagSuggestion {...suggestion.suggestorProps} />
                </FormContainerV2>
              </Box>
            )}

            {suggestion.selectedItems.length > 0 && (
              <Box kind={'vflex'} gap='m' align='center'>
                <Box>
                  {suggestion.selectedItems.map((item, idx) => {
                    return <Fragment key={idx}>{suggestion.selectedTagAreaProps.renderItem(item)}</Fragment>;
                  })}
                </Box>

                <SingleSelect<{ id: string; value: number }>
                  options={reservationItmeValues}
                  maxMenuHeight={160}
                  createOption={(opt) => <span>{opt.id}</span>}
                  selectedValue={selectedTime}
                  className={styles.dropdown}
                  fetchOptions={async (txt) => {
                    if (!txt) return reservationItmeValues || [];
                    const results = reservationItmeValues.filter((item) => item.id.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                    return results;
                  }}
                  onChanged={(selected) => {
                    if (selected) {
                      setSelectedTime(selected);
                    }
                  }}
                  isClearable={false}
                />

                <Box txtAlign='center'>{_t('Ladepunkt ab jetzt für den gewählten Zeitraum reservieren?')}</Box>
              </Box>
            )}
          </ModalConfirmStep>
        );
      },
      pending: (close) => {
        return (
          <ModalPendingStep title={title} cancel={false} accept={false}>
            <Box txtAlign='center'>{_t('Bitte warten')}</Box>
          </ModalPendingStep>
        );
      },
      success: (close) => {
        return (
          <ModalSuccessStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }}>
            <Box txtAlign='center'>{_t('Ladepunkt wurde reserviert')}</Box>
          </ModalSuccessStep>
        );
      },
      failed: (close) => {
        return (
          <ModalFailedStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }} cancel={false}>
            <Box>{_t('Aktion konnte nicht durchgeführt werden')}</Box>
          </ModalFailedStep>
        );
      },
    },
  });

  return { popup, popupProps };
}

export default function ChargePointRemoteActions(
  props: ChargePointRemoteActionProps & {
    //
    onClose: () => void;
    actionExecuted: () => void;
    activeAction: ChargePointRemoteAction | undefined;
    contextMenuItems: RemoteActionContextItem[];
    chargePoint?: ChargePoint;
  }
) {
  const { popup: changeAvailability, popupProps: changeAvailabilityProps } = useChangeAvailabilityAction(props);
  const { popup: stopSession, popupProps: stopSessionProps } = useStopChargingSession(props);
  const { popup: unlock, popupProps: unlockProps } = useUnlock(props);
  const { popup: cancelReserve, popupProps: cancelReserveProps } = useCancelReserve(props);
  const { popup: startSession, popupProps: startSessionProps } = useStartSessionAction(props);
  const { popup: reserve, popupProps: reserveProps } = useReserveAction(props);

  const { chargePoint, activeAction, onClose: done } = props;
  useEffect(() => {
    if (!chargePoint) return;
    if (!activeAction) return;

    if (activeAction === 'stopTransaction' && !stopSession.isOpen()) {
      stopSession.show('confirm', chargePoint).then(() => done());
    } else if (activeAction === 'changeAvailability' && !changeAvailability.isOpen()) {
      changeAvailability.show('confirm', chargePoint).then(() => done());
    } else if (activeAction === 'unlock' && !unlock.isOpen()) {
      unlock.show('confirm', chargePoint).then(() => done());
    } else if (activeAction === 'cancelReserve' && !cancelReserve.isOpen()) {
      cancelReserve.show('confirm', chargePoint).then(() => done());
    } else if (activeAction === 'startTransaction' && !startSession.isOpen()) {
      startSession.show('confirm', chargePoint).then(() => done());
    } else if (activeAction === 'reserve' && !reserve.isOpen()) {
      reserve.show('confirm', chargePoint).then(() => done());
    }
  }, [reserve, startSession, chargePoint, activeAction, cancelReserve, unlock, stopSession, changeAvailability, done]);

  return (
    <>
      <MultiStepModal {...changeAvailabilityProps} />
      <MultiStepModal {...stopSessionProps} />
      <MultiStepModal {...unlockProps} />
      <MultiStepModal {...cancelReserveProps} />
      <MultiStepModal {...startSessionProps} />
      <MultiStepModal {...reserveProps} />

      <Menu id={`remote-action-cp`}>
        {props.chargePoint &&
          props.contextMenuItems.length > 0 &&
          props.contextMenuItems.map((item, idx) => {
            return (
              <Item key={idx} onClick={() => item.action()}>
                {item.title}
              </Item>
            );
          })}
      </Menu>
    </>
  );
}
