import { useState } from 'react';
import { ReactComponent as WarningIco } from '../../../../assets/warning.svg';
import EditPoup, { EditPopupRow } from '../../../../components/edit-popup';
import useBetterTranslate from '../../../../utils/translation-utils';
import styles from './org-user-delete-role-popup.module.scss';

export default function OrgUserDeleteRolePopup(props: { open: boolean; onlyRole: boolean; onCancel: () => void; onSubmit: () => Promise<void> }) {
  const [submitError, setSubmitError] = useState<string>();
  const { _t } = useBetterTranslate('org-user-delete-role-popup');

  const onSubmitWrapper = async () => {
    try {
      await props.onSubmit();
    } catch (error) {
      setSubmitError(_t('Ein unerwarteter Fehler ist aufgetreten.'));
    }
  };

  return (
    <EditPoup
      open={props.open}
      className={styles.popup}
      bodyClassName={styles.body}
      onClose={props.onCancel}
      onSave={async () => await onSubmitWrapper()}
      additionalFooterContentClassName={styles.submitError}
      additionalFooterContent={submitError}
      title={_t('Rollenberechtigung löschen')}
      saveText={_t('Löschen')}
    >
      {props.onlyRole && (
        <>
          <EditPopupRow className={styles.confirm}>{_t('Sind Sie sicher, dass Sie dem Benutzer die einzige Rolle entziehen wollen?')}</EditPopupRow>
          <EditPopupRow className={styles.alert}>
            <WarningIco width={48} height={48} />
            {_t('Der Benutzer wird gelöscht, wenn dieser über keine Rollen verfügt.')}
          </EditPopupRow>
        </>
      )}
      {!props.onlyRole && (
        <EditPopupRow className={styles.alert}>
          <WarningIco width={72} height={72} />
          <span>{_t('Sind Sie sicher, dass Sie dem Benutzer diese Rolle und den entsprechenden Zugang zu den Knoten entziehen wollen?')}</span>
        </EditPopupRow>
      )}
    </EditPoup>
  );
}
