import { getDomainStatusTitle } from '../../utils/domain-status';
import useBetterTranslate from '../../utils/translation-utils';
import { getPlugTypeOptions } from '../outlet-type-icon';
import Pill from '../pill';
import SearchInput from '../search-input';
import SingleSelect from '../select/single-select';
import colorStyles from '../style-utils';
import { ChargePointFilterParameters } from './filter-area-parameters.dto';
import styles from './station-charge-point-filter-area.module.scss';

const chargePointTypeOptions = [
  { name: 'AC', id: 'ac' },
  { name: 'DC', id: 'dc' },
];

export default function StationFilterArea(props: {
  searchTerm: string | undefined;
  updateSearchTerm: (newSearchTerm: string) => void;
  filterParams: ChargePointFilterParameters;
  setFilterParams: (params: ChargePointFilterParameters) => void;
}) {
  const { searchTerm, updateSearchTerm, filterParams, setFilterParams } = props;
  const { _t } = useBetterTranslate('chargepoint-filter-area');

  return (
    <div className={styles.filterArea}>
      <div className={styles.filterContainer1}>
        <div className={styles.searchInput}>
          <SearchInput dataCy='search_input' maxLength={120} onChange={updateSearchTerm} value={searchTerm} placeholder={_t('Suchen')} />
        </div>
        <div>
          <SingleSelect<{ name: string; id: string; title: string }>
            placeholder={_t('Steckertyp')}
            options={getPlugTypeOptions()}
            createOption={(opt) => <span>{opt.title}</span>}
            selectedValue={null}
            fetchOptions={async (txt) => {
              if (!txt) return getPlugTypeOptions() || [];
              const results = (getPlugTypeOptions() || []).filter((item) => item.name.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
              return results;
            }}
            onChanged={(selected) => {
              if (selected && !filterParams.plugTypes?.includes(selected.name)) {
                const selectedPlugTypes = [...(filterParams.plugTypes || []), selected.name];
                setFilterParams({
                  plugTypes: selectedPlugTypes,
                });
              }
            }}
            isClearable={true}
          />
        </div>
        <div>
          <SingleSelect<{ name: string; id: string }>
            className=''
            placeholder={_t('Stromart (AC/DC)')}
            options={chargePointTypeOptions}
            createOption={(opt) => <span>{opt.name}</span>}
            renderDisplayValue={(val) => val.name}
            selectedValue={null}
            fetchOptions={async (txt) => {
              if (!txt) return chargePointTypeOptions || [];
              const results = (chargePointTypeOptions || []).filter((item) => item.name.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
              return results;
            }}
            onChanged={(selected) => {
              if (selected?.id === 'ac') {
                setFilterParams({ ac: '1' });
              } else if (selected?.id === 'dc') {
                setFilterParams({ dc: '1' });
              }
            }}
            isClearable={true}
          />
        </div>
      </div>

      <div className={styles.filterContainer2}>
        <Pill
          dataCy='pills_public'
          selectedClass={colorStyles.components.rateType.public}
          unselectedClass={colorStyles.components.rateTypeInvert.public}
          selected={filterParams.public === '1'}
          onClick={() => setFilterParams({ public: filterParams.public === '1' ? undefined : '1' })}
        >
          {_t('Public Service')}
        </Pill>

        <Pill
          dataCy='pills_available'
          selectedClass={colorStyles.components.connectorsStatus.available.active}
          unselectedClass={colorStyles.components.connectorsStatus.available.inactive}
          selected={filterParams.available === '1'}
          onClick={() => (filterParams.available === '1' ? setFilterParams({ available: undefined }) : setFilterParams({ available: '1' }))}
        >
          {getDomainStatusTitle('available')}
        </Pill>

        <Pill
          dataCy='pills_charging'
          selectedClass={colorStyles.components.connectorsStatus.charging.active}
          unselectedClass={colorStyles.components.connectorsStatus.charging.inactive}
          selected={filterParams.charging === '1'}
          onClick={() => (filterParams.charging === '1' ? setFilterParams({ charging: undefined }) : setFilterParams({ charging: '1' }))}
        >
          {getDomainStatusTitle('charging')}
        </Pill>
        <Pill
          dataCy='pills_occupied'
          selectedClass={colorStyles.components.connectorsStatus.occupied.active}
          unselectedClass={colorStyles.components.connectorsStatus.occupied.inactive}
          selected={filterParams.occupied === '1'}
          onClick={() => (filterParams.occupied === '1' ? setFilterParams({ occupied: undefined }) : setFilterParams({ occupied: '1' }))}
        >
          {getDomainStatusTitle('occupied')}
        </Pill>
        <Pill
          dataCy='pills_failure'
          selectedClass={colorStyles.components.connectorsStatus.failure.active}
          unselectedClass={colorStyles.components.connectorsStatus.failure.inactive}
          selected={filterParams.failure === '1'}
          onClick={() => (filterParams.failure === '1' ? setFilterParams({ failure: undefined }) : setFilterParams({ failure: '1' }))}
        >
          {getDomainStatusTitle('failure')}
        </Pill>

        <Pill
          dataCy='pills_notAvailable'
          selectedClass={colorStyles.components.connectorsStatus.unavailable.active}
          unselectedClass={colorStyles.components.connectorsStatus.unavailable.inactive}
          selected={filterParams.unavailable === '1'}
          onClick={() => (filterParams.unavailable === '1' ? setFilterParams({ unavailable: undefined }) : setFilterParams({ unavailable: '1' }))}
        >
          {getDomainStatusTitle('notAvailable')}
        </Pill>

        <Pill
          dataCy='pills_unknown'
          selectedClass={colorStyles.components.connectorsStatus.unknown.active}
          unselectedClass={colorStyles.components.connectorsStatus.unknown.inactive}
          selected={filterParams.unknown === '1'}
          onClick={() => (filterParams.unknown === '1' ? setFilterParams({ unknown: undefined }) : setFilterParams({ unknown: '1' }))}
        >
          {getDomainStatusTitle('unknown')}
        </Pill>
      </div>
    </div>
  );
}
