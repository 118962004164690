import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { HighlightBox } from '../../components-v2/highlight-box';
import Ico from '../../components-v2/ico';
import InputV2, { FormContainerV2 } from '../../components-v2/input';
import { Modal, ModalAcceptButton, ModalCancelButton, ModalContent, ModalFooter, ModalHeader, ModalProps } from '../../components-v2/modal/modal';
import Box, { Divider } from '../../components-v2/utils';
import { Spacer } from '../../components/page-layout';
import useFormState from '../../hooks/useFormState';
import useBetterTranslate from '../../utils/translation-utils';
import useEffectAsync from '../../utils/useEffectAsync';
import styles from '../stations/custom-name-popup.module.scss'; // Use same css as custom-name-popup

export type CommentPopupArgs = { kind: 'station' | 'chargepoint' | 'card'; currentComment: string };
export function CommentPopup(props: ModalProps<string, false, CommentPopupArgs>) {
  const { _t } = useBetterTranslate('custom-name-popup');
  const formState = useFormState();

  const { open } = props;

  useEffectAsync(async () => {
    if (open) formState.reset();
  }, [open]);

  const [newComment] = formState.useInputState({
    initialValue: props.args?.currentComment ?? '',
    label: _t('Type here the cost center to help identify this device'),
    maxLength: 50,
    validate: async (v) => (v.length > 50 ? _t('Maximum length is 50') : undefined),
  });

  return (
    <Modal<string, false, CommentPopupArgs> {...props}>
      {(close, args) => {
        let entity = props.args?.kind === 'chargepoint' ? _t('chargepoint') : props.args?.kind === 'station' ? _t('station') : _t('card');
        let mode = props.args?.currentComment ? _t('Edit') : _t('Create');
        let postfix = _t('cost center');
        let title = `${mode} ${entity} ${postfix}`;
        if (!args) return <></>;
        return (
          <>
            <ModalHeader title={title} />
            <ModalContent>
              <Box kind={'vflex'} gap='l'>
                <Box kind={'hgrid'}>
                  <Spacer w='1' />
                  <FormContainerV2 {...newComment} w='4'>
                    <InputV2 {...newComment} />
                  </FormContainerV2>
                  <Spacer w='1' />
                </Box>
                <HighlightBox className={styles.highlightBox} ico={<Ico size='24px' fill='primary-500' file={<InfoIcon />} />}>
                  {_t("The cost center must be appropriate, relevant and not excessive. Labels must therefore remain objective, not be insulting or undermine a person's dignity.")}
                </HighlightBox>
              </Box>
            </ModalContent>
            <ModalFooter>
              <ModalCancelButton onClick={() => close(false)} />
              <Divider kind='v' />
              <ModalAcceptButton
                onClick={async () => {
                  if (await formState.submit()) close(newComment.value);
                }}
              >
                {_t('Save')}
              </ModalAcceptButton>
            </ModalFooter>
          </>
        );
      }}
    </Modal>
  );
}
