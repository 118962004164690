import { ReactComponent as StationIco } from '../../assets/charging-station-blue.svg';
import { ReactComponent as CardIco } from '../../assets/color-card.svg';
import { ReactComponent as ConnectorIco } from '../../assets/plug-blue.svg';
import { ReactComponent as SpinnerIco } from '../../assets/spinner.svg';
import { ReactComponent as UserIco } from '../../assets/users.svg';
import { useApi } from '../../hooks/useApi';
import api from '../../services/api';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './node-tooltip.module.scss';

export default function NodeTooltip(props: { clientCode: string; nodeCode: string; nodeTitle: string }) {
  const { _t } = useBetterTranslate('node-tooltip');

  const [resourcesResp, resourcesFetching] = useApi(
    {
      call: async (clientCode?: string, nodeCode?: string) => {
        if (!clientCode || !nodeCode) return undefined;
        const resp = await api.profile.getNodeResourcesCounts(
          {
            clientCode,
            nodeCode,
          },
          { cancelToken: `nodetooltip/${clientCode}/${nodeCode}` }
        );

        return resp;
      },
      map: (data) => {
        return data;
      },
    },
    props.clientCode,
    props.nodeCode
  );

  return (
    <div className={styles.root}>
      <div className={styles.title}>{props.nodeTitle}</div>
      {!resourcesFetching && resourcesResp && (
        <div className={styles.counts}>
          {resourcesResp.stationsCount > 0 && (
            <div className={styles.resources}>
              <StationIco />
              {resourcesResp.stationsCount}
            </div>
          )}
          {resourcesResp.connectorsCount > 0 && (
            <div className={styles.resources}>
              <ConnectorIco />
              {resourcesResp.connectorsCount}
            </div>
          )}
          {resourcesResp.cardsCount > 0 && (
            <div className={styles.resources}>
              <CardIco />
              {resourcesResp.cardsCount}
            </div>
          )}
          {resourcesResp.usersCount > 0 && (
            <div className={styles.users}>
              <UserIco />
              {resourcesResp.usersCount}
            </div>
          )}
          {resourcesResp.stationsCount === 0 && resourcesResp.connectorsCount === 0 && resourcesResp.cardsCount === 0 && resourcesResp.usersCount === 0 && (
            <div className={styles.noResources}>{_t('Keine Ressourcen oder Benutzer zugeordnet')}</div>
          )}
        </div>
      )}
      {resourcesFetching && (
        <div className={styles.progress}>
          <SpinnerIco />
        </div>
      )}
    </div>
  );
}
