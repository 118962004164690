import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import { ReactComponent as CheckIco } from '../assets/check.svg';
import useBetterTranslate from '../utils/translation-utils';
import styles from './filters.module.scss';

export interface ToggleButtonProps {
  className?: string;
  label?: ReactNode;
  ico?: ReactNode;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  // apperance: StateApperances;
  // apperance?: `available` | 'charging',
}
export function ToggleButton(props: PropsWithChildren<ToggleButtonProps>) {
  const cls = classNames({
    [styles.toggleBtn]: true,
    [styles.checked]: props.checked === true,
    // [styles.checked]: props.checked === true,
    // [styles[`apperance_${props.apperance || ''}`]]: true,
  });

  return (
    <button className={cls} type='button' onClick={() => props.onChange?.(!props.checked)}>
      {props.ico && <div className={styles.ico}>{props.ico}</div>}
      {props.label && <div className={styles.label}>{props.label}</div>}
      {props.children && props.children}
    </button>
  );
}

export type StateApperances = `available` | 'charging' | 'occupied' | 'defective' | 'notavailable' | 'unknown' | 'success' | 'undef' | 'alert';

export interface ToggleButtonStateIcoProps {
  apperance?: StateApperances;
}
export function ToggleButtonStateIco(props: ToggleButtonStateIcoProps) {
  const cls = classNames({
    [styles.stateIco]: true,
    [styles[`apperance_${props.apperance || ''}`]]: true,
  });

  return <div className={cls}></div>;
}

export function ToggleButtonX(props: Omit<ToggleButtonProps & { displayText?: string }, 'label' | 'ico'> & { apperance: StateApperances }) {
  const { _t } = useBetterTranslate(`filters`);
  const translations: Record<StateApperances, string> = {
    available: _t(`available`),
    charging: _t(`charging`),
    occupied: _t(`occupied`),
    defective: _t(`defective`),
    notavailable: _t(`notavailable`),
    unknown: _t(`unknown`),
    success: _t(`success`),
    alert: _t(`alert`),
    undef: _t(`undef`),
  };
  return (
    <ToggleButton {...props}>
      <div className={styles.ico}>
        <ToggleButtonStateIco apperance={props.apperance} />
      </div>
      <div className={styles.label}>{props.displayText || translations[props.apperance]}</div>
      <div className={styles.ico}>{props.checked && <CheckIco />}</div>
    </ToggleButton>
  );
}

export function ToggleButtonAvailable(props: Omit<ToggleButtonProps, 'label' | 'ico' | 'apperance'>) {
  return <ToggleButtonX {...props} apperance='available' />;
}
export function ToggleButtonCharging(props: Omit<ToggleButtonProps, 'label' | 'ico' | 'apperance'>) {
  return <ToggleButtonX {...props} apperance='charging' />;
}
export function ToggleButtonOccupied(props: Omit<ToggleButtonProps, 'label' | 'ico' | 'apperance'>) {
  return <ToggleButtonX {...props} apperance='occupied' />;
}
export function ToggleButtonDefective(props: Omit<ToggleButtonProps, 'label' | 'ico' | 'apperance'>) {
  return <ToggleButtonX {...props} apperance='defective' />;
}
export function ToggleButtonNotAvailable(props: Omit<ToggleButtonProps, 'label' | 'ico' | 'apperance'>) {
  return <ToggleButtonX {...props} apperance='notavailable' />;
}
export function ToggleButtonUnknown(props: Omit<ToggleButtonProps, 'label' | 'ico' | 'apperance'>) {
  return <ToggleButtonX {...props} apperance='unknown' />;
}
