import { useState } from 'react';
import { ButtonPrimary } from '../../../../components/button';
import OrgHierarchyPicker from '../../../../components/org-hierarchy/org-hierarchy-picker';
import Page, { ListingCardPlaceholder } from '../../../../components/page';
import { useApi } from '../../../../hooks/useApi';
import { useBetterNavigate } from '../../../../hooks/useBetterNavigate';
import { usePageParams } from '../../../../hooks/usePageParams';
import api from '../../../../services/api';
import { ClientStructureDto, Permission } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import OrgTabPanel from '../../shared/org-tab-panel';
import OrganisationHierarchyView from '../../shared/organisation-hierarchy-view';
import StructureEditHierarchyPopup, { NodeInfo } from '../structure-edit-hierarchy-popup/structure-edit-hierarchy-popup';
import styles from './structure-overview-page.module.scss';

export interface StructureOverviewPageProps {
  className?: string;
}

type FilterParameters = {
  clientCode?: string;
};

export default function StructureOverviewPage(props: StructureOverviewPageProps) {
  const { _t } = useBetterTranslate('structure-overview-page');
  const allPermissions = Object.values(Permission).map((k) => k as Permission);
  const navigate = useBetterNavigate<FilterParameters>();
  const [filterParams] = usePageParams<FilterParameters>({}, {});

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editOrg, setEditOrg] = useState<ClientStructureDto>();

  const [orgRes, orgFetching, orgErr, refresh] = useApi(
    {
      call: async (clientCode?: string) => {
        if (!clientCode) return undefined;
        const res = await api.orgStructure.getStructure(clientCode);
        return res;
      },
      map: (data) => {
        if (!data) return undefined;
        return data;
      },
    },
    filterParams.clientCode
  );

  const onEditClick = async () => {
    if (!filterParams.clientCode) return;
    const resp = await api.orgStructure.getClientStructure(filterParams.clientCode);
    setEditOrg(resp.data);
    setShowEditPopup(true);
  };

  return (
    <Page
      breadCrumb={[{ title: 'Organisation', href: '/organisation/structure/', active: true }]}
      fetching={orgFetching}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={orgErr}
      outOfPlaceHolder={
        <>
          {orgRes && (
            <OrgHierarchyPicker
              selectMode='client'
              clientContext={orgRes.clientContext}
              onClientSelected={(client) => navigate(`/organisation/structure/${client.code}`)}
              searchClients={(search) => api.profile.accessibleClientNodes({ search: search, permissions: allPermissions })}
            />
          )}

          {orgRes && <OrgTabPanel organisation={orgRes?.organization} selectedTab='structure' />}
          {orgRes && orgRes.canManageArea && (
            <ButtonPrimary ralign={true} onClick={onEditClick}>
              {_t('Bearbeiten')}
            </ButtonPrimary>
          )}
        </>
      }
    >
      <OrganisationHierarchyView fetching={orgFetching} organisation={orgRes?.organization} />

      <StructureEditHierarchyPopup
        open={showEditPopup}
        org={editOrg}
        onClose={() => {
          setShowEditPopup(false);
          refresh();
        }}
        onAddNode={async (node: NodeInfo) => {
          if (!filterParams.clientCode || !node.parentCode) return;
          const res = await api.orgStructure.createNode({
            clientCode: filterParams.clientCode,
            parentCode: node.parentCode,
            title: node.title,
            type: node.type,
            cpmsId: node.cpmsId || '',
          });
          setEditOrg(res.data);
        }}
        onEditNode={async (node: NodeInfo) => {
          if (!filterParams.clientCode || !node.code) return;
          const res = await api.orgStructure.updateNode({ clientCode: filterParams.clientCode, code: node.code, title: node.title, cpmsId: node.cpmsId || '' });
          setEditOrg(res.data);
        }}
        onDeleteNode={async (node: NodeInfo) => {
          if (!filterParams.clientCode || !node.code) return;
          const res = await api.orgStructure.deleteNode({ clientCode: filterParams.clientCode, code: node.code });
          setEditOrg(res.data);
        }}
        getUsersCount={async (node: NodeInfo) => {
          if (!filterParams.clientCode || !node?.code) return 0;
          const res = await api.orgStructure.getNodeUsersCount(filterParams.clientCode, node.code);
          return res.data.count;
        }}
      />
    </Page>
  );
}
