import DatePicker, { Day, DayValue } from '@amir04lm26/react-modern-calendar-date-picker';
import '@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { useRef, useState } from 'react';
import { ReactComponent as CalendarIco } from '../assets/calendar.svg';
import { ReactComponent as ClearIco } from '../assets/clear.svg';
import useBetterTranslate from '../utils/translation-utils';
import styles from './date-range-picker.module.scss';
import { useAuth } from '../utils/AuthProvider';
import { formatter } from '../utils/localized-types';

export const GERMAN_LOCALE = {
  // months list by order
  months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],

  // week days by order
  weekDays: [
    {
      name: 'Sunday', // used for accessibility
      short: 'S', // displayed at the top of days' rows
      isWeekend: true, // is it a formal weekend or not?
    },
    {
      name: 'Monday',
      short: 'M',
    },
    {
      name: 'Tuesday',
      short: 'D',
    },
    {
      name: 'Wednesday',
      short: 'M',
    },
    {
      name: 'Thursday',
      short: 'D',
    },
    {
      name: 'Friday',
      short: 'F',
    },
    {
      name: 'Saturday',
      short: 'S',
      isWeekend: true,
    },
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 0,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject: any) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date: any) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date: any) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit: any) {
    return digit;
  },

  // texts in the date picker
  nextMonth: 'Nächster Monat',
  previousMonth: 'Vorhergiger Monat',
  openMonthSelector: 'Öffne Monatsauswahl',
  openYearSelector: 'Öffne Jahresauswahl',
  closeMonthSelector: 'Schließe Monatsauswahl',
  closeYearSelector: 'Schließe Jahresauswahl',
  defaultPlaceholder: 'Wähle...',

  // for input range value
  from: 'von',
  to: 'bis',

  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
};

export const FRENCH_LOCALE = {
  // months list by order
  months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],

  // week days by order
  weekDays: [
    {
      name: 'Sunday', // used for accessibility
      short: 'D', // displayed at the top of days' rows
      isWeekend: true, // is it a formal weekend or not?
    },
    {
      name: 'Monday',
      short: 'L',
    },
    {
      name: 'Tuesday',
      short: 'M',
    },
    {
      name: 'Wednesday',
      short: 'M',
    },
    {
      name: 'Thursday',
      short: 'J',
    },
    {
      name: 'Friday',
      short: 'V',
    },
    {
      name: 'Saturday',
      short: 'S',
      isWeekend: true,
    },
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 0,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject: any) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date: any) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date: any) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit: any) {
    return digit;
  },

  // texts in the date picker
  nextMonth: 'le mois prochain',
  previousMonth: 'le mois précédent',
  openMonthSelector: 'ouvrir le sélecteur de mois',
  openYearSelector: "ouvrir le sélecteur d'année",
  closeMonthSelector: 'fermer le sélecteur de mois',
  closeYearSelector: "fermer le sélecteur d'année",
  defaultPlaceholder: 'choisissez...',

  // for input range value
  from: 'du',
  to: 'au',

  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
};

function toDayValue(from?: string): DayValue {
  if (!from) return null;
  let convertVal = moment(from);
  if (!convertVal) return null;

  const to = { year: convertVal.year(), month: convertVal.month() + 1, day: convertVal.date() };
  return to;
}

function convertRanges(range?: { from?: string; to?: string }) {
  return {
    from: toDayValue(range?.from),
    to: toDayValue(range?.to),
  };
}

export default function DateRangePicker(
  props: Readonly<{
    className?: string;
    maxDate?: Moment;
    minDate?: Moment;
    selected?: { from?: string; to?: string };
    onChange: (val: { from?: string; to?: string }) => void;
    dateFormat?: string;
    addOneDayToEndDate?: boolean;
    clearable?: boolean;
  }>
) {
  const { _t } = useBetterTranslate('date-range-picker');
  const { user } = useAuth();
  const languageCode = user?.preferences.languageCode;

  const MAX_DATE: Day | undefined = props.maxDate ? { year: props.maxDate.year(), month: props.maxDate.month() + 1, day: props.maxDate.date() } : undefined;
  const MIN_DATE: Day | undefined = props.minDate ? { year: props.minDate.year(), month: props.minDate.month() + 1, day: props.minDate.date() } : undefined;

  const [value, setValue] = useState<{ from: DayValue; to: DayValue }>(convertRanges(props.selected));

  const datePickerWrapperRef = useRef<HTMLDivElement | null>(null);
  const formatCustomDate = () => {
    if (!props?.selected?.from || !props?.selected?.to) return _t(`Datum wählen`);

    const from = moment(props.selected.from);
    let to = moment(props.selected.to);
    if (props.addOneDayToEndDate) to = to.subtract(1, 'days');
    if (!from || !to) return _t(`Datum wählen`);

    const customDateFrom = new Date(from.year(), from.month(), from.date());
    const customDateTo = new Date(to.year(), to.month(), to.date());
    return `${_t('von')} ${formatter.formatDate(customDateFrom, languageCode)} ${_t('bis')} ${formatter.formatDate(customDateTo, languageCode)}`;
  };

  return (
    <div className={classNames(props.className)} ref={datePickerWrapperRef}>
      <DatePicker
        locale={languageCode === 'de' ? GERMAN_LOCALE : languageCode === 'fr' ? FRENCH_LOCALE : undefined}
        value={value}
        maximumDate={MAX_DATE}
        minimumDate={MIN_DATE}
        onChange={(newVal) => {
          setValue(newVal);
          if (!newVal.from) return;
          if (!newVal.to) return;

          const from = moment({ day: newVal.from.day, month: newVal.from.month - 1, year: newVal.from.year });
          let to = moment({ day: newVal.to.day, month: newVal.to.month - 1, year: newVal.to.year });
          if (props.addOneDayToEndDate) to = to.add(1, 'days');

          let fromResult = from.toISOString();
          let toResult = to.toISOString();
          if (props.dateFormat) {
            fromResult = from.format(props.dateFormat);
            toResult = to.format(props.dateFormat);
          }

          props.onChange({ from: fromResult, to: toResult });
        }}
        calendarRangeStartClassName={styles.dateRangePickerStart}
        calendarRangeEndClassName={styles.dateRangePickerEnd}
        calendarRangeBetweenClassName={styles.dateRangePickerBetween}
        wrapperClassName={styles.dateRangePickerWrapper}
        calendarClassName={styles.dateRangePickerRoot}
        renderInput={({ ref }) => {
          return (
            <div
              className={classNames(styles.root, styles.dateRangePickerWrapper)}
              onClick={() => {
                ref.current?.focus();
              }}
            >
              <input readOnly className={classNames(styles.customInput, 'inputAnchor')} ref={ref as React.RefObject<HTMLInputElement>} value={formatCustomDate()} />
              {props.clearable && value?.from && value?.to && (
                <div
                  className={classNames(styles.clearIcon)}
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue({ from: null, to: null });
                    props.onChange({ from: undefined, to: undefined });
                  }}
                >
                  <ClearIco />
                </div>
              )}
              <CalendarIco />
            </div>
          );
        }}
      />
    </div>
  );
}

export function DateChooser(props: { className?: string; maxDate?: Moment; minDate?: Moment; selected?: string; onChange: (val: string) => void; dateFormat?: string }) {
  const { _t } = useBetterTranslate('date-range-picker');

  const { user } = useAuth();
  const languageCode = user?.preferences.languageCode || 'en';

  const MAX_DATE: Day | undefined = props.maxDate ? { year: props.maxDate.year(), month: props.maxDate.month() + 1, day: props.maxDate.date() } : undefined;
  const MIN_DATE: Day | undefined = props.minDate ? { year: props.minDate.year(), month: props.minDate.month() + 1, day: props.minDate.date() } : undefined;

  const [value, setValue] = useState<DayValue>(toDayValue(props.selected));

  // const datePickerWrapperRef = useRef<HTMLDivElement | null>(null);
  const formatCustomDate = () => {
    if (!props?.selected) return _t(`Datum wählen`);

    const selected = moment(props.selected);
    if (!selected) return _t(`Datum wählen`);
    const sep = _t('separator');
    return `${selected.date()}${sep}${selected.month() + 1}${sep}${selected.year()}`;
  };

  return (
    <div
      className={classNames(styles.root, props.className)}
      // ref={datePickerWrapperRef}
    >
      <DatePicker
        locale={languageCode === 'DE' ? GERMAN_LOCALE : languageCode === 'FR' ? FRENCH_LOCALE : undefined}
        value={value}
        maximumDate={MAX_DATE}
        minimumDate={MIN_DATE}
        onChange={(newVal) => {
          setValue(newVal);
          if (!newVal) return;

          const value = moment({ day: newVal.day, month: newVal.month - 1, year: newVal.year });

          let valueResult = value.toISOString();
          if (props.dateFormat) {
            valueResult = value.format(props.dateFormat);
          }

          props.onChange(valueResult);
        }}
        calendarRangeStartClassName={styles.dateRangePickerStart}
        calendarRangeEndClassName={styles.dateRangePickerEnd}
        calendarRangeBetweenClassName={styles.dateRangePickerBetween}
        wrapperClassName={styles.dateRangePickerWrapper}
        calendarClassName={styles.dateRangePickerRoot}
        renderInput={({ ref }) => {
          return (
            <div
              className={classNames(styles.root, styles.dateRangePickerWrapper)}
              onClick={() => {
                ref.current?.focus();
              }}
            >
              <input readOnly className={classNames(styles.customInput, 'inputAnchor')} ref={ref as React.RefObject<HTMLInputElement>} value={formatCustomDate()} />
              <CalendarIco />
            </div>
          );
        }}
      />
    </div>
  );
}
