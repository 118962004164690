import classNames from 'classnames';
import { ClientHierarchyNodeTypeDto, SharedClientHierarchyNodeDto } from '../../services/api-client/csp-api';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './node-hierarchy-label.module.scss';

export interface NodeHierarchyLabelProps {
  className?: string;
  code: string;
  allNodes: SharedClientHierarchyNodeDto[];
  hideClientRoot?: boolean;
  hideArea?: boolean;
  hideRegion?: boolean;
  hideLocation?: boolean;
  characterForNoValue?: string;
}

function getParentsWithDepth(item: SharedClientHierarchyNodeDto, allNodes: SharedClientHierarchyNodeDto[]) {
  let result: { depth: number; item: SharedClientHierarchyNodeDto }[] = [];
  const walkParents = (
    item: SharedClientHierarchyNodeDto,
    allNodes: SharedClientHierarchyNodeDto[],
    visit: (current: SharedClientHierarchyNodeDto, all: SharedClientHierarchyNodeDto[]) => void
  ) => {
    const parent = allNodes.find((p) => p.code === item.parentCode);
    if (!parent) return;
    visit(parent, allNodes);
    walkParents(parent, allNodes, visit);
  };

  let counter = 0;
  result.push({ item: item, depth: counter });
  walkParents(item, allNodes, (parent) => {
    counter -= 1;
    result.push({ item: parent, depth: counter });
  });

  for (const entry of result) {
    entry.depth += result.length;
  }

  result = result.sort((r1, r2) => (r1.depth > r2.depth ? 1 : -1));

  return result;
}

export default function NodeHierarchyLabel(
  props: NodeHierarchyLabelProps & { sortFunc?: (nodes: { item: SharedClientHierarchyNodeDto; depth: number }[]) => { item: SharedClientHierarchyNodeDto; depth: number }[] }
) {
  const { _t } = useBetterTranslate('node-hierarchy-label');

  const item = props.allNodes.find((n) => n.code === props.code);
  if (!item) {
    console.error(`could not find node w. code ${props.code} in all nodes prop`);
    return props.characterForNoValue ? <>{props.characterForNoValue}</> : <></>;
  }

  let hierarchy = getParentsWithDepth(item, props.allNodes);
  if (props.hideClientRoot) {
    hierarchy = hierarchy.filter((n) => n.item.type !== ClientHierarchyNodeTypeDto.ClientRoot);
  }
  if (props.hideArea) {
    hierarchy = hierarchy.filter((n) => n.item.type !== ClientHierarchyNodeTypeDto.Area);
  }
  if (props.hideRegion) {
    hierarchy = hierarchy.filter((n) => n.item.type !== ClientHierarchyNodeTypeDto.Region);
  }
  if (props.hideLocation) {
    hierarchy = hierarchy.filter((n) => n.item.type !== ClientHierarchyNodeTypeDto.Location);
  }
  hierarchy = hierarchy.sort((n1, n2) => (n1.depth > n2.depth ? 1 : -1));
  if (props.sortFunc) {
    hierarchy = props.sortFunc(hierarchy);
  }

  const content = hierarchy.map((n) => (n.item.autoCreated ? _t('Standardbereich') : n.item.title)).join(' - ');

  // hierarchy = [...hierarchy, ...hierarchy, ...hierarchy]

  // const items = hierarchy.map((n, i) => {
  //   return (
  //     <Fragment key={i}>
  //       <span className={classNames(styles.node)} key={n.item.code}>
  //         {n.item.title}
  //       </span>
  //       <span className={classNames(styles.sep)} key={n.item.code + 'sep'}>
  //         -
  //       </span>
  //     </Fragment>
  //   );
  // });

  return <div className={classNames(styles.root, props.className)}>{content}</div>;
}

export function NodeHierarchyLabelInverted(props: NodeHierarchyLabelProps) {
  return <NodeHierarchyLabel {...props} sortFunc={(hierarchy) => hierarchy.sort((n1, n2) => (n1.depth > n2.depth ? -1 : 1))}></NodeHierarchyLabel>;
}
