import { FormCard, FormCardBody } from '../../../components/form-card';
import TabPanel, { Tab, Tabs } from '../../../components/tab-panel';
import { useBetterNavigate } from '../../../hooks/useBetterNavigate';
import api from '../../../services/api';
import { SharedAffiliateContext } from '../../../services/api-client/csp-api';
import useBetterTranslate from '../../../utils/translation-utils';
import AffiliatePicker from './affiliate-picker';
import { ReactComponent as BuildingIco } from '../../../assets/building.svg';
import { ReactComponent as RolesIco } from '../../../assets/role.svg';
import { ReactComponent as UsersIco } from '../../../assets/users.svg';

export type SelectedTab = 'clients' | 'roles' | 'users';
export default function AdminTabPanel(props: {
  selectedTab: SelectedTab;
  affiliate: SharedAffiliateContext;
  can: { viewClientList: boolean; viewRoleList: boolean; viewUserList: boolean };
}) {
  const navigate = useBetterNavigate<any>();
  const { _t } = useBetterTranslate('admin-tab-panel');
  return (
    <>
      <AffiliatePicker
        affiliate={props.affiliate}
        searchAffiliates={(search) => {
          if (!props.affiliate) throw new Error(`affiliate context is null or undefined`);
          return api.profile.accessibleAffiliates({ search: search, permissions: props.affiliate.requestedPermissions || [] });
        }}
        onAffiliateSelected={(affiliate) => {
          navigate(`/administration/${props.selectedTab}/${affiliate?.code}`);
        }}
      />
      <FormCard phoneSize='full'>
        <FormCardBody>
          <TabPanel>
            <Tabs>
              {props.can.viewClientList && (
                <Tab
                  fillIco={true}
                  active={props.selectedTab === 'clients'}
                  txt={_t('Kunden')}
                  ico={<BuildingIco />}
                  onClick={() => navigate(`/administration/clients/${props.affiliate.code}`)}
                  dataCy={'tabClients'}
                ></Tab>
              )}
              {props.can.viewRoleList && (
                <Tab
                  fillIco={true}
                  active={props.selectedTab === 'roles'}
                  txt={_t('Rollen')}
                  ico={<RolesIco />}
                  onClick={() => navigate(`/administration/roles/${props.affiliate.code}`)}
                  dataCy={'tabRoles'}
                ></Tab>
              )}
              {props.can.viewUserList && (
                <Tab
                  strokeIco={true}
                  active={props.selectedTab === 'users'}
                  txt={_t('Benutzer')}
                  ico={<UsersIco />}
                  onClick={() => navigate(`/administration/users/${props.affiliate.code}`)}
                  dataCy={'tabUsers'}
                ></Tab>
              )}
            </Tabs>
          </TabPanel>
        </FormCardBody>
      </FormCard>
    </>
  );
}
