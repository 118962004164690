import api from '../services/api';
import { SharedClientContext } from '../services/api-client/csp-api';

export function createClientSearchProps(clientContext?: SharedClientContext) {
  // if (!clientContext) return undefined;
  // if (!clientContext.matchOtherClients) return undefined;

  const handler = async (search: string) => {
    if (!clientContext) throw new Error(`client context is null or undefiend`);

    const result = await api.profile.accessibleClientNodes({ search: search, permissions: clientContext.requestedPermissions });
    return result;
  };

  const result = { clientChangable: !!clientContext?.matchOtherClients, searchClients: handler };

  return result;
}
