import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import ifTrue from '../utils/class-name';
import styles from './tab-panel.module.scss';

export default function TabPanel(props: PropsWithChildren<{}>) {
  return <div className={classNames(styles.root)}>{props.children}</div>;
}

export function Tabs(props: PropsWithChildren<{}>) {
  return <div className={classNames(styles.tabs)}>{props.children}</div>;
}

export function Tab(
  props: PropsWithChildren<{
    txt?: string;
    lalign?: boolean;
    ico?: React.ReactNode;
    active?: boolean;
    fillIco?: boolean;
    strokeIco?: boolean;
    onClick?: () => void;
    dataCy?: string;
  }>
) {
  return (
    <div
      className={classNames(
        styles.tab,
        ifTrue(styles.lalign, props.lalign),
        ifTrue(styles.fillIco, props.fillIco),
        ifTrue(styles.strokeIco, props.strokeIco),
        ifTrue(styles.active, props.active)
      )}
      onClick={props.onClick}
      data-cy={props.dataCy}
    >
      {props.ico && <span className={classNames(styles.ico)}>{props.ico}</span>}
      {props.txt && <span className={classNames(styles.txt)}>{props.txt}</span>}
      {props.children && props.children}
    </div>
  );
}

export function TabContent(props: PropsWithChildren<{ className?: string; active?: boolean; noBackground?: boolean }>) {
  return (
    <section className={classNames(styles.tabContent, props.className, ifTrue(styles.active, props.active), ifTrue(styles.noBg, props.noBackground))}>{props.children}</section>
  );
}
