import classNames from 'classnames';
import { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import shortUUID from '../../services/short-id';
import Info from '../info';
import Pill from '../pill';
import styles from './chart-legend-table.module.scss';

export default function ChartLegendTable(props: {
  className?: string;
  items: {
    onClick?: () => void;
    title: string;
    dataCy: string;
    pillClass: string;
    value: string;
    info?: string;
    tooltip?: string;
    className?: string;
    hide?: boolean;
    blur?: boolean;
    dataCyValue?: string;
  }[];
}) {
  const tooltipId = useMemo(() => {
    return shortUUID();
  }, []);
  return (
    <div className={classNames(styles.root, props.className)}>
      <ReactTooltip id={tooltipId} place={'right'} type={'light'} className={styles.tooltip} />
      {props.items.map((item, i) => {
        return (
          <div data-cy={item.dataCy} key={i} className={classNames(styles.statsRow, item.className, item.hide ? styles.hide : '')}>
            <div data-for={item.tooltip ? tooltipId : ''} data-tip={item.tooltip || ''}>
              <Pill onClick={item.onClick} className={classNames(styles.pill, item.pillClass)}>
                &nbsp;
              </Pill>
            </div>
            <div className={styles.titleValue}>
              <div data-for={item.tooltip ? tooltipId : ''} data-tip={item.tooltip || ''}>
                {item.title}
                {item.info && <Info content={item.info} />}
              </div>
              <div data-cy={item.dataCyValue} className={item.blur ? styles.blurValue : ''}>
                {item.value}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export function ChartLegendTableWithoutPill(props: { className?: string; items: { dataCy?: string; title: string; value: string; info?: string; className?: string }[] }) {
  return (
    <div className={classNames(styles.root, props.className)}>
      {props.items.map((item, i) => {
        return (
          <div key={i} className={classNames(styles.statsRow, item.className)}>
            <div className={styles.titleValue}>
              <div>
                {item.title}
                {item.info && <Info content={item.info} />}
              </div>
              <div data-cy={item.dataCy}>{item.value}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
