import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { ReactComponent as BuildingIco } from '../../../../assets/building.svg';
import { ReactComponent as UsersIco } from '../../../../assets/users.svg';
import { ButtonAccent, ButtonPrimary } from '../../../../components/button';
import DataTable, { DataTableRow } from '../../../../components/data-table/data-table';
import { FormCard, FormCardBody } from '../../../../components/form-card';
import Page, { ListingCardPlaceholder } from '../../../../components/page';
import Paging from '../../../../components/paging';
import SearchInput from '../../../../components/search-input';
import TabPanel, { Tab, Tabs } from '../../../../components/tab-panel';
import { useApi } from '../../../../hooks/useApi';
import { usePageParams } from '../../../../hooks/usePageParams';
import api from '../../../../services/api';
import { pagingValidator, validateString } from '../../../../utils/queryParamValidators';
import useBetterTranslate from '../../../../utils/translation-utils';
import AdminCreatePopup from '../admin-create-popup/admin-create-popup';
import AdminDeletePopup from '../admin-delete-popup/admin-delete-popup';
import styles from './admin-list-page.module.scss';

export default function SystemAdminListPage() {
  const { _t } = useBetterTranslate('system-admin-list-page');
  const navigate = useNavigate();

  const SORT_ALLOWED_VALUES = ['firstName', 'mail'];
  const sortingValidator = {
    sortBy: validateString(SORT_ALLOWED_VALUES),
  };

  type FilterParameters = {
    skip?: number | null;
    limit?: number | null;
    search?: string | undefined;
    sortBy?: string;
    sortDesc?: string;
  };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, { ...pagingValidator, ...sortingValidator });
  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  const [usersRes, usersFetching, usersErr, refreshResult] = useApi(
    {
      call: async (skip?: number | null, limit?: number | null, query?: string | null, sortBy?: any, sortDesc?: string) => {
        const res = await api.systemAdmins.search({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          search: query || undefined,
          sortBy,
          sortDesc: sortDesc === '1',
        });

        return res;
      },
      map: (data) => {
        if (!data) return data;

        return data;
      },
    },
    filterParams.skip,
    filterParams.limit,
    filterParams.search,
    filterParams.sortBy,
    filterParams.sortDesc
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const navigateToAffiliatesPage = () => {
    navigate('/system/affiliates');
  };

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [editedMail, setEditedMail] = useState<string>();
  const onAddClick = () => {
    setEditedMail(undefined);
    setShowAddPopup(true);
  };
  const onEditClick = (mail: string) => {
    setEditedMail(mail);
    setShowAddPopup(true);
  };
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [mailToDelete, setMailToDelete] = useState('');
  const onDeleteClick = (mail: string) => {
    setMailToDelete(mail);
    setShowDeletePopup(true);
  };

  return (
    <Page
      breadCrumb={[{ title: _t('Administration'), href: '/system/admins', active: true }]}
      fetching={usersFetching}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={usersErr}
      outOfPlaceHolder={
        <>
          <FormCard phoneSize='full'>
            <FormCardBody>
              <TabPanel>
                <Tabs>
                  <Tab fillIco={true} txt={_t('Affiliates')} ico={<BuildingIco />} onClick={navigateToAffiliatesPage}></Tab>
                  <Tab active={true} strokeIco={true} txt={_t('Administrators')} ico={<UsersIco />}></Tab>
                </Tabs>
                {/* <TabContent active={true}></TabContent> */}
              </TabPanel>
            </FormCardBody>
          </FormCard>

          <FormCard tabletSize='full'>
            <FormCardBody className={classNames(styles.filters)}>
              <div className={styles.filterArea}>
                <SearchInput placeholder={_t('Administrators suchen')} maxLength={120} onChange={setSearchTerm} value={searchTerm} />
              </div>
              <ButtonPrimary ralign={true} onClick={onAddClick}>
                {_t('Administrator erstellen')}
              </ButtonPrimary>
            </FormCardBody>
          </FormCard>
        </>
      }
    >
      <FormCard tabletSize='full' className=''>
        <FormCardBody>
          <div className={styles.tableArea}>
            <DataTable
              sticky={true}
              sorting={{
                handler: (col, desc) => setFilterParams({ sortBy: col, sortDesc: desc ? '1' : undefined }),
                col: filterParams.sortBy,
                desc: filterParams.sortDesc === '1',
              }}
              records={usersRes?.entries || []}
              // hideHeader={true}
              renderer={{
                row: (record, cols) => (
                  <DataTableRow classNames={styles.row} key={record.mail}>
                    {cols}
                  </DataTableRow>
                ),
                cols: [
                  {
                    headerCol: () => <div>{_t('Name')}</div>,
                    name: 'firstName',
                    sortable: true,
                    col: (record) => <span>{record.fullName}</span>,
                    width: '30%',
                  },
                  {
                    headerCol: () => <div>{_t('E-Mail')}</div>,
                    name: 'mail',
                    sortable: true,
                    col: (record) => <span>{record.mail}</span>,
                    width: '30%',
                  },
                  {
                    headerCol: () => <div></div>,
                    name: 'actions',
                    sortable: false,
                    ralign: true,
                    col: (record) => (
                      <div className={styles.actions}>
                        <ButtonAccent onClick={() => onDeleteClick(record.mail)} className={styles.editBtn}>
                          {_t('Löschen')}
                        </ButtonAccent>
                        <ButtonPrimary onClick={() => onEditClick(record.mail)} className={styles.editBtn}>
                          {_t('Editieren')}
                        </ButtonPrimary>
                      </div>
                    ),
                    width: '40%',
                  },
                ],
              }}
            />
          </div>
        </FormCardBody>
      </FormCard>

      {usersRes && (
        <Paging
          skip={usersRes.skip}
          limit={usersRes.limit}
          total={usersRes.total}
          onChange={(arg) => {
            setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
          }}
        />
      )}

      <AdminCreatePopup open={showAddPopup} close={() => setShowAddPopup(false)} onSubmit={() => refreshResult()} mail={editedMail} />

      <AdminDeletePopup open={showDeletePopup} close={() => setShowDeletePopup(false)} onSubmit={() => refreshResult()} mail={mailToDelete} />
    </Page>
  );
}
