import { useMemo, useState } from 'react';
import { ReactComponent as InformationIco } from '../../../../assets/information.svg';
import EditPoup, { EditPopupRow } from '../../../../components/edit-popup';
import Input from '../../../../components/input';
import SingleSelect from '../../../../components/select/single-select';
import useFormState from '../../../../hooks/useFormState';
import { OrgUserDto, OrgUserRoleDto, OrgUserType } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import useEffectAsync from '../../../../utils/useEffectAsync';
import styles from './user-edit-info-popup.module.scss';
import api from '../../../../services/api';

export interface UserAccount {
  mail: string;
  firstName: string;
  lastName: string;
  defaultLanguage: string;
  roles?: OrgUserRoleDto[];
  existing?: boolean;
}
export default function OrgUserEditInfoPopup(props: {
  open: boolean;
  user?: UserAccount;
  defaultLang: string;
  clientCode?: string;
  onCancel: () => void;
  onSubmit: (user: UserAccount) => Promise<void>;
}) {
  const { _t } = useBetterTranslate('org-user-edit-info-popup');

  // Form state
  const formState = useFormState();
  const resetForm = formState.reset;

  const [userOfCurrentMailAddress, setUserOfCurrentMailAddress] = useState<OrgUserDto>();

  //first name
  const [firstNameField, setFirstName] = formState.useInputState({
    label: _t('Vorname'),
    placeholder: 'Max',
    maxLength: 128,

    initialValue: '',
    validate: async (val) => {
      if (!val) return _t('Dieses Feld darf nicht leer sein.');
      val = val.trim();
      if (val.length === 0) {
        return _t('Dieses Feld darf nicht leer sein.');
      }
      if (val.length > 128) {
        return _t(`Vorname darf nicht länger als 128 Zeichen sein.`);
      }
    },
  });

  //last name
  const [lastNameField, setLastName] = formState.useInputState({
    label: _t('Nachname'),
    placeholder: 'Mustermann',
    maxLength: 128,

    initialValue: '',
    validate: async (val) => {
      if (!val) return _t('Dieses Feld darf nicht leer sein.');
      val = val.trim();
      if (val.length === 0) {
        return _t('Dieses Feld darf nicht leer sein.');
      }
      if (val.length > 128) {
        return _t(`Nachname darf nicht länger als 128 Zeichen sein.`);
      }
    },
  });

  //mail
  const [mailField, setMail] = formState.useInputState({
    label: _t('E-Mail'),
    placeholder: 'Max.Mustermann@beispielmail.com',
    type: 'email',
    maxLength: 254,

    initialValue: '',
    validate: async (val) => {
      if (!val) {
        if (!!userOfCurrentMailAddress) {
          setUserOfCurrentMailAddress(undefined);
          resetForm();
        } else {
          return _t('Dieses Feld darf nicht leer sein.');
        }
      }

      val = val.trim();
      if (val.length > 254) {
        return _t('E-Mail darf nicht länger als 254 Zeichen sein.');
      }

      if (!/^[\S._%+-]+@[\S.-]+\.[A-Za-z]{2,}$/i.test(val)) {
        return _t('Ungültige E-Mail-Adresse.');
      }

      if (props.user?.mail === val) {
        return;
      }

      const existingUser = await api.orgUsers.getUserWithType(props.clientCode || '', mailField.value);
      if (existingUser.data.userType === OrgUserType.CURRENT_CLIENT) return _t('E-Mail ist bereits der Organisation zugeordnet.');
      else if (existingUser.data.userType === OrgUserType.NOT_CURRENT_CLIENT) {
        if (existingUser.data.user) {
          setFirstName(existingUser.data.user.firstName);
          setLastName(existingUser.data.user.lastName);
          setLangField(languages.find((l) => l.id === existingUser?.data?.user?.defaultLanguage) || languages[0]);
          setUserOfCurrentMailAddress(existingUser.data.user);
        }
      }
    },
  });

  const languages = useMemo(
    () => [
      { name: _t('English'), id: 'en' },
      { name: _t('German'), id: 'de' },
      { name: _t('French'), id: 'fr' },
    ],
    [_t]
  );
  const [langField, setLangField] = formState.useFieldState({
    initialValue: languages[0],
    label: _t('Language'),
    dataCy: 'language',
  });

  useEffectAsync(async () => {
    if (props.open) {
      resetForm();
      if (props.user) {
        //edit mode
        setFirstName(props.user.firstName);
        setLastName(props.user.lastName);
        setMail(props.user.mail);
        setLangField(languages.find((l) => l.id === props.user!.defaultLanguage) || languages[0]);
      } else {
        setLangField(languages.find((l) => l.id === props.defaultLang) || languages[0]);
      }
    }
  }, [props.open, props.defaultLang, languages]);

  //submission
  formState.handleSubmit(async () => {
    let userAccount: UserAccount;
    if (!!userOfCurrentMailAddress) {
      userAccount = {
        firstName: userOfCurrentMailAddress.firstName,
        lastName: userOfCurrentMailAddress.lastName,
        mail: userOfCurrentMailAddress.mail,
        defaultLanguage: langField.value.id,
        roles: userOfCurrentMailAddress.roles,
        existing: true,
      };
    } else {
      userAccount = {
        firstName: firstNameField.value.trim(),
        lastName: lastNameField.value.trim(),
        mail: mailField.value.trim(),
        defaultLanguage: langField.value.id,
        existing: false,
      };
    }
    await props.onSubmit(userAccount);
  });

  return (
    <EditPoup
      open={props.open}
      className={styles.popup}
      onClose={props.onCancel}
      onSave={async () => await formState.submit()}
      additionalFooterContentClassName={styles.submitError}
      additionalFooterContent={formState.submitError}
      bodyClassName={styles.popupBody}
      title={props.user ? _t('Benutzer Editieren') : _t('BENUTZER ERSTELLEN')}
      saveText={props.user ? _t('Speichern') : _t('Berechtigung hinzufügen')}
    >
      <EditPopupRow label={mailField.label} controlCassName={props.user ? styles.editEmail : ''}>
        {!props.user && <Input {...mailField} label='' onBlur={() => mailField.validate()} />}
        {props.user && <div>{props.user.mail}</div>}
      </EditPopupRow>

      <EditPopupRow label={firstNameField.label}>
        <Input {...firstNameField} label='' onBlur={() => firstNameField.validate()} />
      </EditPopupRow>

      <EditPopupRow label={lastNameField.label}>
        <Input {...lastNameField} label='' onBlur={() => lastNameField.validate()} />
      </EditPopupRow>

      <EditPopupRow label={langField.label} className={styles.langRow} validationErr={langField.validationErr}>
        <SingleSelect
          className={styles.inputField}
          options={languages}
          fetchOptions={async (v) => {
            return languages.filter((l) => l.name.toLowerCase().includes(v.toLowerCase()));
          }}
          createOption={(opt) => <div>{opt.name}</div>}
          renderDisplayValue={(v) => v.name}
          selectedValue={langField.value}
          onChanged={(v) => setLangField(v || languages[0])}

          // createOption={() => }
        />
      </EditPopupRow>

      <EditPopupRow label=' ' className={styles.passwordRow}>
        {!props.user && (
          <div className={styles.info}>
            <InformationIco />
            <span>
              {!!userOfCurrentMailAddress
                ? _t('Der Benutzer existiert bereits und erhält Zugriff auf diesen Kunden. Um den Namen des Benutzers zu ändern, nutzen Sie die Editierfunktion.')
                : _t('Nach dem Erstellen, erhält der Benutzer eine automatisierte E-mail zur Passwortvergabe.')}
            </span>
          </div>
        )}
      </EditPopupRow>
    </EditPoup>
  );
}
