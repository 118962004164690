import { Api, ApiError, HttpResponse } from './api-client/csp-api';

// const LOCAL_STORAGE_TKN_NAME = 'api-token';
let unauthorizedHandler = () => {};
const securityWorker = async (data: unknown): Promise<any> => {
  // USE COOKIES, nothing needs to be done!??!?
  //
  // let tkn = localStorage.getItem(LOCAL_STORAGE_TKN_NAME);
  // if (!tkn) {
  // throw new Error('auth token could not be found in local storage');
  // }
  //
  // return {
  // headers: {
  // Authorization: `Bearer ${tkn}`,
  // },
  // };
};

export function isHttpResponse<T>(object: any): object is HttpResponse<T, ApiError> {
  return 'data' in object;
}

const customFetch: typeof fetch = (...fetchParams: Parameters<typeof fetch>) => {
  let resPromise = fetch(...fetchParams);
  return resPromise.then((res) => {
    if (res.status === 401) {
      unauthorizedHandler();
    }
    return res;
  });
};

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const apiInternal = new Api({ baseUrl: REACT_APP_API_URL, securityWorker: securityWorker, customFetch: customFetch });

// const setAuthToken = (tkn: string) => {
//   localStorage.setItem(LOCAL_STORAGE_TKN_NAME, tkn);
// };
// const clearAuthToken = () => {
//   localStorage.removeItem(LOCAL_STORAGE_TKN_NAME);
// };
// const getAuthToken = () => {
//   return localStorage.getItem(LOCAL_STORAGE_TKN_NAME);
// };
const onUnauthorized = (handler: () => void) => {
  unauthorizedHandler = handler;
};

let api = {
  ...apiInternal,
  ...{ onUnauthorized },
};
export default api;

export type ApiType = typeof api;
