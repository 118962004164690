// import callerCallsite from 'caller-callsite';
import callsites from 'callsites';
import { v4 } from 'uuid';
import hash from 'hash-it';

export default function uniqueLineIdentifier() {
  const caller = callsites();

  let ident = v4();
  if (caller.length < 2) {
    console.warn(`could not find caller, return new ident: ${ident}`);
    return ident;
  }

  if (typeof caller === 'string') {
    return hash(caller);
  }

  const funcName = caller[1].getFunctionName();
  if (!funcName) {
    console.warn(`unknown func name return new ident: ${ident}`);
  }

  ident = funcName!;
  const line = caller[1].getLineNumber();
  const col = caller[1].getColumnNumber();

  const result = `${ident}:${line}:${col}`;
  return result;
}
