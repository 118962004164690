import classNames from 'classnames';
import { useEffect } from 'react';
import styles from './logout-page.module.scss';

export default function LogoutPage(props: {}) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace('/');
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  });
  return <div className={classNames(styles.root)}></div>;
}
