import classNames from 'classnames';
import { LegacyRef, MouseEvent, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import styles from './button.module.scss';

export type ButtonKind = 'primary' | 'accent' | 'warn' | 'danger' | 'icon';

export default function Button(
  props: PropsWithChildren<{
    kind?: ButtonKind;
    disabled?: boolean;
    href?: string;
    className?: string;
    ralign?: boolean;
    tooltip?: string;
    tabindex?: number;
    ref?: LegacyRef<HTMLButtonElement> | undefined;
    type?: 'submit' | 'reset' | 'button' | undefined;
    onClick?: (event: MouseEvent) => void;
    autoFocus?: boolean;
    submitSelector?: boolean;
    dataCy?: string;
  }>
) {
  const kind = props.kind || 'primary';
  const alignCls = props.ralign ? styles.allignRight : '';
  let dataAttributes: Record<string, any> = {};
  if (props.autoFocus) {
    dataAttributes['data-auto-focus'] = true;
  }
  if (props.submitSelector) {
    dataAttributes['data-submit-selector'] = true;
  }

  if (props.href) {
    return (
      <Link
        {...dataAttributes}
        title={props.tooltip}
        to={props.href}
        className={classNames(styles.root, props.className, styles[kind], alignCls, props.disabled === true ? styles.disabled : '')}
        onClick={props.disabled ? (e) => e.preventDefault() : props.onClick}
      >
        {props.children}
      </Link>
    );
  } else {
    return (
      <button
        {...dataAttributes}
        ref={props.ref}
        title={props.tooltip}
        tabIndex={props.tabindex}
        type={props.type || 'button'}
        disabled={props.disabled}
        className={classNames(styles.root, props.className, styles[kind], alignCls, props.disabled === true ? styles.disabled : '')}
        onClick={props.onClick}
        data-cy={props.dataCy}
      >
        {props.children}
      </button>
    );
  }
}

export function ButtonPrimary(
  props: PropsWithChildren<{
    autoFocus?: boolean;
    submitSelector?: boolean;
    disabled?: boolean;
    className?: string;
    href?: string;
    tooltip?: string;
    ralign?: boolean;
    type?: 'submit' | 'reset' | 'button' | undefined;
    onClick?: () => void;
    dataCy?: string;
  }>
) {
  return <Button {...props} kind={'primary'}></Button>;
}

export function ButtonAccent(
  props: PropsWithChildren<{
    autoFocus?: boolean;
    submitSelector?: boolean;
    disabled?: boolean;
    href?: string;
    className?: string;
    tooltip?: string;
    ralign?: boolean;
    type?: 'submit' | 'reset' | 'button' | undefined;
    onClick?: () => void;
    dataCy?: string;
  }>
) {
  return <Button {...props} kind={'accent'}></Button>;
}
