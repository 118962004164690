import classNames from 'classnames';
import { Fragment, ReactNode, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import shortUUID from '../../services/short-id';
import Info from '../info';
import Pill, { Apperance } from '../pill';
import Box from '../utils';
import styles from './chart-legend-table.module.scss';

export interface ChartLegendItem {
  onClick?: () => void;
  pillApperance?: Apperance;
  title: string;
  dataCy: string;
  // pillClass: string;
  value: string;
  info?: string;
  tooltip?: string;
  className?: string;
  hide?: boolean;
  blur?: boolean;
  dataCyValue?: string;
}
export interface ChartLegendCustomItem {
  node: ReactNode;
}
function isCustomItem(value: any): value is ChartLegendCustomItem {
  if (value.node) return true;
  else return false;
}

export default function ChartLegendTable(props: { className?: string; headline?: ReactNode; items: (ChartLegendItem | ChartLegendCustomItem)[] }) {
  const tooltipId = useMemo(() => {
    return shortUUID();
  }, []);
  return (
    <Box kind='vflex' gap='m' className={classNames(styles.root, props.className)}>
      <ReactTooltip id={tooltipId} place={'right'} type={'light'} className={styles.tooltip} />
      {props.headline && <Box fw='600'>{props.headline}</Box>}
      {props.items.map((item, i) => {
        if (isCustomItem(item)) return <Fragment key={i}>{item.node}</Fragment>;
        return (
          <Box kind='hflex' gap='xs' testId={item.dataCy} key={i} className={classNames(styles.statsRow, item.className, item.hide ? styles.hide : '')}>
            <Box data-for={item.tooltip ? tooltipId : ''} data-tip={item.tooltip || ''}>
              <Pill onClick={item.onClick} className={classNames(styles.pill)} apperance={item.pillApperance} />
            </Box>
            <Box justify='space-between' gap='s' flexGrow='1' kind='hflex' className={styles.titleValue}>
              <Box data-for={item.tooltip ? tooltipId : ''} data-tip={item.tooltip || ''}>
                {item.title}
                {item.info && <Info content={item.info} />}
              </Box>
              <Box data-cy={item.dataCyValue} className={item.blur ? styles.blurValue : ''}>
                {item.value}
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

export function ChartLegendTableWithoutPill(props: { className?: string; items: { dataCy?: string; title: string; value: string; info?: string; className?: string }[] }) {
  return (
    <div className={classNames(styles.root, props.className)}>
      {props.items.map((item, i) => {
        return (
          <div key={i} className={classNames(styles.statsRow, item.className)}>
            <div className={styles.titleValue}>
              <div>
                {item.title}
                {item.info && <Info content={item.info} />}
              </div>
              <div data-cy={item.dataCy}>{item.value}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
