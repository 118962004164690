import Page, { ListingCardPlaceholder } from '../../../components/page';
import styles from './org-stations-list-page.module.scss';
import { useApi } from '../../../hooks/useApi';
import { usePageParams } from '../../../hooks/usePageParams';
import api from '../../../services/api';
import { pagingValidator } from '../../../utils/queryParamValidators';
import useBetterTranslate from '../../../utils/translation-utils';
import OrganisationHierarchyView from '../shared/organisation-hierarchy-view';
import OrgHierarchyPicker from '../../../components/org-hierarchy/org-hierarchy-picker';
import OrgTabPanel from '../shared/org-tab-panel';
import { OrganisationViewDto, OrgStationsListItemDto, Permission } from '../../../services/api-client/csp-api';
import { useBetterNavigate } from '../../../hooks/useBetterNavigate';
import { NodeSelectionState, OrgDiagrammNodeProp } from '../../../components/org-hierarchy/org-diagramm-utils';
import { OrgHierarchyDiagrammContext } from '../../../components/org-hierarchy/org-hierarchy-diagramm';
import { ReactComponent as DisabledIco } from '../../../assets/node-disabled.svg';
import { ReactComponent as SelectedIco } from '../../../assets/node-selected-full.svg';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import classNames from 'classnames';
import { FormCard, FormCardBody } from '../../../components/form-card';
import SearchInput from '../../../components/search-input';
import SingleSelect from '../../../components/select/single-select';
import Pill from '../../../components/pill';
import DataTable, { DataTableRow } from '../../../components/data-table/data-table';
import NodeHierarchyLabel from '../../../components/org-hierarchy/node-hierarchy-label';
import CheckBox from '../../../components/input-check-box';
import { ButtonAccent, ButtonPrimary } from '../../../components/button';
import Paging from '../../../components/paging';
import uniqueLineIdentifier from '../../../utils/unique-line-ident';
import EditPoup from '../../../components/edit-popup';
import DeletePopup, { DeletePopupRow } from '../../../components/delete-popup';
import IconExplanationSection from '../../../components/icon-explanation-section';
import { ExternalHardwareIcon, PublicStationIcon } from '../../../components/evse-linfo-icons';
import colorStyles from '../../../components/style-utils';

export default function OrgStationsListPage() {
  type FilterParameters = {
    skip?: number | null;
    limit?: number | null;
    search?: string | undefined;
    ac?: string;
    dc?: string;
    acdc?: string;
    clientCode?: string;
    public?: string;
    sortBy?: string;
    sortDesc?: string;
  };
  const { _t } = useBetterTranslate('org-stations-list-page');
  const navigate = useBetterNavigate<FilterParameters>();
  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, { ...pagingValidator });
  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };
  const setNewFilterParams = (filter: FilterParameters) => {
    // Set all filter params to undefined except passed ones
    let property: keyof typeof filterParams;
    const undefinedFilterParams: FilterParameters = {};
    for (property in filterParams) {
      undefinedFilterParams[property] = undefined;
    }
    _setInnerFilterParams({
      ...undefinedFilterParams,
      ...filter,
    });
  };
  const MAX_SELECTED_STATIONS = 50;
  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const [selectedNodes, setSelectedNodes] = useState<OrgDiagrammNodeProp[]>([]);
  const [showStationsAssignPopup, setShowStationsAssignPopup] = useState(false);
  const [showStationsUnassignPopup, setShowStationsUnassignPopup] = useState(false);
  const [changeParentData, setChangeParentData] = useState<{ ctx: OrgHierarchyDiagrammContext; node: OrgDiagrammNodeProp; others: OrgDiagrammNodeProp[] } | undefined>(undefined);
  const [selectedStations, setSelectedStations] = useState<OrgStationsListItemDto[]>([]);
  const [selectedNodeInPopup, setSelectedNodeInPopup] = useState<OrgDiagrammNodeProp | undefined>(undefined);
  const [submitError, setSubmitError] = useState(filterParams.search);
  const [showRemoveSelectionPopup, setShowRemoveSelectionPopup] = useState(false);
  const [orgView, setOrgView] = useState<OrganisationViewDto>();
  const [hasExternalHardware, setHasExternalHardware] = useState(false);
  const [hasPublicStations, setHasPublicStations] = useState(false);

  const chargePointTypeOptions = [
    { name: 'AC', id: 'ac' },
    { name: 'DC', id: 'dc' },
    { name: 'AC/DC', id: 'acdc' },
  ];
  const [stationsResp, stationsFetching, stationsApiErr] = useApi(
    {
      call: async (
        clientCode?: string,
        limit?: number | null,
        skip?: number | null,
        query?: string | null,
        ac?: string,
        dc?: string,
        acdc?: string,
        selectedNodes?: OrgDiagrammNodeProp[],
        sortBy?: any,
        sortDesc?: string,
        selectedStations?: OrgStationsListItemDto[],
        isPublic?: string
      ) => {
        if (!clientCode) return undefined;
        const res = await api.orgStations.list(
          {
            clientCode: clientCode,
            limit: Number(limit || 20),
            skip: Number(skip || 0),
            search: query || undefined,
            ac: ac === '1',
            dc: dc === '1',
            acdc: acdc === '1',
            hierarchyNodeCodes: selectedNodes?.map((n) => n.code) || [],
            sortBy,
            sortDesc: sortDesc === '1',
            chargeBoxIdsToExclude: selectedStations?.map((station) => station.chargeBoxId) || [],
            isPublic: isPublic === '1' ? true : undefined,
          },
          { cancelToken: uniqueLineIdentifier() }
        );
        setOrgView(res.data.organization);
        setHasExternalHardware(!!res.data.stations.find((s) => s.isExternalHardware));
        setHasPublicStations(!!res.data.stations.find((s) => s.isPublic));
        return res;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.limit,
    filterParams.skip,
    filterParams.search,
    filterParams.ac,
    filterParams.dc,
    filterParams.acdc,
    selectedNodes,
    filterParams.sortBy,
    filterParams.sortDesc,
    selectedStations,
    filterParams.public
  );
  const hierarchy = [...(stationsResp?.clientContext.accessableNodes || []), ...(stationsResp?.clientContext?.forbiddenParents || [])];
  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const hasParentNodeSwitched = (node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]): boolean => {
    return node.selected === NodeSelectionState.None && others.filter((o) => o.selected === NodeSelectionState.Full && node.parentCode !== o.parentCode).length > 0;
  };

  const haveAllNodesBeenDeselected = (node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]): boolean => {
    return node.selected === NodeSelectionState.Full && others.filter((other) => other.selected === NodeSelectionState.Full).length === 1;
  };

  const deselectNodes = (nodes: OrgDiagrammNodeProp[]) => {
    for (const node of nodes) {
      node.selected = NodeSelectionState.None;
    }
  };

  const resetSelectionStates = (node: OrgDiagrammNodeProp | undefined, others: OrgDiagrammNodeProp[] | undefined) => {
    // The next state updates are not batched by read and lead to two requests to the backend api
    // When this happens, the first request is cancelled automatically by the system, but it would
    // be better if only one request would be triggered.
    // unstable_batch does not have an effect in this case (why?)
    // react upgrade to v18 may fix this
    setChangeParentData(undefined);
    setSearchTerm(undefined);
    setNewFilterParams({});
    setSelectedStations([]);
  };

  const changeParentNodeIfNecessary = (ctx: OrgHierarchyDiagrammContext | undefined, node: OrgDiagrammNodeProp | undefined, others: OrgDiagrammNodeProp[] | undefined) => {
    if (!node || !others || !ctx) {
      return;
    }
    if (hasParentNodeSwitched(node, others) || haveAllNodesBeenDeselected(node, others)) {
      actionsAfterParentNodeChange(ctx, node, others);
    }
  };

  const actionsAfterParentNodeChange = (ctx: OrgHierarchyDiagrammContext, node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]) => {
    resetSelectionStates(node, others);
    if (node.selected === NodeSelectionState.None) {
      deselectNodes(others);
      setSelectedNodes([node]);
    } else {
      setSelectedNodes([]);
    }
    ctx.refresh();
  };

  const onItemClick = (ctx: OrgHierarchyDiagrammContext, node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]) => {
    if (hasParentNodeSwitched(node, others) || haveAllNodesBeenDeselected(node, others)) {
      if (selectedStations.length > 0) {
        setChangeParentData({ ctx, node, others });
        return;
      } else {
        changeParentNodeIfNecessary(ctx, node, others);
      }
    }
    if (node.selected === NodeSelectionState.Full) {
      node.selected = NodeSelectionState.None;
    } else {
      node.selected = NodeSelectionState.Full;
    }
    setSelectedNodes(others.filter((n) => n.selected === NodeSelectionState.Full));
    ctx.refresh();
  };

  const onItemClickPopup = (ctx: OrgHierarchyDiagrammContext, node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]) => {
    if (node.selected === NodeSelectionState.Full) {
      node.selected = NodeSelectionState.None;
    } else {
      others.map((nd) => (nd.selected = NodeSelectionState.None));
      node.selected = NodeSelectionState.Full;
    }
    const selectedNodesInPopup = others.filter((other) => other.selected === NodeSelectionState.Full);
    if (selectedNodesInPopup.length > 1) {
      throw new Error('Too many selected nodes');
    } else if (selectedNodesInPopup.length === 1) {
      setSelectedNodeInPopup(selectedNodesInPopup[0]);
    }
    ctx.refresh();
  };

  const toggleSelectedStation = (stationToToggle: OrgStationsListItemDto): void => {
    const newSelectedStations = selectedStations.map((station) => station.chargeBoxId).includes(stationToToggle.chargeBoxId)
      ? selectedStations.filter((station) => stationToToggle.chargeBoxId !== station.chargeBoxId)
      : [...selectedStations, stationToToggle];
    setSelectedStations(newSelectedStations);
  };

  const compareStationByChargeBoxId = (station1: OrgStationsListItemDto, station2: OrgStationsListItemDto) => {
    return filterParams.sortDesc ? (station1.chargeBoxId < station2.chargeBoxId ? 1 : -1) : station2.chargeBoxId < station1.chargeBoxId ? 1 : -1;
  };

  const compareStationByManufacturer = (station1: OrgStationsListItemDto, station2: OrgStationsListItemDto) => {
    return filterParams.sortDesc ? (station1.manufacturer < station2.manufacturer ? 1 : -1) : station2.manufacturer < station1.manufacturer ? 1 : -1;
  };

  const orderedSelectedStations = filterParams.sortBy === 'manufacturer' ? selectedStations.sort(compareStationByManufacturer) : selectedStations.sort(compareStationByChargeBoxId);

  return (
    <Page
      breadCrumb={[{ title: _t('Organisation'), href: '/organisation', active: true }]}
      // Check filterParams.nodes size to immediately show advice to choose an area and not wait for api response
      fetching={selectedNodes.length > 0 && stationsFetching}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={stationsApiErr}
      outOfPlaceHolder={
        <>
          {stationsResp && (
            <OrgHierarchyPicker
              selectMode='client'
              clientContext={stationsResp.clientContext}
              onClientSelected={(client) => {
                setSelectedNodes([]);
                setSelectedStations([]);
                navigate(`/organisation/stations/${client.code}`);
              }}
              searchClients={(search) => api.profile.accessibleClientNodes({ search: search, permissions: [Permission.ClientOrgUserManage] })}
            />
          )}
          {stationsResp && <OrgTabPanel organisation={orgView!} selectedTab='stations' />}

          <OrganisationHierarchyView
            fetching={!orgView}
            organisation={orgView}
            nodesSelectable={true}
            onlyAreasAccessable={true}
            onItemClick={onItemClick}
            selectedNodes={selectedNodes.map((n) => n.code)}
          />
          <div className={styles.legend}>
            <span>
              <SelectedIco />
              {_t('Ausgewählt')}
            </span>
            <span>
              <DisabledIco />
              {_t('Nicht auswählbar')}
            </span>
          </div>

          {(hasExternalHardware || hasPublicStations) && (
            <FormCard phoneSize='full'>
              <FormCardBody>
                <IconExplanationSection showExternalHardware={hasExternalHardware} showPublicStations={hasPublicStations} />
              </FormCardBody>
            </FormCard>
          )}

          <FormCard tabletSize='full'>
            <FormCardBody className={classNames(styles.filtersAndButtons)}>
              <div className={styles.filters}>
                <div>
                  <SearchInput placeholder={_t('Stationen suchen')} maxLength={120} onChange={setSearchTerm} value={searchTerm} />
                </div>
                <div className={styles.chargePointTypeContainer}>
                  <SingleSelect<{ name: string; id: string }>
                    className=''
                    placeholder={_t('Stromart (AC/DC)')}
                    options={chargePointTypeOptions}
                    createOption={(opt) => <div className={styles.outletNumber}>{opt.name}</div>}
                    renderDisplayValue={(val) => val.name}
                    selectedValue={null}
                    fetchOptions={async (txt) => {
                      if (!txt) return chargePointTypeOptions || [];
                      const results = (chargePointTypeOptions || []).filter((item) => item.name.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                      return results;
                    }}
                    onChanged={(selected) => {
                      if (selected?.id === 'ac') {
                        setFilterParams({ ac: '1' });
                      } else if (selected?.id === 'dc') {
                        setFilterParams({ dc: '1' });
                      } else if (selected?.id === 'acdc') {
                        setFilterParams({ acdc: '1' });
                      }
                    }}
                    isClearable={true}
                  />
                </div>
                <div>
                  <Pill
                    selectedClass={colorStyles.components.rateType.public}
                    unselectedClass={colorStyles.components.rateTypeInvert.public}
                    selected={filterParams.public === '1'}
                    onClick={() => setFilterParams({ public: filterParams.public === '1' ? undefined : '1' })}
                  >
                    {_t('Public Service')}
                  </Pill>
                </div>
              </div>
              <div>
                <ButtonAccent
                  onClick={() => setShowStationsUnassignPopup(true)}
                  disabled={selectedStations.length <= 0}
                  className={classNames(styles.removeSelectionButton, selectedStations.length <= 0 ? styles.withOpacity : undefined)}
                >
                  {_t('Zuordnung aufheben')}
                </ButtonAccent>
                <ButtonPrimary
                  onClick={() => setShowStationsAssignPopup(true)}
                  disabled={selectedStations.length <= 0}
                  className={selectedStations.length <= 0 ? styles.withOpacity : undefined}
                >
                  {_t('Ausgewählte Stationen zuordnen')}
                </ButtonPrimary>
              </div>
            </FormCardBody>
          </FormCard>

          <FormCard phoneSize='full' className={classNames(styles.selectedTags)}>
            {filterParams?.ac && (
              <div>
                <Pill
                  key={'ac'}
                  className={classNames(styles.appliedTag)}
                  onClick={() => {
                    setFilterParams({
                      ac: undefined,
                    });
                  }}
                >
                  AC
                </Pill>
              </div>
            )}

            {filterParams?.dc && (
              <div>
                <Pill
                  key={'dc'}
                  className={classNames(styles.appliedTag)}
                  onClick={() => {
                    setFilterParams({
                      dc: undefined,
                    });
                  }}
                >
                  DC
                </Pill>
              </div>
            )}

            {filterParams?.acdc && (
              <div>
                <Pill
                  key={'acdc'}
                  className={classNames(styles.appliedTag)}
                  onClick={() => {
                    setFilterParams({
                      acdc: undefined,
                    });
                  }}
                >
                  AC/DC
                </Pill>
              </div>
            )}
          </FormCard>

          <FormCard className={styles.selectedStationsData}>
            <div className={styles.selectedStationsCount}>{`${_t('Ausgewählte Stationen')} (${selectedStations.length}/${MAX_SELECTED_STATIONS}):`}</div>
            <ButtonAccent
              onClick={() => {
                setShowRemoveSelectionPopup(true);
              }}
              disabled={selectedStations.length <= 0}
              className={classNames(styles.removeSelectionButton, selectedStations.length <= 0 ? styles.withOpacity : undefined)}
            >
              {_t('Auswahl verwerfen')}
            </ButtonAccent>
          </FormCard>
        </>
      }
    >
      <>
        <FormCard tabletSize='full' className=''>
          <FormCardBody>
            {selectedNodes.length <= 0 && selectedStations.length <= 0
              ? _t('Bitte wählen Sie einen Bereich aus')
              : selectedNodes.length > 0 &&
                stationsResp && (
                  <>
                    <div className={styles.tableArea}>
                      <DataTable
                        sticky={true}
                        sorting={{
                          handler: (col, desc) => setFilterParams({ sortBy: col, sortDesc: desc ? '1' : undefined }),
                          col: filterParams.sortBy,
                          desc: filterParams.sortDesc === '1',
                        }}
                        records={[...orderedSelectedStations, ...(stationsResp?.stations || [])]}
                        renderer={{
                          row: (record, cols) => (
                            <DataTableRow
                              classNames={selectedStations.map((s) => s.chargeBoxId).includes(record.chargeBoxId) ? styles.selectedStationRow : undefined}
                              key={record.chargeBoxId}
                            >
                              {cols}
                            </DataTableRow>
                          ),
                          cols: [
                            {
                              name: 'checkBox',
                              sortable: false,
                              col: (record) => (
                                <CheckBox
                                  key={record.chargeBoxId}
                                  label=''
                                  disabled={
                                    selectedStations.length >= MAX_SELECTED_STATIONS && !selectedStations.map((station) => station.chargeBoxId).includes(record.chargeBoxId)
                                  }
                                  isSelected={selectedStations.map((station) => station.chargeBoxId).includes(record.chargeBoxId) || false}
                                  onChange={() => toggleSelectedStation(record)}
                                />
                              ),
                              width: '5%',
                            },
                            {
                              headerCol: () => (
                                <div className={'styles.header'}>
                                  <div>{_t('EVSE-ID')}</div>
                                  <div className={styles.subHeading}>{_t('Ladepunkte')}</div>
                                </div>
                              ),
                              name: 'chargeBoxId',
                              sortable: true,
                              colClass: () => styles.baseDataCol,
                              col: (record) => (
                                <>
                                  <div className={styles.chargeBoxId}>
                                    <span>{record.chargeBoxId}</span>
                                    <ExternalHardwareIcon isExternalHardware={record.isExternalHardware} />
                                    <PublicStationIcon isPublic={record.isPublic} />
                                  </div>
                                  <div>
                                    {record.connectorsTotalCount} {record.connectorsTotalCount === 1 ? _t('Ladepunkt') : _t('Ladepunkte')}
                                    {record.connectorsTotalCount > 0 && ` |  ${record.mergedChargePointType || ''}`}
                                  </div>
                                </>
                              ),
                              width: '20%',
                            },
                            {
                              headerCol: () => (
                                <>
                                  <div>{_t('Gebiet')}</div>
                                  <div className={styles.subHeading}>
                                    {_t('Standort')} - {_t('Bereich')}
                                  </div>
                                </>
                              ),
                              name: _t('area'),
                              sortable: false,
                              headerClass: styles.header,
                              colClass: (record) => styles.areaCol,
                              col: (record) => (
                                <>
                                  <NodeHierarchyLabel allNodes={hierarchy} code={record.nodeCode} hideClientRoot={true} hideLocation={true} hideArea={true} />
                                  <NodeHierarchyLabel allNodes={hierarchy} code={record.nodeCode} hideClientRoot={true} hideRegion={true} />
                                </>
                              ),
                              width: '45%',
                            },
                            {
                              headerCol: () => (
                                <div className={styles.header}>
                                  <div>{`${_t('Hersteller')} - ${_t('Modell')}`}</div>
                                  <div className={styles.subHeading}>{_t('Seriennummer')}</div>
                                </div>
                              ),
                              name: 'manufacturer',
                              sortable: true,
                              colClass: (record) => styles.extendedDataCol,
                              col: (record) => (
                                <>
                                  <div>
                                    <span>{`${record.manufacturer}  - `}</span>
                                    <span>{record.model}</span>
                                  </div>
                                  <div>
                                    <span>{record.serialNumber}</span>
                                  </div>
                                </>
                              ),
                              width: '30%',
                            },
                          ],
                        }}
                      />
                    </div>
                    <Paging
                      skip={stationsResp.skip}
                      limit={stationsResp.limit}
                      total={stationsResp.total}
                      onChange={(arg) => {
                        setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
                      }}
                    />
                  </>
                )}
          </FormCardBody>
          <EditPoup
            open={showStationsAssignPopup}
            bodyClassName={styles.stationsAssignPopup}
            actionsClassName={styles.nodePickerFooter}
            onClose={() => {
              setSelectedNodeInPopup(undefined);
              setShowStationsAssignPopup(false);
              setSubmitError(undefined);
            }}
            onSave={async () => {
              try {
                if (!selectedNodeInPopup) {
                  throw new Error('Selected node not found');
                }
                const path = hierarchy.find((n) => n.code === selectedNodeInPopup.code)?.path;
                if (!path) {
                  throw new Error('Path not found');
                }
                const res = await api.orgStations.assignStationsToNode({
                  clientCode: filterParams.clientCode!,
                  chargeBoxIds: selectedStations.map((s) => s.chargeBoxId),
                  clientHierarchy: path,
                  targetNodeCode: selectedNodeInPopup.code,
                  sourceNodeCodes: selectedNodes.map((n) => n.code),
                });
                setOrgView(res.data);
                setSelectedNodeInPopup(undefined);
                setSelectedNodes([]);
                setSelectedStations([]);
                setShowStationsAssignPopup(false);
              } catch (err: any) {
                setSubmitError('Ein unerwarteter Fehler ist aufgetreten');
                throw err;
              }
            }}
            showCancel={true}
            saveText={`(${selectedStations.length}) ${_t('Stationen zuordnen')}`}
            saveButtonDisabled={selectedStations.length <= 0 || !selectedNodeInPopup}
            title={' '}
            additionalFooterContent={submitError}
            additionalFooterContentClassName={styles.submitError}
          >
            <OrganisationHierarchyView
              fetching={!orgView}
              organisation={orgView}
              nodesSelectable={true}
              onlyAreasAccessable={true}
              onItemClick={onItemClickPopup}
              accessableNodes={selectedNodes ? selectedNodes.map((n) => n.parentCode || '') : ['']}
              selectedNodes={selectedNodeInPopup ? [selectedNodeInPopup.code] : []}
            />
          </EditPoup>
          <DeletePopup
            open={showStationsUnassignPopup}
            bodyClassName={styles.stationsAssignPopup}
            actionsClassName={styles.nodePickerFooter}
            onClose={() => {
              setShowStationsUnassignPopup(false);
              setSubmitError(undefined);
            }}
            onDelete={async () => {
              try {
                await api.orgStations.unassignStationsFromNode({
                  clientCode: filterParams.clientCode!,
                  chargeBoxIds: selectedStations.map((s) => s.chargeBoxId),
                  nodeCodes: selectedNodes.map((n) => n.code),
                  parentCode: selectedNodes[0]?.parentCode!,
                });
                setShowStationsUnassignPopup(false);
                setSelectedNodes([]);
                setSelectedStations([]);
              } catch (err: any) {
                setSubmitError('Ein unerwarteter Fehler ist aufgetreten');
                throw err;
              }
            }}
            deleteText={_t('Bestätigen')}
            title={_t('Zuordnung aufheben')}
            additionalFooterContent={submitError}
            additionalFooterContentClassName={styles.submitError}
          >
            <DeletePopupRow>
              {_t(`Sind Sie sicher, dass die Zuordnung von {{cpCount}} gewählten Stationen aufgehoben werden soll?`, { cpCount: selectedStations.length })}
            </DeletePopupRow>
            <DeletePopupRow>
              {_t(`Diese werden dem Knoten "Standardbereich" zugewiesen. Stationen, die diesem Knoten bereits zugeordnet waren, werden nicht geändert.`)}
            </DeletePopupRow>
          </DeletePopup>
          <DeletePopup
            open={changeParentData !== undefined || showRemoveSelectionPopup}
            className={styles.popup}
            onDelete={() => {
              resetSelectionStates(changeParentData?.node, changeParentData?.others);
              changeParentNodeIfNecessary(changeParentData?.ctx, changeParentData?.node, changeParentData?.others);
              setShowRemoveSelectionPopup(false);
            }}
            onClose={() => {
              setChangeParentData(undefined);
              setShowRemoveSelectionPopup(false);
            }}
            title={_t('Auswahl verwerfen')}
            deleteText={_t('Bestätigen')}
          >
            <div className={styles.infoText}>{_t(`Sind Sie sicher, dass Sie Ihre Auswahl von {{cpCount}} Stationen verwerfen wollen?`, { cpCount: selectedStations.length })}</div>
          </DeletePopup>
        </FormCard>
      </>
    </Page>
  );
}
