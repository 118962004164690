import moment from 'moment';
import 'moment-duration-format';
import { formatter } from './localized-types';

export function formatDuration(from?: string, to?: string) {
  if (!from) return '';
  if (!to) return '';
  const start = moment(from);
  const end = moment(to);

  const diff = end.diff(start);
  //const result = moment.utc(diff).format('HH:mm'); //will not work correctly if the duration is more than 24 hours.
  const result = (moment.duration(diff, 'ms') as any).format('HH:mm', { trim: false });
  return result;
}

export function formatPrice(price?: number, languageCode?: string, minimumFractionDigits?: number, maximumFractionDigits?: number) {
  if (price === null || price === undefined || !Number.isFinite(price)) return '';
  const result = `${formatter.formatNumber(Math.round(price * 100) / 100, languageCode, minimumFractionDigits, maximumFractionDigits)}`;
  return result;
}

export function formatEnergy(energy?: number, languageCode?: string, minimumFractionDigits?: number, maximumFractionDigits?: number) {
  if (energy === null || energy === undefined) return '';
  const result = `${formatter.formatNumber(Math.round((energy / 1000.0) * 100) / 100, languageCode, minimumFractionDigits, maximumFractionDigits)}`;
  return result;
}

export function formatKWhEnergy(energy?: number, languageCode?: string, minimumFractionDigits?: number, maximumFractionDigits?: number) {
  if (energy === null || energy === undefined) return '';
  return `${formatter.formatNumber(energy, languageCode, minimumFractionDigits, maximumFractionDigits)} kWh`;
}

export function formatPercentage(val: number, languageCode?: string, minimumFractionDigits?: number, maximumFractionDigits?: number) {
  if (!Number.isFinite(val)) return '';
  // if (val <= 7) return '';
  return `${formatter.formatNumber(val, languageCode, minimumFractionDigits, maximumFractionDigits)}%`;
}
