import moment, { Moment } from 'moment';

//validate functions
export const validateMinDate =
  (minDate: Moment) =>
  (value: any): boolean => {
    // Compare dates only, bug: TECS-1105
    const from = moment(value).startOf('day');
    const minDateStartOfDay = minDate.clone();
    minDateStartOfDay.startOf('day');
    return from.isValid() && !moment(value).isBefore(minDateStartOfDay);
  };

export const validateMaxDate =
  (maxDate: Moment) =>
  (value: any): boolean => {
    const to = moment(value);
    return to.isValid() && !moment(value).isAfter(maxDate);
  };

export const validatePositiveInteger = (value: any): boolean => {
  const intValue = Number.parseInt(value);
  return !Number.isNaN(intValue) && intValue > 0;
};

export const validatePositiveFloat = (value: any): boolean => {
  const floatValue = Number.parseFloat(value);
  return !Number.isNaN(floatValue) && floatValue > 0;
};

export const validateString =
  (allowedValues: string[]) =>
  (value: any): boolean => {
    return allowedValues.includes(value);
  };

export const validateArrayOfString =
  (allowedValues: string[]) =>
  (value: any): boolean => {
    if (!Array.isArray(value)) return false;
    for (const item of value) {
      if (!allowedValues.includes(item)) return false;
    }
    return true;
  };
export const validateNodes = (value: any): boolean => {
  return true;
};

//default validators
export const dateRangeValidator = (minDate: Moment, maxDate: Moment) => {
  const map = {
    from: validateMinDate(minDate),
    to: validateMaxDate(maxDate),
  };
  return map;
};

export const pagingValidator = () => {
  const map = {
    skip: validatePositiveInteger,
    limit: validatePositiveInteger,
  };
  return map;
};

export const hierarchyValidator = {
  nodes: (value: any): boolean => {
    if (!Array.isArray(value)) return false;
    if (value.length === 0) return false;
    return true;
  },
};
