import classNames from 'classnames';
import { components, ControlProps, GroupBase, MultiValueGenericProps, MultiValueRemoveProps, OptionProps } from 'react-select';
import styles from './tag-selector.module.scss';

import { Fragment, ReactNode, useMemo } from 'react';
import AsyncSelect from 'react-select/async';
import { ReactComponent as CloseIco } from '../assets/close.svg';
import { ReactComponent as MagnifyingIco } from '../assets/magnifying-glass.svg';
import useBetterTranslate from '../utils/translation-utils';
import Button from './button';

export interface TagOptionProp {
  id: string;
}

export interface TagSelectorProps<TagType> {
  fetchOptions: (input: string) => Promise<TagType[]>;
  createOption: (val: TagType, optProps: OptionProps<TagType, true, GroupBase<TagType>>) => ReactNode;
  createTag: (val: TagType) => ReactNode;
  createRemove?: (val: TagType) => ReactNode;
  removeBtnClass?: (val: TagType) => string;
  displayStyle?: 'search' | 'input';
  placeholder?: string;
  onChanged: (values: TagType[]) => void;
  selectedValues: TagType[];
  classNames?: string;
  dataCy?: string;
}

// function TagOption<TTag extends TagOptionProp>(props: OptionProps<TTag, false, GroupBase<TTag>>){
//   return (
//     <components.Option {...props} className={classNames(props.className, styles.usrSelectOption, props.isFocused ? styles.focused : '')}>
//       <UserAvatar round={true} className={styles.usrIco} mail={props.data.mail} name={props.data.name} />
//       <span>{props.data.name}</span>
//     </components.Option>

//   );
// }

export default function TagSelector<TagType extends TagOptionProp>(props: TagSelectorProps<TagType>) {
  const { _t } = useBetterTranslate('tag-selector');
  const loadOpts = useMemo(() => {
    return (inputValue: string, callback: (options: TagType[]) => void) => {
      props
        .fetchOptions(inputValue)
        .then(callback)
        .catch((e) => {
          if (e instanceof DOMException && e.name === 'AbortError') callback([]);
          else throw e;
        });
    };
  }, [props]);

  // const multiValComponent = useMemo(() => {
  //   const comp = function MultiValueComponent(optProps: MultiValueGenericProps<TagType>){
  //     // const option = props.createOption(optProps.data, optProps);

  //     return (
  //       <div style={{backgroundColor: 'red'}}>
  //         <components.MultiValueContainer {...optProps} />
  //       </div>
  //     );
  //   };

  //   return comp
  // }, [props])
  const multiValLabelComponent = useMemo(() => {
    const comp = function MultiValueLabelComponent(optProps: MultiValueGenericProps<TagType>) {
      const tag = props.createTag(optProps.data);

      return <components.MultiValueLabel {...optProps}>{tag}</components.MultiValueLabel>;
    };

    return comp;
  }, [props]);

  const multiValRemoveComponent = useMemo(() => {
    const comp = function MultiValueRemoveComponent(optProps: MultiValueRemoveProps<TagType>) {
      const rmBtnCls = props.removeBtnClass?.(optProps.data) || '';
      optProps.innerProps.className = rmBtnCls;

      if (props.createRemove) {
        const rm = props.createRemove(optProps.data);
        return <components.MultiValueRemove {...optProps}>{rm}</components.MultiValueRemove>;
      } else {
        return <components.MultiValueRemove {...optProps} />;
      }
    };

    return comp;
  }, [props]);

  const controlComponent = useMemo(() => {
    const comp = function ControlComponent(optProps: ControlProps<TagType, true, GroupBase<TagType>>) {
      if (props.displayStyle === 'search') {
        return (
          <components.Control {...optProps} className={classNames(optProps?.className, styles.control)}>
            <div data-cy={props.dataCy ?? 'tag-selector'} className={styles.searchControlWrapper}>
              <Fragment>{optProps.children}</Fragment>
              <div className={styles.searchControlIco}>
                <MagnifyingIco />
              </div>
            </div>
          </components.Control>
        );
      } else return <components.Control {...optProps} />;
    };

    return comp;
  }, [props]);

  const tagOptComponent = useMemo(() => {
    const comp = function TagOption(optProps: OptionProps<TagType, true, GroupBase<TagType>>) {
      const option = props.createOption(optProps.data, optProps);

      return (
        <components.Option {...optProps} className={classNames(optProps.className, styles.tagSelectOption, optProps.isFocused ? styles.focused : '')}>
          {option}
        </components.Option>
      );
    };

    return comp;
  }, [props]);

  const IndicatorSeparator = props.displayStyle === 'search' ? () => null : components.IndicatorSeparator;
  const DropdownIndicator = props.displayStyle === 'search' ? () => null : components.DropdownIndicator;
  return (
    <div className={classNames(styles.root, props.classNames)}>
      <AsyncSelect
        // this component has a bug within it's caching
        // it use an JS object as a cache, therefor if a user enters the text 'hasOwnProperty' (as an example)
        // it try to override the property (function) 'hasOwnProperty' on the object and later on try to use this as a result
        // this cause an exceptio
        // if needed to cache we should investigate this further for now, just disable the cache.
        cacheOptions={false}
        isMulti={true}
        // menuIsOpen={true}
        isSearchable={true}
        // blurInputOnSelect={true}
        className={classNames(styles.tagSel, styles[props.displayStyle || 'input'])}
        classNamePrefix={'tagSelectPref'}
        noOptionsMessage={(val) => {
          if (!val.inputValue) return <span>{_t(`Bitte geben Sie ein Suchkriterium ein`)}</span>;
          return (
            <span>
              Keine Einträge für <b>{val.inputValue}</b> gefunden
            </span>
          );
        }}
        placeholder={props.placeholder}
        loadOptions={loadOpts}
        options={props.selectedValues}
        value={props.selectedValues}
        onChange={(newValues) => {
          const values = newValues.map((v) => v);
          props.onChanged(values);
        }}
        // menuPosition={'absolute'}
        // menuIsOpen={true}
        // defaultOptions={[{ id: 'fooo' }, { id: 'fooo0' }, { id: 'fooo1' }, { id: 'fooo2' }, { id: 'fooo3' }] as any}
        getOptionValue={(o) => o.id}
        // defaultOptions={true}

        // menuIsOpen={false}
        components={{
          Option: tagOptComponent,
          MultiValueLabel: multiValLabelComponent,
          MultiValueRemove: multiValRemoveComponent,
          IndicatorSeparator: IndicatorSeparator,
          DropdownIndicator: DropdownIndicator,
          Control: controlComponent,
          // IndicatorSeparator: props.style === 'search' ? (() => null ) : undefined
          // MultiValueContainer: multiValComponent,
          //   Control: UserControl,
          //   // SingleValue: OrgSingleValue,
          //   // Input: () => <span></span>
        }}
      />
    </div>
  );
}

export function CardListEntry(props: { card: { id: string; label: string }; onRemove?: () => void }) {
  const { _t } = useBetterTranslate('card-list-entry');

  return (
    <div className={styles.cardEntry}>
      <div className={styles.body}>
        <b>{props.card.id}</b>
        <i>{_t('Kartennummer')}</i>
        <b>{props.card.label}</b>
        <i>{_t('Kartenbezeichnung')}</i>
      </div>
      {props.onRemove && (
        <Button kind='icon' onClick={props.onRemove}>
          <CloseIco />
        </Button>
      )}
    </div>
  );
}
