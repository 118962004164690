import classNames from 'classnames';

import { PropsWithChildren, ReactNode } from 'react';
import { ReactComponent as CollapsedIco } from '../../assets/collapsed.svg';
import { ReactComponent as ExpandedIco } from '../../assets/expanded.svg';
import ifTrue from '../../utils/class-name';
import styles from './accordion.module.scss';

export default function Accordion(
  props: PropsWithChildren<{ expanded?: boolean; onExpand?: (expanded: boolean) => void; className?: string; headline?: ReactNode; onClick?: () => void; dataCy?: string }>
) {
  return (
    <section className={classNames(styles.root, props.className)}>
      <header className={classNames(styles.header, ifTrue(styles.expanded, props.expanded))} onClick={props.onClick}>
        <button type={'button'} className={classNames(styles.expander)} onClick={() => props.onExpand?.(!props.expanded)} data-cy='btn-expand'>
          {props.expanded && <CollapsedIco />}
          {!props.expanded && <ExpandedIco />}
        </button>
        <div className={styles.headerInner}>{props.headline}</div>
      </header>
      <div className={classNames(styles.content, ifTrue(styles.expanded, props.expanded))} data-cy={props.dataCy}>
        {props.children}
      </div>
    </section>
  );
}
