import { useEffect, useState } from 'react';
import useBetterTranslate from '../../../utils/translation-utils';
import { ApiError, HttpResponse, SharedAffiliateContext, SharedAffiliateDto } from '../../../services/api-client/csp-api';
import { FormCard, FormCardBody, FormCardProp, FormCardPropValue } from '../../../components/form-card';
import classNames from 'classnames';
import { CircleIco } from '../../../components/circle-ico';
import { Textfit } from 'react-textfit';
import { ButtonPrimary } from '../../../components/button';
import EditPoup from '../../../components/edit-popup';
import SingleSelect from '../../../components/select/single-select';
import { isHttpResponse } from '../../../services/api';
import styles from './affiliate-picker.module.scss';

export default function AffiliatePicker(props: {
  affiliate?: SharedAffiliateContext;
  searchAffiliates: (search: string) => Promise<SharedAffiliateDto[] | HttpResponse<SharedAffiliateDto[], ApiError>>;
  onAffiliateSelected: (affiliate: SharedAffiliateDto | null) => void;
}) {
  const { _t } = useBetterTranslate('affiliate-picker');

  // affiliate picker setup
  const [showPickerPopup, setShowPickerPopup] = useState(false);

  return (
    <FormCard phoneSize='full' className={classNames(styles.root)}>
      <FormCardBody>
        <div className={classNames(styles.affiliateSelectBar)}>
          <CircleIco colorStyle={'primary'}>
            <Textfit className={'csp-fit-text-v-center'}>{props.affiliate?.code || ''}</Textfit>
          </CircleIco>
          <FormCardProp>
            <FormCardPropValue className={styles.commonParent}>{props.affiliate?.title || ''}</FormCardPropValue>
          </FormCardProp>

          {props.affiliate?.matchOtherAffiliate && (
            <ButtonPrimary ralign={true} onClick={() => setShowPickerPopup(true)}>
              {_t('Affiliate wechseln')}
            </ButtonPrimary>
          )}
        </div>
      </FormCardBody>
      <SelectAffiliatePopup
        open={showPickerPopup}
        affiliate={props.affiliate}
        onCancel={() => {
          setShowPickerPopup(false);
        }}
        onAffiliateSelected={(affiliate: SharedAffiliateDto | null) => {
          setShowPickerPopup(false);
          if (affiliate) props.onAffiliateSelected({ code: affiliate.code, title: affiliate.title });
        }}
        searchAffiliates={async (search) => {
          const res = await props.searchAffiliates(search);
          if (isHttpResponse(res)) {
            return res.data.map((r) => r);
          } else {
            return res.map((r) => r);
          }
        }}
      />
    </FormCard>
  );
}

export function SelectAffiliatePopup(props: {
  open: boolean;
  affiliate?: { code: string; title: string };
  onCancel: () => void;
  searchAffiliates: (search: string) => Promise<SharedAffiliateDto[] | HttpResponse<SharedAffiliateDto[], ApiError>>;
  onAffiliateSelected: (client: SharedAffiliateDto | null) => void;
}) {
  const { _t } = useBetterTranslate('affiliate-select-popup');
  const [selectedAffiliate, setSelectedAffiliate] = useState<SharedAffiliateDto | null>(null);

  useEffect(() => {
    if (props.open && props.affiliate) {
      setSelectedAffiliate({ code: props.affiliate.code, title: props.affiliate.title });
    }
  }, [props.open, props.affiliate]);

  return (
    <EditPoup
      open={props.open}
      className={styles.popup}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onClose={props.onCancel}
      onSave={() => {
        if (selectedAffiliate && selectedAffiliate?.code !== props.affiliate?.code) {
          setTimeout(() => {
            props.onAffiliateSelected(selectedAffiliate);
          }, 100);
        } else {
          props.onCancel();
        }
      }}
      showCancel={true}
      saveText={_t('Übernehmen')}
      title={_t('Wählen Sie ein Affiliate')}
    >
      <SingleSelect<{ id: string; dto: SharedAffiliateDto }>
        className={styles.select}
        createOption={(opt) => {
          return (
            <div className={styles.affiliateOpt}>
              <b>{opt.dto.title}</b>
              <i>{opt.id}</i>
            </div>
          );
        }}
        maxMenuHeight={250}
        fetchOptions={async (search) => {
          const res = await props.searchAffiliates(search);
          if (isHttpResponse(res)) {
            return res.data.map((r) => ({ id: r.code, dto: r }));
          } else {
            return res.map((r) => ({ id: r.code, dto: r }));
          }
        }}
        options={true}
        renderDisplayValue={(v) => <>{v.dto.title}</>}
        selectedValue={selectedAffiliate ? { id: selectedAffiliate?.code, dto: selectedAffiliate } : null}
        onChanged={(val) => {
          if (!val) return;
          setSelectedAffiliate(val.dto);
        }}
      />
    </EditPoup>
  );
}
