import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BuildingIco } from '../../../../assets/building.svg';
import { ReactComponent as UsersIco } from '../../../../assets/users.svg';
import { ButtonAccent, ButtonPrimary } from '../../../../components/button';
import DataTable, { DataTableRow } from '../../../../components/data-table/data-table';
import EditPoup, { EditPopupRow } from '../../../../components/edit-popup';
import { FormCard, FormCardBody } from '../../../../components/form-card';
import Page, { ListingCardPlaceholder } from '../../../../components/page';
import Paging from '../../../../components/paging';
import SearchInput from '../../../../components/search-input';
import TabPanel, { Tab, Tabs } from '../../../../components/tab-panel';
import { useApi } from '../../../../hooks/useApi';
import { usePageParams } from '../../../../hooks/usePageParams';
import api from '../../../../services/api';
import { AffiliateDto } from '../../../../services/api-client/csp-api';
import { pagingValidator, validateString } from '../../../../utils/queryParamValidators';
import useBetterTranslate from '../../../../utils/translation-utils';
import AffiliateCreatePopup from '../affiliate-create-popup/affiliate-create-popup';
import styles from './affiliate-list-page.module.scss';

export default function AffiliateListPage() {
  const SORT_BY_VALUES = ['title'];
  const { _t } = useBetterTranslate('affiliate-list-page');
  const navigate = useNavigate();

  type FilterParameters = {
    skip?: number | null;
    limit?: number | null;
    search?: string | undefined;
    sortBy?: string;
    sortDesc?: string;
  };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, { ...pagingValidator, sortBy: validateString(SORT_BY_VALUES) });
  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const [affiliateToDelete, setAffiliateToDelete] = useState<AffiliateDto | undefined>();
  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  const [affiliatesRes, affiliatesFetching, affiliatesErr, refreshResult] = useApi(
    {
      call: async (skip?: number | null, limit?: number | null, query?: string | null, sortBy?: any, sortDesc?: string) => {
        const res = await api.affiliate.search({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          search: query || undefined,
          sortBy,
          sortDesc: sortDesc === '1',
        });

        return res;
      },
      map: (data) => {
        if (!data) return data;

        return data;
      },
    },
    filterParams.skip,
    filterParams.limit,
    filterParams.search,
    filterParams.sortBy,
    filterParams.sortDesc
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const navigateToAdminsPage = () => {
    navigate('/system/admins');
  };

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [selectedAffiliateCode, setSelectedAffiliateCode] = useState<string>();
  const onAddClick = () => {
    setSelectedAffiliateCode(undefined);
    setShowAddPopup(true);
  };
  const onEditClick = (affiliateCode: string) => {
    setSelectedAffiliateCode(affiliateCode);
    setShowAddPopup(true);
  };

  return (
    <Page
      breadCrumb={[{ title: _t('Affiliates'), href: '/system/affiliates', active: true }]}
      fetching={affiliatesFetching}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={affiliatesErr}
      outOfPlaceHolder={
        <>
          <FormCard phoneSize='full'>
            <FormCardBody>
              <TabPanel>
                <Tabs>
                  <Tab fillIco={true} active={true} txt={_t('Affiliates')} ico={<BuildingIco />}></Tab>
                  <Tab strokeIco={true} txt={_t('Administrators')} ico={<UsersIco />} onClick={navigateToAdminsPage}></Tab>
                </Tabs>
                {/* <TabContent active={true}></TabContent> */}
              </TabPanel>
            </FormCardBody>
          </FormCard>

          <FormCard tabletSize='full'>
            <FormCardBody className={styles.filterCard}>
              <SearchInput maxLength={120} onChange={setSearchTerm} value={searchTerm} placeholder={_t('Affiliates suchen')} />
              <ButtonPrimary ralign={true} onClick={onAddClick}>
                {_t('Affiliate erstellen')}
              </ButtonPrimary>
            </FormCardBody>
          </FormCard>
        </>
      }
    >
      <FormCard tabletSize='full' className=''>
        <FormCardBody>
          <div className={styles.tableArea}>
            <DataTable
              sticky={true}
              records={affiliatesRes?.entries || []}
              sorting={{
                handler: (col, desc) => setFilterParams({ sortBy: col, sortDesc: desc ? '1' : undefined }),
                col: filterParams.sortBy,
                desc: filterParams.sortDesc === '1',
              }}
              // hideHeader={true}
              renderer={{
                row: (record, cols) => (
                  <DataTableRow classNames={styles.row} key={record.code}>
                    {cols}
                  </DataTableRow>
                ),
                cols: [
                  {
                    headerCol: () => <div>{_t('Name')}</div>,
                    name: 'title',
                    sortable: true,
                    width: '70%',
                    col: (record) => {
                      return (
                        <div className={styles.fakeRow}>
                          <div className={classNames(styles.affiliateLbls)}>
                            <b>{record.title}</b>
                            <i>
                              {record.code} {record.cpmsId ? `/ ${record.cpmsId}` : ''}
                            </i>
                          </div>
                        </div>
                      );
                    },
                  },
                  {
                    name: 'actions',
                    ralign: true,
                    col: (record) => {
                      return (
                        <div className={classNames(styles.actions)}>
                          <ButtonAccent className={styles.btn} disabled={true} onClick={() => setAffiliateToDelete(record)}>
                            {_t('Löschen')}
                          </ButtonAccent>
                          <ButtonPrimary className={styles.btn} onClick={() => onEditClick(record.code || '')}>
                            {_t('Editieren')}
                          </ButtonPrimary>
                        </div>
                      );
                    },
                    width: '30%',
                  },
                ],
              }}
            />
          </div>
        </FormCardBody>
      </FormCard>

      {affiliatesRes && (
        <Paging
          skip={affiliatesRes.skip}
          limit={affiliatesRes.limit}
          total={affiliatesRes.total}
          onChange={(arg) => {
            setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
          }}
        />
      )}

      <AffiliateCreatePopup open={showAddPopup} affiliateCode={selectedAffiliateCode} close={() => setShowAddPopup(false)} onSubmit={() => refreshResult()} />

      <EditPoup
        open={!!affiliateToDelete}
        onClose={() => setAffiliateToDelete(undefined)}
        title={_t('Affiliate Löschen')}
        saveText={_t('Löschen')}
        onSave={async () => {
          if (!affiliateToDelete) return;
          await api.affiliate.delete(affiliateToDelete.code);
          setAffiliateToDelete(undefined);
          refreshResult();
        }}
      >
        {affiliateToDelete && (
          <>
            <EditPopupRow className={classNames(styles.deletePopupRow)}>
              {_t('Sind Sie sicher, dass Sie Affiliates {{title}} löschen möchten?', { title: affiliateToDelete.title })}
            </EditPopupRow>
            <EditPopupRow className={classNames(styles.deletePopupRow, styles.alert)}>
              ⚠️ {_t('Bei der Löschung des Affiliate werden alle Daten des Affiliate gelöscht.')}
            </EditPopupRow>
          </>
        )}
      </EditPoup>
    </Page>
  );
}
