import { useEffect, useState } from 'react';
import { MultistepPopup, ProgressStep, FailedStep, SuccessStep, ConfirmStep } from '../../../../components/edit-popup';
import { ClientHierarchyNodeTypeDto } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import { NodeInfo } from '../structure-edit-hierarchy-popup/structure-edit-hierarchy-popup';

export default function StructureDeleteNodePopup(props: {
  open: boolean;
  node?: NodeInfo;
  getUsersCount: (node: NodeInfo) => Promise<number>;
  close: () => void;
  deleteNode: (node: NodeInfo) => Promise<void>;
}) {
  const { _t } = useBetterTranslate('structure-delete-node-popup');
  const nodeTypeTitle =
    props.node?.type === ClientHierarchyNodeTypeDto.Region
      ? _t('Gebiet')
      : props.node?.type === ClientHierarchyNodeTypeDto.Location
      ? _t('Standort')
      : props.node?.type === ClientHierarchyNodeTypeDto.Area
      ? _t('Bereich')
      : '';

  const [activeStep, setActiveStep] = useState<'confirm' | 'users' | 'pending' | 'failed' | 'success'>('confirm');
  const [usersCount, setUsersCount] = useState<number>(0);

  useEffect(() => {
    if (props.open) {
      setActiveStep('confirm');
    }
  }, [props.open]);

  const deleteNode = async () => {
    if (!props.node) return;
    setActiveStep('pending');
    try {
      await props.deleteNode(props.node);
      setActiveStep('success');
    } catch (error) {
      setActiveStep('failed');
    }
  };

  return (
    <MultistepPopup
      title={_t('{{nodeTypeTitle}} löschen', { nodeTypeTitle })}
      open={props.open}
      allSteps={['confirm', 'users', 'pending', 'failed', 'success']}
      activeStep={activeStep}
      steps={{
        confirm: (
          <ConfirmStep
            text=' '
            wanText={'\u26A0 ' + _t('Sind Sie sicher, dass Sie den Bereich “{{title}}” löschen wollen?', { title: props.node?.title })}
            acceptText={_t('Löschen')}
            accept={async () => {
              if (!props.node) return;
              const users = await props.getUsersCount(props.node);
              if (users > 0) {
                setUsersCount(users);
                setActiveStep('users');
              } else {
                await deleteNode();
              }
            }}
            decline={props.close}
          />
        ),
        users: (
          <ConfirmStep
            text={_t('{{usersCount}} Benutzer sind keinem weiteren Knoten zugeordnet.', { usersCount: usersCount })}
            wanText={'\u26A0 ' + _t('Benutzer die keinem Knoten zugeordnet sind werden unwiederbringlich gelöscht.')}
            acceptText={_t('Löschen')}
            accept={async () => {
              await deleteNode();
            }}
            decline={props.close}
          />
        ),
        pending: <ProgressStep onClose={props.close} />,
        failed: <FailedStep onClose={props.close} text={_t('Prozess fehlgeschlagen')} />,
        success: <SuccessStep onClose={props.close} text={_t('Prozess abgeschlossen')} />,
      }}
      onClose={props.close}
    />
  );
}
