import { ReactComponent as StationsIco } from '../../../assets/charge.svg';
// add these when pages implemented
// import { ReactComponent as CardsIco } from '../../../assets/color-card.svg';
// import { ReactComponent as ClustersIco } from '../../../assets/gauge-display.svg';
import { ReactComponent as StructureIco } from '../../../assets/hierarchy-picker.svg';
import { ReactComponent as UsersIco } from '../../../assets/users.svg';
import { FormCard, FormCardBody } from '../../../components/form-card';
import TabPanel, { Tab, Tabs } from '../../../components/tab-panel';
import { useBetterNavigate } from '../../../hooks/useBetterNavigate';
import { OrganisationViewDto } from '../../../services/api-client/csp-api';
import useBetterTranslate from '../../../utils/translation-utils';

export type SelectedTab = 'structure' | 'stations' | 'clusters' | 'cards' | 'users';
export default function OrgTabPanel(props: { selectedTab: SelectedTab; organisation: OrganisationViewDto }) {
  const navigate = useBetterNavigate<any>();
  const { _t } = useBetterTranslate('org-tab-panel');
  return (
    <FormCard phoneSize='full'>
      <FormCardBody>
        <TabPanel>
          <Tabs>
            {props.organisation.structure.visible && (
              <Tab
                active={props.selectedTab === 'structure'}
                fillIco={false}
                txt={_t('Struktur')}
                ico={<StructureIco />}
                onClick={() => navigate(`/organisation/structure/${props.organisation.code}`)}
              ></Tab>
            )}

            {props.organisation.stations.visible && (
              <Tab
                active={props.selectedTab === 'stations'}
                fillIco={true}
                txt={_t('Stationen')}
                ico={<StationsIco />}
                onClick={() => navigate(`/organisation/stations/${props.organisation.code}`)}
              ></Tab>
            )}

            {/*
// add also icons on top when activate this
            {props.organisation.cluster.visible && (
              <Tab active={props.selectedTab === 'clusters'} fillIco={true} txt={_t('Cluster')} ico={<ClustersIco />} onClick={() => {}}></Tab>
            )}
            {props.organisation.cards.visible && <Tab active={props.selectedTab === 'cards'} fillIco={true} txt={_t('Karten')} ico={<CardsIco />} onClick={() => {}}></Tab>}
*/}

            {props.organisation.users.visible && (
              <Tab
                active={props.selectedTab === 'users'}
                strokeIco={true}
                txt={_t('Benutzer')}
                ico={<UsersIco />}
                onClick={() => navigate(`/organisation/users/${props.organisation.code}`)}
              ></Tab>
            )}
          </Tabs>
        </TabPanel>
      </FormCardBody>
    </FormCard>
  );
}
