import { useEffect, useState } from 'react';
import Page, { ListingCardPlaceholder } from '../../../../components/page';
import { useApi } from '../../../../hooks/useApi';
import api from '../../../../services/api';
import styles from './org-users-list-page.module.scss';

import classNames from 'classnames';
import _ from 'lodash';
import { useDebounce } from 'react-use';
import { ReactComponent as ViewRoleNodesIco } from '../../../../assets/hierarchy-picker.svg';
import { ReactComponent as EditRoleNodesIco } from '../../../../assets/pen.svg';
import { ReactComponent as ShowMoreIco } from '../../../../assets/show-more.svg';
import { ReactComponent as DeleteRoleIco } from '../../../../assets/trash.svg';
import { ReactComponent as InfoIco } from '../../../../assets/info.svg';
import { ButtonAccent, ButtonPrimary } from '../../../../components/button';
import DataTable, { DataTableRow } from '../../../../components/data-table/data-table';
import { FormCard, FormCardBody } from '../../../../components/form-card';
import { NodeSelectionState, OrgDiagrammNodeProp } from '../../../../components/org-hierarchy/org-diagramm-utils';
import { OrgHierarchyDiagrammContext } from '../../../../components/org-hierarchy/org-hierarchy-diagramm';
import OrgHierarchyPicker from '../../../../components/org-hierarchy/org-hierarchy-picker';
import Paging from '../../../../components/paging';
import Pill from '../../../../components/pill';
import SearchInput from '../../../../components/search-input';
import SingleSelect from '../../../../components/select/single-select';
import { useBetterNavigate } from '../../../../hooks/useBetterNavigate';
import { usePageParams } from '../../../../hooks/usePageParams';
import { ClientHierarchyNodeTypeDto, OrgUserDto, OrgUserRoleDto, Permission, RoleType, SharedClientContext } from '../../../../services/api-client/csp-api';
import { pagingValidator, validateString } from '../../../../utils/queryParamValidators';
import useBetterTranslate from '../../../../utils/translation-utils';
import OrgTabPanel from '../../shared/org-tab-panel';
import OrganisationHierarchyView from '../../shared/organisation-hierarchy-view';
import OrgUserAddRolesPopup from '../user-add-roles-popup/org-user-add-roles-popup';
import OrgUserDeleteRolePopup from '../user-delete-role-popup/org-user-delete-role-popup';
import OrgUserDeletePopup from '../user-delete-user-popup/org-user-delete-popup';
import OrgUserEditInfoPopup, { UserAccount } from '../user-edit-info-popup/user-edit-info-popup';
import OrgUserEditRolePopup from '../user-edit-role-popup/org-user-edit-role-popup';
import RolePermissionsPopup from '../../../../components/role-permissions-popup';
import { PermissionsGroupsOfRole, getPermissionGroupStructure } from '../../../administration/roles/role-create-popup/permissions-groups';

const SHOW_ROLES_LIMIT = 3;
const MAX_HIERARCHY_DEPTH = 4; // Root Node, Region, Location, Area

enum UserMode {
  CREATE_NEW = 'create-new',
  EDIT_EXISTING = 'edit-existing',
  ASSIGN_TO_CLIENT = 'assign-to-client',
}

export default function OrgUsersListPage() {
  const { _t } = useBetterTranslate('org-user-list-popup');
  const navigate = useBetterNavigate<FilterParameters>();

  const SORT_ALLOWED_VALUES = ['firstName', 'mail'];
  const sortingValidator = {
    sortBy: validateString(SORT_ALLOWED_VALUES),
  };

  type FilterParameters = {
    skip?: number | null;
    limit?: number | null;
    search?: string | undefined;
    sortBy?: string;
    sortDesc?: string;
    clientCode?: string;
    roles?: string[];
  };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, { ...pagingValidator, ...sortingValidator });
  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  const [expandedRecords, setExpandedRecords] = useState<String[]>([]);

  const [usersRes, usersFetching, usersErr, refreshResult] = useApi(
    {
      call: async (clientCode?: string, skip?: number | null, limit?: number | null, query?: string | null, roleIds?: string[], sortBy?: any, sortDesc?: string) => {
        if (!clientCode) return undefined;
        const res = await api.orgUsers.search({
          clientCode: clientCode,
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          search: query || undefined,
          roleIds: roleIds,
          sortBy,
          sortDesc: sortDesc === '1',
        });
        return res;
      },
      map: (data) => {
        if (!data) return data;
        setExpandedRecords([]);
        setClientContext(data.clientContext);
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.skip,
    filterParams.limit,
    filterParams.search,
    filterParams.roles,
    filterParams.sortBy,
    filterParams.sortDesc
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const [clientContext, setClientContext] = useState<SharedClientContext>();
  const [selectedNodes, setSelectedNodes] = useState<OrgDiagrammNodeProp[]>([]);

  //user states
  const [currentUser, setCurrentUser] = useState<OrgUserDto>();
  const [userMode, setUserMode] = useState<UserMode>();
  const [showUserAccountPopup, setShowUserAccountPopup] = useState(false);
  const [showUserDeletePopup, setShowUserDeletePopup] = useState(false);

  //role states
  const [currentRole, setCurrentRole] = useState<OrgUserRoleDto>();
  const [editRoleMode, setEditRoleMode] = useState(false);
  const [showAddRolesPopup, setShowAddRolesPopup] = useState(false);
  const [showEditRolePopup, setShowEditRolePopup] = useState(false);
  const [showDeleteRolePopup, setShowDeleteRolePopup] = useState(false);
  const [deleteOnlyRoleMode, setDeleteOnlyRoleMode] = useState(false);

  //permission states
  const [showRolesPermissionsPopup, setShowRolesPermissionsPopup] = useState(false);
  const [currentPermissionGroups, setCurrentPermissionGroups] = useState<PermissionsGroupsOfRole>();

  //user functions
  const onAddUserClick = async () => {
    await setDataBeforeEdit(true);
    setUserMode(UserMode.CREATE_NEW);
    setShowUserAccountPopup(true);
  };

  const onEditUserClick = async (user: OrgUserDto) => {
    await setDataBeforeEdit(true, user);
    setUserMode(UserMode.EDIT_EXISTING);
    setShowUserAccountPopup(true);
  };

  const onDeleteUserClick = async (user: OrgUserDto) => {
    await setDataBeforeEdit(false, user);
    setShowUserDeletePopup(true);
  };

  const createUser = async (roleIds: string[], nodeCodes: string[]): Promise<void> => {
    if (!currentUser) return;
    await api.orgUsers.createUser({
      clientCode: usersRes?.clientContext.code || '',
      defaultLanguage: currentUser.defaultLanguage || 'en',
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      mail: currentUser.mail,
      roleIds,
      nodeCodes,
    });
    refreshResult();
    return;
  };

  const assignClientToExistingUser = async (roleIds: string[], nodeCodes: string[]): Promise<void> => {
    if (!currentUser) return;
    await api.orgUsers.addClientToUser({
      clientCode: usersRes?.clientContext.code || '',
      defaultLanguage: currentUser.defaultLanguage || 'en',
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      mail: currentUser.mail,
      roleIds,
      nodeCodes,
    });
    refreshResult();
    return;
  };

  const updateUserAccount = async (user: UserAccount): Promise<void> => {
    await api.orgUsers.updateUserAccount({
      defaultLanguage: user.defaultLanguage || 'en',
      clientCode: usersRes?.clientContext.code || '',
      firstName: user.firstName,
      lastName: user.lastName,
      mail: user.mail,
    });
    refreshResult();
    return;
  };

  const deleteUser = async (): Promise<void> => {
    if (!currentUser) return;
    await api.orgUsers.deleteUser({
      clientCode: usersRes?.clientContext.code || '',
      mail: currentUser.mail,
    });
    refreshResult();
    return;
  };

  //role functions
  const onViewRoleClick = async (user: OrgUserDto, role: OrgUserRoleDto) => {
    await setDataBeforeEdit(true, user, role);
    setEditRoleMode(false);
    setShowEditRolePopup(true);
  };

  const onEditRoleClick = async (user: OrgUserDto, role: OrgUserRoleDto) => {
    await setDataBeforeEdit(true, user, role);
    setEditRoleMode(true);
    setShowEditRolePopup(true);
  };

  const onAddRolesClick = async (user: OrgUserDto) => {
    await setDataBeforeEdit(true, user);
    setUserMode(UserMode.EDIT_EXISTING);
    setShowAddRolesPopup(true);
  };

  const getPermissionsOfRole = async (roleId: string) => {
    const res = await api.orgUsers.getPermissionsOfRole(filterParams.clientCode || '', roleId);
    return res.data.permissions;
  };

  const onRolesPermissionsClick = async (user: OrgUserDto, role: OrgUserRoleDto) => {
    await setDataBeforeEdit(false, user, role);
    setShowRolesPermissionsPopup(true);
  };

  const onDeleteRoleClick = async (user: OrgUserDto, role: OrgUserRoleDto) => {
    await setDataBeforeEdit(false, user, role);
    const isOnlyRoleResp = await api.orgUsers.checkIfOnlyRole({
      clientCode: usersRes?.clientContext.code || '',
      userMail: user.mail,
      roleId: role.id,
    });
    setDeleteOnlyRoleMode(isOnlyRoleResp.data);
    setShowDeleteRolePopup(true);
  };

  const updateRole = async (nodeCodes: string[]): Promise<void> => {
    if (!currentUser || !currentRole) return;
    await api.orgUsers.updateUserRole({
      clientCode: usersRes?.clientContext.code || '',
      userMail: currentUser.mail,
      roleId: currentRole.id,
      nodeCodes,
    });
    refreshResult();
  };

  const addRoles = async (roleIds: string[], nodeCodes: string[]): Promise<void> => {
    if (!currentUser) return;
    await api.orgUsers.addUserRoles({
      clientCode: usersRes?.clientContext.code || '',
      userMail: currentUser.mail,
      roleIds,
      nodeCodes,
    });
    refreshResult();
  };

  const deleteRole = async (): Promise<void> => {
    if (!currentUser || !currentRole) return;
    await api.orgUsers.deleteUserRole({
      clientCode: usersRes?.clientContext.code || '',
      userMail: currentUser.mail,
      roleId: currentRole.id,
    });
    refreshResult();
  };

  const setDataBeforeEdit = async (refresh: boolean, user?: OrgUserDto, role?: OrgUserRoleDto) => {
    let clientContext = usersRes?.clientContext;
    let currentUser = user;
    let currentRole = role;
    if (refresh) {
      if (user) {
        const resp = await api.orgUsers.getUser(filterParams.clientCode || '', user.mail);
        clientContext = resp.data.clientContext;
        currentUser = resp.data.user;
        if (role) {
          currentRole = resp.data.user.roles.find((item) => item.id === role.id);
        }
      } else {
        const resp = await api.orgUsers.getClientContext(filterParams.clientCode || '');
        clientContext = resp.data;
      }
    }
    setClientContext(clientContext);
    setCurrentUser(currentUser);
    setCurrentRole(currentRole);

    if (currentRole) {
      const permissions = await getPermissionsOfRole(currentRole?.id || '');
      const groups = getPermissionGroupStructure(permissions);
      setCurrentPermissionGroups({ roleId: currentRole?.id || '', roleName: currentRole?.name || '', roleType: currentRole?.type as RoleType, permissionsGroups: groups });
    }
  };

  const onItemClick = (ctx: OrgHierarchyDiagrammContext, node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]) => {
    if (node.selected === NodeSelectionState.Full) {
      node.selected = NodeSelectionState.None;
    } else {
      node.selected = NodeSelectionState.Full;
    }
    setSelectedNodes(others.filter((n) => n.selected === NodeSelectionState.Full));
    ctx.refresh();
  };

  const getNodeTitleHierarchy = (nodeCode: string): string => {
    let titleHierarchy = '';
    const allNodes = [...(clientContext?.accessableNodes || []), ...(clientContext?.forbiddenParents || [])];
    let currentNode = allNodes.find((node) => node.code === nodeCode);
    if (currentNode) {
      titleHierarchy = currentNode.title;
      for (let i = 0; i < MAX_HIERARCHY_DEPTH; i++) {
        const parentCode: string = currentNode?.parentCode || '';
        currentNode = allNodes.find((node) => node.code === parentCode);
        if (currentNode) {
          titleHierarchy = currentNode.title.concat(` / ${titleHierarchy}`);
        }
      }
    }
    return titleHierarchy;
  };

  const hasUserSpecificRoleOnAnyNode = (user: OrgUserDto, role: OrgUserRoleDto, nodes: OrgDiagrammNodeProp[]): boolean => {
    const userRole = user.roles.find((userRole) => userRole.id === role.id);
    const selectedNodeCodes = nodes.map((node) => node.code);
    return userRole?.nodeCodes.some((nodeCode) => selectedNodeCodes.includes(nodeCode)) || false;
  };

  const hasUserAnyRoleOnAnyNode = (user: OrgUserDto, nodes: OrgDiagrammNodeProp[]): boolean => {
    const selectedNodeCodes = nodes.map((node) => node.code);
    const userRoleNodeCodes = user.roles.flatMap((role) => role.nodeCodes);
    return selectedNodeCodes.some((selectedNodeCode) => userRoleNodeCodes.includes(selectedNodeCode));
  };

  const wouldActivatedRoleBeHidden = (record: OrgUserDto) => {
    if (selectedNodes.length > 0) {
      const hiddenItems = record.roles.slice(SHOW_ROLES_LIMIT);
      if (hiddenItems.some((hiddenItem) => hasUserSpecificRoleOnAnyNode(record, hiddenItem, selectedNodes))) {
        return true;
      }
    }
    return false;
  };

  const onOrganisationChange = (clientCode: string) => {
    navigate(`/organisation/users/${clientCode}`);
    setSelectedNodes([]);
  };

  return (
    <Page
      breadCrumb={[{ title: _t('Organisation'), href: '/organisation', active: true }]}
      fetching={usersFetching}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={usersErr}
      outOfPlaceHolder={
        <>
          {usersRes && (
            <OrgHierarchyPicker
              selectMode='client'
              clientContext={usersRes.clientContext}
              onClientSelected={(client) => onOrganisationChange(client.code)}
              searchClients={(search) => api.profile.accessibleClientNodes({ search: search, permissions: [Permission.ClientOrgUserManage] })}
            />
          )}
          {usersRes && <OrgTabPanel organisation={usersRes?.organization} selectedTab='users' />}

          <OrganisationHierarchyView
            fetching={usersFetching}
            organisation={usersRes?.organization}
            nodesSelectable={true}
            accessableNodes={clientContext?.accessableNodes.map((n) => n.code)}
            onItemClick={onItemClick}
            selectedNodes={selectedNodes.map((n) => n.code)}
            doNotPropagate={true}
            rootNodeSelectable={true}
          />

          <FormCard tabletSize='full'>
            <FormCardBody className={classNames(styles.filters)}>
              <div className={styles.filterArea}>
                <SearchInput placeholder={_t('Benutzer suchen')} maxLength={120} onChange={setSearchTerm} value={searchTerm} />
              </div>

              <SingleSelect<{ name: string; id: string }>
                className={styles.roleSelect}
                placeholder={_t('Rollen')}
                fetchOptions={async (txt) => {
                  if (!txt) return usersRes?.clientRoles || [];
                  const results = (usersRes?.clientRoles || []).filter((item) => item.name.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                  const mappedRoles = results.map((r) => ({ id: r.id!, name: r.name }));
                  return mappedRoles;
                }}
                options={usersRes?.clientRoles || []}
                createOption={(opt) => <span>{opt.name}</span>}
                selectedValue={null}
                renderDisplayValue={(val) => val.name}
                // selectedValue={({ id: 'foo', name: 'bar' })}
                onChanged={(selected) => {
                  if (!selected) return;
                  let updated = [selected.id, ...(filterParams.roles || [])];
                  updated = _.uniq(updated);
                  setFilterParams({ roles: updated });
                }}
                isClearable={true}
              />
              <ButtonPrimary ralign={true} onClick={onAddUserClick}>
                {_t('Benutzer erstellen')}
              </ButtonPrimary>
            </FormCardBody>
          </FormCard>
          <FormCard tabletSize='full'>
            {((usersRes && filterParams.roles && filterParams.roles.length > 0) || selectedNodes.length > 0) && (
              <div className={styles.appliedFilterArea}>
                {selectedNodes.length > 0 &&
                  selectedNodes.map((node) => {
                    return (
                      <Pill
                        key={node.code}
                        hasClose={true}
                        className={classNames(
                          styles.filteredItem,
                          node.type === ClientHierarchyNodeTypeDto.ClientRoot
                            ? styles.filteredRootNode
                            : node.type === ClientHierarchyNodeTypeDto.Region
                            ? styles.filteredRegionNode
                            : node.type === ClientHierarchyNodeTypeDto.Location
                            ? styles.filteredLocationNode
                            : node.type === ClientHierarchyNodeTypeDto.Area
                            ? styles.filteredAreaNode
                            : undefined
                        )}
                        onClick={() => {
                          const excluded = selectedNodes.filter((pillNode) => node.code !== pillNode.code);
                          setSelectedNodes(excluded);
                        }}
                        tooltip={getNodeTitleHierarchy(node.code)}
                      >
                        {node.title}
                      </Pill>
                    );
                  })}
                {usersRes &&
                  filterParams.roles &&
                  filterParams.roles.length > 0 &&
                  filterParams.roles.map((rId) => {
                    const model = usersRes.clientRoles.find((r) => r.id === rId);
                    if (!model) return <></>;
                    return (
                      <Pill
                        key={model.id}
                        hasClose={true}
                        className={classNames(styles.filteredItem, styles.filteredRole)}
                        onClick={() => {
                          const excluded = (filterParams.roles || []).filter((r) => r !== model.id);
                          setFilterParams({ roles: [...excluded] });
                        }}
                      >
                        {model.name}
                      </Pill>
                    );
                  })}
              </div>
            )}
          </FormCard>
        </>
      }
    >
      <>
        <FormCard tabletSize='full' className=''>
          <FormCardBody>
            <div className={styles.tableArea}>
              <DataTable
                sticky={true}
                sorting={{
                  handler: (col, desc) => setFilterParams({ sortBy: col, sortDesc: desc ? '1' : undefined }),
                  col: filterParams.sortBy,
                  desc: filterParams.sortDesc === '1',
                }}
                records={selectedNodes.length > 0 ? (usersRes?.users || []).filter((user) => hasUserAnyRoleOnAnyNode(user, selectedNodes)) : usersRes?.users || []}
                // hideHeader={true}
                renderer={{
                  row: (record, cols) => (
                    <DataTableRow classNames={styles.row} key={record.mail}>
                      {cols}
                    </DataTableRow>
                  ),
                  cols: [
                    {
                      headerCol: () => <div>{_t('Name')}</div>,
                      name: _t('firstName'),
                      sortable: true,
                      col: (record) => <span>{record.fullName}</span>,
                      width: '25%',
                    },
                    {
                      headerCol: () => <div>{_t('E-Mail')}</div>,
                      name: _t('mail'),
                      sortable: true,
                      col: (record) => <span>{record.mail}</span>,
                      width: '25%',
                    },
                    {
                      headerCol: () => <div>{_t('Rollen')}</div>,
                      name: _t('role'),
                      sortable: false,
                      colClass: (record) => styles.rolesCol,
                      col: (record) => {
                        const forceExpanded = wouldActivatedRoleBeHidden(record);
                        const isExpanded = !!expandedRecords.find((item) => item === record.mail) || forceExpanded;
                        const displayItems = isExpanded ? record.roles : record.roles.slice(0, SHOW_ROLES_LIMIT);
                        const label = isExpanded
                          ? _t('Weniger Rollen anzeigen')
                          : _t(`Mehr Rollen anzeigen ({{hiddenCount}} weitere)`, { hiddenCount: record.roles.length - SHOW_ROLES_LIMIT });
                        const icon = isExpanded ? <ShowMoreIco className={styles.showLess} /> : <ShowMoreIco />;
                        const onClick = () => setExpandedRecords(isExpanded ? expandedRecords.filter((item) => item !== record.mail) : [...expandedRecords, record.mail]);
                        return (
                          <div className={styles.rolesCol}>
                            <div className={styles.roleList}>
                              {displayItems.map((item, idx) => (
                                <div key={idx} className={classNames(styles.roleItem, hasUserSpecificRoleOnAnyNode(record, item, selectedNodes) ? styles.highlighted : undefined)}>
                                  <div className={classNames(styles.name)}>
                                    <span className={styles.dot}></span>
                                    <span>
                                      {item.name}
                                      <span className={styles.infoIco}>
                                        <InfoIco onClick={async () => await onRolesPermissionsClick(record, item)}></InfoIco>
                                      </span>
                                    </span>
                                  </div>
                                  <div className={styles.actions}>
                                    <span title={_t('Zuordnung der Rolle zur Hierarchie anzeigen')} onClick={async () => await onViewRoleClick(record, item)}>
                                      <ViewRoleNodesIco />
                                    </span>
                                    <span title={_t('Zuordnung der Rolle zur Hierarchie bearbeiten')} onClick={async () => await onEditRoleClick(record, item)}>
                                      <EditRoleNodesIco />
                                    </span>
                                    <span title={_t('Rollenberechtigung löschen')} onClick={async () => await onDeleteRoleClick(record, item)}>
                                      <DeleteRoleIco />
                                    </span>
                                  </div>
                                </div>
                              ))}
                              {record.roles.length > SHOW_ROLES_LIMIT && !forceExpanded && (
                                <div className={styles.expandItem} onClick={onClick}>
                                  {' '}
                                  {icon} {label}
                                </div>
                              )}
                            </div>
                            <div className={styles.actions}>
                              <div className={styles.addRole}>
                                <ButtonAccent onClick={async () => await onAddRolesClick(record)}> {_t('Rolle hinzufügen')} </ButtonAccent>
                              </div>
                              <div className={styles.buttons}>
                                <ButtonAccent onClick={async () => await onDeleteUserClick(record)}> {_t('Löschen')} </ButtonAccent>
                                <ButtonPrimary onClick={async () => await onEditUserClick(record)}> {_t('Editieren')} </ButtonPrimary>
                              </div>
                            </div>
                          </div>
                        );
                      },
                      width: '50%',
                    },
                  ],
                }}
              />
            </div>
          </FormCardBody>
        </FormCard>

        {usersRes && (
          <Paging
            skip={usersRes.skip}
            limit={usersRes.limit}
            total={usersRes.total}
            onChange={(arg) => {
              setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
            }}
          />
        )}
      </>

      <OrgUserEditInfoPopup
        open={showUserAccountPopup}
        user={userMode === UserMode.EDIT_EXISTING ? currentUser : undefined}
        clientCode={filterParams.clientCode || ''}
        defaultLang={usersRes?.defaultLang || 'en'}
        onCancel={() => setShowUserAccountPopup(false)}
        onSubmit={async (user) => {
          if (user.existing) {
            setUserMode(UserMode.ASSIGN_TO_CLIENT);
          }
          if (userMode === UserMode.CREATE_NEW || userMode === UserMode.ASSIGN_TO_CLIENT) {
            setCurrentUser({ ...user, fullName: `${user.firstName} ${user.lastName}`, defaultLanguage: user.defaultLanguage, roles: [] });
            setShowUserAccountPopup(false);
            setShowAddRolesPopup(true);
            return Promise.resolve();
          } else {
            await updateUserAccount(user);
            setShowUserAccountPopup(false);
          }
        }}
      />

      <OrgUserDeletePopup
        open={showUserDeletePopup}
        onCancel={() => setShowUserDeletePopup(false)}
        onSubmit={async () => {
          await deleteUser();
          setShowUserDeletePopup(false);
        }}
      />

      <OrgUserAddRolesPopup
        open={showAddRolesPopup}
        fullName={currentUser?.fullName || ''}
        clientContext={clientContext}
        clientRoles={usersRes?.clientRoles}
        disabledRoleIds={userMode === UserMode.CREATE_NEW ? [] : currentUser?.roles.map((role) => role.id)}
        onCancel={() => setShowAddRolesPopup(false)}
        onSubmit={async (roleIds: string[], nodeCodes: string[]) => {
          if (userMode === UserMode.ASSIGN_TO_CLIENT) {
            await assignClientToExistingUser(roleIds, nodeCodes);
          } else if (userMode === UserMode.CREATE_NEW) {
            await createUser(roleIds, nodeCodes);
          } else {
            await addRoles(roleIds, nodeCodes);
          }
          setShowAddRolesPopup(false);
        }}
        getPermissionsOfRole={getPermissionsOfRole}
      />

      <OrgUserEditRolePopup
        open={showEditRolePopup}
        fullName={currentUser?.fullName || ''}
        clientContext={clientContext}
        userRole={currentRole}
        edit={editRoleMode}
        onCancel={() => setShowEditRolePopup(false)}
        onSubmit={async (nodeCodes: string[]) => {
          if (editRoleMode) {
            await updateRole(nodeCodes);
          }
          setShowEditRolePopup(false);
        }}
        onDeleteRole={() => {
          setShowEditRolePopup(false);
          setShowDeleteRolePopup(true);
        }}
        permissionGroups={currentPermissionGroups?.permissionsGroups || []}
      />

      <RolePermissionsPopup
        open={showRolesPermissionsPopup}
        permissionsGroups={currentPermissionGroups?.permissionsGroups || []}
        onClose={() => {
          setShowRolesPermissionsPopup(false);
          setCurrentPermissionGroups(undefined);
        }}
        roleType={currentRole?.type as RoleType}
        roleName={currentRole?.name}
      />

      <OrgUserDeleteRolePopup
        open={showDeleteRolePopup}
        onlyRole={deleteOnlyRoleMode}
        onCancel={() => setShowDeleteRolePopup(false)}
        onSubmit={async () => {
          await deleteRole();
          setShowDeleteRolePopup(false);
        }}
      />
    </Page>
  );
}
