import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { ButtonAccent, ButtonPrimary } from '../../../../components/button';
import DataTable, { DataTableColumn, DataTableRow } from '../../../../components/data-table/data-table';
import EditPoup, { EditPopupRow } from '../../../../components/edit-popup';
import { FormCard, FormCardBody } from '../../../../components/form-card';
import Page, { ListingCardPlaceholder } from '../../../../components/page';
import Paging from '../../../../components/paging';
import Pill from '../../../../components/pill';
import SearchInput from '../../../../components/search-input';
import colorStyles from '../../../../components/style-utils';
import { Tag } from '../../../../components/tag';
import { useApi } from '../../../../hooks/useApi';
import { usePageParams } from '../../../../hooks/usePageParams';
import api from '../../../../services/api';
import { ListRoleEntryDto, RoleType } from '../../../../services/api-client/csp-api';
import { pagingValidator, validateArrayOfString, validateString } from '../../../../utils/queryParamValidators';
import useBetterTranslate from '../../../../utils/translation-utils';
import RoleCreatePopup from '../role-create-popup/role-create-popup';
import styles from './role-list-page.module.scss';
import AdminTabPanel from '../../shared/admin-tab-panel';
import { ReactComponent as InfoIco } from '../../../../assets/info.svg';
import RolePermissionsPopup from '../../../../components/role-permissions-popup';
import { PermissionsGroupsOfRole, getPermissionGroupStructure } from '../role-create-popup/permissions-groups';

export default function RoleListPage() {
  const TYPE_ALLOWED_VALUES = Object.values(RoleType);
  const SORT_BY_VALUES = ['name', 'type'];

  const roleValidators = {
    serviceType: validateArrayOfString(TYPE_ALLOWED_VALUES),
    sortBy: validateString(SORT_BY_VALUES),
  };

  const { _t } = useBetterTranslate('role-list-page');

  type FilterParameters = {
    affiliateCode?: string;
    skip?: number | null;
    limit?: number | null;
    name?: string;
    types?: string[];
    sortBy?: string;
    sortDesc?: string;
  };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, { ...pagingValidator, ...roleValidators });
  const [roleToDelete, setRoleToDelete] = useState<ListRoleEntryDto | undefined>();
  const [associatedUsersTotalCount, setAssociatedUsersTotalCount] = useState<number | undefined>();
  const [associatedUsersWithoutOtherRoleCount, setAssociatedUsersWithoutOtherRoleCount] = useState<number | undefined>();
  const [firstDeletionStepConfirmed, setFirstDeletionStepConfirmed] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState(filterParams.name);
  const [submitError, setSubmitError] = useState<string>();
  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);

  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  const [rolesRes, rolesFetching, rolesErr, refreshResult] = useApi(
    {
      call: async (affiliateCode?: string, skip?: number | null, limit?: number | null, name?: string, types?: any, sortBy?: any, sortDesc?: string) => {
        if (!affiliateCode) return undefined;
        const res = await api.role.search({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          name: name,
          types,
          sortBy,
          sortDesc: sortDesc === '1',
          affiliateCode,
        });

        return res;
      },
      map: (data) => {
        if (!data) return data;
        return data;
      },
    },
    filterParams.affiliateCode,
    filterParams.skip,
    filterParams.limit,
    filterParams.name,
    filterParams.types,
    filterParams.sortBy,
    filterParams.sortDesc
  );

  useApi(
    {
      call: async (roleId?: string | undefined) => {
        if (roleId) {
          if (!filterParams.affiliateCode) return;
          const res = await api.role.getAssociatedUsers(filterParams.affiliateCode, { roleId });
          return res;
        }
      },
      map: (clientCount) => {
        setAssociatedUsersTotalCount(clientCount?.total);
        setAssociatedUsersWithoutOtherRoleCount(clientCount?.withoutOtherRole);
        return clientCount;
      },
    },
    roleToDelete?.id
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.name) return;
      setFilterParams({ name: searchTerm });
    },
    800,
    [searchTerm, filterParams.name]
  );

  useEffect(() => {
    setSearchTerm(filterParams.name);
  }, [filterParams.name]);

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState<string>();
  const onAddClick = () => {
    setSelectedRoleId(undefined);
    setShowAddPopup(true);
  };
  const onEditClick = (roleId: string) => {
    setSelectedRoleId(roleId);
    setShowAddPopup(true);
  };

  const getPermissionsOfRole = async (roleId: string) => {
    const res = await api.role.getPermissionsOfRole(filterParams.affiliateCode || '', roleId);
    return res.data.permissions;
  };
  const [currentPermissionGroups, setCurrentPermissionGroups] = useState<PermissionsGroupsOfRole>();
  const onRolesPermissionsClick = async (role: ListRoleEntryDto) => {
    const permissions = await getPermissionsOfRole(role.id || '');
    const groups = getPermissionGroupStructure(permissions);
    setCurrentPermissionGroups({ roleId: role.id || '', roleName: role.name || '', roleType: role.type as RoleType, permissionsGroups: groups });
  };

  const getRoleTypeText = (type: RoleType) => {
    if (type === RoleType.Root) return _t('Root');
    if (type === RoleType.Standard) return _t('Standard');
    if (type === RoleType.Individual) return _t('Individual');
    return type;
  };

  const getRoleTypeColorStyle = (type: RoleType) => {
    if (type === RoleType.Root) return colorStyles.components.rateType.public;
    if (type === RoleType.Standard) return colorStyles.components.rateType.work;
    if (type === RoleType.Individual) return colorStyles.components.rateType.employee;
    return colorStyles.components.rateType.unknown;
  };

  const getRoleTypeInvertColorStyle = (type: RoleType) => {
    if (type === RoleType.Root) return colorStyles.components.rateTypeInvert.public;
    if (type === RoleType.Standard) return colorStyles.components.rateTypeInvert.work;
    if (type === RoleType.Individual) return colorStyles.components.rateTypeInvert.employee;
    return colorStyles.components.rateTypeInvert.unknown;
  };

  const tryToSetRoleToDelete = async (roleEntry: ListRoleEntryDto) => {
    if (!filterParams.affiliateCode) return;
    const isAssigned = (await api.role.isRoleAssignedToCurrentUser(filterParams.affiliateCode, { roleId: roleEntry.id || '' })).data;
    if (isAssigned) {
      setShowErrorPopup(true);
    } else {
      setRoleToDelete(roleEntry);
    }
  };

  const cols: DataTableColumn<ListRoleEntryDto>[] = [
    {
      headerCol: () => <div>{_t('Name')}</div>,
      name: 'name',
      sortable: true,
      col: (record: ListRoleEntryDto) => (
        <div>
          <span>{record.name}</span>
          <span className={styles.infoIco}>
            <InfoIco onClick={async () => await onRolesPermissionsClick(record)}></InfoIco>
          </span>
        </div>
      ),
      width: '40%',
      dataCy: 'hdrNameSort',
    },
    {
      headerCol: () => <div>{_t('Rollentyp')}</div>,
      name: 'type',
      sortable: true,
      col: (record: ListRoleEntryDto) => (
        <div>
          <Tag className={classNames(styles.rateType, getRoleTypeColorStyle(record.type), styles.roleTypeCol)}>{getRoleTypeText(record.type)}</Tag>
          {record.clientTitle && <div className={styles.roleClientTitle}>{record.clientTitle}</div>}
        </div>
      ),
      width: '25%',
      dataCy: 'hdrRoleTypeSort',
    },
  ];
  const showActions =
    rolesRes?.can.editIndividualRole ||
    rolesRes?.can.editStandardRole ||
    rolesRes?.can.editRootRole ||
    rolesRes?.can.deleteIndividualRole ||
    rolesRes?.can.deleteStandardRole ||
    rolesRes?.can.deleteRootRole;
  if (showActions) {
    cols.push({
      headerCol: () => <></>,
      name: 'ACTIONS',
      sortable: false,
      col: (record: ListRoleEntryDto) => {
        let showEdit =
          record.createdBySystem === false &&
          ((record.type === RoleType.Individual && rolesRes?.can.editIndividualRole) ||
            (record.type === RoleType.Standard && rolesRes?.can.editStandardRole) ||
            (record.type === RoleType.Root && rolesRes?.can.editRootRole));
        let showDelete =
          record.createdBySystem === false &&
          ((record.type === RoleType.Individual && rolesRes?.can.deleteIndividualRole) ||
            (record.type === RoleType.Standard && rolesRes?.can.deleteStandardRole) ||
            (record.type === RoleType.Root && rolesRes?.can.deleteRootRole));
        return (
          <div className={styles.actions}>
            {showDelete && (
              <ButtonAccent className={styles.btn} onClick={() => tryToSetRoleToDelete(record)} dataCy='btnDelete'>
                {_t('Löschen')}
              </ButtonAccent>
            )}
            {showEdit && (
              <ButtonPrimary className={styles.btn} onClick={() => onEditClick(record.id || '')} dataCy='btnEdit'>
                {_t('Editieren')}
              </ButtonPrimary>
            )}
          </div>
        );
      },
      width: '35%',
    });
  }

  return (
    <Page
      breadCrumb={[{ title: _t('Administration'), href: '/administration/roles', active: true }]}
      fetching={rolesFetching}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={rolesErr}
      outOfPlaceHolder={
        <>
          {rolesRes && (
            <AdminTabPanel
              selectedTab='roles'
              affiliate={rolesRes.affiliateContext}
              can={{ viewClientList: rolesRes.can.viewClientList, viewRoleList: true, viewUserList: rolesRes.can.viewUserList }}
            />
          )}

          <FormCard tabletSize='full' className={styles.filterCard}>
            <SearchInput placeholder={_t('Rollen suchen')} onChange={setSearchTerm} value={searchTerm} maxLength={50} dataCy='srcRoles' />

            <div className={classNames(styles.filters, styles.activeQuickFilter)}>
              {[RoleType.Root, RoleType.Standard, RoleType.Individual].map((roleType) => {
                const selected = filterParams.types?.includes(roleType) || false;
                let cls;
                if (selected) {
                  cls = getRoleTypeColorStyle(roleType);
                } else {
                  cls = getRoleTypeInvertColorStyle(roleType);
                }
                const onClick = () => {
                  let types;
                  if (selected) {
                    types = filterParams.types?.filter((item) => item !== roleType);
                    if (types && types?.length === 0) {
                      types = undefined;
                    }
                  } else {
                    types = filterParams.types ? [...filterParams.types, roleType] : [roleType];
                  }
                  setFilterParams({ types });
                };

                const dataCy =
                  roleType === RoleType.Root
                    ? 'btnRootRole'
                    : roleType === RoleType.Standard
                    ? 'btnStandardRole'
                    : roleType === RoleType.Individual
                    ? 'btnIndividualRole'
                    : undefined;

                return (
                  <Pill key={roleType} selected={selected} className={classNames(styles.rateType, cls)} onClick={onClick} dataCy={dataCy}>
                    {getRoleTypeText(roleType)}
                  </Pill>
                );
              })}
            </div>

            {(rolesRes?.can.writeRootRole || rolesRes?.can.writeStandardRole) && (
              <ButtonPrimary ralign={true} onClick={onAddClick} dataCy='btnCreateRole'>
                {_t('Rolle erstellen')}
              </ButtonPrimary>
            )}
          </FormCard>
        </>
      }
    >
      <FormCard tabletSize='full' className=''>
        <FormCardBody>
          <div className={styles.tableArea}>
            <DataTable
              sticky={true}
              records={rolesRes?.roles || []}
              sorting={{
                handler: (col, desc) => setFilterParams({ sortBy: col, sortDesc: desc ? '1' : undefined }),
                col: filterParams.sortBy,
                desc: filterParams.sortDesc === '1',
              }}
              renderer={{
                row: (record, cols) => (
                  <DataTableRow classNames={styles.row} key={record.id}>
                    {cols}
                  </DataTableRow>
                ),
                cols,
              }}
              dataCy='tblRole'
            />
          </div>
        </FormCardBody>
      </FormCard>

      {rolesRes && (
        <Paging
          skip={rolesRes.skip}
          limit={rolesRes.limit}
          total={rolesRes.total}
          onChange={(arg) => {
            setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
          }}
        />
      )}

      <RoleCreatePopup
        affiliateCode={filterParams.affiliateCode || ''}
        canWriteRoot={!!rolesRes?.can.writeRootRole}
        canWriteStandard={!!rolesRes?.can.writeStandardRole}
        canWriteIndividual={!!rolesRes?.can.writeIndividualRole}
        open={showAddPopup}
        save={true}
        roleId={selectedRoleId}
        close={() => setShowAddPopup(false)}
        onSubmit={() => refreshResult()}
        dataCy={selectedRoleId ? 'popEditRole' : 'popCreateRole'}
      />

      <EditPoup
        open={!!roleToDelete && (associatedUsersTotalCount !== undefined || associatedUsersWithoutOtherRoleCount !== undefined)}
        onClose={() => {
          setRoleToDelete(undefined);
          setAssociatedUsersTotalCount(undefined);
          setAssociatedUsersWithoutOtherRoleCount(undefined);
          setFirstDeletionStepConfirmed(false);
          setSubmitError(undefined);
        }}
        title={_t('Rolle löschen')}
        dataCy={{ root: 'popDeleteRole', buttonSave: 'btnDelete' }}
        saveText={_t('Löschen')}
        onSave={async () => {
          if (!roleToDelete?.id) return;
          if (firstDeletionStepConfirmed === false && associatedUsersWithoutOtherRoleCount! > 0) {
            setFirstDeletionStepConfirmed(true);
            return;
          }
          try {
            if (!filterParams.affiliateCode) return;
            await api.role.deleteRole({ id: roleToDelete.id, affiliateCode: filterParams.affiliateCode });
          } catch (err: any) {
            setSubmitError('Ein unerwarteter Fehler ist aufgetreten');
            throw err;
          }
          setRoleToDelete(undefined);
          setAssociatedUsersTotalCount(undefined);
          setAssociatedUsersWithoutOtherRoleCount(undefined);
          setFirstDeletionStepConfirmed(false);
          refreshResult();
        }}
        additionalFooterContent={submitError}
        additionalFooterContentClassName={styles.submitError}
      >
        {roleToDelete && (
          <>
            {!firstDeletionStepConfirmed && associatedUsersTotalCount !== undefined && (
              <EditPopupRow className={classNames(styles.deletePopupRow)} dataCy={{ content: 'txaDeleteRole' }}>
                {_t("Sind Sie sicher, dass Sie die Rolle '{{roleName}}' löschen möchten?", { roleName: roleToDelete.name })}
              </EditPopupRow>
            )}

            {!firstDeletionStepConfirmed && associatedUsersTotalCount! > 0 && (
              <EditPopupRow className={classNames(styles.deletePopupRow, styles.alert)} dataCy={{ content: 'wamDelete' }}>
                ⚠️{' '}
                {_t(
                  'Diese Rolle ist mit {{usersCount}} Benutzer(n) verknüpft. Wenn Sie diese Rolle löschen, können diese Benutzer nicht mehr auf den aktuellen Service zugreifen.',
                  { usersCount: associatedUsersTotalCount }
                )}
              </EditPopupRow>
            )}

            {firstDeletionStepConfirmed && associatedUsersWithoutOtherRoleCount! > 0 && (
              <>
                <EditPopupRow className={classNames(styles.deletePopupRow)}>
                  {_t('{{usersCount}} Benutzern ist keine weitere Rolle zugeordnet.', { usersCount: associatedUsersWithoutOtherRoleCount })}
                </EditPopupRow>
                <EditPopupRow className={classNames(styles.deletePopupRow, styles.alert)}>
                  ⚠️ {_t('Benutzer ohne zugeordnete Rollen werden unwiederbringlich gelöscht.')}
                </EditPopupRow>
              </>
            )}
          </>
        )}
      </EditPoup>

      <EditPoup
        open={showErrorPopup}
        onClose={() => {
          setShowErrorPopup(false);
        }}
        title={_t('Rolle löschen')}
        cancelText={_t('Schließen')}
        additionalFooterContent={submitError}
        additionalFooterContentClassName={styles.submitError}
      >
        <EditPopupRow className={classNames(styles.deletePopupRow, styles.alert)}>
          ⚠️ {_t('Der angemeldete Benutzer kann diese Rolle nicht löschen, da diese dem Benutzer selbst zugewiesen ist.')}
        </EditPopupRow>
      </EditPoup>
      <RolePermissionsPopup
        open={!!currentPermissionGroups?.roleId}
        permissionsGroups={currentPermissionGroups?.permissionsGroups || []}
        onClose={() => {
          setCurrentPermissionGroups(undefined);
        }}
        roleType={currentPermissionGroups?.roleType as RoleType}
        roleName={currentPermissionGroups?.roleName}
      />
    </Page>
  );
}
