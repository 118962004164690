import React, { createContext, useState } from 'react';

import api from '../services/api';
import { UserPreferencesDto } from '../services/api-client/csp-api';
import { getBrowserLanguageCode } from './translation-utils';
import useEffectAsync from './useEffectAsync';
export enum LoginErrorType {
  AuthenticatedButUnknown = 0,
  OtherError = 1,
}

export interface IUser {
  name: string;
  mail: string;
  preferences: UserPreferencesDto;
}
interface AuthProviderProps {
  user: IUser | null;
  fetching: boolean;
  loginErr: { code: string; message: string; type: LoginErrorType } | null;
  beginLogin: (returnUrl: string) => void;
  refreshProfile: () => Promise<void>;
  logout: () => void;
}

export const AuthProviderCtx = createContext<AuthProviderProps>({
  user: null,
  fetching: false,
  loginErr: null,
  beginLogin: (returnUrl: string) => {},
  logout: () => {},
  refreshProfile: () => {
    return Promise.resolve();
  },
});

export default function AuthProvider(props: React.PropsWithChildren<{}>) {
  const [user, setUser] = useState<IUser | null>(null);
  const [fetching, isFetching] = useState(false);
  const [loginErr, setLoginErr] = useState<{ code: string; message: string; type: LoginErrorType } | null>(null);

  const fetchProfile = async () => {
    isFetching(true);
    setLoginErr(null);
    try {
      try {
        let profileRes = await api.profile.getProfile();
        let preferences = await api.profile.getPreferences();
        if (!preferences.data.languageCode) {
          await api.profile.changeLanguage(getBrowserLanguageCode());
          preferences = await api.profile.getPreferences();
        }

        let usr: IUser = {
          mail: profileRes.data.mail,
          name: profileRes.data.name,
          preferences: preferences.data,
        };

        try {
          setUser(usr); // ignore state update/rendering errors
        } catch {}
      } catch (err: any) {
        if (err.error?.statusCode === 401) {
          setUser(null);
        } else if (err.error?.statusCode === 403) {
          setLoginErr({ code: err.error?.statusCode || 'UNKNOWN', message: '', type: LoginErrorType.AuthenticatedButUnknown });
        } else if (err.error?.message) {
          setLoginErr({ code: err.error?.statusCode || 'UNKNOWN', message: err.error.message, type: LoginErrorType.OtherError });
        } else if (err.message) {
          setLoginErr({ code: err.error?.statusCode || 'UNKNOWN', message: err.message, type: LoginErrorType.OtherError });
        } else {
          setLoginErr({ code: err.error?.statusCode || 'UNKNOWN', message: 'unexpected error', type: LoginErrorType.OtherError });
        }
      }
    } finally {
      isFetching(false);
    }
  };

  const logout = async () => {
    // console.log('logout current user ...');
    // await api.auth.logout();
    // // location.replace will force a reload of the browser
    // // states should gone anyway
    // // setLoginErr(null);
    // // setUser(null);
    // window.location.replace('/logout');
    const targetUrl = `${process.env.REACT_APP_API_URL}/auth/cognito/logout`;
    console.log('logout current user via', targetUrl);
    window.location.replace(targetUrl);
  };

  const beginLogin = (returnUrl: string) => {
    //const targetUrl = `${process.env.REACT_APP_API_URL}/auth/gigya/login/?return=${encodeURIComponent(returnUrl)}`;
    const targetUrl = `${process.env.REACT_APP_API_URL}/auth/cognito/login/?return=${encodeURIComponent(returnUrl)}`;
    console.log('login current user via', targetUrl);
    window.location.replace(targetUrl);
  };

  useEffectAsync(async () => {
    await fetchProfile();

    // // in cases of redirects (login) we stored the state in localStorage
    const backupState = sessionStorage.getItem(`csp.login.state`);
    const history = window.history;

    if (!history.state?.usr && backupState) {
      console.warn(`state is empty but a backupState in sessionStorage could be found`, backupState);
      try {
        const restoredState = JSON.parse(backupState);
        history.replaceState({ ...history.state, usr: restoredState }, '');
      } catch {
      } finally {
        sessionStorage.removeItem(`csp.login.state`);
      }
    }
  }, []);

  api.onUnauthorized(() => {
    setLoginErr(null);
    setUser(null);
  });

  let contextValue: AuthProviderProps = {
    user: user,
    loginErr: loginErr,
    beginLogin: beginLogin,
    refreshProfile: fetchProfile,
    fetching: fetching,
    logout: () => logout(),
  };

  return <AuthProviderCtx.Provider value={contextValue}>{props.children}</AuthProviderCtx.Provider>;
}

export function useAuth() {
  return React.useContext(AuthProviderCtx);
}
