import classNames from 'classnames';
import { getPlugTypeOptions } from '../outlet-type-icon';
import Pill from '../pill';
import styles from './applied-tags.module.scss';
import { ChargePointFilterParameters } from './filter-area-parameters.dto';

export default function ChargePointAppliedTags(props: { plugTypes: string[]; show: { ac: boolean; dc: boolean }; setFilterParams: (params: ChargePointFilterParameters) => void }) {
  const { plugTypes, show, setFilterParams } = props;
  return (
    <div className={styles.appliedTags}>
      {plugTypes
        ?.filter((type) =>
          getPlugTypeOptions()
            .map((option) => option.name)
            .includes(type)
        )
        .map((plugType) => {
          return (
            <div>
              <Pill
                hasClose={true}
                key={plugType}
                className={classNames(styles.tag)}
                onClick={() => {
                  setFilterParams({
                    plugTypes: plugTypes?.filter((type) => type !== plugType),
                  });
                }}
              >
                {getPlugTypeOptions()
                  .filter((opt) => opt.name === plugType)
                  .map((opt) => opt.title)}
              </Pill>
            </div>
          );
        })}
      <div>
        {show.ac && (
          <Pill
            key={'ac'}
            hasClose={true}
            className={classNames(styles.tag)}
            onClick={() => {
              setFilterParams({
                ac: undefined,
              });
            }}
          >
            {'AC'}
          </Pill>
        )}
      </div>

      <div>
        {show.dc && (
          <Pill
            key={'dc'}
            hasClose={true}
            className={classNames(styles.tag)}
            onClick={() => {
              setFilterParams({
                dc: undefined,
              });
            }}
          >
            {'DC'}
          </Pill>
        )}
      </div>
    </div>
  );
}
