import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { ReactComponent as DisabledIco } from '../../../../assets/node-disabled.svg';
import { ReactComponent as InheritedSelectedIco } from '../../../../assets/node-selected-inherited.svg';
import { ReactComponent as SelectedIco } from '../../../../assets/node-selected-full.svg';
import { mapItems, NodeSelectionState, OrgDiagrammNodeProp, walkChildren } from '../../../../components/org-hierarchy/org-diagramm-utils';
import OrgHierarchyDiagramm, { OrgHierarchyDiagrammSettings, useOrgHiearchyDiagramm } from '../../../../components/org-hierarchy/org-hierarchy-diagramm';
import { SharedClientContext } from '../../../../services/api-client/csp-api';

import useBetterTranslate from '../../../../utils/translation-utils';
import styles from './org-user-hierarchy-assignor.module.scss';

function selectNode(node: OrgDiagrammNodeProp, allItems: OrgDiagrammNodeProp[]) {
  node.selected = NodeSelectionState.Full;
  walkChildren({
    all: allItems,
    starting: node,
    visit: (n) => {
      n.selected = NodeSelectionState.Inherited;
    },
  });
}

function unSelectNode(node: OrgDiagrammNodeProp, allItems: OrgDiagrammNodeProp[]) {
  const oldState = node.selected;
  node.selected = NodeSelectionState.None;
  walkChildren({
    all: allItems,
    starting: node,
    visit: (n) => {
      if (n.selected !== NodeSelectionState.Full) n.selected = NodeSelectionState.None;
    },
  });

  if (oldState === NodeSelectionState.Inherited) {
    let currentNode = node;
    while (true) {
      let nodeCode = currentNode.code;
      let parentCode = currentNode.parentCode;
      let parent = allItems.find((n) => n.code === parentCode);
      if (!parent) break; //should never happen
      const parentState = parent.selected;
      parent.selected = NodeSelectionState.None;
      const siblings = allItems.filter((n) => n.parentCode === parentCode && n.code !== nodeCode);
      siblings.forEach((n) => selectNode(n, allItems));
      if (parentState === NodeSelectionState.Full) {
        break;
      } else {
        currentNode = parent;
      }
    }
  }
}

// The node can't be deselected if it inherited the selection from its parent while its parent is not accessible.
export function canNodeBeDeselected(node: OrgDiagrammNodeProp, allItems: OrgDiagrammNodeProp[]) {
  if (node.selected === NodeSelectionState.Full) {
    return true;
  }
  // then it is Inherited
  let currentNode = node;
  while (true) {
    let parentCode = currentNode.parentCode;
    let parent = allItems.find((n) => n.code === parentCode);
    if (!parent) return false; //should never happen
    if (parent.selected === NodeSelectionState.Full) {
      return parent.accessible;
    } else {
      currentNode = parent;
    }
  }
}

const OrgUserHierarchyAssignor = forwardRef(
  (
    props: {
      open: boolean;
      nodesSelectable: boolean;
      clientContext?: SharedClientContext;
      errorMsg?: string;
      selectedNodes?: string[];
      onChange?: () => void;
    },
    ref
  ) => {
    const { _t } = useBetterTranslate('org-hierarchy-assignor');
    const onChangeWrapper = useCallback(() => {
      return props.onChange;
    }, [props.onChange]);

    const diagProps = useMemo(() => {
      const settings: OrgHierarchyDiagrammSettings = {
        diagramProps: {
          readonly: true,
          className: styles.diagram,
          nodesSelectable: props.nodesSelectable,
        },
        onItemClick: (ctx, node, allItems) => {
          if (!props.nodesSelectable) return;

          if (node.selected === NodeSelectionState.Full || node.selected === NodeSelectionState.Inherited) {
            if (!canNodeBeDeselected(node, allItems)) return; //we may should show a message for the user
          }

          if (node.selected === NodeSelectionState.Full || node.selected === NodeSelectionState.Inherited) {
            unSelectNode(node, allItems);
          } else {
            selectNode(node, allItems);
          }
          ctx.refresh();
          const onChange = onChangeWrapper();
          if (onChange) {
            onChange();
          }
        },
        clientRootChangable: false,
      };

      return settings;
    }, [props.nodesSelectable, onChangeWrapper]);

    let [diagrammProps, diagContext] = useOrgHiearchyDiagramm(diagProps);

    useImperativeHandle(
      ref,
      () => {
        return {
          getSelectedNodes: () => {
            const nodeCodes = diagContext.getSelected().map((n) => n.code);
            return nodeCodes;
          },
        };
      },
      [diagContext]
    );

    useEffect(() => {
      if (!props.open) return;
      diagContext.fitToView();
    }, [props.open, diagContext]);

    useEffect(() => {
      if (!props.open) return;
      if (props.clientContext) {
        const accessibleMapped = mapItems(props.clientContext.accessableNodes, props.clientContext.code, true);
        const forbiddenMapped = mapItems(props.clientContext.forbiddenParents, props.clientContext.code, false);
        const mappedItems = [...accessibleMapped, ...forbiddenMapped].sort((a, b) => a.title.localeCompare(b.title));
        if (props.selectedNodes && props.selectedNodes.length > 0) {
          for (const selected of props.selectedNodes) {
            const item = mappedItems.find((n) => n.code === selected);
            if (!item) {
              console.warn(`item with code: ${selected} is marked as selected but was not provided in the allItems list`);
              continue;
            }
            selectNode(item, mappedItems);
          }
        }
        diagContext.setItems(mappedItems);
      }
    }, [props.open, props.selectedNodes, props.clientContext, diagContext]);

    return (
      <div className={styles.root}>
        <div className={styles.header}>{_t('Knoten Berechtigung')}</div>
        <OrgHierarchyDiagramm {...diagrammProps} />
        <div className={styles.footer}>
          <div className={styles.error}>{props.errorMsg}</div>
          <div className={styles.legend}>
            <span>
              <SelectedIco />
              {_t('Ausgewählt')}
            </span>
            <span>
              <InheritedSelectedIco />
              <span>{_t('Vererbt')}</span>
            </span>
            <span>
              <DisabledIco />
              {_t('Nicht auswählbar')}
            </span>
          </div>
        </div>
      </div>
    );
  }
);

export default OrgUserHierarchyAssignor;
