import moment from 'moment';
import { TimeFilterKey } from '../../components/chart-filter';

export function getTimeRange(timeFilter: TimeFilterKey, from?: string, to?: string): { from: string | undefined; to: string | undefined } {
  if (timeFilter === 'custom') {
    return { from, to };
  } else {
    const newTo = moment().startOf('minute');
    const newFrom =
      timeFilter === '1M'
        ? moment().subtract(1, 'month')
        : timeFilter === '14d'
        ? moment().subtract(14, 'day')
        : timeFilter === '7d'
        ? moment().subtract(7, 'day')
        : timeFilter === '24h'
        ? moment().subtract(1, 'day')
        : timeFilter === '12h'
        ? moment().subtract(12, 'hour')
        : timeFilter === '6h'
        ? moment().subtract(6, 'hour')
        : timeFilter === '1h'
        ? moment().subtract(1, 'hour')
        : moment().subtract(1, 'day');
    return { from: newFrom.toISOString(), to: newTo.toISOString() };
  }
}
