import classNames from 'classnames';
import styles from './input-radio-button.module.scss';

export default function RadioButton(props: { label: string; className?: string; classNameInput?: string; isSelected: boolean; disabled?: boolean; onChange?: () => void }) {
  return (
    <label className={classNames(styles.root, props.className, props.disabled ? styles.disabled : '')}>
      <input onChange={props.onChange || (() => {})} type='radio' checked={props.isSelected} disabled={props.disabled} />
      <span className={styles.radioLabel}>{props.label}</span>
    </label>
  );
}
