import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../components/button';
import { ApiErrorPanel, LoadingSpinner, PageLayout, SuccessPanel } from '../../../../components/page-layout';
import { useLocationState } from '../../../../hooks/useBetterNavigate';
import api from '../../../../services/api';
import { ApiError, ClientDto } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import { useOnMountUnsafeAsync } from '../../../../utils/useEffectAsync';
import styles from './process-client-create.page.module.scss';

export interface ProcessClientCreatePageProps {
  className?: string;
}

export default function ProcessClientCreatePage(props: ProcessClientCreatePageProps) {
  const { _t } = useBetterTranslate(`process-client-create-page`);
  const navigate = useNavigate();
  const { affiliateCode } = useParams();
  const [creationError, setCreationError] = useState<ApiError>();
  const [createdClient, setCreatedClient] = useState<ClientDto>();

  // const tmpResult = {
  //   clientTitle: string;
  //   affli: string;
  // }

  const clientDto = useLocationState<ClientDto>('client');
  // useOnMountUnsafeAsync needed here because react strict cause duplicate useEffect calls and we are doing here a server POST
  useOnMountUnsafeAsync(async () => {
    if (!clientDto) {
      console.error(`unexpected missing client from history state`);
      return;
    }

    try {
      const resp = await api.client.importClient(clientDto);
      setCreatedClient(resp.data);
    } catch (e) {
      console.error(`client creation failed`, e);
      setCreationError(e as ApiError);
    }
  });

  const goToResourceImport = () => {
    if (!createdClient) return;
    navigate(`/administration/clients/${createdClient.affiliateCode}/import-resources/${createdClient.code}`);
  };

  const showSpinner = !createdClient && !creationError;
  const showError = creationError;
  const showSuccess = !!createdClient && !creationError;

  return (
    <PageLayout className={classNames(styles.root, props.className)}>
      {showSpinner && (
        <LoadingSpinner>
          <span>{_t(`Create Client {{clientTitle}}`, { clientTitle: clientDto?.title })}</span>
        </LoadingSpinner>
      )}
      {showError && <ApiErrorPanel apiError={creationError} />}

      {showSuccess && (
        <SuccessPanel>
          <span>{_t(`The client was successfully created in the affiliate {{affiliateCode}}.`, { affiliateCode })}</span>
          <span>{_t(`In the next step the resources get assigned to the client.`)}</span>
          <Button onClick={() => goToResourceImport()} kind='primary'>
            Continue
          </Button>
        </SuccessPanel>
      )}
    </PageLayout>
  );
}
