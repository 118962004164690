import { useRef, useState } from 'react';
import { ReactComponent as DeleteRoleIco } from '../../../../assets/trash.svg';
import EditPoup, { EditPopupRow } from '../../../../components/edit-popup';
import Input from '../../../../components/input';
import { OrgUserRoleDto, RoleType, SharedClientContext } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import useEffectAsync from '../../../../utils/useEffectAsync';
import OrgUserHierarchyAssignor from '../user-hierarchy-assignor/org-user-hierarchy-assignor';
import styles from './org-user-edit-role-popup.module.scss';
import { ReactComponent as InfoIco } from '../../../../assets/info.svg';
import RolePermissionsPopup from '../../../../components/role-permissions-popup';
import { PermissionsGroup } from '../../../administration/roles/role-create-popup/permissions-groups';

export default function OrgUserEditRolePopup(props: {
  open: boolean;
  fullName: string;
  clientContext?: SharedClientContext;
  userRole?: OrgUserRoleDto;
  edit: boolean;
  onCancel: () => void;
  onSubmit: (nodeIds: string[]) => Promise<void>;
  onDeleteRole: () => void;
  permissionGroups: PermissionsGroup[];
}) {
  const { _t } = useBetterTranslate('org-user-edit-popup');
  const [nodesError, setNodesError] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();
  const hierarchySelectorRef = useRef(null);

  useEffectAsync(async () => {
    if (props.open) {
      setNodesError(undefined);
      setSubmitError(undefined);
    }
  }, [props.open]);
  //submission

  const validateNodes = (): boolean => {
    if (!props.edit) return true;
    const nodeCodes = (hierarchySelectorRef.current as any).getSelectedNodes();
    const isValid = nodeCodes.length > 0;
    setNodesError(isValid ? undefined : '\u26A0 ' + _t('Es muss mindestens ein Knoten ausgewählt werden.'));
    return isValid;
  };

  const submit = async () => {
    if (!validateNodes()) {
      return;
    }
    let nodeCodes = (hierarchySelectorRef.current as any).getSelectedNodes() as string[];
    nodeCodes = nodeCodes.filter((nodeCode) => {
      const forbidden = props.clientContext?.forbiddenParents.find((item) => item.code === nodeCode);
      return !forbidden;
    });
    try {
      await props.onSubmit(nodeCodes);
    } catch (error) {
      setSubmitError(_t('Ein unerwarteter Fehler ist aufgetreten.'));
    }
  };

  const [showRolePermissionsPopup, setShowRolePermissionsPopup] = useState<boolean>(false);
  const onRolesPermissionsClick = async () => {
    setShowRolePermissionsPopup(true);
  };

  return (
    <>
      <EditPoup
        open={props.open}
        className={styles.popup}
        onClose={props.onCancel}
        onSave={async () => await submit()}
        additionalFooterContentClassName={styles.submitError}
        additionalFooterContent={submitError}
        bodyClassName={styles.popupBody}
        footerClassName={styles.footer}
        actionsClassName={styles.actions}
        title={props.edit ? _t('Rollenberechtigung editieren') : _t('Rollenberechtigung anzeigen')}
        showCancel={props.edit}
        saveText={props.edit ? _t('Speichern') : _t('Schließen')}
      >
        <EditPopupRow label={props.fullName} lblClassName={styles.fullNameLbl}>
          <div className={styles.roleNameContainer}>
            <div className={styles.infoIco}>
              <InfoIco onClick={async () => await onRolesPermissionsClick()}></InfoIco>
            </div>
            <div className={styles.inputArea}>
              <Input label='' type='text' value={props.userRole?.name || ''} disabled={true} className={styles.inputField} />
            </div>
          </div>
        </EditPopupRow>

        <OrgUserHierarchyAssignor
          ref={hierarchySelectorRef}
          open={props.open}
          nodesSelectable={props.edit}
          selectedNodes={props.userRole?.nodeCodes}
          clientContext={props.clientContext}
          errorMsg={nodesError}
          onChange={validateNodes}
        />
        {props.edit && (
          <div className={styles.deleteRole} onClick={props.onDeleteRole}>
            <DeleteRoleIco />
            <span>{_t('Rollenberechtigung löschen')}</span>
          </div>
        )}
      </EditPoup>

      <RolePermissionsPopup
        open={showRolePermissionsPopup}
        permissionsGroups={props.permissionGroups}
        onClose={() => {
          setShowRolePermissionsPopup(false);
        }}
        roleType={props.userRole?.type as RoleType}
        roleName={props.userRole?.name}
      />
    </>
  );
}
