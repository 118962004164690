import classNames from 'classnames';
import colorStyles from '../../../components/style-utils';
import { percentageSave } from '../../../utils/math';
import useBetterTranslate from '../../../utils/translation-utils';
import ChartLegendTable from '../../charg-legend-table/chart-legend-table';
import DonutChart from '../../donut-chart/donut-chart';
import Box, { Divider } from '../../utils';
import { CHARGEPOINT_DOMAIN_STATUS_COLORS, STATION_DOMAIN_STATUS_COLORS } from '../domain-mappings';
import styles from './station-status-summary-panel.module.scss';

export interface SummaryChartPanelProps {
  stations: {
    total: number;
    overallTotal: number;

    online: number;
    offline: number;
    failures: number;
    notInOperation: number;

    onOnlineClicked?: () => void;
    onOfflineClicked?: () => void;
    onFailureClicked?: () => void;
    onNotInOperationClicked?: () => void;
  };
  connectors: {
    total: number;
    overallTotal: number;

    available: number;
    charging: number;
    unavailable: number;
    occupied: number;
    unknown: number;
    failure: number;

    onAvailableClicked?: () => void;
    onChargingClicked?: () => void;
    onUnavailableClicked?: () => void;
    onOccupiedClicked?: () => void;
    onUnknownClicked?: () => void;
    onFailureClicked?: () => void;
  };
}

export default function StationStatusSummaryPanel(props: SummaryChartPanelProps) {
  const { _t } = useBetterTranslate('station-status-summary-panel');
  return (
    <div className={styles.root}>
      <div className={styles.chart}>
        <div className={styles.donut}>
          <DonutChart
            items={[
              { color: 'atenso-blue', value: props.stations.total },
              { color: 'brand-neutral-100', value: props.stations.overallTotal - props.stations.total },
            ]}
          >
            <DonutChart
              counterClockWise={false}
              items={[
                { color: STATION_DOMAIN_STATUS_COLORS.online, value: props.stations.online },
                { color: STATION_DOMAIN_STATUS_COLORS.offline, value: props.stations.offline },
                { color: STATION_DOMAIN_STATUS_COLORS.failure, value: props.stations.failures },
                { color: STATION_DOMAIN_STATUS_COLORS.notInOperation, value: props.stations.notInOperation },
              ]}
            >
              <Box kind={'vflex'} fg='atenso-blue' gap='xs'>
                <Box testId={'stations_total'} fs={['5xl']} lh='3xl' fw='700'>
                  {props.stations.total || '0'}
                </Box>
                <Box fw='500' fs='s'>
                  {_t('Ladestationen')}
                </Box>
              </Box>
            </DonutChart>
          </DonutChart>
        </div>
        <ChartLegendTable
          className={classNames(styles.legend)}
          items={[
            {
              onClick: props.stations.onOnlineClicked,
              dataCy: 'online',
              pillApperance: STATION_DOMAIN_STATUS_COLORS.online,
              title: _t('Online'),
              value: `${props.stations.online} (${percentageSave(props.stations.online, props.stations.total).toFixed(0)} %)`,
              tooltip: _t('Die Station überträgt Daten und funktioniert ordnungsgemäß.'),
            },
            {
              onClick: props.stations.onOfflineClicked,
              dataCy: 'offline',
              pillApperance: STATION_DOMAIN_STATUS_COLORS.offline,
              title: _t('Offline'),
              value: `${props.stations.offline} (${percentageSave(props.stations.offline, props.stations.total).toFixed(0)} %)`,
              tooltip: _t('Die Station sendet kein Signal. Es ist nicht bekannt, ob die Station ordnungsgemäß funktioniert.'),
            },
            {
              onClick: props.stations.onFailureClicked,
              dataCy: 'defective',
              pillApperance: STATION_DOMAIN_STATUS_COLORS.failure,
              title: _t('Fehlerhaft'),
              value: `${props.stations.failures} (${percentageSave(props.stations.failures, props.stations.total).toFixed(0)} %)`,
              tooltip: _t('Die Station hat eine Fehlernachricht gesendet und funktioniert nicht ordnungsgemäß.'),
            },
            {
              onClick: props.stations.onNotInOperationClicked,
              dataCy: 'notInOp',
              pillApperance: STATION_DOMAIN_STATUS_COLORS.notInOperation,
              title: _t('Nicht in Betrieb'),
              value: `${props.stations.notInOperation} (${percentageSave(props.stations.notInOperation, props.stations.total).toFixed(0)} %)`,
              tooltip: _t(
                'Die Station wurde im System bereits angelegt aber noch nicht in Betrieb genommen oder wurde außer Betrieb gesetzt. Die Ladepunkte dieser Stationen werden im System nicht angezeigt.'
              ),
            },
          ]}
        />
      </div>
      <Divider kind='v' className={styles.divider} />
      <div className={styles.chart}>
        <div className={styles.donut}>
          <DonutChart
            counterClockWise={false}
            items={[
              { color: 'atenso-blue', value: props.connectors.total },
              { color: 'brand-neutral-100', value: props.connectors.overallTotal - props.connectors.total },
            ]}
          >
            <DonutChart
              className={colorStyles.components.connectorsStatus.colors.__define}
              counterClockWise={false}
              items={[
                { color: CHARGEPOINT_DOMAIN_STATUS_COLORS.available, value: props.connectors.available },
                { color: CHARGEPOINT_DOMAIN_STATUS_COLORS.charging, value: props.connectors.charging },
                { color: CHARGEPOINT_DOMAIN_STATUS_COLORS.occupied, value: props.connectors.occupied },
                { color: CHARGEPOINT_DOMAIN_STATUS_COLORS.failure, value: props.connectors.failure },
                { color: CHARGEPOINT_DOMAIN_STATUS_COLORS.notAvailable, value: props.connectors.unavailable },
                { color: CHARGEPOINT_DOMAIN_STATUS_COLORS.unknown, value: props.connectors.unknown },
              ]}
            >
              <Box kind={'vflex'} fg='atenso-blue' gap='xs'>
                <Box fs='5xl' lh='3xl' testId={'connectors_total'} fw='700'>
                  {props.connectors.total || '0'}
                </Box>
                <Box fw='500' fs='s'>
                  {_t(`Ladepunkte`)}
                </Box>
              </Box>
            </DonutChart>
          </DonutChart>
        </div>
        <ChartLegendTable
          className={classNames(styles.legend)}
          items={[
            {
              onClick: props.connectors.onAvailableClicked,
              dataCy: 'available',
              pillApperance: CHARGEPOINT_DOMAIN_STATUS_COLORS.available,
              title: _t('Verfügbar'),
              value: `${props.connectors.available} (${percentageSave(props.connectors.available, props.connectors.total).toFixed(0)} %)`,
              tooltip: _t('Ladepunkte sind bereit zur Verwendung.'),
            },
            {
              onClick: props.connectors.onChargingClicked,
              pillApperance: CHARGEPOINT_DOMAIN_STATUS_COLORS.charging,
              dataCy: 'charging',
              title: _t('Laden'),
              value: `${props.connectors.charging} (${percentageSave(props.connectors.charging, props.connectors.total).toFixed(0)} %)`,
              tooltip: _t('Ladepunkte werden derzeit verwendet und es findet ein Ladevorgang statt.'),
            },
            {
              onClick: props.connectors.onOccupiedClicked,
              pillApperance: CHARGEPOINT_DOMAIN_STATUS_COLORS.occupied,
              dataCy: 'occupied',
              title: _t('Besetzt'),
              value: `${props.connectors.occupied} (${percentageSave(props.connectors.occupied, props.connectors.total).toFixed(0)} %)`,
              tooltip: _t('Ladepunkte sind mit einem Fahrzeug verbunden aber es findet kein Ladevorgang statt.'),
            },
            {
              onClick: props.connectors.onFailureClicked,
              pillApperance: CHARGEPOINT_DOMAIN_STATUS_COLORS.failure,
              dataCy: 'failure',
              title: _t('Fehlerhaft'),
              value: `${props.connectors.failure} (${percentageSave(props.connectors.failure, props.connectors.total).toFixed(0)} %)`,
              tooltip: _t('Ladepunkte haben eine Fehlernachricht gesendet und funktionieren nicht ordnungsgemäß.'),
            },
            {
              onClick: props.connectors.onUnavailableClicked,
              pillApperance: CHARGEPOINT_DOMAIN_STATUS_COLORS.notAvailable,
              dataCy: 'notAvailable',
              title: _t('Nicht Verfügbar'),
              value: `${props.connectors.unavailable} (${percentageSave(props.connectors.unavailable, props.connectors.total).toFixed(0)} %)`,
              tooltip: _t('Ladepunkte sind reserviert oder gesperrt.'),
            },
            {
              onClick: props.connectors.onUnknownClicked,
              pillApperance: CHARGEPOINT_DOMAIN_STATUS_COLORS.unknown,
              dataCy: 'unknown',
              title: _t('Unbekannt'),
              value: `${props.connectors.unknown} (${percentageSave(props.connectors.unknown, props.connectors.total).toFixed(0)} %)`,
              tooltip: _t('Ladestation ist offline und es wurde kein Fehler für den Ladepunkt gesendet. Es ist unbekannt, ob der Ladepunkt verwendet werden kann.'),
            },
          ]}
        />
      </div>
    </div>
  );
}
