import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import { Item, Menu, animation, useContextMenu } from 'react-contexify';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as AddIco } from '../assets/add.svg';
import { ReactComponent as ArrowRightIco } from '../assets/arrow-right.svg';
import { ReactComponent as CloseIco } from '../assets/close.svg';
import { ReactComponent as DotsIco } from '../assets/dots-three.svg';
import { ReactComponent as EditIco } from '../assets/edit.svg';
import useBetterTranslate from '../utils/translation-utils';
import styles from './form-card.module.scss';
import { PageContainerProps, pageContainerStyles } from './page-container-grid';

type ContextMenuStyle = 'dots' | 'arrow';

export function FormCard(props: PropsWithChildren<{ className?: string } & PageContainerProps>) {
  return <section className={classNames(styles.root, props.className, ...pageContainerStyles(styles, props))}>{props.children}</section>;
}

export function FormCardTitle(props: PropsWithChildren<{ className?: string }>) {
  return <h2 className={classNames(styles.title, props.className)}>{props.children}</h2>;
}

export function FormCardContextItem(props: PropsWithChildren<{ className?: string; onClick?: () => void }>) {
  return (
    <Item className={classNames(styles.ctxMenuItem, props.className)} onClick={props.onClick}>
      {props.children}
    </Item>
  );
}

export function FormCardEditContextItem(props: { className?: string; lbl?: string; onClick?: () => void }) {
  const { _t } = useBetterTranslate('form-card');
  return (
    <FormCardContextItem className={props.className} onClick={props.onClick}>
      <EditIco />
      {props.lbl || _t('Bearbeiten')}
    </FormCardContextItem>
  );
}
export function FormCardDeleteContextItem(props: { className?: string; lbl?: string; onClick?: () => void }) {
  const { _t } = useBetterTranslate('form-card');
  return (
    <FormCardContextItem className={props.className} onClick={props.onClick}>
      <CloseIco />
      {props.lbl || _t('Löschen')}
    </FormCardContextItem>
  );
}
export function FormCardAddContextItem(props: { className?: string; lbl?: string; onClick?: () => void }) {
  const { _t } = useBetterTranslate('form-card');
  return (
    <FormCardContextItem className={props.className} onClick={props.onClick}>
      <AddIco />
      {props.lbl || _t('Hinzufügen')}
    </FormCardContextItem>
  );
}

export function FormCardContextTrigger(props: { menuItems: ReactNode; style?: ContextMenuStyle }) {
  const id = uuidv4();
  const { show } = useContextMenu({ id: id });
  let ico = <DotsIco />;
  if (!props.style || props.style === 'dots') {
    ico = <DotsIco />;
  } else if (props.style === 'arrow') {
    ico = <ArrowRightIco className={classNames(styles.icoDown)} />;
  }

  return (
    <>
      <button type='button' key={'trigger-bt'} onClick={(e) => show(e)} className={styles.ctxTrigger}>
        {ico}
      </button>
      <Menu key={'trigger-menu'} id={id} animation={animation.flip}>
        {props.menuItems}
      </Menu>
    </>
  );
}

export function FormCardBody(props: PropsWithChildren<{ className?: string; fullHeight?: boolean; contextMenuStyle?: ContextMenuStyle; contextMenuItems?: ReactNode[] }>) {
  return (
    <div className={classNames(styles.body, props.className, props.fullHeight ? styles.fullHeight : '')}>
      {props.contextMenuItems && props.contextMenuItems.length > 0 && <FormCardContextTrigger style={props.contextMenuStyle} menuItems={props.contextMenuItems} />}
      {props.children}
    </div>
  );
}

export function FormCardRow(
  props: PropsWithChildren<{ className?: string; noSeparator?: boolean; fullHeight?: boolean; contextMenuStyle?: ContextMenuStyle; contextMenuItems?: ReactNode[] }>
) {
  return (
    <div className={classNames(styles.row, props.className, props.noSeparator ? styles.noSep : '', props.fullHeight ? styles.fullHeight : '')}>
      <div className={classNames(styles.inner, props.fullHeight ? styles.fullHeight : '')}>{props.children}</div>

      {props.contextMenuItems && props.contextMenuItems.length > 0 && <FormCardContextTrigger style={props.contextMenuStyle} menuItems={props.contextMenuItems} />}
    </div>
  );
}

export function FormCardCol(props: PropsWithChildren<{ className?: string; noSeparator?: boolean }>) {
  return <div className={classNames(styles.col, props.className, props.noSeparator ? styles.noSep : '')}>{props.children}</div>;
}

export function FormCardProp(props: PropsWithChildren<{ className?: string }>) {
  return <div className={classNames(styles.prop, props.className)}>{props.children}</div>;
}

export function FormCardPropValue(props: PropsWithChildren<{ className?: string }>) {
  return <div className={classNames(styles.val, props.className)}>{props.children}</div>;
}

export function FormCardPropLbl(props: PropsWithChildren<{ className?: string }>) {
  return <div className={classNames(styles.lbl, props.className)}>{props.children}</div>;
}
