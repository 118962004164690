import classNames from 'classnames';
import { KeyboardEvent, ReactNode } from 'react';
import styles from './input-check-box.module.scss';

export default function CheckBox(props: {
  className?: string;
  classNameInput?: string;
  classNameSubLbl?: string;
  isSelected: boolean;
  label: string | ReactNode;
  disabled?: boolean;
  autoFocus?: boolean;
  subLbl?: ReactNode;
  onChange?: () => void;
  preventDefaultToClickLabel?: boolean;
  dataCy?: string;
}) {
  const handleKeyUp = (ev: KeyboardEvent<HTMLElement>) => {
    if (ev.key.toLocaleLowerCase() === 'escape') {
      ev.stopPropagation();
      ev.currentTarget?.blur();
    }
  };

  let dataAttributes: Record<string, any> = {};
  if (props.autoFocus) {
    dataAttributes['data-auto-focus'] = true;
  }

  return (
    <label className={classNames(styles.root, props.className)} onClick={(e) => e.stopPropagation()} data-cy={props.dataCy}>
      <div className={styles.inputWrapper}>
        <input {...dataAttributes} onKeyUp={handleKeyUp} onChange={props.onChange || (() => {})} type='checkbox' checked={props.isSelected} disabled={props.disabled} />
      </div>

      <div
        className={classNames(styles.labelsContainer, props.disabled ? styles.disabled : '')}
        onClick={(e) => (props.preventDefaultToClickLabel ? e.preventDefault() : () => {})}
      >
        <span className={styles.radioLabel}>{props.label}</span>
        {props.subLbl && <span className={classNames(styles.subLabel, props.classNameSubLbl)}>{props.subLbl}</span>}
      </div>
    </label>
  );
}
