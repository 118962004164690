import { useNavigate, useParams } from 'react-router-dom';
import { FormCard, FormCardBody } from '../../../components/form-card';
import Page, { ListingCardPlaceholder } from '../../../components/page';
import useBetterTranslate from '../../../utils/translation-utils';
import { ButtonPrimary } from '../../../components/button';
import api from '../../../services/api';
import { useApi } from '../../../hooks/useApi';
import DataTable, { DataTableRow } from '../../../components/data-table/data-table';
import { MigrationJobStatus, MigrationLogEntryStatus } from '../../../services/api-client/csp-api';
import styles from './ev-migration-job-details-page.module.scss';
import { useEffect, useRef } from 'react';
import { formatter } from '../../../utils/localized-types';
import { useAuth } from '../../../utils/AuthProvider';
import OperationTabPanel from '../shared/operation-tab-panel';

export default function EvMigrationJobDetailsPage() {
  const { _t } = useBetterTranslate('ev-migration-job-page');
  const { user } = useAuth();
  const languageCode = user?.preferences.languageCode;
  const navigate = useNavigate();
  const { jobId } = useParams();
  const bottomHtmlElement = useRef<HTMLDivElement>(null);

  const [jobRes, , jobErr, jobRefreshResult] = useApi(
    {
      call: async (jobId?: string) => {
        if (!jobId) return;
        const res = await api.evMigration.getJob(jobId);

        return res;
      },
      map: (data) => {
        if (!data) return data;
        return data;
      },
    },
    jobId
  );

  const scrollToBottom = () => {
    bottomHtmlElement?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (jobRes?.finishedAt) return;
    const interval = setInterval(() => {
      if (!jobRes?.finishedAt) {
        jobRefreshResult();
        setTimeout(() => {
          scrollToBottom();
        }, 300);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [jobId, jobRes?.finishedAt, jobRefreshResult]);

  const getJobStatusText = (status?: MigrationJobStatus) => {
    if (!status) return '';
    if (status === MigrationJobStatus.InProgress) return _t('In Progress');
    if (status === MigrationJobStatus.Success) return _t('Success');
    if (status === MigrationJobStatus.Failed) return _t('Failed');
    if (status === MigrationJobStatus.PartialSuccess) return _t('Partial Success');
  };

  const getEntryStatusText = (status: MigrationLogEntryStatus) => {
    if (status === MigrationLogEntryStatus.NotStarted) return _t('Not Started');
    if (status === MigrationLogEntryStatus.InProgress) return _t('In Progress');
    if (status === MigrationLogEntryStatus.Failed) return _t('Failed');
    if (status === MigrationLogEntryStatus.Success) return _t('Success');
  };

  return (
    <Page
      breadCrumb={[{ title: _t('Move HTP Stations to EV'), href: '/operations/ev-migration', active: true }]}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={jobErr}
      outOfPlaceHolder={<OperationTabPanel selectedTab='move-stations-to-evp' />}
    >
      <FormCard tabletSize='full'>
        <FormCardBody>
          <div className={styles.headerArea}>
            <div className={styles.jobInfo}>
              <table>
                <tbody>
                  <tr>
                    <td>{_t('Created By')}</td>
                    <td>{jobRes?.createdBy}</td>
                  </tr>
                  <tr>
                    <td>{_t('Created At')}</td>
                    <td>
                      {formatter.formatDateTimeFromIsoString(jobRes?.createdAt, languageCode, {
                        day: 'numeric',
                        month: 'numeric',
                        year: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>{_t('Status')}</td>
                    <td>{getJobStatusText(jobRes?.status)}</td>
                  </tr>
                  <tr>
                    <td>{_t('Updated At')}</td>
                    <td>
                      {formatter.formatDateTimeFromIsoString(jobRes?.lastUpdatedAt, languageCode, {
                        day: 'numeric',
                        month: 'numeric',
                        year: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </td>
                  </tr>
                  {jobRes?.finishedAt && (
                    <tr>
                      <td>{_t('Finished At')}</td>
                      <td>
                        {formatter.formatDateTimeFromIsoString(jobRes?.finishedAt, languageCode, {
                          day: 'numeric',
                          month: 'numeric',
                          year: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div>
              <ButtonPrimary ralign={true} dataCy='btnBactToJobs' onClick={() => navigate('/operations/ev-migration')}>
                {_t('Back To Ev Migration')}
              </ButtonPrimary>
            </div>
          </div>
          <div className={styles.tableArea} ref={bottomHtmlElement}>
            <DataTable
              sticky={true}
              records={jobRes?.entries || []}
              renderer={{
                row: (record, cols) => (
                  <DataTableRow classNames={styles.row} key={record.chargeBoxId}>
                    {cols}
                  </DataTableRow>
                ),
                cols: [
                  {
                    headerCol: () => <div>{_t('ChargeBoxId')}</div>,
                    name: 'chargeBoxId',
                    col: (record) => <div>{record.chargeBoxId}</div>,
                    width: '15%',
                  },
                  {
                    headerCol: () => <div>{_t('Status')}</div>,
                    name: 'status',
                    col: (record) => <div>{getEntryStatusText(record.status)}</div>,
                    width: '15%',
                  },
                  {
                    headerCol: () => <div>{_t('Steps')}</div>,
                    name: 'steps',
                    col: (record) => (
                      <div>
                        {record.steps.map((step, index) => {
                          return (
                            <div key={index}>
                              {index + 1}. {step}
                            </div>
                          );
                        })}
                      </div>
                    ),
                    width: '50%',
                  },
                  {
                    headerCol: () => <div>{_t('Failure Reason')}</div>,
                    name: 'failure',
                    col: (record) => <div>{record.failureReason}</div>,
                    width: '20%',
                  },
                ],
              }}
              dataCy='tblMigrationLog'
            />
            <div style={{ height: '10px' }} />
          </div>
        </FormCardBody>
      </FormCard>
    </Page>
  );
}
