import classNames from 'classnames';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Textfit } from 'react-textfit';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import { translate } from '../../i18n';
import { isHttpResponse } from '../../services/api';
import { ApiError, ClientHierarchyNodeTypeDto, HttpResponse, SharedClientContext, SharedClientDto, SharedClientHierarchyNodeDto } from '../../services/api-client/csp-api';
import useBetterTranslate from '../../utils/translation-utils';
import { ButtonPrimary } from '../button';
import { CircleIco } from '../circle-ico';
import EditPoup from '../edit-popup';
import { FormCard, FormCardBody, FormCardProp, FormCardPropValue } from '../form-card';
import { NodeSelectionState, applyAndPropagateSelection, compressSelectedNodes, mapItems, setSelectionStates } from './org-diagramm-utils';
import OrgHierarchyDiagramm, { OrgHierarchyDiagrammContext, OrgHierarchyDiagrammSettings, useOrgHiearchyDiagramm } from './org-hierarchy-diagramm';
import styles from './org-hierarchy-picker.module.scss';
import SelectClientPopup from './select-client-popup';

const _t = translate('org-hierarchy-picker');
export interface OrgHierarchyPickResult {
  clientCode: string;
  hierarchyNodes: string[];
}

type PickerSelectModes = 'client' | 'node';

type OrgHierarchyPickeBaseProps = {
  className?: string;

  clientContext?: SharedClientContext;
  // selected
};
type ClientAdditionalProps = {
  searchClients: (search: string) => Promise<SharedClientDto[] | HttpResponse<SharedClientDto[], ApiError>>;
  onClientSelected: (client: { code: string; title: string }) => void;
};
type NodeAdditionalProps = {
  onNodeSelected: (clientCode: string, selectedCodes?: string[]) => void;
  searchClients?: (search: string) => Promise<SharedClientDto[] | HttpResponse<SharedClientDto[], ApiError>>;
  selectedNodes?: string[];
  clientChangable: boolean;
};

function isNodePartiallySelected(code: string, selectedHierarchy: SharedClientHierarchyNodeDto[], entireHierarchy: SharedClientHierarchyNodeDto[]) {
  const allChildren = entireHierarchy.filter((n) => n.parentCode === code);
  const selectedChildren = selectedHierarchy.filter((n) => n.parentCode === code);
  return allChildren.length !== selectedChildren.length;
}

function getStatsByType(nodeType: ClientHierarchyNodeTypeDto, selectedHierarchy: SharedClientHierarchyNodeDto[], entireHierarchy: SharedClientHierarchyNodeDto[]) {
  const allSelected = selectedHierarchy.filter((n) => n.type === nodeType);
  const partialSelected = allSelected.map((n) => n.code).filter((c) => isNodePartiallySelected(c, selectedHierarchy, entireHierarchy));
  const avalable = entireHierarchy.filter((n) => n.type === nodeType);

  return {
    total: avalable.length,
    selected: allSelected.length,
    hasPartialSelected: partialSelected.length > 0,
  };
}
function createBreadcrumbItem(nodeType: ClientHierarchyNodeTypeDto, selectedHierarchy: SharedClientHierarchyNodeDto[], entireHierarchy: SharedClientHierarchyNodeDto[]) {
  const elements = entireHierarchy.filter((n) => n.type === nodeType);
  if (elements.length <= 0) return null;

  const stats = getStatsByType(nodeType, selectedHierarchy, entireHierarchy);
  return {
    label: `${stats.selected}/${stats.total} ${stats.hasPartialSelected ? _t('teilweise') : ''} ${_t('ausgewählt')}`,
    type: nodeType,
  };
}

function buildBreadcrumbModels(ctx?: SharedClientContext) {
  const breadCrumbItems: { label: string; type: ClientHierarchyNodeTypeDto }[] = [];
  if (!ctx?.selectedHierarchy) return breadCrumbItems;
  if (!ctx?.accessableNodes) return breadCrumbItems;

  const hierarchy = [...ctx.accessableNodes, ...ctx.forbiddenParents];

  const rootNode = hierarchy.find((n) => n.type === ClientHierarchyNodeTypeDto.ClientRoot);
  if (!rootNode) return breadCrumbItems;

  breadCrumbItems.push({ label: rootNode.title, type: ClientHierarchyNodeTypeDto.ClientRoot });
  const region = createBreadcrumbItem(ClientHierarchyNodeTypeDto.Region, ctx.selectedHierarchy, hierarchy);
  if (region) {
    breadCrumbItems.push(region);
  }
  const location = createBreadcrumbItem(ClientHierarchyNodeTypeDto.Location, ctx.selectedHierarchy, hierarchy);
  if (location) {
    breadCrumbItems.push(location);
  }
  const area = createBreadcrumbItem(ClientHierarchyNodeTypeDto.Area, ctx.selectedHierarchy, hierarchy);
  if (area) {
    breadCrumbItems.push(area);
  }

  return breadCrumbItems;
}

export type OrgHierarchyPickeProps<TMode extends PickerSelectModes> = (TMode extends 'client' ? ClientAdditionalProps : NodeAdditionalProps) & {
  selectMode: TMode;
} & OrgHierarchyPickeBaseProps;

export default function OrgHierarchyPicker<TMode extends PickerSelectModes>(props: OrgHierarchyPickeProps<TMode>) {
  const { _t } = useBetterTranslate('org-hierarchy-picker');

  const clientPickerProps: OrgHierarchyPickeProps<'client'> | undefined = props.selectMode === 'client' ? (props as any as OrgHierarchyPickeProps<'client'>) : undefined;
  const nodePickerProps: OrgHierarchyPickeProps<'node'> | undefined = props.selectMode === 'node' ? (props as any as OrgHierarchyPickeProps<'node'>) : undefined;

  // client picker setup
  const [showOrgPickerPopup, setShowOrgPickerPopup] = useState(false);

  const searchCLientsHandler = useMemo(() => {
    if (!nodePickerProps) return undefined;
    if (!nodePickerProps.searchClients) return undefined;

    const searchClientsWrapper = async (ctx: OrgHierarchyDiagrammContext, search: string) => {
      const res = await nodePickerProps!.searchClients!(search);
      if (isHttpResponse(res)) {
        return res.data.map((r) => r);
      } else {
        return res.map((r) => r);
      }
    };

    return searchClientsWrapper;
  }, [nodePickerProps]);

  const clientPickHandler = useCallback(
    (ctx: OrgHierarchyDiagrammContext, client: string, dto?: SharedClientDto) => {
      if (dto) {
        const mapped = mapItems(dto.hierarchy, client);
        ctx.setItems(mapped);
      } else {
        if (!nodePickerProps?.clientContext?.accessableNodes) return;

        const hierarchy = [...nodePickerProps.clientContext.accessableNodes, ...nodePickerProps.clientContext.forbiddenParents];
        const mapped = mapItems(hierarchy, client);
        if (nodePickerProps.selectedNodes && nodePickerProps.selectedNodes.length > 0) {
          setSelectionStates(nodePickerProps.selectedNodes, mapped);
        }

        ctx.setItems(mapped);
      }

      ctx.fitToView(100);
    },
    [nodePickerProps?.clientContext?.accessableNodes, nodePickerProps?.selectedNodes, nodePickerProps?.clientContext?.forbiddenParents]
  );

  // node picker setup
  const [showNodePickerPopup, setShowNodePickerPopup] = useState(false);

  // const [nodePickerSelectedNodes, setNodePickerSelectedNodes] = useState(nodePickerProps?.selectedNodes || []);

  const diagProps = useMemo(() => {
    const settings: OrgHierarchyDiagrammSettings = {
      diagramProps: {
        readonly: true,
        className: styles.diagramm,
        nodesSelectable: true,
      },
      onItemClick: (ctx, node, others) => {
        if (node.selected === NodeSelectionState.Full || node.selected === NodeSelectionState.Partial) {
          applyAndPropagateSelection(NodeSelectionState.None, node, others);
        } else {
          applyAndPropagateSelection(NodeSelectionState.Full, node, others);
        }
        ctx.refresh();
      },
      searchClientRoot: searchCLientsHandler,
      clientRootChangable: nodePickerProps?.clientChangable || false,
      onClientPicked: clientPickHandler,
    };

    return settings;
  }, [nodePickerProps?.clientChangable, clientPickHandler, searchCLientsHandler]);

  let [diagrammProps, diagContext] = useOrgHiearchyDiagramm(diagProps);

  useEffect(() => {
    if (!showNodePickerPopup) return;

    diagContext.fitToView();
  }, [showNodePickerPopup, diagContext]);

  useEffect(() => {
    if (!nodePickerProps?.clientContext?.accessableNodes) return;
    if (!showNodePickerPopup) return;

    const hierarchy = [...nodePickerProps.clientContext.accessableNodes, ...nodePickerProps.clientContext.forbiddenParents];
    const mapped = mapItems(hierarchy, nodePickerProps?.clientContext?.code);
    if (nodePickerProps.selectedNodes && nodePickerProps.selectedNodes.length > 0) {
      setSelectionStates(nodePickerProps.selectedNodes, mapped);
    }

    diagContext.setItems(mapped);
  }, [
    diagContext,
    nodePickerProps?.clientContext?.code,
    nodePickerProps?.clientContext?.accessableNodes,
    showNodePickerPopup,
    nodePickerProps?.selectedNodes,
    nodePickerProps?.clientContext?.forbiddenParents,
  ]);

  // nodePickerProps?.clientContext?.availableHierarchy[0].type

  const breadcrumNodes = buildBreadcrumbModels(props.clientContext);

  return (
    <FormCard phoneSize='full' className={classNames(styles.root)}>
      <FormCardBody>
        {/* <FormCardRow className={s}> */}
        {clientPickerProps && (
          <div className={classNames(styles.orgSelectBar)}>
            <CircleIco colorStyle={'primary'}>
              <Textfit className={'csp-fit-text-v-center'}>{props.clientContext?.code || ''}</Textfit>
            </CircleIco>
            <FormCardProp>
              <FormCardPropValue className={styles.commonParent}>{props.clientContext?.title || ''}</FormCardPropValue>
              {/* <FormCardPropLbl>{props?.clientContext?.title || ''}</FormCardPropLbl> */}
            </FormCardProp>

            {clientPickerProps.clientContext?.matchOtherClients && (
              <ButtonPrimary ralign={true} onClick={() => setShowOrgPickerPopup(true)}>
                {_t('Organisation wechseln')}
              </ButtonPrimary>
            )}
          </div>
        )}
        {nodePickerProps && (
          <ul className={classNames(styles.breadcrumb)}>
            {breadcrumNodes.map((item, i) => {
              return (
                <Fragment key={i}>
                  <li data-cy={`hier_${item.type}`} onClick={() => setShowNodePickerPopup(true)} className={classNames(styles.breadcrumbItem, styles[item.type])} key={`${i}node`}>
                    {item.label}
                  </li>
                  <li className={classNames(styles.breadcrumbChevron)} key={`${i}chevron`}>
                    <ChevronRight />
                  </li>
                </Fragment>
              );
            })}
          </ul>
        )}
      </FormCardBody>

      {nodePickerProps && (
        <EditPoup
          dataCy={{
            buttonSave: 'hier_pick_btn_apply',
            buttonCancel: 'hier_pick_btn_cancel',
          }}
          open={showNodePickerPopup}
          bodyClassName={classNames(styles.popup, styles.nodePicker)}
          actionsClassName={styles.nodePickerFooter}
          onClose={() => {
            setShowNodePickerPopup(false);
          }}
          onSave={() => {
            setShowNodePickerPopup(false);
            const selectedNodes = diagContext.getSelected();
            const allNodes = diagContext.getAll();
            const rootNode = allNodes.find((n) => n.type === 'clientRoot');
            if (!rootNode) {
              console.error(`no clientRoot found to be selected in the hierarchy`);
              return;
            }

            let selectedCodes = selectedNodes.map((n) => n.code);
            let compressedCodes = compressSelectedNodes(selectedCodes, allNodes);
            // tont proagate the root code because this is usually a path param and dont be needed in query strings
            compressedCodes = compressedCodes.filter((c) => c !== rootNode.code);

            nodePickerProps.onNodeSelected(rootNode.code, compressedCodes);
          }}
          showCancel={true}
          saveText={_t('Übernehmen')}
          title={' '}
        >
          <OrgHierarchyDiagramm {...diagrammProps} />
        </EditPoup>
      )}

      {clientPickerProps && (
        <SelectClientPopup
          open={showOrgPickerPopup}
          client={props.clientContext}
          onCancel={() => {
            setShowOrgPickerPopup(false);
          }}
          onClientSelected={(client: SharedClientDto | null) => {
            setShowOrgPickerPopup(false);
            if (client) clientPickerProps.onClientSelected({ code: client.code, title: client.title });
          }}
          searchClients={async (search) => {
            const res = await clientPickerProps.searchClients(search);
            if (isHttpResponse(res)) {
              return res.data.map((r) => r);
            } else {
              return res.map((r) => r);
            }
          }}
        />
      )}
    </FormCard>
  );
}
