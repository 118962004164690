import { useCallback, useState } from 'react';
import useFormState from '../../../../hooks/useFormState';
import styles from './user-delete-popup.module.scss';
import useEffectAsync from '../../../../utils/useEffectAsync';
import DeletePopup from '../../../../components/delete-popup';
import api from '../../../../services/api';
import useBetterTranslate from '../../../../utils/translation-utils';

export default function UserDeletePopup(props: { affiliateCode: string; open: boolean; close: () => void; onSubmit: () => void; mail: string }) {
  const { _t } = useBetterTranslate('user-delete-popup');

  // Form state
  const formState = useFormState();

  //load data before open
  const [showPopup, setShowPopup] = useState(props.open);
  const onOpen = useCallback(async () => {}, []);

  useEffectAsync(async () => {
    if (props.open) {
      await onOpen();
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [props.open]);

  //submission
  formState.handleSubmit(async () => {
    await api.users.deleteUser(props.affiliateCode, props.mail);
    props.onSubmit();
    props.close();
  });

  return (
    <DeletePopup
      open={showPopup}
      className={styles.popup}
      onClose={props.close}
      onDelete={async () => await formState.submit()}
      title={_t('Benutzer Löschen')}
      deleteText={_t('Löschen')}
      additionalFooterContentClassName={styles.submitError}
      additionalFooterContent={formState.submitError}
      dataCy={'popDeleteUser'}
    >
      <div className={styles.infoText} data-cy='txaDeleteUser'>
        {_t('Möchten Sie den Benutzer {{userMail}} wirklich löschen?', { userMail: props.mail })}
      </div>
    </DeletePopup>
  );
}
