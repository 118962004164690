import { useEffect, useRef } from 'react';

/**
 * same as useEffect but supports async ops in it.
 * IMPORTANT: it is not possible to provide a cleanup handler
 * TODO: maybe add one in future
 * @param handler
 * @param deps
 */
export default function useEffectAsync(handler: () => Promise<void>, deps: any[]) {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    (async () => {
      await handler();
    })();
    return () => {};
  }, deps);
}

// Issue w. strict mode, get triggered twice
// in cases where u want to post data to an API in a "page load" this will be an issue
export function useOnMountUnsafeAsync(handler: () => Promise<void>) {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      handler();
    }
  }, []);
}
