import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import * as momentTimezone from 'moment-timezone';
import { TimeGroupResolution } from '../services/api-client/csp-api';
import { formatter } from './localized-types';

export function startOfDay(fromDate?: string): string | undefined {
  return fromDate ? moment(fromDate).startOf('day').utc().toISOString() : fromDate;
}

export function endOfDay(toDate?: string) {
  return toDate ? moment(toDate).endOf('day').utc().toISOString() : toDate;
}

export function getTimezone(): string {
  return momentTimezone.tz.guess();
}

export function formateByResolution(dateStr: string, languageCode: string, resolution: TimeGroupResolution): string {
  const date = moment(dateStr).locale(languageCode);
  if (resolution === TimeGroupResolution.WEEK) {
    return `${languageCode === 'de' ? 'KW' : languageCode === 'en' ? 'CW' : 'SC'} ${date.week()}`;
  } else if (resolution === TimeGroupResolution.MONTH) {
    return date.format('MMMM YYYY');
  } else {
    return formatter.formatDate(date.toDate(), languageCode);
  }
}
