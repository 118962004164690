import classNames from 'classnames';
import { MouseEventHandler, ReactNode, useMemo } from 'react';
import { components, ControlProps, GroupBase, OptionProps } from 'react-select';

import AsyncSelect from 'react-select/async';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './single-select.module.scss';

export interface OptionProp {
  id: string;
}

export interface SingleSelectProps<OptType> {
  fetchOptions: (input: string) => Promise<OptType[]>;
  createOption: (val: OptType, optProps: OptionProps<OptType, false, GroupBase<OptType>>) => ReactNode;
  createRemove?: (val: OptType) => ReactNode;
  removeBtnClass?: (val: OptType) => string;
  placeholder?: string;
  onChanged: (value: OptType | null) => void;
  selectedValue: OptType | null;
  classNames?: string;
  // onMenuOpen: () => void;
  options?: OptType[] | boolean;
  className?: string;
  renderDisplayValue?: (val: OptType) => ReactNode;
  isClearable?: boolean;
  menuOpen?: boolean;
  maxMenuHeight?: number;
  dataCy?: string;

  onClickInside?: MouseEventHandler<HTMLDivElement>;
}

export default function SingleSelect<OptType extends OptionProp>(props: SingleSelectProps<OptType>) {
  const { _t } = useBetterTranslate('single-select');
  const loadOpts = useMemo(() => {
    return (inputValue: string, callback: (options: OptType[]) => void) => {
      props.fetchOptions(inputValue).then((values) => {
        callback(values);
      });
    };
  }, [props]);

  const OptComponent = useMemo(() => {
    const comp = function TagOption(optProps: OptionProps<OptType, false, GroupBase<OptType>>) {
      const option = props.createOption(optProps.data, optProps);

      return (
        <components.Option {...optProps} className={classNames(optProps.className, styles.option, optProps.isFocused ? styles.focused : '')}>
          {option}
        </components.Option>
      );
    };

    return comp;
  }, [props]);

  // const DropDownIndicatorWrapperComponent = useMemo(() => {
  //   const comp = function DropDownIndicatorWrapper(optProps: DropdownIndicatorProps<OptType, false, GroupBase<OptType>>) {
  //     return (
  //       <div onClick={(ev) => {
  //         ev.stopPropagation();
  //         ev.preventDefault();
  //       }}>
  //         <components.DropdownIndicator {...optProps} />
  //       </div>
  //     )
  //   };

  //   return comp
  // }, [props]);

  const ControlComponent = useMemo(() => {
    const comp = function ControlComponent<OptType extends OptionProp>(compProps: ControlProps<OptType, false, GroupBase<OptType>>) {
      let values = compProps.hasValue ? compProps.getValue() : null;
      let value = values && values.length > 0 ? values[0] : null;

      return (
        <components.Control {...compProps}>
          {!compProps.menuIsOpen && value && <span className={styles.displayHolder}>{props.renderDisplayValue ? props.renderDisplayValue(value as any) : value.id}</span>}
          {compProps.children}
        </components.Control>
      );
    };

    return comp;
  }, [props]);

  return (
    <div className={classNames(styles.root, props.classNames)} onClick={props.onClickInside} data-cy={props.dataCy}>
      <AsyncSelect
        // this component has a bug within it's caching
        // it use an JS object as a cache, therefor if a user enters the text 'hasOwnProperty' (as an example)
        // it try to override the property (function) 'hasOwnProperty' on the object and later on try to use this as a result
        // this cause an exceptio
        // if needed to cache we should investigate this further for now, just disable the cache.
        cacheOptions={false}
        isClearable={props.isClearable || false}
        isMulti={false}
        menuIsOpen={props.menuOpen}
        isSearchable={true}
        maxMenuHeight={props.maxMenuHeight}
        // blurInputOnSelect={true}
        className={classNames(styles.select, props.className)}
        classNamePrefix={'singleSelectPrefix'}
        noOptionsMessage={(val) => {
          if (!val.inputValue) return <span>{_t('Bitte geben Sie ein Suchkriterium ein')}</span>;
          return <span>{_t('Keine Einträge für "{{searchValue}}" gefunden', { searchValue: val.inputValue })}</span>;
        }}
        defaultOptions={props.options}
        placeholder={props.placeholder}
        blurInputOnSelect={true}
        // openMenuOnClick={false}

        // openMenuOnClick={false}
        loadOptions={loadOpts}
        // options={props.options}
        value={props.selectedValue}
        onChange={(newVal) => {
          props.onChanged(newVal);
        }}
        getOptionValue={(o) => o.id}
        // menuPosition={'fixed'}
        // defaultOptions={true}

        // menuIsOpen={false}
        components={{
          Option: OptComponent,
          // DownChevron: DownChevronWrapperComponent,
          // DropdownIndicator: DropDownIndicatorWrapperComponent,
          // DownChevron: components.DownChevron,
          // IndicatorSeparator: components.IndicatorSeparator,
          // DropdownIndicator: components.DropdownIndicator,
          Control: ControlComponent,
          // IndicatorSeparator: props.style === 'search' ? (() => null ) : undefined
          // MultiValueContainer: multiValComponent,
          //   Control: UserControl,
          //   // SingleValue: OrgSingleValue,
          //   // Input: () => <span></span>
        }}
      />
    </div>
  );
}
