import { ReactComponent as SpinnerIco } from '../../assets/spinner3.svg';
import Badge, { DotBadge } from '../../components-v2/badge/badge';
import { rateTypeColor, SESSION_STATUS_COLORS, sessionStatusTranslation } from '../../components-v2/business-components/domain-mappings';
import { DataTableLayout, sortChecker, sortHandler } from '../../components-v2/data-table/data-table';
import Box, { contrastColor } from '../../components-v2/utils';
import { NodeHierarchyLabelInverted } from '../../components/org-hierarchy/node-hierarchy-label';
import NodeLabel from '../../components/org-hierarchy/node-label';
import { rateTypeTranslation } from '../../components/tag';
import api from '../../services/api';
import { LmChargingSessionListItemDto, LmChargingSessionListResultDto, LmChargingSessionSumsResultDto, RateServiceType, SessionStatus } from '../../services/api-client/csp-api';
import { useAuth } from '../../utils/AuthProvider';
import { endOfDay, getTimezone, startOfDay } from '../../utils/date';
import { formatEnergy, formatPrice } from '../../utils/format';
import useBetterTranslate from '../../utils/translation-utils';
import uniqueLineIdentifier from '../../utils/unique-line-ident';
import { ChargingSessionsListPage, ExportSessionsArgs, FilterParameters, SessionTimeCell } from './charging-sessions-list-page';

const SERVICE_TYPE_ALLOWED_VALUES = [RateServiceType.WORK, RateServiceType.EMPLOYEE, RateServiceType.PUBLIC, RateServiceType.UNDEFINED];

export default function LmChargingSessionsListPage() {
  const SORT_BY_VALUES = ['evseId', 'purchaseCost', 'totalCost'];
  const { _t } = useBetterTranslate('lm-charging-sessions-list-page');
  const { user } = useAuth();

  const sessionsApiCall = async (filter: FilterParameters) => {
    const apiFilter = toSearchApiFilter(filter);
    if (!apiFilter) return undefined;

    const resp = await api.lmChargingSessions.list(apiFilter, { cancelToken: uniqueLineIdentifier() });
    return resp;
  };

  const sumsApiCall = async (filter: FilterParameters) => {
    const apiFilter = toSearchApiFilter(filter);
    if (!apiFilter) return undefined;

    const resp = await api.lmChargingSessions.sums(apiFilter, { cancelToken: uniqueLineIdentifier() });
    return resp;
  };

  const exportApiCall = async (filterParams: FilterParameters, args: ExportSessionsArgs, cancelToken: string) => {
    const apiFilter = toSearchApiFilter(filterParams);
    if (!apiFilter) return undefined;

    return await api.lmChargingSessions.export(
      {
        filter: apiFilter,
        sessionFields: args.sessionFields,
        stationFields: args.stationFields,
        cardFields: [],
        fileType: args.fileType,
        aggregateByStationId: args.aggregateByStationId,
        aggregateByStationLocation: args.aggregateByStationLocation,
        aggregateByCardId: false,
        aggregateByCardLocation: false,
        serviceTypeLabels: args.serviceTypeLabels,
        aggregationLabels: args.aggregationLabels,
        timezone: args.timezone,
      },
      { cancelToken }
    );
  };

  const toSearchApiFilter = (filter: FilterParameters) => {
    if (!filter.clientCode) return undefined;
    let consumedEnergyFrom = filter.energyFrom;
    if (consumedEnergyFrom !== undefined) {
      consumedEnergyFrom = consumedEnergyFrom * 1000;
    }
    let consumedEnergyTo = filter.energyTo;
    if (consumedEnergyTo !== undefined) {
      consumedEnergyTo = consumedEnergyTo * 1000;
    }
    const sortBy: any = filter.sortBy === 'totalCost' ? 'workTotalCost' : filter.sortBy;
    return {
      client: filter.clientCode,
      hierarchyNodeCodes: filter.nodes || [filter.clientCode],
      limit: Number(filter.limit || 20),
      skip: Number(filter.skip || 0),
      from: startOfDay(filter.from),
      to: endOfDay(filter.to),
      entityFilter: filter.entity || [],
      serviceTypes: filter.serviceType as RateServiceType[],
      sortBy: sortBy,
      sortDesc: filter.sortDesc === '1',
      energy: { from: consumedEnergyFrom, to: consumedEnergyTo },
      purchaseCost: { from: filter.purchaseCostFrom, to: filter.purchaseCostTo },
      workTotalCost: { from: filter.totalCostFrom, to: filter.totalCostTo },
      status: filter.status as SessionStatus[],
      stationIsPublic: filter.public === '1' ? true : undefined,
    };
  };

  const energyApiCall = async (clientCode?: string, from?: string, to?: string, nodes?: string[]) => {
    if (!clientCode) return undefined;
    const resp = await api.lmChargingSessions.energyConsumption(
      {
        client: clientCode,
        hierarchyNodeCodes: nodes || [clientCode],
        from: startOfDay(from),
        to: endOfDay(to),
        timezone: getTimezone(),
      },
      { cancelToken: uniqueLineIdentifier() }
    );
    return resp;
  };

  const searchEntityTags = async (clientCode: string | undefined, txt: string) => {
    if (!clientCode) return [];
    const entityFilterRes = await api.lmChargingSessions.searchFilters({ q: txt, clientCode }, { cancelToken: uniqueLineIdentifier() });
    return entityFilterRes.data;
  };

  return (
    <ChargingSessionsListPage<LmChargingSessionListItemDto, LmChargingSessionListResultDto, LmChargingSessionSumsResultDto>
      pagePrefix={'lm'}
      sortValues={SORT_BY_VALUES}
      totalCostFilter={true}
      purchaseCostFilter={true}
      isStationPublicFilter={true}
      serviceTypes={SERVICE_TYPE_ALLOWED_VALUES}
      sessionsApiCall={sessionsApiCall}
      sumsApiCall={sumsApiCall}
      energyApiCall={energyApiCall}
      searchEntityTags={searchEntityTags}
      exportFieldsConfig={{ showStations: true, showCards: false }}
      exportApiCall={exportApiCall}
      tableLayout={(sessionsResp, filterParams, setFilterParams, sumResp) => {
        // const hierarchy = sessionsResp?.allClientHierarchy || [];
        const result: DataTableLayout<LmChargingSessionListItemDto> = [
          {
            width: '3fr',
            header: {
              onSort: sortHandler('evseId', setFilterParams),
              sorting: sortChecker('evseId', filterParams),
              node: () => <Box fw='800'>{_t('EVSE-ID')}</Box>,
            },
            cell: {
              node: (record) => (
                <Box gap='xs' fw='600'>
                  {record.evseId}
                </Box>
              ),
            },
          },
          {
            width: '3fr',
            header: {
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Location')}</Box>
                  <Box>{_t('Area')}</Box>
                </Box>
              ),
            },
            cell: {
              node: (record) => {
                const hierarchy = [...(sessionsResp?.clientContext.accessableNodes || []), ...(sessionsResp?.clientContext?.forbiddenParents || [])];
                return (
                  <Box gap='xs' kind={'vflex'}>
                    <Box fw='800'>
                      <NodeHierarchyLabelInverted allNodes={hierarchy} code={record.nodeCode} hideArea={true} hideClientRoot={true} />
                    </Box>
                    <NodeLabel allNodes={hierarchy} code={record.nodeCode} />
                  </Box>
                );
              },
            },
          },
          {
            width: '3fr',
            header: {
              onSort: sortHandler('startTime', setFilterParams),
              sorting: sortChecker('startTime', filterParams),
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Start')}</Box>
                  <Box>{_t('End')}</Box>
                  <Box>{_t('Duration')}</Box>
                </Box>
              ),
            },
            cell: {
              node: (record) => <SessionTimeCell startTime={record.startTime} endTime={record.endTime} />,
            },
          },

          {
            width: '2fr',
            header: {
              onSort: sortHandler('rateServiceType', setFilterParams),
              sorting: sortChecker('rateServiceType', filterParams),
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Used')}</Box>
                  <Box fw='800'>{_t('Service')}</Box>
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box h='100%' kind={'vflex'} justify='center'>
                  <Badge testId={'el_service'} fg={contrastColor(rateTypeColor(record.rateServiceType))} bg={rateTypeColor(record.rateServiceType)}>
                    {rateTypeTranslation(record.rateServiceType)}
                  </Badge>
                </Box>
              ),
            },
          },

          {
            width: '2fr',
            header: {
              onSort: sortHandler('energy', setFilterParams),
              sorting: sortChecker('energy', filterParams),
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Charged')}</Box>
                  <Box fw='800'>{_t('Energy')}</Box>
                  <Box>{_t('(kWh)')}</Box>
                </Box>
              ),
            },
            subHeader: {
              node: () => (
                <Box kind={'vflex'} justify='center' align='flex-end' fw='700'>
                  {!sumResp ? <SpinnerIco /> : <div>{formatEnergy(sumResp.totalEnergy, user?.preferences.languageCode, 2)}</div>}
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box h='100%' kind={'vflex'} justify='center' align='flex-end' testId='el_energy'>
                  {formatEnergy(record.energy, user?.preferences.languageCode, 2)}
                </Box>
              ),
            },
          },

          {
            width: '2fr',
            header: {
              onSort: sortHandler('purchaseCost', setFilterParams),
              sorting: sortChecker('purchaseCost', filterParams),
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Refund')}</Box>
                  <Box>{_t('(€)')}</Box>
                </Box>
              ),
            },
            subHeader: {
              node: () => (
                <Box kind={'vflex'} justify='center' align='flex-end' fw='700'>
                  {!sumResp ? <SpinnerIco /> : <div>{formatPrice(sumResp.purchaseCost, user?.preferences.languageCode, 2)}</div>}
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box h='100%' kind={'vflex'} justify='center' align='flex-end' testId='el_refund'>
                  {formatPrice(record?.purchaseCost, user?.preferences.languageCode, 2)}
                </Box>
              ),
            },
          },

          {
            width: '2fr',
            header: {
              onSort: sortHandler('totalCost', setFilterParams),
              sorting: sortChecker('totalCost', filterParams),
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Cost')}</Box>
                  <Box>{_t('(€)')}</Box>
                </Box>
              ),
            },
            subHeader: {
              node: () => (
                <Box kind={'vflex'} justify='center' align='flex-end' fw='700'>
                  {!sumResp ? <SpinnerIco /> : <div>{formatPrice(sumResp.workTotalCost, user?.preferences.languageCode)}</div>}
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box h='100%' kind={'vflex'} justify='center' align='flex-end' testId='el_cost'>
                  {formatPrice(record?.workTotalCost, user?.preferences.languageCode, 2)}
                </Box>
              ),
            },
          },
          {
            width: '2fr',
            header: {
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Status')}</Box>
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box h='100%' kind={'vflex'} justify='center' testId='el_status'>
                  <DotBadge circleColor={SESSION_STATUS_COLORS[record.status]}>{sessionStatusTranslation(record.status)}</DotBadge>
                </Box>
              ),
            },
          },
        ];

        return result;
      }}
    />
  );
}
