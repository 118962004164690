import { useCallback, useMemo, useState } from 'react';
import EditPoup, { EditPopupRow } from '../../../../components/edit-popup';
import Input from '../../../../components/input';
import SingleSelect from '../../../../components/select/single-select';
import useFormState from '../../../../hooks/useFormState';
import api from '../../../../services/api';
import { AffiliateDto, Permission } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import useEffectAsync from '../../../../utils/useEffectAsync';
import { CARDS_PERMISSION_CODE, OPERATION_PERMISSION_CODE, PermissionsGroup, getAllPermissionsGroups } from '../../../administration/roles/role-create-popup/permissions-groups';
import PermissionGroupsComponent from '../../../administration/roles/role-create-popup/permissions-groups-component';
import styles from './affiliate-create-popup.module.scss';

export default function AffiliateCreatePopup(props: { open: boolean; affiliateCode?: string; close: () => void; onSubmit: () => void }) {
  const [affiliate, setAffiliate] = useState<AffiliateDto>();

  const { _t } = useBetterTranslate('client-create-popup');

  // Form state
  const formState = useFormState();
  const resetForm = formState.reset;

  //title
  const [titleField, setTitle] = formState.useInputState({
    label: _t('Name'),
    placeholder: _t('Name hinzufügen'),
    maxLength: 100,

    initialValue: '',
    validate: async (val) => {
      if (!val) return _t('Dieses Feld darf nicht leer sein.');
      val = val.trim();
      if (val.length === 0) {
        return _t('Dieses Feld darf nicht leer sein.');
      }
      if (val.length > 100) {
        return _t(`Name darf nicht länger als 100 Zeichen sein.`);
      }
      if (affiliate && affiliate.title === val) {
        return;
      }
      const exist = await api.affiliate.titleExists({ title: val });
      if (exist.data) return _t(`Name ist bereits vergeben.`);
    },
  });

  //code
  const [codeField, setCode] = formState.useInputState({
    label: _t('Code'),
    labelInfo: _t('Der Code wird als eindeutige Kennung im System verwendet.'),
    placeholder: _t('Code'),
    maxLength: 6,

    initialValue: '',
    validate: async (val) => {
      if (affiliate) {
        return;
      }
      if (!val) return _t('Dieses Feld darf nicht leer sein.');

      if (val.length > 6) {
        return _t(`Code darf nicht länger als 6 Zeichen sein.`);
      }

      if (val === 'ROOT') {
        return _t(`Code ist bereits vergeben.`); //reserved word
      }

      const exist = await api.affiliate.codeExists({ code: val });
      if (exist.data) return _t(`Code ist bereits vergeben.`);
    },
    transform: (val) => {
      if (!val) return val;
      return val.replace(/[^a-z0-9]/gi, '').toUpperCase();
    },
  });

  //cpms
  const [cpmsField, setCpms] = formState.useInputState({
    label: _t('Organisation Group'),
    placeholder: _t('Organisation Group'),
    maxLength: 50,

    initialValue: '',
    validate: async (val) => {
      if (!val && codeField.value === 'HTB') return;
      if (!val) return _t('Dieses Feld darf nicht leer sein.');

      if (affiliate && affiliate.cpmsId === val) {
        return;
      }
      const existInEcs = await api.affiliate.cpmsExists({ cpmsId: val });
      if (existInEcs.data) return _t(`Organisation Group ist bereits vergeben.`);

      const existInEvp = await api.affiliate.cpmsExistsInEvp({ cpmsId: val });
      if (!existInEvp.data) return _t(`Organisation Group ist nicht in EVP vorhanden.`);
    },
    transform: (val) => {
      if (!val) return val;
      return val.trim();
    },
  });

  const languages = useMemo(
    () => [
      { name: _t('English'), id: 'en' },
      { name: _t('German'), id: 'de' },
      { name: _t('French'), id: 'fr' },
    ],
    [_t]
  );
  const [langField, setLangField] = formState.useFieldState({
    initialValue: languages.find((l) => l.id === affiliate?.defaultLanguageCode) || languages[0],
    label: _t('Language'),
    dataCy: 'language',
  });

  const [permissionsSettings, setPermissionsSettings] = useState<{ options: PermissionsGroup[]; existing?: Permission[] }>({ options: [] });
  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>([]);

  const [showPopup, setShowPopup] = useState(props.open);
  const onOpen = useCallback(async () => {
    resetForm();

    if (props.affiliateCode) {
      //edit mode
      const affiliate = (await api.affiliate.get(props.affiliateCode)).data;
      setAffiliate(affiliate);
      setTitle(affiliate.title);
      setCode(affiliate.code);
      setCpms(affiliate.cpmsId || '');
      setPermissionsSettings({ options: getAllPermissionsGroups(), existing: affiliate.permissions });
      setSelectedPermissions(affiliate.permissions);
      setLangField(languages.find((l) => l.id === affiliate.defaultLanguageCode) || languages[0]);
    } else {
      setAffiliate(undefined);
      const permissionGroups = getAllPermissionsGroups();
      const selectedPermission: Permission[] = [];
      permissionGroups.forEach((pg) => {
        if (![OPERATION_PERMISSION_CODE, CARDS_PERMISSION_CODE].includes(pg.code)) {
          pg.items.forEach((i) => selectedPermission.push(i.value));
        }
      });
      setPermissionsSettings({ options: permissionGroups, existing: undefined });
      setSelectedPermissions(selectedPermission);
      setLangField(languages[0]);
    }
  }, [props.affiliateCode, resetForm, setAffiliate, setTitle, setCode, setCpms, setLangField, languages]);

  useEffectAsync(async () => {
    if (props.open) {
      formState.reset();
      await onOpen();
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [props.open]);

  //submission
  formState.handleSubmit(async () => {
    let affiliate: AffiliateDto = {
      title: titleField.value.trim(),
      code: codeField.value,
      cpmsId: cpmsField.value,
      permissions: selectedPermissions,
      defaultLanguageCode: langField.value.id,
    };
    if (props.affiliateCode) {
      await api.affiliate.update(affiliate);
    } else {
      await api.affiliate.create(affiliate);
    }
    props.onSubmit();
    props.close();
  });

  return (
    <EditPoup
      open={showPopup}
      className={styles.popup}
      skipAutoFocusOnOpen={false}
      onClose={props.close}
      onSave={async () => await formState.submit()}
      additionalFooterContentClassName={styles.submitError}
      additionalFooterContent={formState.submitError}
      bodyClassName={styles.popupBody}
      title={props.affiliateCode ? _t('Affiliate Editieren') : _t('Affiliate Erstellen')}
      saveText={props.affiliateCode ? _t('Speichern') : _t('Erstellen')}
    >
      <EditPopupRow label={titleField.label}>
        <Input {...titleField} label='' onBlur={() => titleField.validate()} className={styles.inputField} />
      </EditPopupRow>

      <EditPopupRow label={codeField.label}>
        {!props.affiliateCode && <Input {...codeField} label='' onBlur={() => codeField.validate()} className={styles.inputField} />}
        {affiliate && <div>{affiliate.code}</div>}
      </EditPopupRow>

      <EditPopupRow label={cpmsField.label}>
        {!props.affiliateCode && <Input {...cpmsField} label='' onBlur={() => cpmsField.validate()} className={styles.inputField} />}
        {affiliate && <div>{affiliate.cpmsId}</div>}
      </EditPopupRow>

      <EditPopupRow label={langField.label} className={styles.langRow} validationErr={langField.validationErr}>
        <SingleSelect
          className={styles.inputField}
          options={languages}
          fetchOptions={async (v) => {
            return languages.filter((l) => l.name.toLowerCase().includes(v.toLowerCase()));
          }}
          createOption={(opt) => <div>{opt.name}</div>}
          renderDisplayValue={(v) => v.name}
          selectedValue={langField.value}
          onChanged={(v) => setLangField(v || languages[0])}

          // createOption={() => }
        />
      </EditPopupRow>

      <PermissionGroupsComponent
        permissionGroupsOptions={permissionsSettings.options}
        existingPermissions={permissionsSettings.existing}
        value={selectedPermissions}
        formState={formState}
        label={_t('Affiliate Berechtigung')}
        labelInfo={_t('Wählen Sie die Berechtigungen aus, das diese Affiliate haben soll.')}
        noSelectedPermissionError={_t('Um ein Affiliate zu erstellen, muss sie mindestens eine Berechtigung haben.')}
        removePermissionsWarning={_t('Diese Berechtigung wird allen Rollen entzogen, die diese Berechtigung besitzen.')}
        dataCy=''
        onChange={(permissions: Permission[]) => setSelectedPermissions(permissions)}
      />
    </EditPoup>
  );
}
