import api from '../../services/api';
import {
  ExportCardColumn,
  ExportCardField,
  ExportFileType,
  ExportSessionColumn,
  ExportSessionField,
  ExportStationColumn,
  ExportStationField,
  RateServiceType,
} from '../../services/api-client/csp-api';

import classNames from 'classnames';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as InfoIco } from '../../assets/info.svg';
import Accordion from '../../components/accordion/accordion';
import { CancelBtn, EditPopupRowUsingDiv, FailedStep, MultistepPopup, ProgressStep, SaveBtn } from '../../components/edit-popup';
import { FormCard, FormCardBody } from '../../components/form-card';
import CheckBox from '../../components/input-check-box';
import RadioButton from '../../components/input-radio-button';
import useFormState from '../../hooks/useFormState';
import { getTimezone } from '../../utils/date';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './charging-sessions-export-popup.module.scss';
import { ExportSessionsArgs } from './charging-sessions-list-page';

const SESSION_GROUP_CODE = 'sessions';
const STATION_GROUP_CODE = 'stations';
const CARD_GROUP_CODE = 'cards';

interface ColumnItem<T extends ExportSessionColumn | ExportStationColumn | ExportCardColumn> {
  column: T;
  description?: string;
}

interface ColumnGroup<T extends ExportSessionColumn | ExportStationColumn | ExportCardColumn> {
  code: string;
  label: string;
  columnItems: ColumnItem<T>[];
  selected: boolean;
  selectedCount: number;
}

export default function ExportSessionsPopup(props: {
  showStations: boolean;
  showCards: boolean;
  canAggregate: boolean;
  open: boolean;
  sessionsCount: number;
  close: () => void;
  onSubmit: (args: ExportSessionsArgs, cancelToken: string) => Promise<void>;
}) {
  const { _t } = useBetterTranslate('charging-sessions-export-popup');

  // Form state
  const formState = useFormState();
  const resetForm = formState.reset;

  const [groupField] = formState.useFieldState<ColumnGroup<any>[]>({
    label: '',
    initialValue: getColumnGroups(props.showStations, props.showCards, _t),
    validate: async (val) => {
      if (!val.find((group) => group.columnItems.find((item) => item.column.selected))) {
        return _t('Es muss mindestens ein Element für den Export ausgewählt werden.');
      }
    },
  });
  const [expandedGroupField] = formState.useFieldState<string>({
    label: '',
    initialValue: '',
  });

  const [exportCancelTokenField] = formState.useFieldState<string>({
    label: '',
    initialValue: '',
  });

  const toggleColumn = (item: ColumnItem<any>) => {
    item.column.selected = !item.column.selected;
    const group = groupField.value.find((group) => group.columnItems.find((col) => col.column === item.column));
    if (group) {
      if (item.column.selected) {
        group.selectedCount++;
        if (group.selectedCount === group.columnItems.length) {
          group.selected = true;
        }
      } else {
        group.selectedCount--;
        group.selected = false;
      }
    }
    groupField.onChange([...groupField.value]);
  };

  const toggleColumnGroup = (columnGroup: ColumnGroup<any>) => {
    columnGroup.selected = !columnGroup.selected;
    columnGroup.columnItems.forEach((item) => (item.column.selected = columnGroup.selected));
    columnGroup.selectedCount = columnGroup.selected ? columnGroup.columnItems.length : 0;
    groupField.onChange([...groupField.value]);
  };

  //filetype
  const [fileTypeField] = formState.useFieldState<ExportFileType>({
    label: _t('Dateityp'),
    initialValue: ExportFileType.Csv,
  });

  const [aggregateByStationLocationField] = formState.useFieldState<boolean>({
    label: _t('Stationen'),
    initialValue: false,
  });

  const [aggregateByCardLocationField] = formState.useFieldState<boolean>({
    label: _t('Badges'),
    initialValue: false,
  });

  const [aggregateByStationIdField] = formState.useFieldState<boolean>({
    label: _t('Stationen'),
    initialValue: false,
  });

  const [aggregateByCardIdField] = formState.useFieldState<boolean>({
    label: _t('Badges'),
    initialValue: false,
  });

  const [activeStepField] = formState.useFieldState<'options' | 'pending' | 'failed'>({
    label: '',
    initialValue: 'options',
  });

  const [failedMsgField] = formState.useFieldState<string>({
    label: '',
    initialValue: '',
  });

  const labels: Record<RateServiceType, string> = {
    [RateServiceType.EMPLOYEE]: _t('EMPLOYEE'),
    [RateServiceType.HOME]: _t('HOME'),
    [RateServiceType.PRIVATE]: _t('PRIVATE'),
    [RateServiceType.PUBLIC]: _t('PUBLIC'),
    [RateServiceType.UNDEFINED]: _t('UNDEFINED'),
    [RateServiceType.WORK]: _t('WORK'),
  };
  formState.handleSubmit(async () => {
    activeStepField.onChange('pending');
    const args: ExportSessionsArgs = {
      sessionFields: getSelectedColumns(SESSION_GROUP_CODE),
      stationFields: props.showStations ? getSelectedColumns(STATION_GROUP_CODE) : [],
      cardFields: props.showCards ? getSelectedColumns(CARD_GROUP_CODE) : [],
      fileType: fileTypeField.value,
      aggregateByStationLocation: aggregateByStationLocationField.value,
      aggregateByCardLocation: aggregateByCardLocationField.value,
      aggregateByStationId: aggregateByStationIdField.value,
      aggregateByCardId: aggregateByCardIdField.value,
      serviceTypeLabels: Object.values(RateServiceType).map((serviceType) => {
        return { serviceType, label: labels[serviceType] };
      }),
      aggregationLabels: {
        cardNumber: _t('Kartennummer'),
        sessionsCount: _t('Anzahl Ladevorgänge'),
        totalDuration: _t('Ladezeit gesamt'),
        startDate: _t('Startdatum'),
        endDate: _t('Enddatum'),
        exportedDate: _t('Datum des Exports'),
        exportedTime: _t('Uhrzeit des Exports'),
      },
      timezone: getTimezone(),
    };
    try {
      const cancelToken = uuidv4();
      exportCancelTokenField.onChange(cancelToken);
      await props.onSubmit(args, cancelToken);
      props.close();
    } catch (error) {
      failedMsgField.onChange(_t('Ein unerwarteter Fehler ist aufgetreten.'));
      activeStepField.onChange('failed');
    }
  });

  const getSelectedColumns = (code: string) => {
    const group = groupField.value.find((g) => g.code === code);
    if (!group) return [];
    return group.columnItems.map((item) => item.column);
  };

  const cancelExportRequest = () => {
    api.abortRequest(exportCancelTokenField.value);
    props.close();
  };

  useEffect(() => {
    if (props.open) {
      resetForm();
      if (props.sessionsCount > 100000) {
        failedMsgField.onChange(_t('Too many records to be exported!'));
        activeStepField.onChange('failed');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const exportOptionsStep = () => {
    return (
      <div className={styles.exportOptions}>
        <div className={styles.body}>
          <div className={styles.columnGroupsList}>
            {groupField.value.map((group, idx) => {
              return (
                <FormCard phoneSize='full' key={idx}>
                  <FormCardBody>
                    <Accordion
                      onClick={() => expandedGroupField.onChange(expandedGroupField.value !== group.code ? group.code : '')}
                      expanded={expandedGroupField.value === group.code}
                      headline={
                        <div className={classNames(styles.accordionHeadline, groupField.validationErr ? styles.checkBoxValidationError : '')}>
                          <CheckBox
                            autoFocus={true}
                            className={styles.checkbox}
                            label={group.label}
                            isSelected={group.selected}
                            onChange={() => {
                              toggleColumnGroup(group);
                            }}
                          />
                          <span>
                            {group.selectedCount}/{group.columnItems.length}
                          </span>
                        </div>
                      }
                      onExpand={(expand) => {
                        expandedGroupField.onChange(expand ? group.code : '');
                      }}
                    >
                      <div className={classNames(styles.columns, groupField.validationErr ? styles.checkBoxValidationError : '')}>
                        {group.columnItems.map((item, nestedIdx) => {
                          return (
                            <CheckBox
                              className={styles.checkbox}
                              key={nestedIdx}
                              label={item.column.label}
                              subLbl={item.description}
                              isSelected={item.column.selected}
                              onChange={() => {
                                toggleColumn(item);
                              }}
                            />
                          );
                        })}
                      </div>
                    </Accordion>
                  </FormCardBody>
                </FormCard>
              );
            })}
          </div>
          <div className={styles.groupsValidation}>{groupField.validationErr}</div>

          <EditPopupRowUsingDiv label={fileTypeField.label} className={styles.popupRow} lblClassName={styles.popupRowLbl}>
            <div className={styles.fileTypeOptions}>
              <RadioButton label={'CSV'} isSelected={fileTypeField.value === ExportFileType.Csv} onChange={() => fileTypeField.onChange(ExportFileType.Csv)} />
              <RadioButton label={'XLSX'} isSelected={fileTypeField.value === ExportFileType.Xlsx} onChange={() => fileTypeField.onChange(ExportFileType.Xlsx)} />
            </div>
          </EditPopupRowUsingDiv>

          {props.canAggregate && (
            <EditPopupRowUsingDiv label={_t('Geladene Energie auf Standorte aggregieren:')} className={styles.popupRow} lblClassName={styles.popupRowLbl}>
              <div className={styles.aggregationOptions}>
                {props.showStations && (
                  <CheckBox
                    label={_t('Stationen')}
                    isSelected={aggregateByStationLocationField.value}
                    onChange={() => aggregateByStationLocationField.onChange(!aggregateByStationLocationField.value)}
                  />
                )}
                {props.showCards && (
                  <CheckBox
                    label={_t('Badges')}
                    isSelected={aggregateByCardLocationField.value}
                    onChange={() => aggregateByCardLocationField.onChange(!aggregateByCardLocationField.value)}
                  />
                )}
              </div>
            </EditPopupRowUsingDiv>
          )}

          {props.canAggregate && (
            <EditPopupRowUsingDiv label={_t('Geladene Energie auf Ressourcen aggregieren:')} className={styles.popupRow} lblClassName={styles.popupRowLbl}>
              <div className={styles.aggregationOptions}>
                {props.showStations && (
                  <CheckBox
                    label={_t('Stationen')}
                    isSelected={aggregateByStationIdField.value}
                    onChange={() => aggregateByStationIdField.onChange(!aggregateByStationIdField.value)}
                  />
                )}
                {props.showCards && (
                  <CheckBox label={_t('Badges')} isSelected={aggregateByCardIdField.value} onChange={() => aggregateByCardIdField.onChange(!aggregateByCardIdField.value)} />
                )}
              </div>
            </EditPopupRowUsingDiv>
          )}
        </div>
        <div className={styles.footer}>
          <div className={classNames(styles.info)}>
            <InfoIco width={24} height={24} />
            {_t('Es werden {{sessionsCount}} Ladevorgänge exportiert', { sessionsCount: props.sessionsCount })}
          </div>
          <div className={classNames(styles.actions)}>
            <CancelBtn dataCy='btn_cancel' onClick={props.close} />
            <SaveBtn dataCy='btn_export' onClick={async () => await formState.submit()}>
              {_t('Exportieren')}
            </SaveBtn>
          </div>
        </div>
      </div>
    );
  };

  return (
    <MultistepPopup
      title={_t('EXPORT - Ladevorgänge')}
      open={props.open}
      allSteps={['options', 'pending', 'failed']}
      activeStep={activeStepField.value}
      steps={{
        options: exportOptionsStep(),
        pending: <ProgressStep onClose={props.close} onCancel={cancelExportRequest} />,
        failed: <FailedStep onClose={props.close} text={failedMsgField.value} />,
      }}
      onClose={props.close}
    />
  );
}

function getColumnGroups(showStations: boolean, showCards: boolean, _t: (text: string) => string) {
  const groups: ColumnGroup<ExportSessionColumn | ExportStationColumn | ExportCardColumn>[] = [];
  const sessionGroup: ColumnGroup<ExportSessionColumn> = {
    code: SESSION_GROUP_CODE,
    label: _t('Allgemeine Daten'),
    columnItems: [
      {
        column: { field: ExportSessionField.EvseId, label: _t('EVSE-ID'), selected: true },
        description: _t('Eindeutige Identifikationsnummer der Ladestation.'),
      },
      {
        column: { field: ExportSessionField.StationRegion, label: _t('Getätigt an (Gebiet/Gesellschaft)'), selected: true },
        description: _t('Beschreibt welcher Regions oder Geschellschaft die Ladestation zugeordnet ist.'),
      },
      {
        column: { field: ExportSessionField.StationLocation, label: _t('Getätigt an (Standort)'), selected: true },
        description: _t('Beschreibt welchem Standort die Ladestation zugeordnet ist.'),
      },
      {
        column: { field: ExportSessionField.CardRegion, label: _t('Getätigt von (Gebiet/Gesellschaft)'), selected: true },
        description: _t('Beschreibt welchem Standort die Ladekarte zugeordnet ist.'),
      },
      {
        column: { field: ExportSessionField.CardLocation, label: _t('Getätigt von (Standort)'), selected: true },
        description: _t('Beschreibt welchem Standort die Ladekarte zugeordnet ist.'),
      },
      {
        column: { field: ExportSessionField.CardComment, label: _t('Kostenstelle der Karte'), selected: true },
      },
      {
        column: { field: ExportSessionField.StartTime, label: _t('Ladestart (UTC)'), selected: true },
      },
      {
        column: { field: ExportSessionField.EndTime, label: _t('Ladeende (UTC)'), selected: true },
      },
      {
        column: { field: ExportSessionField.StartTimeLocal, label: _t('Ladestart (Lokal)'), selected: true },
      },
      {
        column: { field: ExportSessionField.EndTimeLocal, label: _t('Ladeende (Lokal)'), selected: true },
      },
      {
        column: { field: ExportSessionField.Duration, label: _t('Dauer'), selected: true },
      },
      {
        column: { field: ExportSessionField.Energy, label: _t('Geladene Energie'), selected: true },
      },
      {
        column: { field: ExportSessionField.RateServiceType, label: _t('Genutzter Service'), selected: true },
      },
    ],
    selected: true,
    selectedCount: 0,
  };
  sessionGroup.selectedCount = sessionGroup.columnItems.length;
  groups.push(sessionGroup);
  if (showStations) {
    const stationGroup: ColumnGroup<ExportStationColumn> = {
      code: STATION_GROUP_CODE,
      label: _t('Daten zu Ladestationen'),
      columnItems: [
        {
          column: { field: ExportStationField.StationManufacturer, label: _t('Hersteller'), selected: true },
        },
        {
          column: { field: ExportStationField.StationModel, label: _t('Modell'), selected: true },
        },
        {
          column: { field: ExportStationField.ConnectorOutletTypeAndMaxPower, label: _t('Steckeart und max. Ladeleistung'), selected: true },
        },
        {
          column: { field: ExportStationField.ConnectorType, label: _t('Ladeart'), selected: true },
          description: _t('Angabe ob der Ladepunkt mit Wechsel- oder Gleichstrom lädt.'),
        },
        {
          column: { field: ExportStationField.ConnectorName, label: _t('Bezeichnung des Ladepunkts'), selected: true },
        },
        {
          column: { field: ExportStationField.PurchaseCost, label: _t('Ladeerstattung'), selected: true },
          description: _t('Die zu erwartende Erstattung für den Ladevorgang (netto).'),
        },
        {
          column: { field: ExportStationField.StationStreet, label: _t('Straße'), selected: true },
        },
        {
          column: { field: ExportStationField.StationPostalCode, label: _t('Postleitzahl'), selected: true },
        },
        {
          column: { field: ExportStationField.StationCity, label: _t('Ort'), selected: true },
        },
        {
          column: { field: ExportStationField.StationIsExternalHardware, label: _t('External Hardware'), selected: true },
        },
        {
          column: { field: ExportStationField.StationIsExternalHardware, label: _t('Public Service'), selected: true },
        },
      ],
      selected: true,
      selectedCount: 0,
    };
    stationGroup.selectedCount = stationGroup.columnItems.length;
    groups.push(stationGroup);
  }
  if (showCards) {
    const cardGroup: ColumnGroup<ExportCardColumn> = {
      code: CARD_GROUP_CODE,
      label: _t('Daten zu Ladebadges'),
      columnItems: [
        {
          column: { field: ExportCardField.CardNumber, label: _t('Kartennummer'), selected: true },
          description: _t('Identifikationsnummer der Ladekarte (meist aufgedruckt).'),
        },
        {
          column: { field: ExportCardField.CardLabel, label: _t('Kartenbezeichnung'), selected: true },
          description: _t('Im System hinterlegte Bezeichnung der Karte.'),
        },
        {
          column: { field: ExportCardField.CardOwnerName, label: _t('Karteninhaber'), selected: true },
          description: _t('Beschreibt die Gesellschaft, der die Ladekarte zugeordnet ist.'),
        },
        {
          column: { field: ExportCardField.CardCustomerReference, label: _t('Leasingvertragsnummer'), selected: true },
          description: _t('Vom Leasinggeber hinterlegte Vertragsnummer.'),
        },
        {
          column: { field: ExportCardField.CardRateServiceType, label: _t('Standard Service'), selected: true },
          description: _t('Beschreibt den Service, der standardmäßig mit dieser Karte verwendet wird.'),
        },
        {
          column: { field: ExportCardField.CardTagId, label: _t('RFID Tag'), selected: true },
          description: _t('Auslesbare Kennung des RFID-Chips der Ladekarte.'),
        },
        {
          column: { field: ExportCardField.TotalCost, label: _t('Ladekosten'), selected: true },
          description: _t('Die zu erwartenden Kosten für den Ladevorgang (netto).'),
        },
      ],
      selected: true,
      selectedCount: 0,
    };
    cardGroup.selectedCount = cardGroup.columnItems.length;
    groups.push(cardGroup);
  }

  return groups;
}
