import { Dispatch, FormEvent, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { LabelWithInfo } from '../components/info';
import useBetterTranslate from '../utils/translation-utils';

export interface FormField<T> {
  label: ReactNode;
  value: T;
  validationErr: string | undefined;
  transform: (value: T) => T;
  validate: () => Promise<string | undefined>;
  onChange: (value: T) => void;
  reset: () => void;
  setWithoutValidate: Dispatch<SetStateAction<T>>;
}

interface InputField extends FormField<string> {
  type: string;
  placeholder?: string;
  maxLength?: number;
}

export type FormFieldParam<T> = {
  label: string;
  labelInfo?: string;
  required?: boolean;
  initialValue: T;
  transform?: (value: T) => T;
  validate?: (value: T) => Promise<string | undefined>;
  dataCy?: string;
};
export type InputFieldParam<T> = FormFieldParam<T> & { maxLength: number; placeholder?: string; type?: string };

export const formValidator = {
  notEmpty(val: string | undefined, msg: string) {
    if (!val) return msg;
    val = msg.trim();
    if (val.length <= 0) return msg;
  },
  maxLen(length: number, val: string | undefined, msg: string) {
    if (!val) return;
    if (val.length > length) return msg;
  },
  email(val: string, msg: string) {
    if (!/^[\S._%+-]+@[\S.-]+\.[A-Za-z]{2,}$/i.test(val)) return msg;
  },
};

export default function useFormState(props?: { submitHandler?: () => Promise<void> }) {
  const { _t } = useBetterTranslate('use-form');

  const fields: FormField<any>[] = [];
  const [submitError, setSubmitError] = useState<string>();
  const [hasValidationErrors, setHasValidationErrors] = useState(false);

  let submitHandler = props?.submitHandler;

  const useFieldState = <T>(props: FormFieldParam<T>): [FormField<T>, Dispatch<SetStateAction<T>>] => {
    const field = useFormFieldState(props);
    fields.push(field);
    return [field, field.setWithoutValidate];
  };

  const useInputState = (props: InputFieldParam<string>): [InputField, Dispatch<SetStateAction<string>>] => {
    const [field] = useFieldState(props);
    return [{ ...field, placeholder: props.placeholder, maxLength: props.maxLength, type: props.type || 'text' }, field.setWithoutValidate];
  };

  const validateFields = async () => {
    try {
      const errors = await Promise.all(
        fields.map(async (field) => {
          const err = await field.validate();
          // use this for debugging
          // if(err){
          //   console.error(`${field}: ${err}`);
          // }
          return err;
        })
      );
      const isInvalid = errors.find((error) => error);
      return !isInvalid;
    } catch (error) {
      setSubmitError(_t('Ein unerwarteter Fehler ist aufgetreten.'));
      return false;
    }
  };

  const submit = async (ev?: FormEvent) => {
    ev?.preventDefault();
    let isValid = await validateFields();
    setHasValidationErrors(!isValid);
    if (!isValid) return false;
    if (!submitHandler) return true;

    try {
      await submitHandler();
      setSubmitError(undefined);
      return true;
    } catch (error) {
      setSubmitError(_t('Ein unerwarteter Fehler ist aufgetreten.'));
      return false;
    }
  };

  const reset = () => {
    fields.forEach((field) => field.reset());
    setSubmitError(undefined);
    setHasValidationErrors(false);
  };

  return {
    useFieldState,
    useInputState,
    submit,
    handleSubmit: (handler: () => Promise<void>) => {
      submitHandler = handler;
    },
    reset: reset,
    submitError,
    hasValidationErrors,
  };
}

function useFormFieldState<T>(props: FormFieldParam<T>): FormField<T> {
  const [fieldValue, setFieldValue] = useState<T>(props.initialValue);
  const [validationErrMsg, setValidationErrMsg] = useState<string | undefined>();
  const transform = props.transform || ((val: T) => val);
  const validate = async (): Promise<string | undefined> => {
    if (props.validate) {
      const errMsg = await props.validate(fieldValue);
      setValidationErrMsg(errMsg ? `${errMsg}` : undefined);
      return errMsg;
    }
  };
  const setValue = (value: T) => {
    const transformed = transform(value);
    setFieldValue(transformed);
  };

  const reset = () => {
    setFieldValue(props.initialValue);
    setTimeout(() => setValidationErrMsg(undefined), 100);
  };

  useEffect(() => {
    if (validationErrMsg) {
      validate();
    }
  });

  const label = props.labelInfo ? LabelWithInfo(props.label, props.labelInfo, props.dataCy) : `${props.label + (props.required ? '*' : '')}`;
  return {
    label: label,
    value: fieldValue,
    validationErr: validationErrMsg,
    transform,
    validate,
    onChange: setValue,
    reset,
    setWithoutValidate: setFieldValue,
  };
}
