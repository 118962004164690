import classNames from 'classnames';
import { ReactComponent as SearchIco } from '../assets/magnifying-glass.svg';
import Input from './input';
import styles from './search-input.module.scss';

export default function SearchInput(props: {
  onChange?: (newVal: string) => void;
  value?: string;
  className?: string;
  classNameInput?: string;
  maxLength?: number;
  placeholder?: string;
  dataCy?: string;
  disabled?: boolean;
}) {
  return (
    <div className={classNames(styles.root, props.className)} data-cy={props.dataCy}>
      <Input
        className={classNames(styles.inputControl, props.classNameInput)}
        onChange={props.onChange || (() => {})}
        value={props.value || ''}
        type={'text'}
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
      <div className={styles.icoWrapper}>
        <SearchIco />
      </div>
    </div>
  );
}
