import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { translate } from '../i18n';
import { ColorStyle } from './color-style';
import Pill from './pill';
import colorStyles from './style-utils';
import styles from './tag.module.scss';

const _t = translate('tag');

export function Tag(props: PropsWithChildren<{ dataCy?: string; selected?: boolean; className?: string; colorStyle?: ColorStyle; onClick?: () => void }>) {
  const style: ColorStyle = props.colorStyle || 'accent';
  const selectedCls = props.selected ? styles.selected : '';
  return (
    <span data-cy={props.dataCy} onClick={props.onClick} className={classNames(styles.root, props.className, selectedCls, styles[style], props.onClick ? styles.clickable : '')}>
      {props.children}
    </span>
  );
}

export function rateTypeColorStyle(rateType: string) {
  if (rateType === 'HOME') return colorStyles.components.rateType.home;
  if (rateType === 'EMPLOYEE') return colorStyles.components.rateType.employee;
  if (rateType === 'PUBLIC') return colorStyles.components.rateType.public;
  if (rateType === 'WORK') return colorStyles.components.rateType.work;
  if (rateType === 'UNDEFINED') return colorStyles.components.rateType.unknown;

  return colorStyles.components.rateType.unknown;
}
export function rateTypeColorStyleInvert(rateType: string) {
  if (rateType === 'HOME') return colorStyles.components.rateTypeInvert.home;
  if (rateType === 'EMPLOYEE') return colorStyles.components.rateTypeInvert.employee;
  if (rateType === 'PUBLIC') return colorStyles.components.rateTypeInvert.public;
  if (rateType === 'WORK') return colorStyles.components.rateTypeInvert.work;
  if (rateType === 'UNDEFINED') return colorStyles.components.rateTypeInvert.unknown;

  return colorStyles.components.rateTypeInvert.unknown;
}
export function rateTypeTranslation(rateType: string): string {
  if (rateType === 'HOME') return _t('Home');
  if (rateType === 'EMPLOYEE') return _t('Employee');
  if (rateType === 'PUBLIC') return _t('Public');
  if (rateType === 'WORK') return _t('Work');
  if (rateType === 'UNDEFINED') return _t('Unbekannt');

  return _t('Unbekannt');
}

export function rateTypeTooltip(rateType: string): string {
  if (rateType === 'HOME') return _t('Dienstwagen an Heimladestation');
  if (rateType === 'EMPLOYEE') return _t('Privates Fahrzeug von Mitarbeitenden');
  if (rateType === 'PUBLIC') return _t('Öffentlicher Ladevorgang (Roaming)');
  if (rateType === 'WORK') return _t('Dienstwagen am Standort');
  return _t('Ladevorgang konnte keinem Service zugeordnet werden'); //UNBEKANNT
}

export function RateTypeTag(props: {
  dataCy?: string;
  displayStyle?: 'tag' | 'pill';
  selected?: boolean;
  rateType: string;
  txt?: string;
  classNames?: string;
  onClick?: () => void;
}) {
  const dispStyle = props.displayStyle || 'tag';

  let txt = props.txt || rateTypeTranslation(props.rateType);
  let cls = rateTypeColorStyleInvert(props.rateType);
  if (props.selected) {
    cls = rateTypeColorStyle(props.rateType);
  }

  if (dispStyle === 'tag') {
    return (
      <Tag dataCy={props.dataCy} selected={props.selected} colorStyle='custom' className={classNames(styles.rateType, props.classNames, cls)} onClick={props.onClick}>
        {txt}
      </Tag>
    );
  } else {
    return (
      <Pill dataCy={props.dataCy} selected={props.selected} className={classNames(styles.rateType, props.classNames, cls)} onClick={props.onClick}>
        {txt}
      </Pill>
    );
  }
}
