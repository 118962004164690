import * as React from 'react';
import { useState } from 'react';
import api from '../services/api';
import { useAuth } from '../utils/AuthProvider';
import { useUserPreferences } from '../utils/UsePreferenceProvider';
import useBetterTranslate from '../utils/translation-utils';
import useEffectAsync from '../utils/useEffectAsync';
import MainMenuDesktop from './main-menu-desktop';
import styles from './main-menu.module.scss';
import { MenuItem, SWITCH_APP_HREF } from './menu-types';

export function getDefaultCode(apiPref?: { codes: string[]; root: boolean }, localPref?: string) {
  if (!apiPref) return undefined;
  if (localPref) {
    if (apiPref.root) return localPref;
    if (apiPref.codes.find((c) => c === localPref)) return localPref;
  }

  if (apiPref.codes.length > 0) return apiPref.codes[0];
  return undefined;
}

export default function MainMenu() {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  let { user } = useAuth();

  const { _t: t } = useBetterTranslate('main-menu');
  const _t = React.useMemo(() => t, [t]);

  const localPrefs = useUserPreferences();

  useEffectAsync(async () => {
    if (!user) return;

    const views = user.preferences.views;

    const getDefaultCode = (apiPref?: { codes: string[]; root: boolean }, localPref?: string) => {
      if (!apiPref) return undefined;
      if (localPref) {
        if (apiPref.root) return localPref;
        if (apiPref.codes.find((c) => c === localPref)) return localPref;
      }

      if (apiPref.codes.length > 0) return apiPref.codes[0];
      return undefined;
    };

    let menu: MenuItem[] = [];

    const locationsCode = getDefaultCode(views.locations.clients, localPrefs.clientCode);
    if (views.locations.visible && locationsCode) {
      menu.push({ title: _t('Standort'), href: `/locations/status/${locationsCode}`, nested: [], dataCy: 'mnuLocation' });
    }

    const infrastructureCode = getDefaultCode(views.infrastructure.clients, localPrefs.clientCode);
    // console.log('infrastructureCode', infrastructureCode);
    if (views.infrastructure.visible && infrastructureCode) {
      menu.push({ title: _t('Ladeinfrastruktur'), href: `/infrastructure/stations/${infrastructureCode}`, nested: [], dataCy: 'mnuChargingInfrastructure' });
    }

    const cardsCode = getDefaultCode(views.cards.clients, localPrefs.clientCode);
    if (views.cards.visible && cardsCode) {
      menu.push({ title: _t('Ladekarten'), href: `/charging-cards/${cardsCode}`, nested: [], dataCy: 'mnuChargingCards' });
    }

    const sessionsCode = getDefaultCode(views.sessions.clients, localPrefs.clientCode);
    if (views.sessions.visible && sessionsCode && views.sessions.prefix) {
      menu.push({ title: _t('Ladevorgänge'), href: `/${views.sessions.prefix}-charging-sessions/${sessionsCode}`, nested: [], dataCy: 'mnuChargingRecords' });
    }

    const loadManagementCode = getDefaultCode(views.clusters.clients, localPrefs.clientCode);
    if (views.clusters.visible && loadManagementCode) {
      const clientPreferences = await api.profile.getPreferencesForClient(loadManagementCode, {});
      if (clientPreferences.data.clusters.count === 1) {
        menu.push({
          title: _t('Lastmanagement'),
          href: `/loadmanagement/${loadManagementCode}/cluster-stations/${clientPreferences.data.clusters.defaultId}`,
          nested: [],
          dataCy: 'mnuLoadManagement',
        });
      } else {
        menu.push({ title: _t('Lastmanagement'), href: `/loadmanagement/${loadManagementCode}`, nested: [], dataCy: 'mnuLoadManagement' });
      }
    }

    // use load mgmt for now until permission is added
    const statusLogCode = getDefaultCode(views.logs.clients, localPrefs.clientCode);
    if (views.logs.visible && statusLogCode) {
      menu.push({ title: _t('Status Log'), href: `/status-log/${statusLogCode}`, nested: [], dataCy: 'mnuStatusLog' });
    }

    const dataCyOrg = 'mnuOrganisation';
    if (views.organization.structure?.visible) {
      const orgStructureCode = getDefaultCode(views.organization.structure?.clients, localPrefs.clientCode);
      menu.push({ title: _t('Organisation'), href: `/organisation/structure/${orgStructureCode}`, nested: [], dataCy: dataCyOrg });
    } else if (views.organization.users?.visible) {
      const orgUsersCode = getDefaultCode(views.organization.users?.clients, localPrefs.clientCode);
      menu.push({ title: _t('Organisation'), href: `/organisation/users/${orgUsersCode}`, nested: [], dataCy: dataCyOrg });
    }

    if (views.administration.clients?.visible || views.administration.roles?.visible || views.administration.users?.visible) {
      const dataCyAdmin = 'mnuAdministration';
      const clientsCode = getDefaultCode(views.administration.clients?.affiliates, localPrefs.affiliateCode);
      const rolesCode = getDefaultCode(views.administration.roles?.affiliates, localPrefs.affiliateCode);
      const usersCode = getDefaultCode(views.administration.users?.affiliates, localPrefs.affiliateCode);
      if (views.administration.clients && clientsCode) {
        menu.push({ title: _t('Administration'), href: `/administration/clients/${clientsCode}`, nested: [], dataCy: dataCyAdmin });
      } else if (views.administration.roles) {
        menu.push({ title: _t('Administration'), href: `/administration/roles/${rolesCode}`, nested: [], dataCy: dataCyAdmin });
      } else if (views.administration.users) {
        menu.push({ title: _t('Administration'), href: `/administration/users/${usersCode}`, nested: [], dataCy: dataCyAdmin });
      }
    }

    if (views.system.affiliates || views.system.users) {
      if (views.system.affiliates) {
        menu.push({ title: _t('Super Administration'), href: '/system/affiliates', nested: [] });
      } else if (views.system.affiliates) {
        menu.push({ title: _t('Super Administration'), href: '/system/users', nested: [] });
      }
    }

    if (views.operations.visible) {
      menu.push({ title: _t('Operations'), href: `/operations`, nested: [] });
    }

    if (views.dev?.translations || views.dev?.versions) {
      menu.push({ title: _t('Development'), href: '/dev', nested: [], dataCy: 'mnuDevelopment' });
    }
    if (views.kiosk.visible) {
      menu.push({ title: _t('Switch Application'), href: `/${SWITCH_APP_HREF}`, nested: [] });
    }

    setMenuItems(menu);
  }, [localPrefs.clientCode, localPrefs.affiliateCode, _t]);

  return (
    <React.Fragment>
      {user && (
        <>
          <MainMenuDesktop className={styles.dektop} items={menuItems} profile={user} />
          {/* <MainMenuMobile className={styles.mobile} items={menuItems} profile={user} /> */}
        </>
      )}
    </React.Fragment>
  );
}
