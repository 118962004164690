import dagre from 'dagre';
import { Elements, FlowElement, isNode, Node, Position } from 'react-flow-renderer';
import { ClientHierarchyNodeTypeDto, ClientStructureNodeDto, SharedClientDto, SharedClientHierarchyNodeDto } from '../../services/api-client/csp-api';

function calcItemWidth(node: Node<OrgDiagrammNodeProp>) {
  // IF you need to have dynamic width (based on content) than use this:

  // const BASE_SIZE = 120; // paddings + icons + others
  // const FONT_WIDTH_FACTOR = 8; // additional based on length of text

  // let result = BASE_SIZE + (10 * FONT_WIDTH_FACTOR);
  // if (!node.data) return result;

  // result = BASE_SIZE + ((node.data?.title || '').length * FONT_WIDTH_FACTOR);

  // return result;

  // 'we' decided to use nodes with fixed width, so we just use this magic number
  // wich relates to the $width variable in ogr-hiearchy-doagramm .node style

  const defaultWidth = 280;
  if (node?.type === 'rootSelectNode') {
    // if this is a node with a select box included, we will make it bigger
    return defaultWidth * 1.5;
  }
  return defaultWidth;
}

function calcItemHeight(node: Node<OrgDiagrammNodeProp>) {
  let nodeHeight = 42;

  return nodeHeight;
}

export function calcItemOffset(parent: Node<OrgDiagrammNodeProp>) {
  const width = calcItemWidth(parent);
  return { x: parent.position.x + (width + 42), y: parent.position.y };
}

export function layoutElements(elements: FlowElement<OrgDiagrammNodeProp>[], direction = 'TB') {
  const dagreGraph = new dagre.graphlib.Graph({ compound: true });
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  const hasRegion = !!elements.find((el) => el.data?.type === ClientHierarchyNodeTypeDto.Region);
  const hasLocation = !!elements.find((el) => el.data?.type === ClientHierarchyNodeTypeDto.Location);

  dagreGraph.setNode(`__${ClientHierarchyNodeTypeDto.ClientRoot}`, { height: 42 * 8 + 5 * 8, width: 280 * 5, label: `__${ClientHierarchyNodeTypeDto.ClientRoot}` });
  if (hasRegion) dagreGraph.setNode(`__${ClientHierarchyNodeTypeDto.Region}`, { height: 42 * 8 + 5 * 8, width: 280 * 5, label: `__${ClientHierarchyNodeTypeDto.Region}` });
  if (hasLocation) dagreGraph.setNode(`__${ClientHierarchyNodeTypeDto.Location}`, { height: 42 * 8 + 5 * 8, width: 280 * 5, label: `__${ClientHierarchyNodeTypeDto.Location}` });
  dagreGraph.setNode(`__${ClientHierarchyNodeTypeDto.Area}`, { height: 42 * 8 + 5 * 8, width: 280 * 5, label: `__${ClientHierarchyNodeTypeDto.Area}` });

  const virtualNodes: { nodeId: string; virtualId: string }[] = [];
  elements.forEach((el) => {
    if (isNode(el)) {
      const width = calcItemWidth(el);
      const height = calcItemHeight(el);
      dagreGraph.setNode(el.id, { width: width, height: height });
      dagreGraph.setParent(el.id, `__${el.data?.type}`);
      if (el.data?.type === ClientHierarchyNodeTypeDto.Area && hasLocation && el.data.parentCode) {
        const parentType = elements.find((p) => p.data?.code === el.data?.parentCode)?.data?.type;
        if (parentType === ClientHierarchyNodeTypeDto.Region) {
          const virtualId = `${el.id}-virtual-location`;
          dagreGraph.setNode(virtualId, { width: width, height: height });
          dagreGraph.setParent(virtualId, `__${ClientHierarchyNodeTypeDto.Location}`);
          virtualNodes.push({ nodeId: el.id, virtualId });
        }
      }
      // else if(el.data?.type === ClientHierarchyNodeTypeDto.Location && hasRegion && el.data.parentCode){
      //   const parentType = elements.find(p=>p.data?.code === el.data?.parentCode)?.data?.type;
      //   if(parentType === ClientHierarchyNodeTypeDto.ClientRoot){
      //     const virtualId = `${el.id}-virtual-region`;
      //     dagreGraph.setNode(virtualId, { width: width, height: height });
      //     dagreGraph.setParent(virtualId, `__${ClientHierarchyNodeTypeDto.Region}`);
      //     virtualNodes.push({nodeId:el.id, virtualId});
      //   }
      // }
    }
  });
  elements.forEach((el) => {
    if (!isNode(el)) {
      const virtual = virtualNodes.find((x) => x.nodeId === el.target);
      if (virtual) {
        dagreGraph.setEdge(el.source, virtual.virtualId);
        dagreGraph.setEdge(virtual.virtualId, el.target);
      } else {
        dagreGraph.setEdge(el.source, el.target);
      }
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? Position.Left : Position.Top;
      el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      const width = calcItemWidth(el);
      const height = calcItemHeight(el);
      el.position = {
        x: nodeWithPosition.x - width / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - height / 2,
      };
    }

    return el;
  });
}

// export type OrgNodeType = ClientHierarchyNodeTypeDto;

export enum NodeSelectionState {
  Partial = 'partial',
  Full = 'full',
  Inherited = 'inherited',
  None = 'none',
}

export interface OrgDiagrammNodeProp {
  code: string;
  parentCode?: string;
  title: string;
  type: ClientHierarchyNodeTypeDto;
  selected?: NodeSelectionState;
  accessible: boolean;
  autoCreated?: boolean;
  cpmsId?: string;
  clientCode: string;
  actions?: OrgDiagrammNodeAction[];

  provideSelectOptions?: (search: string, node: OrgDiagrammNodeProp) => Promise<SharedClientDto[]>;
  onClientPicked?: (code: string, dto?: SharedClientDto) => void;

  // style?: OrgNodeStyles[];
  // stations?: string[],
  // clusters?: string[],
  // cards?: string[],
  // style?: OrgNodeStyles[];
}

export interface OrgDiagrammNodeAction {
  icon: React.ReactNode;
  onClick: (node: OrgDiagrammNodeProp, ev?: any) => void;
}

export function mapOrgDiagrammNodeElements(models: OrgDiagrammNodeProp[]): Elements<OrgDiagrammNodeProp> {
  let elements: Elements<OrgDiagrammNodeProp> = [];

  for (const model of models) {
    const mapped = mapOrgDiagrammNode(model);
    elements.push(mapped);
    if (model.parentCode) {
      const connection = { id: `${model.code}->-${model.parentCode}`, source: model.parentCode, target: model.code, type: 'orgeedge' };
      // elements = addEdge(connection, elements);
      elements.push(connection);
    }
  }

  return elements;
}

export function walkChildren(props: { visit: (node: OrgDiagrammNodeProp, paren: OrgDiagrammNodeProp) => void; starting: OrgDiagrammNodeProp; all: OrgDiagrammNodeProp[] }) {
  const children = props.all.filter((c) => c.parentCode === props.starting.code);
  for (const child of children) {
    props.visit(child, props.starting);
    walkChildren({ all: props.all, starting: child, visit: props.visit });
  }
}

export function walkParents(props: { visit: (node: OrgDiagrammNodeProp) => void; starting: OrgDiagrammNodeProp; all: OrgDiagrammNodeProp[] }) {
  const parents = props.all.filter((p) => p.code === props.starting.parentCode);
  for (const parent of parents) {
    props.visit(parent);
    walkParents({ all: props.all, starting: parent, visit: props.visit });
  }
}

/**
 * compress the selected nodes to a smaller list
 * which means if parent of nodes is selected than the siblings wll not be returned
 * @param selectedCodes all nodes which where selcted (also their parents should be included here)
 * @param allItems all items
 */
export function compressSelectedNodes(selectedCodes: string[], allItems: OrgDiagrammNodeProp[]) {
  const result: string[] = [];
  for (const selected of selectedCodes) {
    const node = allItems.find((n) => n.code === selected);
    if (!node) continue;

    // check if the parent is also selected, than we don't need to add the node to the result because it is explicit selcted via parent
    const parent = allItems.find((p) => p.code === node.parentCode);
    if (parent && selectedCodes.includes(parent.code)) continue;
    result.push(selected);
  }

  return result;
}

export function setSelectionStates(selectedCodes: string[], allItems: OrgDiagrammNodeProp[], force?: boolean, doNotPropagate?: boolean) {
  for (const selected of selectedCodes) {
    const item = allItems.find((n) => n.code === selected);
    if (!item) {
      console.warn(`item with code: ${selected} is marked as selected but was not provided in the allItems list`);
      continue;
    }

    applyAndPropagateSelection(NodeSelectionState.Full, item, allItems, force, doNotPropagate);
  }
}

export function applyAndPropagateSelection(
  newSelVal: NodeSelectionState,
  changeItem: OrgDiagrammNodeProp,
  allItems: OrgDiagrammNodeProp[],
  force?: boolean,
  doNotPropagate?: boolean
) {
  /* when force is true then the selection is propagated to the children nodes regardless if they accessible or not.
  this is only needed when setting initial selected nodes at OrgHierarchyAssignor where some nodes could be not accessible, but we still want to mark them & their children as selected */
  changeItem.selected = newSelVal;

  if (doNotPropagate) {
    return;
  }

  walkChildren({
    all: allItems,
    starting: changeItem,
    visit: (n) => {
      if (force || n.accessible) n.selected = newSelVal;
    },
  });

  walkParents({
    all: allItems,
    starting: changeItem,
    visit: (n) => {
      const allChildren = allItems.filter((c) => n.code === c.parentCode);
      const fullSelectedChildren = allChildren.filter((c) => c.selected === NodeSelectionState.Full);
      const partialSelectedChildren = allChildren.filter((c) => c.selected === NodeSelectionState.Partial);
      if (n.accessible && fullSelectedChildren.length === allChildren.length) {
        n.selected = NodeSelectionState.Full;
      } else if (fullSelectedChildren.length > 0 || partialSelectedChildren.length > 0) {
        n.selected = NodeSelectionState.Partial;
      } else {
        n.selected = NodeSelectionState.None;
      }
    },
  });
}

export function mapOrgDiagrammNode(businessModel: OrgDiagrammNodeProp) {
  let result = {
    id: businessModel.code,
    data: businessModel,
    type: 'orgnode',
    position: { x: 0, y: 0 },
  };

  return result;
}

export function mapClientStructureNodes(nodes: ClientStructureNodeDto[], clientCode: string, accessible = true, actions?: OrgDiagrammNodeAction[]) {
  const orgItems = nodes.map((n) => {
    return mapClientStructureNode(n, clientCode, accessible, actions);
  });
  return orgItems;
}

export function mapClientStructureNode(node: ClientStructureNodeDto, clientCode: string, accessible = true, actions?: OrgDiagrammNodeAction[]) {
  const uiNode: OrgDiagrammNodeProp = {
    code: node.code,
    title: node.title,
    type: node.type as ClientHierarchyNodeTypeDto,
    parentCode: node.parentCode,
    selected: NodeSelectionState.None,
    accessible,
    autoCreated: node.autoCreated,
    cpmsId: node.cpmsId,
    clientCode: clientCode,
    actions,
  };
  return uiNode;
}

export function mapItems(nodes: SharedClientHierarchyNodeDto[], clientCode: string, accessible = true, actions?: OrgDiagrammNodeAction[]) {
  const orgItems = nodes.map((n) => {
    return mapItem(n, clientCode, accessible, actions);
  });
  return orgItems;
}

export function mapItem(node: SharedClientHierarchyNodeDto, clientCode: string, accessible = true, actions?: OrgDiagrammNodeAction[]) {
  const uiNode: OrgDiagrammNodeProp = {
    code: node.code,
    title: node.title,
    type: node.type,
    parentCode: node.parentCode,
    selected: NodeSelectionState.None,
    accessible,
    autoCreated: node.autoCreated,
    cpmsId: node.cpmsId,
    clientCode,
    actions,
  };
  return uiNode;
}
