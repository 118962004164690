import classNames from 'classnames';
import { ReactComponent as ClockIco } from '../../assets/clock.svg';
import { ReactComponent as ContextMenuIcon } from '../../assets/dots-three.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as FlashIco } from '../../assets/flash.svg';
import { ReactComponent as LockIco } from '../../assets/lock2.svg';
import Button, { ButtonAccent } from '../../components/button';
import { NodeHierarchyLabelInverted } from '../../components/org-hierarchy/node-hierarchy-label';
import OutletTypeIcon, { PlugType, localizePlugType } from '../../components/outlet-type-icon';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { ChargePoint } from '../../pages/stations/chargepoint-remote-actions';
import { SharedClientHierarchyNodeDto } from '../../services/api-client/csp-api';
import { getDomainStatusClassActive, getDomainStatusClassInactive, getDomainStatusTitle } from '../../utils/domain-status';
import useBetterTranslate from '../../utils/translation-utils';
import { ExternalHardwareIcon, PublicStationIcon } from '../evse-linfo-icons';
import styles from './chargepoint-list-item.module.scss';

export interface ChargepointListItemModel {
  domainStatus: 'available' | 'charging' | 'notAvailable' | 'failure' | 'unknown' | 'occupied';
  evseId?: string;
  customName?: string;
  chargeBoxId: string;
  connectorId: string;
  nodeCode: string;
  outletType: string;
  chargePointType: string;
  possibleChargingPower: string;
  domainStatusOfStation: 'online' | 'offline' | 'failure' | 'notInOperation';
  currentLoad: number;
  currentLoadUnit: string;
  loadMgmtOfClusterAndStationActive: boolean;
  can: { remoteStartSession: boolean; remoteStopSession: boolean; remoteUnlock: boolean; remoteReserve: boolean; remoteChangeAvailability: boolean; goToSessions: boolean };
  isAssignedToCluster: boolean;
  isReduced: boolean;
  domainSubStatusForNotAvailable: 'reserved' | 'locked' | 'none';
  clusterId?: string;
  isExternalHardware: boolean;
  isPublic: boolean;
}

export default function ChargepointListItem(props: {
  item: ChargepointListItemModel;
  allNodes: SharedClientHierarchyNodeDto[];
  sessionsLink: string;
  stationsPath?: string;
  clientCode?: string;
  loadManagementLinkActive?: boolean;
  onClickRemoteActionButton: (ev: React.MouseEvent<Element, MouseEvent>, cp: ChargePoint) => void;
  hasContextIcons: boolean;
  onClickChangeCustomName: () => void;
  hasChangeCustomNamePopup?: boolean;
}) {
  const { item, allNodes, sessionsLink, stationsPath, clientCode, loadManagementLinkActive, onClickRemoteActionButton, hasContextIcons } = props;
  const { _t } = useBetterTranslate('chargepoints-list-item');

  const navigate = useBetterNavigate();
  const navigateToClusterDetailChargepointsPage = (clusterId: string) => {
    const link = `/loadmanagement/${clientCode}/cluster-chargepoints/${clusterId}`;
    navigate(link);
  };
  const navigateToStationsTab = (chargeBoxId: string) => {
    stationsPath ? navigate(stationsPath, { search: chargeBoxId }) : void 0;
  };

  function isCharging(cp: ChargepointListItemModel) {
    return cp.domainStatus === 'charging';
  }

  function isReservedOrLocked(cp: ChargepointListItemModel) {
    return isReserved(cp) || isLocked(cp);
  }

  function isReserved(cp: ChargepointListItemModel) {
    return cp.domainStatus === 'notAvailable' && cp.domainSubStatusForNotAvailable === 'reserved';
  }

  function isLocked(cp: ChargepointListItemModel) {
    return cp.domainStatus === 'notAvailable' && cp.domainSubStatusForNotAvailable === 'locked';
  }

  return (
    <div className={styles.cpListRow} data-cy='element_charging_points' data-cy-id={item.evseId || item.customName}>
      <div data-cy={'el_status'} className={classNames(styles.cpStatus, getDomainStatusClassActive(item.domainStatus))}></div>
      <div className={styles.cpData}>
        <div className={styles.cpBaseData}>
          <div className={classNames(styles.statusText, getDomainStatusClassInactive(item.domainStatus))}>{getDomainStatusTitle(item.domainStatus)}</div>
          <div className={styles.evseId}>
            {item.evseId ? (
              <>
                <span
                  className={classNames(stationsPath && styles.textLink, !item.customName || item.customName === item.evseId ? styles.noCustomName : '')}
                  onClick={() => navigateToStationsTab(item.chargeBoxId)}
                >
                  {item.customName || item.evseId}
                </span>
                {props.hasChangeCustomNamePopup && (
                  <button type='button' onClick={() => props.onClickChangeCustomName()} className={styles.editButton}>
                    <EditIcon className={styles.editIcon} />
                  </button>
                )}
                <ExternalHardwareIcon isExternalHardware={item.isExternalHardware} />
                <PublicStationIcon isPublic={item.isPublic} />
              </>
            ) : (
              _t('EVSE ID unbekannt')
            )}
          </div>
          <NodeHierarchyLabelInverted allNodes={allNodes} code={item.nodeCode} hideArea={true} hideClientRoot={true} className={styles.nodeHierarchy} />
        </div>
        <div className={styles.cpExtendedData}>
          <div data-cy={item.evseId} className={styles.evseId}>
            {item.evseId || _t('EVSE ID unbekannt')}
          </div>
          <div className={styles.plugType}>
            <OutletTypeIcon className={styles.plugTypeImage} type={item.outletType as PlugType} color='black' />
            <span>{localizePlugType(item.outletType) || _t('Unbekannt')}</span>
            <div>
              <span>{item.chargePointType || ''}</span>
              <span>
                {item.possibleChargingPower && ' max. '}
                {item.possibleChargingPower}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.cpCurrentState}>
          {isCharging(item) && (
            <div
              className={classNames(styles.cpCurrentPower, item.isReduced ? styles.reduced : undefined)}
              title={item.isReduced ? `${_t('Aktuelle Ladeleistung')} (${_t('reduziert')})` : _t('Aktuelle Ladeleistung')}
            >
              <span className={styles.flashIconAndDescription}>
                <FlashIco className={classNames(styles.flashIcon)} />
                {_t('P')}
              </span>
              <span>{`${item.currentLoad?.toFixed(0) || 0} ${item.currentLoadUnit || _t('kW')}`}</span>
            </div>
          )}
          {isReservedOrLocked(item) && (
            <div
              className={classNames(styles.cpCurrentPower, isReserved(item) ? styles.reserved : item.domainSubStatusForNotAvailable === 'locked' ? styles.locked : undefined)}
              title={isReserved(item) ? _t('Ladepunkt ist reserviert') : _t('Ladepunkt ist gesperrt')}
            >
              {isReserved(item) ? <ClockIco className={classNames(styles.clockIcon)} /> : <LockIco className={classNames(styles.lockIcon)} />}
            </div>
          )}
        </div>
        <div className={styles.cpLoadManagement}>
          {item.isAssignedToCluster && (
            <>
              <span
                className={classNames(styles.cpLoadManagementLabel, loadManagementLinkActive ? styles.textLink : undefined)}
                onClick={loadManagementLinkActive ? () => navigateToClusterDetailChargepointsPage(item.clusterId || '') : () => {}}
              >
                {_t('Lastmanagement')}:
              </span>
              <ul>
                <li
                  className={classNames(
                    styles.cpLoadManagementActivationStatus,
                    item.loadMgmtOfClusterAndStationActive ? styles.activeLoadManagement : styles.inactiveLoadManagement
                  )}
                >
                  {item.loadMgmtOfClusterAndStationActive ? _t('Aktiviert') : _t('Deaktiviert')}
                </li>
              </ul>
            </>
          )}
        </div>
        <div className={styles.cpButtonsAndMore}>
          <ButtonAccent data-cy={'el_charging_records'} disabled={!item.can.goToSessions} href={sessionsLink} className={styles.btnSessions}>
            {_t('Ladevorgänge')}
          </ButtonAccent>
          <Button
            kind='icon'
            disabled={!hasContextIcons}
            tooltip={
              !hasContextIcons
                ? _t('Fernsteuerung nicht möglich: Die Ladestation ist entweder offline oder nicht in Betrieb oder Sie verfügen nicht über die notwendige Berechtigung.')
                : ''
            }
            onClick={(ev: React.MouseEvent<Element, MouseEvent>) => {
              onClickRemoteActionButton(ev, item);
            }}
            className={styles.btnDots}
          >
            <ContextMenuIcon></ContextMenuIcon>
          </Button>
        </div>
      </div>
    </div>
  );
}
