import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import styles from './highlight-box.module.scss';

export type HighlightBoxAppereance = 'mute';
export interface HighlightBoxProps {
  className?: string;
  contentClassName?: string;
  apperance?: HighlightBoxAppereance;
  ico?: ReactNode;
  icoClassName?: string;
}

export function HighlightBox(props: PropsWithChildren<HighlightBoxProps>) {
  const cls = {
    [styles[props.apperance || 'mute']]: true,
    [styles.root]: true,
  };
  return (
    <div className={classNames(props.className, cls)}>
      {props.ico && <div className={classNames(styles.icoContainer, props.icoClassName)}>{props.ico}</div>}
      <div className={classNames(styles.content, props.contentClassName)}>{props.children}</div>
    </div>
  );
}
