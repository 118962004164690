import DataTable, { DataTableRow } from '../../../components/data-table/data-table';
import { FormCard, FormCardBody } from '../../../components/form-card';
import Page, { ListingCardPlaceholder } from '../../../components/page';
import { usePageParams } from '../../../hooks/usePageParams';
import useBetterTranslate from '../../../utils/translation-utils';
import { pagingValidator, validateArrayOfString, validateString } from '../../../utils/queryParamValidators';
import styles from './ev-migration-page.module.scss';
import { MigrationJobStatus } from '../../../services/api-client/csp-api';
import { useApi } from '../../../hooks/useApi';
import api from '../../../services/api';
import Paging from '../../../components/paging';
import classNames from 'classnames';
import { ButtonPrimary } from '../../../components/button';
import colorStyles from '../../../components/style-utils';
import Pill from '../../../components/pill';
import SearchInput from '../../../components/search-input';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import EvMigrationNewJobPopup from './ev-migration-new-job-popup';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../utils/AuthProvider';
import { formatter } from '../../../utils/localized-types';
import OperationTabPanel from '../shared/operation-tab-panel';

type FilterParameters = {
  skip?: number | null;
  limit?: number | null;
  status?: string[];
  chargeBoxId?: string;
  sortBy?: string;
  sortDesc?: string;
};

const jobValidators = {
  serviceType: validateArrayOfString(Object.values(MigrationJobStatus)),
  sortBy: validateString(['createdAt', 'createdBy', 'status', 'finishedAt', 'lastUpdatedBy']),
};

export default function EvMigrationPage() {
  const { _t } = useBetterTranslate('ev-migration-page');

  const { user } = useAuth();
  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, { ...pagingValidator, ...jobValidators });
  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  const [searchChargeBoxId, setSearchChargeBoxId] = useState(filterParams.chargeBoxId);
  useDebounce(
    () => {
      if (searchChargeBoxId === filterParams.chargeBoxId) return;
      setFilterParams({ chargeBoxId: searchChargeBoxId });
    },
    800,
    [searchChargeBoxId, filterParams.chargeBoxId]
  );

  useEffect(() => {
    setSearchChargeBoxId(filterParams.chargeBoxId);
  }, [filterParams.chargeBoxId]);

  const [jobsRes, jobsFetching, jobsErr] = useApi(
    {
      call: async (skip?: number | null, limit?: number | null, status?: any[], chargeBoxId?: string, sortBy?: any, sortDesc?: string) => {
        const res = await api.evMigration.searchJobs({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          status,
          chargeBoxIds: chargeBoxId ? [chargeBoxId] : undefined,
          sortBy,
          sortDesc: sortDesc !== undefined ? sortDesc === '1' : undefined,
        });

        return res;
      },
      map: (data) => {
        if (!data) return data;
        return data;
      },
    },
    filterParams.skip,
    filterParams.limit,
    filterParams.status,
    filterParams.chargeBoxId,
    filterParams.sortBy,
    filterParams.sortDesc
  );

  const getStatusText = (status: MigrationJobStatus) => {
    if (status === MigrationJobStatus.InProgress) return _t('In Progress');
    if (status === MigrationJobStatus.Success) return _t('Success');
    if (status === MigrationJobStatus.Failed) return _t('Failed');
    if (status === MigrationJobStatus.PartialSuccess) return _t('Partial Success');
  };

  const getStatusColorStyle = (status: MigrationJobStatus) => {
    if (status === MigrationJobStatus.InProgress) return colorStyles.components.connectorsStatus.charging.active;
    if (status === MigrationJobStatus.Success) return colorStyles.components.stationStatus.online.active;
    if (status === MigrationJobStatus.Failed) return colorStyles.components.stationStatus.failure.active;
    if (status === MigrationJobStatus.PartialSuccess) return colorStyles.components.stationStatus.offline.active;
    return colorStyles.components.stationStatus.notInOperation.active;
  };

  const getStatusInvertColorStyle = (status: MigrationJobStatus) => {
    if (status === MigrationJobStatus.InProgress) return colorStyles.components.connectorsStatus.charging.inactive;
    if (status === MigrationJobStatus.Success) return colorStyles.components.stationStatus.online.inactive;
    if (status === MigrationJobStatus.Failed) return colorStyles.components.stationStatus.failure.inactive;
    if (status === MigrationJobStatus.PartialSuccess) return colorStyles.components.stationStatus.offline.inactive;
    return colorStyles.components.stationStatus.notInOperation.inactive;
  };

  const [showNewPopup, setShowNewPopup] = useState(false);
  const navigate = useNavigate();
  const createJob = async (chargeBoxIds: string[]): Promise<void> => {
    const response = await api.evMigration.createJob({ chargeBoxIds });
    setShowNewPopup(false);
    setTimeout(() => {
      navigate(`/operations/ev-migration/${response.data.jobId}`);
    }, 300);
  };

  return (
    <>
      <Page
        breadCrumb={[{ title: _t('Move HTP Stations to EV'), href: '/operations/ev-migration', active: true }]}
        fetching={jobsFetching}
        placeHolder={<ListingCardPlaceholder />}
        className={styles.root}
        error={jobsErr}
        outOfPlaceHolder={
          <>
            <OperationTabPanel selectedTab='move-stations-to-evp' />
            <FormCard tabletSize='full' className={styles.filterCard}>
              <SearchInput placeholder={_t('ChargeBoxId')} onChange={setSearchChargeBoxId} value={searchChargeBoxId} maxLength={50} dataCy='srcChargeBoxId' />

              <div className={classNames(styles.quickFilters)}>
                {Object.keys(MigrationJobStatus).map((enumKey) => {
                  const enumValue = MigrationJobStatus[enumKey as keyof typeof MigrationJobStatus];
                  const selected = filterParams.status?.includes(enumValue) || false;
                  let cls;
                  if (selected) {
                    cls = getStatusColorStyle(enumValue);
                  } else {
                    cls = getStatusInvertColorStyle(enumValue);
                  }
                  const onClick = () => {
                    let status;
                    if (selected) {
                      status = filterParams.status?.filter((item) => item !== enumValue);
                      if (status && status?.length === 0) {
                        status = undefined;
                      }
                    } else {
                      status = filterParams.status ? [...filterParams.status, enumValue] : [enumValue];
                    }
                    setFilterParams({ status });
                  };

                  return (
                    <Pill key={enumValue} selected={selected} className={classNames(styles.rateType, cls)} onClick={onClick} dataCy={`btn${enumValue}`}>
                      {getStatusText(enumValue)}
                    </Pill>
                  );
                })}
              </div>

              <ButtonPrimary ralign={true} dataCy='btnNewJob' onClick={() => setShowNewPopup(true)}>
                {_t('Start New')}
              </ButtonPrimary>
            </FormCard>
          </>
        }
      >
        <FormCard tabletSize='full' className=''>
          <FormCardBody>
            <div className={styles.tableArea}>
              <DataTable
                sticky={true}
                records={jobsRes?.jobs || []}
                sorting={{
                  handler: (col, desc) => setFilterParams({ sortBy: col, sortDesc: desc ? '1' : undefined }),
                  col: filterParams.sortBy,
                  desc: filterParams.sortDesc === '1',
                }}
                renderer={{
                  row: (record, cols) => (
                    <DataTableRow classNames={styles.row} key={record.jobId}>
                      {cols}
                    </DataTableRow>
                  ),
                  cols: [
                    {
                      headerCol: () => <div>{_t('Created By')}</div>,
                      name: 'createdBy',
                      sortable: true,
                      col: (record) => <div>{record.createdBy}</div>,
                      width: '20%',
                    },
                    {
                      headerCol: () => <div>{_t('Created At')}</div>,
                      name: 'createdAt',
                      sortable: true,
                      col: (record) => (
                        <div>
                          {formatter.formatDateTimeFromIsoString(record.createdAt, user?.preferences.languageCode, {
                            day: 'numeric',
                            month: 'numeric',
                            year: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </div>
                      ),
                      width: '15%',
                    },
                    {
                      headerCol: () => <div>{_t('Stations')}</div>,
                      name: 'stations',
                      sortable: false,
                      col: (record) => <div>{record.entries.length}</div>,
                      width: '10%',
                    },
                    {
                      headerCol: () => <div>{_t('Status')}</div>,
                      name: 'status',
                      sortable: true,
                      col: (record) => <div>{getStatusText(record.status)}</div>,
                      width: '15%',
                    },
                    {
                      headerCol: () => <div>{_t('Finished At')}</div>,
                      name: 'finishedAt',
                      sortable: true,
                      col: (record) => (
                        <div>
                          {formatter.formatDateTimeFromIsoString(record.finishedAt, user?.preferences.languageCode, {
                            day: 'numeric',
                            month: 'numeric',
                            year: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </div>
                      ),
                      width: '15%',
                    },
                    {
                      headerCol: () => <div>{_t('Updated At')}</div>,
                      name: 'lastUpdatedAt',
                      sortable: true,
                      col: (record) => (
                        <div>
                          {formatter.formatDateTimeFromIsoString(record.lastUpdatedAt, user?.preferences.languageCode, {
                            day: 'numeric',
                            month: 'numeric',
                            year: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </div>
                      ),
                      width: '15%',
                    },
                    {
                      headerCol: () => <div></div>,
                      name: 'action',
                      sortable: false,
                      col: (record) => <NavLink to={`/operations/ev-migration/${record.jobId}`}>Details</NavLink>,
                      width: '5%',
                    },
                  ],
                }}
                dataCy='tblJobs'
              />
            </div>
          </FormCardBody>
        </FormCard>

        {jobsRes && (
          <Paging
            skip={jobsRes.skip}
            limit={jobsRes.limit}
            total={jobsRes.total}
            onChange={(arg) => {
              setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
            }}
          />
        )}
      </Page>

      <EvMigrationNewJobPopup open={showNewPopup} close={() => setShowNewPopup(false)} onSubmit={createJob} dataCy='popNewJob' />
    </>
  );
}
