type StylesType = { readonly [key: string]: string };

export type PageContainerSize = 'auto' | 'full' | 'three-fourth' | 'two-third' | 'half' | 'two-fourth' | 'one-third' | 'one-fourth';
export interface PageContainerProps {
  phoneSize?: PageContainerSize;
  tabletSize?: PageContainerSize;
  desktopSize?: PageContainerSize;
}

export function pageContainerStyles(styles: StylesType, props: PageContainerProps) {
  const phoneSize: PageContainerSize = props.phoneSize || 'auto';
  const tabletSize: PageContainerSize = props.tabletSize || 'auto';
  const desktopSize: PageContainerSize = props.desktopSize || 'auto';
  const result = [styles[`phone-${phoneSize}`], styles[`tablet-${tabletSize}`], styles[`desktop-${desktopSize}`]];
  return result;
}
