import classNames from 'classnames';

import { PropsWithChildren, PropsWithRef, useCallback, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  ArrowHeadType,
  Connection,
  Controls,
  Elements,
  FlowElement,
  Handle,
  Node,
  OnLoadParams,
  Position,
  ReactFlowProvider as ReactFlowProviderImported,
  getEdgeCenter,
  getMarkerEnd,
  getSmoothStepPath,
  isNode,
} from 'react-flow-renderer';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as ArrowDown } from '../../assets/arrow-down.svg';
import { ReactComponent as BuildingIco } from '../../assets/building.svg';
import { ReactComponent as CloseIco } from '../../assets/close.svg';
import { translate } from '../../i18n';
import { ClientHierarchyNodeTypeDto, SharedClientDto } from '../../services/api-client/csp-api';
import ifTrue from '../../utils/class-name';
import useBetterTranslate from '../../utils/translation-utils';
import NodeTooltip from './node-tooltip';
import { NodeSelectionState, OrgDiagrammNodeAction, OrgDiagrammNodeProp, layoutElements, mapOrgDiagrammNodeElements } from './org-diagramm-utils';
import styles from './org-hierarchy-diagramm.module.scss';
import SelectClientPopup from './select-client-popup';

const ReactFlowProvider = ReactFlowProviderImported as any;

const _t = translate('org-hierarchy-diagramm');
const NODE_TOOLTIP_ID = 'hierarchy-node-tooltip-id';

export function OrgHierarchyNode(
  props: PropsWithChildren<{
    className?: string;
    nodeType: ClientHierarchyNodeTypeDto;
    selected?: NodeSelectionState;
    isValidConn?: (conn: Connection) => boolean;
    isConnectable: boolean;
    isAccessible: boolean;
    isAutoCreated?: boolean;
    actions?: OrgDiagrammNodeAction[];
    node: OrgDiagrammNodeProp;
    // onClick?: () => void;
  }>
) {
  return (
    <div
      className={classNames(
        styles.node,
        styles[props.isAccessible ? (props.isAutoCreated ? 'autoCreated' : props.nodeType) : 'disabled'],
        ifTrue(styles.selected, props.selected === NodeSelectionState.Full),
        ifTrue(styles.selectedPartial, props.selected === NodeSelectionState.Partial),
        ifTrue(styles.selectedInherited, props.selected === NodeSelectionState.Inherited),
        props.className
      )}
      data-for={NODE_TOOLTIP_ID}
      data-tip={props.node.code}
    >
      {props.nodeType !== ClientHierarchyNodeTypeDto.ClientRoot && (
        <Handle
          isConnectable={props.isConnectable}
          className={classNames(styles.handle)}
          type='target'
          position={Position.Left}
          isValidConnection={(conn) => {
            if (!props.isValidConn) return false;
            return props.isValidConn(conn);
          }}
        />
      )}
      <div data-cy={`hier_pick_${props.node.type}`} className={classNames(styles.body)}>
        {props.children}
      </div>
      {props.actions && (
        <div className={styles.actions}>
          {props.actions.map((action, idx) => (
            <span key={idx} onClick={(ev) => action.onClick(props.node, ev)}>
              {action.icon}
            </span>
          ))}
        </div>
      )}
      <Handle
        isConnectable={props.isConnectable}
        className={styles.handle}
        type='source'
        position={Position.Right}
        isValidConnection={(conn) => {
          if (!props.isValidConn) return false;
          return props.isValidConn(conn);
        }}
      />
    </div>
  );
}

export function OrgHierarchyEdge(
  props: PropsWithChildren<{
    className?: string;
    id: string;
    sourceX: number;
    sourceY: number;
    targetX: number;
    targetY: number;
    sourcePosition: Position;
    targetPosition: Position;
    // data,
    arrowHeadType: ArrowHeadType;
    markerEndId: string;
  }>
) {
  const foreignObjectSize = 20;
  // getBezierPath (centerX: props.sourceX, centerY: props.targetY)
  // getSmoothStepPath (centerX: props.sourceX + 30, centerY: props.sourceY)
  const edgePath = getSmoothStepPath({
    sourceX: props.sourceX,
    sourceY: props.sourceY,
    sourcePosition: props.sourcePosition,
    targetX: props.targetX,
    targetY: props.targetY,
    targetPosition: props.targetPosition,
    centerX: props.sourceX + 30,
    centerY: props.sourceY,
  });
  const markerEnd = getMarkerEnd(props.arrowHeadType, props.markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX: props.sourceX,
    sourceY: props.sourceY,
    targetX: props.targetX,
    targetY: props.targetY,
  });

  return (
    <>
      <path id={props.id} className='react-flow__edge-path' d={edgePath} markerEnd={markerEnd} />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className='edgebutton-foreignobject'
        requiredExtensions='http://www.w3.org/1999/xhtml'
      >
        <div className={classNames(props.className, styles.edge)}>
          {props.children}
          {/* <button
              className="edgebutton"
              onClick={(event)  => props.onClick?.(event, props.id)}>
              <CloseIco />
            </button> */}
        </div>
      </foreignObject>
    </>
  );
}

export class OrgHierarchyDiagrammContext {
  constructor(
    private elements: Elements<OrgDiagrammNodeProp>,
    private onRefresh: (ctx: OrgHierarchyDiagrammContext) => void,
    private searchClientRoot?: (ctx: OrgHierarchyDiagrammContext, search: string, node: OrgDiagrammNodeProp) => Promise<SharedClientDto[]>,
    private onClientPicked?: (ctx: OrgHierarchyDiagrammContext, code: string, dto?: SharedClientDto) => void,
    private diagramReference?: OnLoadParams<Elements<OrgDiagrammNodeProp>>
  ) {}

  getSelected() {
    const result = this.elements
      .map((item) => item.data)
      .filter((item) => item && item.selected === NodeSelectionState.Full)
      .map((item) => item!);
    return result;
  }
  getAll() {
    const result = this.elements.filter((item) => !!item.data).map((item) => item.data!);
    return result;
  }
  getElements() {
    return this.elements;
  }

  setItems(models: OrgDiagrammNodeProp[]) {
    let uiModels = mapOrgDiagrammNodeElements(models);
    const clientRoot = uiModels.find((n) => n.data?.type === 'clientRoot');
    if (clientRoot && this.searchClientRoot) {
      clientRoot.data!.provideSelectOptions = this.searchClientRoot
        ? async (search, node) => {
            const result = await this.searchClientRoot!(this, search, node);
            return result;
          }
        : undefined;

      clientRoot.data!.onClientPicked = this.onClientPicked
        ? async (code: string, dto?: SharedClientDto) => {
            const result = await this.onClientPicked!(this, code, dto);
            return result;
          }
        : undefined;

      clientRoot.type = 'rootSelectNode';
    }

    uiModels = layoutElements(uiModels, 'LR');

    this.elements = uiModels;

    this.onRefresh?.(this);
  }

  fitToView(delay?: number) {
    if (!this.diagramReference) return;
    setTimeout(() => this.diagramReference?.fitView(), delay || 400);
    this.rebuildNodeTooltip(delay);
  }

  rebuildNodeTooltip(delay?: number) {
    setTimeout(() => ReactTooltip.rebuild(), delay || 400);
  }

  refresh() {
    this.onRefresh?.(this);
  }
}

export type OrgHierarchyDiagrammSettings = {
  searchClientRoot?: (ctx: OrgHierarchyDiagrammContext, search: string, node: OrgDiagrammNodeProp) => Promise<SharedClientDto[]>;
  clientRootChangable?: boolean;
  onClientPicked?: (ctx: OrgHierarchyDiagrammContext, code: string, dto?: SharedClientDto) => void;
  onItemClick?: (ctx: OrgHierarchyDiagrammContext, node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]) => void;
  diagramProps: Omit<Omit<OrgHierarchyDiagrammProps, 'elements'>, 'onItemClick'>;
};
export function useOrgHiearchyDiagramm(props: OrgHierarchyDiagrammSettings): [OrgHierarchyDiagrammProps, OrgHierarchyDiagrammContext] {
  const [externalProps, setExternalProps] = useState<OrgHierarchyDiagrammProps>({ ...props.diagramProps, elements: [] });
  const [diag, setDiag] = useState<OnLoadParams<Elements<OrgDiagrammNodeProp>>>();

  const context = useMemo(() => {
    return new OrgHierarchyDiagrammContext(
      [],
      (ctx) => {
        setExternalProps((currentExternalProps) => {
          const elements = [...ctx.getElements()];
          return { ...currentExternalProps, elements: elements };
        });
      },
      props.clientRootChangable ? props.searchClientRoot : undefined,
      props.onClientPicked,
      diag
    );
  }, [props.searchClientRoot, props.onClientPicked, props.clientRootChangable, diag]);

  const { onItemClick } = props;
  const onClickWrapper = useCallback(
    (node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]) => {
      if (onItemClick) onItemClick(context, node, others);
    },
    [onItemClick, context]
  );

  useEffect(() => {
    setExternalProps({
      ...props.diagramProps,
      elements: [...context.getElements()],
      onLoad: setDiag,
      onItemClick: onClickWrapper,
    });
  }, [props.diagramProps, context, onClickWrapper]);

  useEffect(() => {}, [onClickWrapper]);

  return [externalProps, context];
}

function RootSelectNode(nodeProp: FlowElement<OrgDiagrammNodeProp & { title: any }> & { isConnectable: boolean }) {
  const { _t } = useBetterTranslate('org-hierarchy-diagramm');
  const nodeData = nodeProp.data!;
  const [showPopup, setShowPopup] = useState<boolean>(false);

  return (
    <OrgHierarchyNode
      isConnectable={nodeProp.isConnectable}
      selected={nodeData.selected}
      nodeType={nodeData.type}
      isAccessible={nodeData.accessible}
      isAutoCreated={nodeData.autoCreated}
      className={classNames(styles.rootSelectNode, nodeProp.className)}
      actions={nodeData.actions}
      node={nodeData}
    >
      <span className={styles.title}>{nodeData.title}</span>

      <span
        className={styles.action}
        title={_t('Organisation wechseln')}
        onClick={(e) => {
          e.stopPropagation();
          setShowPopup(true);
        }}
      >
        <BuildingIco />
        <ArrowDown width={32} height={32} />
      </span>

      <SelectClientPopup
        open={showPopup}
        client={{ code: nodeData.code, title: nodeData.title }}
        onCancel={() => {
          setShowPopup(false);
        }}
        onClientSelected={(client) => {
          if (!client) return;
          setShowPopup(false);
          nodeData.onClientPicked?.(client.code, client);
        }}
        searchClients={async (search: string) => {
          const result = (await nodeData.provideSelectOptions?.(search, nodeData)) || [];
          return result;
        }}
      />
    </OrgHierarchyNode>
  );
}

const nodeTypes = {
  orgnode: (nodeProp: FlowElement<OrgDiagrammNodeProp & { title: any }> & { isConnectable: boolean }) => {
    const nodeData = nodeProp.data!;
    return (
      <OrgHierarchyNode
        isConnectable={nodeProp.isConnectable}
        className={classNames(nodeProp.className)}
        selected={nodeData.selected}
        nodeType={nodeData.type}
        isAccessible={nodeData.accessible}
        isAutoCreated={nodeData.autoCreated}
        actions={nodeData.actions}
        node={nodeData}
      >
        {nodeData.autoCreated ? _t('Standardbereich') : nodeData.title}
      </OrgHierarchyNode>
    );
  },
  rootSelectNode: RootSelectNode,
};

export type HierarchyDiagramReference = { fitToView: (delay?: number) => void };
type OrgHierarchyDiagrammProps = {
  className?: string;
  readonly?: boolean;
  nodesSelectable?: boolean;
  // direction: 'TB' | 'LR';
  elements: Elements<OrgDiagrammNodeProp>;
  onItemClick?: (node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]) => void;
  // diagRef?: DiagrammRef;
  onLoad?: (ref: OnLoadParams<Elements<OrgDiagrammNodeProp>>) => void;
};

export default function OrgHierarchyDiagramm(props: PropsWithRef<OrgHierarchyDiagrammProps>) {
  // const [flowInstance, setFlowInstance] = useState<HierarchyDiagramReference>();

  const edgeTypes = {
    orgeedge: (edgeProps: any) => {
      return (
        <OrgHierarchyEdge {...edgeProps}>
          {!props.readonly && (
            <button
              className='edgebutton'
              // TODO: remove conn clicked
              // onClick={(event)  => props.onClick?.(event, props.id)}
            >
              <CloseIco />
            </button>
          )}
        </OrgHierarchyEdge>
      );
    },
  };

  const toolTipContent = useMemo(() => {
    return (nodeCode: string) => {
      const element = props.elements.find((element) => element.id === nodeCode);
      if (!element || !element.data?.clientCode || !element.data?.title) return undefined;
      const title = element.data.autoCreated ? _t('Standardbereich') : element.data.title;
      return <NodeTooltip clientCode={element.data?.clientCode} nodeCode={element?.id} nodeTitle={title} />;
    };
  }, [props.elements]);

  // useEffect(() => {
  //   if (!flowInstance?.current) return;

  //   flowInstance.current.fitView();
  // }, [flowInstance?.current]);

  // useEffect(()=> {
  //   setTimeout(()=>ReactTooltip.rebuild(), 1000);
  //   console.log('ReactTooltip is rebuild')
  // }, [props.elements])

  return (
    <div className={classNames(styles.root, props.className)}>
      <ReactFlowProvider>
        {props.elements.length > 0 && (
          <ReactFlow
            elements={props.elements}
            nodeTypes={nodeTypes as any}
            edgeTypes={edgeTypes as any}
            minZoom={0.125}
            // snapToGrid={true}
            nodesDraggable={!props.readonly}
            nodesConnectable={!props.readonly}
            elementsSelectable={!props.readonly}
            className={classNames(
              props.className,
              ifTrue(styles.readonlyDiagramm, !!props.readonly),
              ifTrue(styles.nodesUnselectable, !props.nodesSelectable),
              ifTrue(styles.nodesSelecteable, props.nodesSelectable)
            )}
            onElementClick={(ev, node) => {
              if (!props.onItemClick) return;
              if (!node) return;

              if (!isNode(node)) return;

              const nodeData = (node as Node<OrgDiagrammNodeProp>)?.data;
              if (!nodeData) return;
              if (!nodeData.accessible) return;

              const allItems = props.elements.filter((e) => isNode(e)).map((e) => e.data!);
              props.onItemClick(nodeData, allItems);
              // onNodeSelected(elements)
            }}
            // onClick={() => props.onCanvasClicked?.()}
            onLoad={(instance) => {
              props.onLoad?.(instance);
            }}
            onConnect={(params) => {
              // if (props.readonly) return;
              // props.onConnect?.(params);
            }}
          >
            <Controls className={styles.controls} showInteractive={false} />
          </ReactFlow>
        )}
      </ReactFlowProvider>
      <ReactTooltip
        id={NODE_TOOLTIP_ID}
        place={'top'}
        type={'light'}
        effect={'solid'}
        className={styles.tooltip}
        getContent={toolTipContent}
        clickable={true}
        delayHide={10}
        delayShow={1000}
        border={true}
        overridePosition={({ left, top }) => {
          top += 5;
          return { top, left };
        }}
      />
    </div>
  );
}
