import classNames from 'classnames';
import { useEffect } from 'react';
import MainLogoLg from '../assets/main-logo-lg.png';
import Button from '../components/button';
import { LoginErrorType, useAuth } from '../utils/AuthProvider';
import useBetterTranslate from '../utils/translation-utils';
import styles from './login-page.module.scss';

export default function LoginPage(props: {}) {
  const { _t } = useBetterTranslate('login-page');

  const { user, beginLogin, loginErr, fetching: profileFetching, logout } = useAuth();
  const { state } = window.history;

  useEffect(() => {
    if (user) return;
    if (profileFetching) return;
    if (loginErr) return;
    //
    console.log('will do alogin');
    const returnUrl = window.location.href;
    if (state?.usr) {
      console.warn(`current page has a state, will backup it in sessionStorage`, state.usr);
      sessionStorage.setItem(`csp.login.state`, JSON.stringify(state.usr));
    }
    beginLogin(returnUrl);
  }, [user, profileFetching, loginErr, state, beginLogin]);

  return (
    <div className={classNames(styles.root)}>
      {/* <div> */}

      {profileFetching && <div className={styles.profileFetching}>...</div>}
      {loginErr && loginErr.type === LoginErrorType.AuthenticatedButUnknown && (
        <div className={styles.loginErr}>
          <span>{_t(`Sie besitzen keinen Zugriff auf diese Anwendung`)}</span>
          <Button onClick={() => logout()} kind='primary'>
            {_t(`Abmelden`)}
          </Button>
        </div>
      )}
      {loginErr && loginErr.type === LoginErrorType.OtherError && (
        <div className={styles.loginErr}>
          <span>{_t('Es ist leider ein Fehler aufgetreten')}:</span>
          <span className={styles.message}>{loginErr.message}</span>
          <span className={styles.code}>
            {_t('Fehlercode')}: {loginErr.code}
          </span>
        </div>
      )}
      {/* </div> */}
    </div>
  );
}
