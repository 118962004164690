import i18n, { StringMap } from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import { UiTranslationResponse } from './services/api-client/csp-api';
import _ from 'lodash';
import api from './services/api';

const LOLALIZE_MODE_KEY = `LOLALIZE_MODE_ENABLED`;
export function toggleLOLalizeMode(enable: boolean) {
  localStorage.setItem(LOLALIZE_MODE_KEY, enable ? 'true' : 'false');
  if (!enable) {
    localStorage.removeItem(LOLALIZE_MODE_KEY);
  }
  console.log('new mode:', localStorage.getItem(LOLALIZE_MODE_KEY));
}

export function isLOLalizeModeEnabled() {
  const val = localStorage.getItem(LOLALIZE_MODE_KEY);
  if (val === 'true') return true;
  return false;
}

// import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

export const translate = (namespace: string) => {
  if (isLOLalizeModeEnabled()) {
    return (val: string, args?: StringMap) => 'LOL';
  }

  const _t = (key: string) => {
    const result = i18n.t(key, { ns: namespace });
    return result || key;
  };
  return _t;
};

export async function swicthLanguage(lang: string) {
  const profileLanguage = lang;
  let localEtag = localStorage.getItem(`TRANSLATIONS_${profileLanguage}_ETAG`) || '';
  const serverEtag = await api.profile.getTranslationEtag(profileLanguage);
  if (localEtag !== serverEtag.data.etag) {
    console.log(`etag missmatch local (${profileLanguage}) ${localEtag} / server ${serverEtag.data.etag}`);
    const translations = (await api.profile.getTranslations(profileLanguage)).data;
    localStorage.setItem(`TRANSLATIONS_${profileLanguage}_ETAG`, translations.etag);
    localStorage.setItem(`TRANSLATIONS_${profileLanguage}`, JSON.stringify(translations));
  } else {
    console.log(`reuse existing translation (${profileLanguage}) ${localEtag} / server ${serverEtag.data.etag}`);
  }

  const translationsStr = localStorage.getItem(`TRANSLATIONS_${lang}`) || '';
  let translations: UiTranslationResponse = { etag: '', items: [] };
  try {
    translations = JSON.parse(translationsStr);
  } catch {
    translations = { etag: '', items: [] };
  }

  const namespaces = _.groupBy(translations.items || [], (item) => item.namespace);
  const bundles: { ns: string; data: Record<string, string> }[] = [];
  for (const [ns, items] of Object.entries(namespaces)) {
    let data: Record<string, string> = {};
    for (const item of items) {
      // skip items w. empty values, cause this items are "missing" items,
      // if a bundle has a key (also w. a empty string) it is assumed to be "existing"
      if (!item.value) continue;

      data[item.key] = item.value;
    }
    bundles.push({ ns, data });
  }

  for (const bundle of bundles || []) {
    if (bundle.ns === 'create-ticket-action') {
      console.log('bundle', bundle);
    }
    // console.log('add bundle', i18n.language, bundle.ns);
    i18n.addResourceBundle(lang, bundle.ns, bundle.data);
  }

  i18n.changeLanguage(lang);
  console.log('languages initialized, bootstrap lang', lang);
}

export async function initLanguages() {
  await i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      // if we enable fallback lang, the missing key will never be fires as long as it exists
      // in the fallback language, so we avoid to activate this
      // fallbackLng: 'de',
      fallbackLng: false,

      lng: 'en',
      debug: false,
      // saveMissing: true,
      // saveMissingTo: 'all',
      // missingKeyHandler: (langs, ns, key, fallbackVal, v1, v2) => {
      //   console.log('missing key', key, 'fallbackVal',fallbackVal)
      //   for (const lang of langs) {
      //     if (lang === 'dev') continue; // dev is devmode language, we ignore that...
      //     // addMissingTranslation({ language: lang, ns: ns, key: key, fallbackValue: fallbackVal });
      //   }
      // },
      react: {
        useSuspense: false,
      },
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
}

export default i18n;
