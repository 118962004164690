import classNames from 'classnames';
import { Fragment, PropsWithChildren, ReactNode } from 'react';
import { ReactComponent as SortAsc } from '../../assets/sort-asc.svg';
import { ReactComponent as SortDesc } from '../../assets/sort-desc.svg';
import ifTrue from '../../utils/class-name';
import styles from './data-table.module.scss';

export interface DataTableProps<TRecord> {
  records: TRecord[];
  renderer: RecordRenderOpts<TRecord>;
  sticky?: boolean;
  sorting?: {
    handler: (col: string | undefined, desc: boolean) => void;
    col?: string;
    desc?: boolean;
  };
  hideHeader?: boolean;
  dataCy?: string;
  // renderRow: (r: TRecord, idx: number) => ReactNode
  // renderHeader?: (records: TRecord[]) => ReactNode
}

export interface DataTableColumn<TRecord> {
  col: (record: TRecord) => ReactNode;
  headerCol?: (record: TRecord[]) => ReactNode;
  sumCol?: (record: TRecord[]) => ReactNode;
  name: string;
  width: string;
  sortable?: boolean;
  vcenter?: boolean;
  ralign?: boolean;
  dataCy?: string;

  headerClass?: string;
  sumClass?: string;
  colClass?: (r: TRecord) => string;
}
interface RecordRenderOpts<TRecord> {
  rowAttributes?: (record: TRecord) => object;
  row?: (record: TRecord, cols: ReactNode[]) => ReactNode;
  headerRow?: (cols: ReactNode[], records: TRecord[]) => ReactNode;
  sumRow?: (cols: ReactNode[], records: TRecord[]) => ReactNode;
  cols: DataTableColumn<TRecord>[];
}

export function DataTableRow(props: PropsWithChildren<{ classNames?: string; dataAttrs?: object }>) {
  return (
    <div {...(props.dataAttrs || {})} className={classNames(styles.row, props.classNames)}>
      {props.children}
    </div>
  );
}
export function DataTableHeaderRow(props: PropsWithChildren<{}>) {
  return <div className={classNames(styles.headerRow)}>{props.children}</div>;
}
export function DataTableSumRow(props: PropsWithChildren<{ className?: string }>) {
  return <div className={classNames(styles.sumRow, props.className)}>{props.children}</div>;
}

export default function DataTable<TRecord>(props: DataTableProps<TRecord>) {
  const hasSumCol = !!props.renderer.sumRow || !!props.renderer.cols.find((c) => !!c.sumCol);

  const handleSortingChange = (col: string) => {
    if (col === props.sorting?.col) {
    }
    if (col === props.sorting?.col) {
      if (props.sorting.desc) {
        props.sorting?.handler(undefined, false);
      } else {
        props.sorting?.handler(col, true);
      }
    } else {
      props.sorting?.handler(col, false);
    }
  };

  const headerCols = props.renderer.cols.map((c, i) => {
    const colClasses = [styles.hrColWrapper, c.headerClass];
    let sortStateDesc = null;
    if (c.sortable) {
      colClasses.push(styles.sortableCol);
      if (props.sorting?.col === c.name) {
        sortStateDesc = props.sorting?.desc === true;
      }
    }
    const lastColumn = i === props.renderer.cols.length - 1;
    const col = (
      <div
        className={classNames(...colClasses)}
        style={!lastColumn ? { flexBasis: c.width, flexShrink: 0, flexGrow: 0, maxWidth: c.width } : {}}
        key={i}
        onClick={() => (c.sortable ? handleSortingChange(c.name) : null)}
        data-cy={c.dataCy}
      >
        {c.headerCol && c.headerCol(props.records)}
        {c.sortable && (sortStateDesc ? <SortDesc /> : <SortAsc className={sortStateDesc === null ? classNames(styles.inactiveSort) : ''} />)}
      </div>
    );

    const sumStyle = !lastColumn ? { flexBasis: c.width, flexShrink: 0, flexGrow: 0, maxWidth: c.width } : {};
    const sumCls = classNames(styles.sumColWrapper, ifTrue(styles.colRightAlign, c.ralign), c.sumClass, !hasSumCol ? styles.noSumCol : '');
    let sumCol = (
      <div key={i} className={sumCls} style={sumStyle}>
        {c.sumCol ? c.sumCol(props.records) : <span className={sumCls} style={sumStyle}></span>}
      </div>
    );
    return { col, sumCol };
  });

  const cols = headerCols.map((h) => h.col);
  const sumCols = headerCols.map((h) => h.sumCol);
  const headerRow = props.renderer.headerRow ? props.renderer.headerRow(cols, props.records) : <DataTableHeaderRow>{cols}</DataTableHeaderRow>;
  const sumRow = props.renderer.sumRow ? (
    props.renderer.sumRow(sumCols, props.records)
  ) : (
    <DataTableSumRow className={classNames(ifTrue(styles.hidden, !hasSumCol))}>{sumCols}</DataTableSumRow>
  );

  return (
    <div className={styles.root} data-cy={props.dataCy}>
      {props.hideHeader !== true && (
        <div
          className={classNames(styles.header, {
            [styles.sticky]: !!props.sticky,
          })}
        >
          <div className={styles.colHeaders}>{headerRow}</div>
          <div className={styles.sumHeaders}>{sumRow}</div>
        </div>
      )}
      <div className={styles.rows}>
        {props.records.map((r, i) => {
          const cols = props.renderer.cols.map((c, i2) => {
            const vcenterCls = {
              [styles['vcenter']]: c.vcenter === true || c.vcenter === undefined,
            };
            const col = c.col(r) as any;
            const lastCol = i2 === props.renderer.cols.length - 1;
            return (
              <div
                data-cy={`col_${c.name}`}
                className={classNames(styles.trColWrapper, ifTrue(styles.colRightAlign, c.ralign), c.colClass?.(r), vcenterCls)}
                style={!lastCol ? { flexBasis: c.width, flexShrink: 0, flexGrow: 0, maxWidth: c.width } : {}}
                key={i2}
              >
                {col}
              </div>
            );
          });

          let row = props.renderer.row ? (
            props.renderer.row(r, cols)
          ) : (
            <DataTableRow dataAttrs={props.renderer?.rowAttributes?.(r) || {}} key={i}>
              {cols}
            </DataTableRow>
          );
          return <Fragment key={i}>{row}</Fragment>;
        })}
      </div>
    </div>
  );
}
