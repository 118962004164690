import classNames from 'classnames';
import Pill from '../pill';
import styles from './applied-tags.module.scss';
import { StationFilterParameters } from './filter-area-parameters.dto';

export default function StationAppliedTags(props: { show: { ac: boolean; dc: boolean; acdc: boolean }; setFilterParams: (params: StationFilterParameters) => void }) {
  const { show, setFilterParams } = props;
  return (
    <div className={styles.appliedTags}>
      <div>
        {show.ac && (
          <Pill
            key={'ac'}
            hasClose={true}
            className={classNames(styles.tag)}
            onClick={() => {
              setFilterParams({
                ac: undefined,
              });
            }}
          >
            {'AC'}
          </Pill>
        )}
      </div>

      <div>
        {show.dc && (
          <Pill
            key={'dc'}
            hasClose={true}
            className={classNames(styles.tag)}
            onClick={() => {
              setFilterParams({
                dc: undefined,
              });
            }}
          >
            {'DC'}
          </Pill>
        )}
      </div>

      <div>
        {show.acdc && (
          <Pill
            key={'acdc'}
            hasClose={true}
            className={classNames(styles.tag)}
            onClick={() => {
              setFilterParams({
                acdc: undefined,
              });
            }}
          >
            {'AC/DC'}
          </Pill>
        )}
      </div>
    </div>
  );
}
