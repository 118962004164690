import classNames from 'classnames';
import { ReactComponent as ChevronLeftDoubleIco } from '../assets/chevron-left-double.svg';
import { ReactComponent as ChevronLeftIco } from '../assets/chevron-left.svg';
import { ReactComponent as ChevronRightDoubleIco } from '../assets/chevron-right-double.svg';
import { ReactComponent as ChevronRightIco } from '../assets/chevron-right.svg';
import ifTrue from '../utils/class-name';
import useBetterTranslate from '../utils/translation-utils';
import styles from './paging.module.scss';

export default function Paging(props: { limits?: []; className?: string; skip: number; total: number; limit: number; onChange: (args: { skip: number; limit: number }) => void }) {
  const { _t } = useBetterTranslate('paging');
  let pages = Math.ceil(props.total / props.limit);
  const SIZES = props.limits || [10, 20, 50, 100];
  const actualSkip = props.skip;
  const currentPageIdx = actualSkip / props.limit;
  const PAGES_LEFT = 2;
  const PAGES_RIGHT = 3; // includes the current page
  const pageArray: { num: number; lbl: string; cls: string }[] = [];

  for (let idx = 0; idx < PAGES_RIGHT; idx++) {
    const pageNum = idx + 1 + currentPageIdx;
    if (pageNum > pages) continue;
    pageArray.push({ num: pageNum, lbl: pageNum.toFixed(0), cls: classNames(styles.pageBtn, ifTrue(styles.active, currentPageIdx === pageNum - 1)) });
  }

  for (let idx = 0; idx < PAGES_LEFT; idx++) {
    const pageNum = currentPageIdx - idx;
    if (pageNum <= 0) continue;
    pageArray.unshift({ num: pageNum, lbl: pageNum.toFixed(0), cls: classNames(styles.pageBtn, ifTrue(styles.active, currentPageIdx === pageNum - 1)) });
  }

  if (pageArray.findIndex((p) => p.num === pages) < 0 && currentPageIdx < pages) {
    pageArray.push({ num: -1, lbl: '...', cls: classNames(styles.pageBtn, styles.ellipsis) });
  }
  if (pageArray.findIndex((p) => p.num === 1) < 0 && currentPageIdx > 0) {
    pageArray.unshift({ num: -1, lbl: '...', cls: classNames(styles.pageBtn, styles.ellipsis) });
  }

  let fromRecord = props.limit * currentPageIdx + 1;
  let toRecord = props.limit * currentPageIdx + props.limit;
  if (toRecord > props.total) {
    toRecord = props.total;
  }

  const onNavigate = (arg: { skip?: number; limit?: number }) => {
    const skip = arg.skip === undefined ? 0 : arg.skip;
    const limit = arg.limit || props.limit;
    props.onChange({ skip, limit });
  };

  return (
    <div className={classNames(styles.root, props.className)} data-cy={'paging'}>
      <div className={classNames(styles.sizes)} data-cy={'pagLeftSide'}>
        <div>{_t(`Zeilen pro Seite`)}</div>
        <div>
          <select
            onChange={(el) => {
              let val = parseInt(el.target.value);
              if (!Number.isInteger(val)) {
                val = 10;
              }

              onNavigate({ limit: val });
            }}
            value={SIZES.find((s) => s === props.limit) || SIZES[1]}
            data-cy={'ddlLinesPerPage'}
          >
            {SIZES.map((s) => {
              return <option key={s}>{s}</option>;
            })}
          </select>
        </div>
        <div>
          {props.total > 0 ? fromRecord : 0}-{toRecord} {_t('von')} {props.total}
        </div>
      </div>

      <div className={classNames(styles.rightAligned)} data-cy={'pagRightSide'}>
        <div className={classNames(styles.pages)}>
          {_t('Seite')} {pages > 0 ? currentPageIdx + 1 : 0} {_t('von')} {pages}
        </div>

        <div className={classNames(styles.paginationWrapper)}>
          <button type='button' disabled={currentPageIdx <= 0} onClick={() => onNavigate({ skip: 0 })} className={classNames(styles.pageBtn)} data-cy={'btnFirstPage'}>
            <ChevronLeftDoubleIco />
          </button>
          <button
            type='button'
            disabled={currentPageIdx <= 0}
            onClick={() => onNavigate({ skip: (currentPageIdx - 1) * props.limit })}
            className={classNames(styles.pageBtn)}
            data-cy={'btnPreviousPage'}
          >
            <ChevronLeftIco />
          </button>
          {pageArray.map((p, idx) => {
            return (
              <button
                type='button'
                key={`p_${idx}`}
                onClick={() => {
                  if (p.num >= 0) onNavigate({ skip: (p.num - 1) * props.limit });
                }}
                className={classNames(p.cls)}
              >
                {p.lbl}
              </button>
            );
          })}
          <button
            type='button'
            disabled={currentPageIdx + 1 >= pages}
            onClick={() => onNavigate({ skip: (currentPageIdx + 1) * props.limit })}
            className={classNames(styles.pageBtn)}
            data-cy={'btnNextPage'}
          >
            <ChevronRightIco />
          </button>
          <button
            type='button'
            disabled={currentPageIdx + 1 >= pages}
            onClick={() => onNavigate({ skip: (pages - 1) * props.limit })}
            className={classNames(styles.pageBtn)}
            data-cy={'btnLastPage'}
          >
            <ChevronRightDoubleIco />
          </button>
        </div>
      </div>
    </div>
  );
}
