import classNames from 'classnames';
import { PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as CheckProgressIco } from '../../assets/check-progress.svg';
import { ReactComponent as CheckIco } from '../../assets/check.svg';
import { ReactComponent as CloseIco } from '../../assets/close2.svg';
import { ReactComponent as SpinnerIco } from '../../assets/spinner.svg';
import Ico from '../ico';
import Box, { Divider } from '../utils';
import { Modal, ModalAcceptButton, ModalCancelButton, ModalContent, ModalFooter, ModalHeader, ModalProps, useModal, UseModalProps } from './modal';
import st from './modal.multi-step.module.scss';

// type RestParams<T extends any[]> =
//   ((...p: T) => void) extends ((p1: infer P1, ...rest: infer R) => void) ? R : never;
type DropFirst<T extends unknown[]> = T extends [any, ...infer U] ? U : never;
export type StepsBase<T> = Record<keyof T, (close: CloseFunc, ...args: any) => ReactNode>;

// export function wrap<T extends StepsBase<T>>(steps: T) {
//   return function <K extends keyof T>(step: K, arg: Parameters<T[K]>): ReactNode {
//     const stepFunction = steps[step];

//     if (!stepFunction) {
//       throw new Error(`Step ${String(step)} does not exist`);
//     }

//     return stepFunction(arg);
//   };
// }

// const goTo = wrap({
//   step1: (param1: string) => <></>,
//   step2: (param1: number) => <></>,
// });

// function test(){
//   goTo('step1', 'fooo');
//   goTo('step2', ); // should produce error
//   goTo('step3', 'fooo'); // should produce error as step3 not exists
// }

export type CloseFunc<TOk = any, TNok = any> = (val: TOk | TNok | null) => void;

export type StepRenderer<Steps extends StepsBase<Steps>, TOk, TNok> = <K extends keyof Steps>(
  close: CloseFunc<TOk, TNok>,
  stepAction: (args: Parameters<Steps[K]>) => ReactNode
) => ReactNode;
// export type GoToStepFunc<T extends StepsBase<T>, TOk, TNok, K extends keyof T> = (stepName: K, ...args: Parameters<T[K]>) => void;

export type MultiStepModalProps<TOk, TNok> = {
  renderCurrent?: (close: CloseFunc<TOk, TNok>) => ReactNode;
} & ModalProps<TOk, TNok, {}>;

export function MultiStepModal<TOk, TNok>(props: MultiStepModalProps<TOk, TNok>) {
  return (
    <Modal<TOk, TNok, {}> {...props}>
      {(close) => {
        let step: ReactNode = <></>;
        if (props.renderCurrent) {
          step = props.renderCurrent(close);
          return step;
        }
      }}
    </Modal>
  );
}

interface ModalConfirmStepProps {
  title?: ReactNode;
  cancel?:
    | {
        text?: ReactNode;
        onClick: () => void;
      }
    | false;
  accept?:
    | {
        disabled?: boolean;
        text?: ReactNode;
        onClick: () => void;
      }
    | false;
}
export function ModalConfirmStep(props: PropsWithChildren<ModalConfirmStepProps>) {
  return (
    <>
      {props.title && <ModalHeader title={props.title} />}
      <ModalContent className={st.multiStepBody}>{props.children}</ModalContent>
      {(props.accept !== false || props.cancel !== false) && (
        <ModalFooter>
          {props.cancel && <ModalCancelButton onClick={props.cancel.onClick} children={props.cancel.text} />}
          {props.cancel && props.accept && <Divider kind='v' />}
          {props.accept && <ModalAcceptButton disabled={props.accept.disabled} onClick={props.accept.onClick} children={props.accept.text} />}
        </ModalFooter>
      )}
    </>
  );
}

interface ModalPendingStepProps {
  title?: ReactNode;
  cancel?:
    | {
        text?: ReactNode;
        onClick: () => void;
      }
    | false;
  accept?:
    | {
        text?: ReactNode;
        onClick: () => void;
      }
    | false;
}
export function ModalPendingStep(props: PropsWithChildren<ModalPendingStepProps>) {
  return (
    <>
      {props.title && <ModalHeader title={props.title} />}
      <ModalContent className={st.multiStepBody}>
        <Box kind={'vflex'} gap='l' align='center'>
          <Box className={st.stepIco}>
            <Box className={classNames(st.spinnerIco)} pad={'500'} bg='blue-blue-jeans'>
              <Ico fill='primary-white' size='100%' file={<SpinnerIco />} />
            </Box>
          </Box>
          {props.children}
        </Box>
      </ModalContent>
      {(props.accept !== false || props.cancel !== false) && (
        <ModalFooter>
          {props.cancel && <ModalCancelButton onClick={props.cancel.onClick} children={props.cancel.text} />}
          {props.cancel && props.accept && <Divider kind='v' />}
          {props.accept && <ModalAcceptButton onClick={props.accept.onClick} children={props.accept.text} />}
        </ModalFooter>
      )}
    </>
  );
}

interface ModalFailedStepProps {
  title?: ReactNode;
  cancel?:
    | {
        text?: ReactNode;
        onClick: () => void;
      }
    | false;
  accept?:
    | {
        text?: ReactNode;
        onClick: () => void;
      }
    | false;
}
export function ModalFailedStep(props: PropsWithChildren<ModalFailedStepProps>) {
  return (
    <>
      {props.title && <ModalHeader title={props.title} />}
      <ModalContent className={st.multiStepBody}>
        <Box kind={'vflex'} gap='l' align='center'>
          <Box className={st.stepIco}>
            <Box className={classNames(st.failedIco)} pad={'500'} bg='functional-error-full'>
              <Ico fill='primary-white' size='100%' file={<CloseIco />} />
            </Box>
          </Box>
          {props.children}
        </Box>
      </ModalContent>
      {(props.accept !== false || props.cancel !== false) && (
        <ModalFooter>
          {props.cancel && <ModalCancelButton onClick={props.cancel.onClick} children={props.cancel.text} />}
          {props.cancel && props.accept && <Divider kind='v' />}
          {props.accept && <ModalAcceptButton onClick={props.accept.onClick} children={props.accept.text} />}
        </ModalFooter>
      )}
    </>
  );
}

interface ModalSuccessStepProps {
  title?: ReactNode;
  cancel?:
    | {
        text?: ReactNode;
        onClick: () => void;
      }
    | false;
  accept?:
    | {
        text?: ReactNode;
        onClick: () => void;
      }
    | false;
}
export function ModalSuccessStep(props: PropsWithChildren<ModalSuccessStepProps>) {
  return (
    <>
      {props.title && <ModalHeader title={props.title} />}
      <ModalContent className={st.multiStepBody}>
        <Box kind={'vflex'} gap='l' align='center'>
          <Box className={st.stepIco}>
            <Box className={classNames(st.successIco)} pad={'500'} bg='functional-validation-full'>
              <Ico fill='primary-white' size='100%' file={<CheckIco />} />
            </Box>
          </Box>
          {props.children}
        </Box>
      </ModalContent>
      {props.accept !== false && props.cancel !== false && (
        <ModalFooter>
          {props.cancel && <ModalCancelButton onClick={props.cancel.onClick} children={props.cancel.text} />}
          {props.cancel && props.accept && <Divider kind='v' />}
          {props.accept && <ModalAcceptButton onClick={props.accept.onClick} children={props.accept.text} />}
        </ModalFooter>
      )}
    </>
  );
}

interface ModalPartialStepProps {
  title?: ReactNode;
  cancel?:
    | {
        text?: ReactNode;
        onClick: () => void;
      }
    | false;
  accept?:
    | {
        text?: ReactNode;
        onClick: () => void;
      }
    | false;
}
export function ModalPartialStep(props: PropsWithChildren<ModalPartialStepProps>) {
  return (
    <>
      {props.title && <ModalHeader title={props.title} />}
      <ModalContent className={st.multiStepBody}>
        <Box kind={'vflex'} gap='l' align='center'>
          <Box className={st.stepIco}>
            <Box className={classNames(st.partialIco)} pad={'400'} bg='brand-neutral-400'>
              <Ico fill='primary-white' size='100%' file={<CheckProgressIco />} />
            </Box>
          </Box>
          {props.children}
        </Box>
      </ModalContent>
      {props.accept !== false && props.cancel !== false && (
        <ModalFooter>
          {props.cancel && <ModalCancelButton onClick={props.cancel.onClick} children={props.cancel.text} />}
          {props.cancel && props.accept && <Divider kind='v' />}
          {props.accept && <ModalAcceptButton onClick={props.accept.onClick} children={props.accept.text} />}
        </ModalFooter>
      )}
    </>
  );
}

export type UseMultiStepResult<TOk, TNok, Steps extends StepsBase<Steps>> = [
  {
    show: <K extends keyof Steps>(step: K, ...args: DropFirst<Parameters<Steps[K]>>) => Promise<TOk | TNok | null>;
    goTo: <K extends keyof Steps>(stepName: K, ...args: DropFirst<Parameters<Steps[K]>>) => void;
    isOpen: () => boolean;
    // GoToStepFunc<Steps, TOk, TNok, keyof Steps>,
  },
  MultiStepModalProps<TOk, TNok>
];
export type UseMultiStepModalProps<Steps extends StepsBase<Steps>> = {
  steps: Steps;
} & UseModalProps;
export function useMultiStep<Steps extends StepsBase<Steps>, TOk, TNok>(props: UseMultiStepModalProps<Steps>): UseMultiStepResult<TOk, TNok, Steps> {
  const [showModal, modalProps] = useModal<TOk, TNok, {}>(props);
  const modalArgs = useMemo(() => ({}), []);
  const [controlProps, setControlProps] = useState<MultiStepModalProps<TOk, TNok>>({
    open: false,
    ...props,
  });
  const [currentStep, setCurrentStep] = useState<keyof Steps>();
  const refObj = useRef<{
    show: <K extends keyof Steps>(step: K, ...args: DropFirst<Parameters<Steps[K]>>) => Promise<TOk | TNok | null>;
    // controlProps: MultiStepModalProps<TOk, TNok>,
    goTo: <K extends keyof Steps>(step: K, ...args: DropFirst<Parameters<Steps[K]>>) => Promise<void>;
    isOpen: () => boolean;
    __steps: Steps;
    __stepArgs: any[];
  }>();

  const goToStep = useCallback(async <K extends keyof Steps>(step: K, ...args: DropFirst<Parameters<Steps[K]>>) => {
    if (!refObj.current) return;
    refObj.current.__stepArgs = args;
    setCurrentStep(step);
  }, []);

  const showInitStep = useCallback(
    async <K extends keyof Steps>(step: K, ...args: DropFirst<Parameters<Steps[K]>>): Promise<TOk | TNok | null> => {
      goToStep(step, ...args);
      const modalResult = await showModal(modalArgs);
      return modalResult;
    },
    [showModal, goToStep, modalArgs]
  );

  useEffect(() => {
    if (!refObj.current) return;
    if (!currentStep) return;

    const renderer = (close: CloseFunc<TOk, TNok>) => {
      if (!refObj.current) return;
      if (!currentStep) return;

      const renderFunc = refObj.current.__steps[currentStep];
      const args = refObj.current.__stepArgs || [];

      return (renderFunc as any)(close, ...args);
    };
    setControlProps({ ...modalProps, renderCurrent: renderer });
  }, [currentStep, modalProps]);

  if (!refObj.current) {
    refObj.current = {} as any;
  }
  refObj.current!.show = showInitStep;
  refObj.current!.__steps = props.steps;
  refObj.current!.goTo = goToStep;
  refObj.current!.isOpen = () => controlProps.open;

  return [refObj.current!, controlProps];
}
