import classNames from 'classnames';
import React, { useState } from 'react';
import Tooltip from 'react-tooltip';
import { ReactComponent as CollapsedIco } from '../../assets/collapsed.svg';
import { ReactComponent as ContextMenuIcon } from '../../assets/dots-three.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as ExpandedIco } from '../../assets/expanded.svg';
import Button, { ButtonAccent } from '../../components/button';
import { NodeHierarchyLabelInverted } from '../../components/org-hierarchy/node-hierarchy-label';
import NodeLabel from '../../components/org-hierarchy/node-label';
import OutletTypeIcon, { PlugType } from '../../components/outlet-type-icon';
import colorStyles from '../../components/style-utils';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { ChargePoint } from '../../pages/stations/chargepoint-remote-actions';
import { ChargePointItemDto, ChargePointsOfStationResultDto, SharedClientHierarchyNodeDto } from '../../services/api-client/csp-api';
import { getDomainStatusColorStyle, getDomainStatusTextColorStyle, getDomainStatusTitle } from '../../utils/domain-status';
import useBetterTranslate from '../../utils/translation-utils';
import useEffectAsync from '../../utils/useEffectAsync';
import { ExternalHardwareIcon, PublicStationIcon } from '../evse-linfo-icons';
import ChargepointListItem from './chargepoint-list-item';
import styles from './station-list-item.module.scss';

interface ChargePointOutletAndStatusCount {
  outletType: string;
  domainStatus: string;
  count: number;
}

export interface StationDetails {
  chargePoints: ChargePointItemDto[];
}

export interface StationListItemModel {
  chargeBoxId: string;
  customName?: string;
  domainStatus?: string;
  connectorsTotalCount: number;
  nodeCode: string;
  manufacturer: string;
  model: string;
  serialNumber: string;
  clusterDisplayName?: string;
  mergedChargePointType?: string;
  isExternalHardware: boolean;
  isPublic: boolean;
  can: {
    remoteReset: boolean;
    remoteChangeAvailability: boolean;
    goToSessions: boolean;
  };
  loadMgmtOfClusterActive?: boolean;
  loadManagementActive?: boolean;
  clusterId?: string;
  connectors?: {
    domainStatus?: string;
    outletType?: string;
  }[];
}

export interface StationListItemProps {
  item: StationListItemModel;
  allNodes: SharedClientHierarchyNodeDto[];
  sessionsLink: string;
  chargepointsPath: string;
  clientCode?: string;
  loadManagementLinkActive?: boolean;
  onClickStationRemoteActionButton: (ev: React.MouseEvent<Element, MouseEvent>) => void;
  onClickChargePointRemoteActionButton: (ev: React.MouseEvent<Element, MouseEvent>, cp: ChargePoint) => void;
  hasStationContextItems: boolean;
  hasChargePointContextItems: (cp: ChargePoint) => boolean;
  fetchStationDetails: () => Promise<ChargePointsOfStationResultDto>;
  onClickChangeCustomNameStation: () => void;
  onClickChangeCustomNameChargePoint: (chargePoint: ChargePointItemDto) => void;
  hasChangeCustomNamePopup?: boolean;
}

export default function StationListItem({
  item,
  allNodes,
  loadManagementLinkActive,
  sessionsLink,
  chargepointsPath,
  clientCode,
  onClickStationRemoteActionButton,
  onClickChargePointRemoteActionButton,
  hasStationContextItems,
  fetchStationDetails,
  hasChargePointContextItems,
  hasChangeCustomNamePopup,
  onClickChangeCustomNameStation,
  onClickChangeCustomNameChargePoint,
}: StationListItemProps) {
  const { _t } = useBetterTranslate('station-list-item');
  const loadMgmgOfClusterAndStationActive = item.loadMgmtOfClusterActive && item.loadManagementActive;

  const [stationDetails, setStationDetails] = useState<StationDetails>();
  const [expanded, setExpanded] = useState<boolean>();

  const navigate = useBetterNavigate();
  const navigateToClusterDetailStationsPage = (clusterId: string) => {
    const link = `/loadmanagement/${clientCode}/cluster-stations/${clusterId}`;
    navigate(link);
  };
  const navigateToChargePointsTab = (chargeBoxId: string) => {
    navigate(chargepointsPath, { search: chargeBoxId });
  };

  useEffectAsync(async () => {
    if (expanded) {
      const res = await fetchStationDetails();
      setStationDetails({ chargePoints: res.chargePoints });
    }
  }, [item, expanded]);

  const chargePointGroups: ChargePointOutletAndStatusCount[] =
    item.domainStatus === 'notInOperation'
      ? []
      : item.connectors?.reduce((accumulator: ChargePointOutletAndStatusCount[], currentValue) => {
          const existingValue = accumulator.find((value) => value.domainStatus === currentValue.domainStatus && value.outletType === currentValue.outletType);
          if (existingValue) {
            existingValue.count++;
          } else {
            accumulator.push({ domainStatus: currentValue.domainStatus || '', outletType: currentValue.outletType || '', count: 1 });
          }
          return accumulator;
        }, []) || [];

  return (
    <React.Fragment>
      <div data-cy={'element_charging_station'} data-cy-id={item.chargeBoxId} className={styles.stationListRow} key={item.chargeBoxId}>
        <div
          data-cy={'el_status'}
          className={classNames(
            styles.stationStatus,
            item.domainStatus === 'offline'
              ? colorStyles.components.stationStatus.offline.active
              : item.domainStatus === 'online'
              ? colorStyles.components.stationStatus.online.active
              : item.domainStatus === 'notInOperation'
              ? colorStyles.components.stationStatus.notInOperation.active
              : colorStyles.components.stationStatus.failure.active
          )}
        ></div>
        <div className={styles.stationDataContainer}>
          <div className={styles.stationBasicData}>
            <div className={styles.chargeBoxId}>
              <button
                type='button'
                className={classNames(styles.collapseControlArea, item.connectorsTotalCount === 0 && styles.inactive)}
                onClick={() => (item.connectorsTotalCount > 0 ? setExpanded((expanded) => !expanded) : undefined)}
              >
                {item.connectors && item.connectors.length > 0 && !expanded && <CollapsedIco />}
                {(!item.connectors || item.connectors.length <= 0 || expanded) && <ExpandedIco />}
              </button>
              <Tooltip id={'name-tooltip'} place={'right'} type={'light'} />
              <span
                data-cy={'el_evseId'}
                className={classNames(styles.customName, !item.customName || item.customName === item.chargeBoxId ? styles.noCustomName : '')}
                data-id='name-tooltip'
                data-for='name-tooltip'
                data-tip={item.customName || item.chargeBoxId}
              >
                {item.customName || item.chargeBoxId}
              </span>
              {hasChangeCustomNamePopup && (
                <button type='button' onClick={() => onClickChangeCustomNameStation()} className={styles.editButton}>
                  <EditIcon className={styles.editIcon} />
                </button>
              )}
              <ExternalHardwareIcon isExternalHardware={item.isExternalHardware} />
              <PublicStationIcon isPublic={item.isPublic} />
            </div>
            <NodeHierarchyLabelInverted allNodes={allNodes} code={item.nodeCode} hideArea={true} hideClientRoot={true} />
            <div>
              <NodeLabel allNodes={allNodes} code={item.nodeCode} />
            </div>
          </div>
          <div className={styles.stationExtendedData}>
            <div className={styles.stationExtendedDataHighlighted} onClick={() => navigateToChargePointsTab(item.chargeBoxId)}>
              {item.chargeBoxId}
            </div>
            <div>
              {_t('Modell')}: {item.manufacturer + ' - ' + item.model}
            </div>
            <div>
              {_t('S/N')}: {item.serialNumber}
            </div>
            {item.clusterDisplayName && (
              <div className={styles.loadMgmtData}>
                <span
                  className={classNames(styles.loadManagementLabel, loadManagementLinkActive ? styles.textLink : undefined)}
                  onClick={loadManagementLinkActive ? () => navigateToClusterDetailStationsPage(item.clusterId || '') : () => {}}
                >
                  {_t('Lastmanagement')}:
                </span>
                <ul className={styles.stationAndClusterLoadManagementActivationStatus}>
                  <li className={classNames(loadMgmgOfClusterAndStationActive ? styles.activeLoadManagement : styles.inactiveLoadManagement)}>
                    {loadMgmgOfClusterAndStationActive ? _t('Aktiviert') : _t('Deaktiviert')}
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className={styles.chargePointData}>
            <div className={styles.chargePointBasicDataAndButton}>
              <div className={styles.chargePointCount}>
                {item.connectorsTotalCount} {item.connectorsTotalCount === 1 ? _t('Ladepunkt') : _t('Ladepunkte')}
                {item.connectorsTotalCount > 0 && ` |  ${item.mergedChargePointType || ''}`}
              </div>

              <div className={classNames(styles.actions)}>
                <ButtonAccent disabled={!item.can.goToSessions} href={sessionsLink} className={styles.chargePointSessionsButton}>
                  {_t('Ladevorgänge')}
                </ButtonAccent>

                <Button
                  kind='icon'
                  disabled={!hasStationContextItems}
                  tooltip={
                    !hasStationContextItems
                      ? _t('Fernsteuerung nicht möglich: Die Ladestation ist entweder offline oder nicht in Betrieb oder Sie verfügen nicht über die notwendige Berechtigung.')
                      : ''
                  }
                  onClick={onClickStationRemoteActionButton}
                >
                  <ContextMenuIcon></ContextMenuIcon>
                </Button>
              </div>
            </div>
            <div className={styles.outletIcons}>
              {chargePointGroups.map((group, index) => {
                return (
                  <div className={styles.iconAndNumber} key={index} title={getDomainStatusTitle(group.domainStatus)}>
                    <OutletTypeIcon
                      type={(group.outletType as PlugType) || ('Unknown' as PlugType)}
                      className={classNames(styles.outletIcon, colorStyles.components.connectorsStatus.colors.__define)}
                      color={getDomainStatusColorStyle(group.domainStatus)}
                    />
                    <div className={classNames(styles.outletNumber, getDomainStatusTextColorStyle(group.domainStatus))}>{group.count}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {expanded && stationDetails && (
        <div className={classNames(styles.cpListContainer, styles.tabContent)}>
          {stationDetails.chargePoints.map((cp, i) => {
            const hasContextIcons = hasChargePointContextItems({
              can: cp.can,
              chargeBoxId: cp.chargeBoxId,
              connectorId: cp.connectorId,
              domainStatus: cp.domainStatus,
              domainStatusOfStation: cp.domainStatusOfStation,
              evseId: cp.evseId,
            });
            return (
              <ChargepointListItem
                item={cp}
                key={i}
                allNodes={allNodes}
                sessionsLink={sessionsLink}
                loadManagementLinkActive={loadManagementLinkActive}
                clientCode={clientCode}
                onClickRemoteActionButton={onClickChargePointRemoteActionButton}
                hasContextIcons={hasContextIcons}
                hasChangeCustomNamePopup={hasChangeCustomNamePopup}
                onClickChangeCustomName={() => onClickChangeCustomNameChargePoint(cp)}
              ></ChargepointListItem>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
}
