import classNames from 'classnames';
import { ChangeEvent, HTMLInputTypeAttribute, PropsWithChildren, ReactNode } from 'react';
import Autosuggest, { AutosuggestPropsSingleSection } from 'react-autosuggest';
import { ReactComponent as WarningIco } from '../assets/warning.svg';
import { classIf } from '../utils/class-name';
import suggestTheme from './input-suggest.module.scss';
import styles from './input.module.scss';

// export interface SuggestionsProps<T>{
//   suggestions: T[];
//   getSuggestion
//   suggestionRender?: (item: T) => ReactNode;
// }
export interface InputProps<TSuggestion extends unknown> {
  className?: string;
  placeholder?: string;
  label?: ReactNode;
  type: HTMLInputTypeAttribute;
  value: string;
  maxLength?: number;
  onChange?: (newVal: string) => void;
  onBlur?: () => void;
  tabindex?: number;

  // new behavior of labels (not absolute)
  v2?: boolean;

  validationErr?: ReactNode;
  suggest?: Omit<AutosuggestPropsSingleSection<TSuggestion>, 'inputProps'>;
  disabled?: boolean | undefined;
  dataCy?: { label?: string; error?: string };
}

export function FormContainer(
  props: PropsWithChildren<{ renderMode?: `label` | 'span'; className?: string; label?: ReactNode; validationErr?: ReactNode; dataCy?: { label?: string; error?: string } }>
) {
  const content = (
    <>
      {props.label && (
        <div className={classNames(styles.lblWrapper, 'input-lbl-wrapper')}>
          <span>{props.label}</span>
        </div>
      )}
      {props.children}
      {props.validationErr && (
        <div className={classNames(styles.lblWrapper, styles.lblError, 'input-lbl-wrapper', 'input-lbl-wrapper-has-err')}>
          <ErrorLabel showWarnSign={true} dataCy={props.dataCy?.error}>
            {props.validationErr}
          </ErrorLabel>
          {/* <span>{props.validationErr}</span> */}
        </div>
      )}
    </>
  );

  const classes = classNames(styles.root, styles.v2Labels, props.className, props.validationErr ? styles.hasValidationError : '');

  if (props.renderMode === 'span') {
    return (
      <span className={classes} data-cy={props.dataCy?.label}>
        {content}
      </span>
    );
  } else {
    return (
      <label className={classes} data-cy={props.dataCy?.label}>
        {content}
      </label>
    );
  }
}

export default function Input<TSuggestion extends unknown>(props: InputProps<TSuggestion>) {
  let inputEl = (
    <input
      tabIndex={props.tabindex}
      placeholder={props.placeholder}
      type={props.type}
      value={props.value}
      maxLength={props.maxLength}
      onChange={(ev) => {
        if (props.onChange) props.onChange(ev.target.value);
      }}
      onBlur={props.onBlur}
      disabled={props.disabled}
    />
  );
  if (props.suggest) {
    inputEl = (
      <Autosuggest
        {...props.suggest}
        theme={suggestTheme}
        inputProps={{
          value: props.value,
          placeholder: props.placeholder,
          type: 'text',
          onChange: (ev) => {
            const unwrappedEv = ev as ChangeEvent<HTMLInputElement>;
            if (props.onChange) props.onChange(unwrappedEv.target.value);
          },
        }}
      />
    );
  }

  return (
    <label
      className={classNames(styles.root, classIf(props.v2, styles.v2Labels), props.className, props.validationErr ? styles.hasValidationError : '')}
      data-cy={props.dataCy?.label}
    >
      {props.label && (
        <div className={classNames(styles.lblWrapper, 'input-lbl-wrapper')}>
          <span>{props.label}</span>
        </div>
      )}
      {inputEl}

      {props.validationErr && (
        <div className={classNames(styles.lblWrapper, styles.lblError, 'input-lbl-wrapper', 'input-lbl-wrapper-has-err')}>
          <ErrorLabel showWarnSign={true} dataCy={props.dataCy?.error}>
            {props.validationErr}
          </ErrorLabel>
          {/* <span>{props.validationErr}</span> */}
        </div>
      )}
    </label>
  );
}

export function ErrorLabel(props: PropsWithChildren<{ classNames?: string; showWarnSign?: boolean; dataCy?: string }>) {
  const showWarn = (props.showWarnSign || props.showWarnSign === undefined) && props.children;
  return (
    <span className={classNames(styles.className, styles.errorLabel)} data-cy={props.dataCy}>
      {showWarn ? (
        <span className={classNames(styles.warnIco)}>
          <WarningIco />
        </span>
      ) : (
        <></>
      )}
      {props.children}
    </span>
  );
}
