import { read as readXlsx, utils, writeFile } from '@e965/xlsx';
import classNames from 'classnames';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ToggleButtonX } from '../../components-v2/filters';
import Button from '../../components/button';
import DataTable from '../../components/data-table/data-table';
import { FormCard, FormCardBody } from '../../components/form-card';
import Page from '../../components/page';
import Pill from '../../components/pill';
import TabPanel, { TabContent } from '../../components/tab-panel';
import { isLOLalizeModeEnabled, toggleLOLalizeMode } from '../../i18n';
import api from '../../services/api';
import { TranslationNamespaceDto } from '../../services/api-client/csp-api';
import { TRANSLATION_KEY_INDEX } from '../../translation-index';
import { getSupportedLanaguages } from '../../utils/translation-utils';
import useEffectAsync from '../../utils/useEffectAsync';
import styles from './dev-translations-page.module.scss';

export interface TranslationsPageProps {
  className?: string;
}

interface TranslationRowXlsx extends Record<string, string> {
  component: string;
  key: string;
}

interface TranslationRow {
  component: string;
  key: string;
  de: string;
  en: string;
  fr: string;
  state: 'nontranslated' | 'obsolete' | 'translated';
}

async function createViewModel() {
  const result: TranslationRow[] = [];
  for (const idxNs of [...TRANSLATION_KEY_INDEX]) {
    for (const item of idxNs.keys) {
      let existing = result.find((r) => r.component === idxNs.ns && item === r.key);
      if (existing) continue;

      const missing: TranslationRow = {
        component: idxNs.ns,
        de: '',
        en: '',
        fr: '',
        key: item,
        state: 'nontranslated',
      };

      result.push(missing);
    }
  }

  const checkAllLangsExist = (item: any) => {
    for (const lang of getSupportedLanaguages()) {
      if (!item[lang]) return false;
    }
    return true;
  };

  for (const lang of getSupportedLanaguages()) {
    const translations = (await api.devTools.getTranslations(lang)).data;
    for (const ns of translations) {
      for (const item of ns.items) {
        let exist = result.find((existing) => existing.component === ns.namespace && existing.key === item.key);
        if (!exist) {
          exist = { component: ns.namespace, key: item.key, state: 'obsolete', de: '', en: '', fr: '' };
          result.push(exist);
        }

        (exist as any)[lang] = item.value;
        if (exist.state === 'obsolete') continue;
        exist.state = checkAllLangsExist(exist) ? 'translated' : 'nontranslated';
      }
    }
  }

  return result;
}

export default function TranslationsPage(props: TranslationsPageProps) {
  const [lolalizeModeEnabled] = useState(isLOLalizeModeEnabled());
  const [vm, setVm] = useState<TranslationRow[]>([]);

  const [sortCol, setSortCol] = useState<string | undefined>('component');
  const [sortDesc, setSortDesc] = useState(true);
  const filePickerRef = useRef<HTMLInputElement>(null);
  const [showTranslated, setShowTranslated] = useState(true);
  const [showUnTranslated, setShowUnTranslated] = useState(true);
  const [showObsolete, setShowObsolete] = useState(true);

  useEffectAsync(async () => {
    const vm = await createViewModel();
    setVm(vm);
  }, []);

  const onChangeLOLalizeMode = (enable: boolean) => {
    toggleLOLalizeMode(enable);
    window.location.reload();
  };

  useEffect(() => {
    setVm((current) => {
      if (sortCol === 'component' && sortDesc) {
        return current.sort((a, b) => a.component.localeCompare(b.component));
      } else if (sortCol === 'component' && !sortDesc) {
        return current.sort((a, b) => b.component.localeCompare(a.component));
      } else if (sortCol === 'key' && sortDesc) {
        return current.sort((a, b) => a.key.localeCompare(b.key));
      } else if (sortCol === 'key' && !sortDesc) {
        return current.sort((a, b) => b.key.localeCompare(a.key));
        // } else if (sortCol === 'value' && sortDesc) {
        // return current.sort((a, b) => a.value.localeCompare(b.value));
        // } else if (sortCol === 'value' && !sortDesc) {
        // return current.sort((a, b) => b.value.localeCompare(a.value));
      } else {
        return current;
      }
    });
  }, [sortDesc, sortCol]);
  // useEf

  const generateXlsx = async () => {
    // utils.book_append_sheet(workbook, sheet,
    const sheetRecords: TranslationRowXlsx[] = [];
    for (const item of vm) {
      let sheetRec = sheetRecords.find((r) => r.component === item.component && r.key === item.key);
      if (!sheetRec) {
        sheetRec = { component: item.component, key: item.key, de: item.de, en: item.en, fr: item.fr, state: item.state };
        sheetRecords.push(sheetRec);
      }
    }

    const sheet = utils.json_to_sheet(sheetRecords);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, sheet, 'translations');
    writeFile(workbook, 'customer-portal-translations.xlsx');
  };

  const onWantImportFile = async (ev: ChangeEvent<HTMLInputElement>) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (!ev.target.files) return;
    if (ev.target.files.length <= 0) return;

    const file = ev.target.files[0];
    const buffer = await file.arrayBuffer();
    const workbook = await readXlsx(buffer);
    const sheet = workbook.Sheets['translations'];
    if (!sheet) return alert(`workbook does not contain sheet named 'translations'`);

    const sheetContent = utils.sheet_to_json(sheet) as TranslationRowXlsx[];
    for (const lang of getSupportedLanaguages()) {
      const stored: TranslationNamespaceDto[] = [];
      // (await api.devTools.getTranslations(lang)).data;
      // const request:TranslationLanguagePayload = {namespaces: []};
      for (const sheetRow of sheetContent) {
        const value = sheetRow[lang];
        const ns = sheetRow.component;
        const key = sheetRow.key;

        if (!ns) continue;
        if (!key) continue;
        let storedNs = stored.find((n) => n.namespace === ns);
        if (!storedNs) {
          storedNs = { items: [], namespace: ns };
          stored.push(storedNs);
        }

        let storedK = storedNs.items.find((k) => k.key === key);
        if (!storedK) {
          storedK = { key: key, value: '' };
          storedNs.items.push(storedK);
        }

        storedK.value = value || '';
      }

      await api.devTools.storeTranslations(lang, { namespaces: stored });
      alert(`updated langue: ${lang}`);
    }

    const newVm = await createViewModel();
    setVm(newVm);
  };

  return (
    <Page breadCrumb={[{ title: 'Translations', href: '/dev/translations', active: true }]} className={styles.root}>
      <h2>Translation Development & Inspection</h2>

      <FormCard phoneSize='full'>
        <FormCardBody className={styles.settingsArea}>
          <div className={styles.settingsRegion}>
            <h4>LOL-alize mode</h4>
            <div className={styles.translationInspection}>
              <Pill
                selected={lolalizeModeEnabled}
                selectedClass={styles.activeChecked}
                unselectedClass={styles.activeUnChekced}
                onClick={() => onChangeLOLalizeMode(!lolalizeModeEnabled)}
              >
                Active
              </Pill>

              <Pill
                selected={!lolalizeModeEnabled}
                selectedClass={styles.inactiveChecked}
                unselectedClass={styles.inactiveUnChekced}
                onClick={() => onChangeLOLalizeMode(!lolalizeModeEnabled)}
              >
                Inactive
              </Pill>
            </div>
          </div>
          <div className={styles.settingsRegion}>
            <h4>Filters</h4>
            <div className={styles.translationInspection}>
              <ToggleButtonX
                apperance='success'
                displayText={`translated ${vm.filter((a) => a.state === 'translated').length}`}
                checked={showTranslated}
                onChange={(v) => setShowTranslated(v)}
              />
              <ToggleButtonX
                apperance='alert'
                displayText={`not translated ${vm.filter((a) => a.state === 'nontranslated').length}`}
                checked={showUnTranslated}
                onChange={(v) => setShowUnTranslated(v)}
              />
              <ToggleButtonX
                apperance='undef'
                displayText={`obsolete ${vm.filter((a) => a.state === 'obsolete').length}`}
                checked={showObsolete}
                onChange={(v) => setShowObsolete(v)}
              />
            </div>
          </div>
        </FormCardBody>
      </FormCard>

      {/* <FormCard tabletSize='one-fourth'>
        <FormCardBody>
          <Input placeholder='Search' onChange={setSearch} value={search} type={'text'} />
        </FormCardBody>
      </FormCard> */}
      <FormCard tabletSize='one-fourth'>
        <FormCardBody>
          <Button
            onClick={() => {
              generateXlsx();
            }}
            kind='primary'
          >
            Export
          </Button>
        </FormCardBody>
      </FormCard>
      <FormCard tabletSize='one-fourth'>
        <FormCardBody>
          <Button
            onClick={(ev) => {
              filePickerRef.current!.value = '';
              filePickerRef.current?.click();
            }}
            kind='primary'
          >
            Import
          </Button>
          <input accept='.xlsx' type='file' onChange={onWantImportFile} id='file' ref={filePickerRef} style={{ display: 'none' }} />
        </FormCardBody>
      </FormCard>

      <FormCard phoneSize='full'>
        <FormCardBody>
          <TabPanel>
            <TabContent active={true}>
              <>
                <DataTable
                  sticky={true}
                  records={vm.filter((v) => {
                    if (v.state === 'nontranslated' && !showUnTranslated) return false;
                    if (v.state === 'translated' && !showTranslated) return false;
                    if (v.state === 'obsolete' && !showObsolete) return false;
                    return true;
                  })}
                  sorting={{
                    handler: (col, desc) => {
                      setSortCol(col);
                      setSortDesc(desc);
                    },
                    col: sortCol,
                    desc: sortDesc,
                  }}
                  renderer={{
                    cols: [
                      {
                        name: 'component',
                        sortable: true,
                        headerCol: () => <div>Component</div>,
                        col: (record) => <span>{record.component}</span>,
                        width: '20%',
                      },
                      {
                        name: 'key',
                        sortable: true,
                        headerCol: () => <div>Key</div>,
                        col: (record) => <span>{record.key}</span>,
                        width: '20%',
                      },
                      {
                        name: 'value',
                        sortable: false,
                        colClass: (record) => classNames(styles.statusCol, styles[record.state]),
                        headerCol: () => <div>Translation</div>,
                        col: (record) => (
                          <div className={styles.valCol}>
                            <div className={styles.langRow}>
                              <b>DE:</b>
                              <span>{record.de}</span>
                            </div>
                            <div className={styles.langRow}>
                              <b>EN:</b>
                              <span>{record.en}</span>
                            </div>
                            <div className={styles.langRow}>
                              <b>FR:</b>
                              <span>{record.fr}</span>
                            </div>
                          </div>
                        ),
                        width: '60%',
                      },
                    ],
                  }}
                />
              </>
            </TabContent>
          </TabPanel>
        </FormCardBody>
      </FormCard>
    </Page>
  );
}
