import classNames from 'classnames';
import { useCallback, useState } from 'react';

import EditPoup, { EditPopupRow, EditPopupRowUsingDiv } from '../../../../components/edit-popup';
import Info from '../../../../components/info';
import Input from '../../../../components/input';

import RadioButton from '../../../../components/input-radio-button';
import useFormState from '../../../../hooks/useFormState';
import api from '../../../../services/api';
import { Permission, RoleDto, RoleType } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import useEffectAsync from '../../../../utils/useEffectAsync';
import { ROOT_ONLY_PERMISSIONS_CODES, PermissionsGroup, getAllPermissionsGroupsExceptRoot, getRootOnlyPermissionsGroups, getAllPermissionsGroups } from './permissions-groups';
import styles from './role-create-popup.module.scss';
import PermissionGroupsComponent from './permissions-groups-component';

export default function RoleCreatePopup(props: {
  affiliateCode: string;
  canWriteStandard: boolean;
  canWriteIndividual: boolean;
  canWriteRoot: boolean;
  open: boolean;
  save: boolean;
  roleId?: string;
  clientCode?: string;
  clientTitle?: string;
  exitingRoleNames?: string[];
  close: () => void;
  onSubmit: (role: RoleDto) => void;
  dataCy?: string;
}) {
  const { _t } = useBetterTranslate('role-create-page');

  // role
  const [orgRole, setOrgRole] = useState<RoleDto>();

  // Form state
  const formState = useFormState();
  const resetForm = formState.reset;

  //name
  const [nameField, setName] = formState.useInputState({
    label: _t('Rollenname'),
    placeholder: _t('Rollenname hinzufügen'),
    maxLength: 40,
    initialValue: '',
    validate: async (val) => {
      if (!val) return _t('Dieses Feld darf nicht leer sein.');
      val = val.trim();
      if (val.length < 4) {
        return _t('Rollenname muss mindestens 4 Zeichen enthalten.');
      }
      if (val.length > 40) {
        return _t('Rollenname ist länger als 40 Zeichen.');
      }
      if (orgRole && orgRole.name === val) {
        return;
      }
      if (props.exitingRoleNames && props.exitingRoleNames.includes(val)) {
        return _t('Rollenname wird bereits von einer anderen Rolle verwendet.');
      }
      const exist = await api.role.nameExists(props.affiliateCode, { name: val });
      if (exist.data) return _t('Rollenname wird bereits von einer anderen Rolle verwendet.');
    },
  });

  //type
  const [typeField, setType] = formState.useFieldState<RoleType>({
    label: _t('Rollentyp'),

    initialValue: props.canWriteStandard ? RoleType.Standard : RoleType.Root,
    transform: (val) => {
      if (val !== RoleType.Root) {
        const options = permissionsSettings.options.filter((group) => !ROOT_ONLY_PERMISSIONS_CODES.includes(group.code));
        setPermissionsSettings({ ...permissionsSettings, options });
        setSelectedPermissions(selectedPermissions.filter((p) => !!options.find((group) => group.items.map((i) => i.value).includes(p))));
      } else if (!permissionsSettings.options?.find((group) => ROOT_ONLY_PERMISSIONS_CODES.includes(group.code))) {
        const options = getPermissionsGroup([...getRootOnlyPermissionsGroups(), ...permissionsSettings.options]);
        setPermissionsSettings({ ...permissionsSettings, options });
      }
      return val;
    },
  });

  const [allowedPermissions, setAllowedPermissions] = useState<Permission[]>([]);

  const getRoleTypeText = (type: RoleType) => {
    if (type === RoleType.Root) return _t('Root');
    if (type === RoleType.Standard) return _t('Standard');
    if (type === RoleType.Individual) return _t('Individual');
    return type;
  };

  //permissions
  const [permissionsSettings, setPermissionsSettings] = useState<{ options: PermissionsGroup[]; existing?: Permission[] }>({ options: [] });
  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>([]);

  const getPermissionsGroup = useCallback(
    (permissionGroups: PermissionsGroup[]) => {
      permissionGroups.forEach((group) => {
        group.items = group.items.filter((i) => allowedPermissions.includes(i.value));
      });
      return permissionGroups.filter((group) => group.items.length > 0);
    },
    [allowedPermissions]
  );

  useEffectAsync(async () => {
    if (props.affiliateCode) {
      const result = (await api.role.getAllowedPermissions(props.affiliateCode)).data;
      setAllowedPermissions(result.permissions);
    }
  }, [props.affiliateCode]);

  //load data before opening
  const [showPopup, setShowPopup] = useState(props.open);
  const onOpen = useCallback(async () => {
    resetForm();
    if (props.roleId) {
      //edit mode
      const role = (await api.role.get(props.affiliateCode, props.roleId)).data;
      setOrgRole(role);
      setName(role.name);
      setType(role.type);
      let permissionGroups: PermissionsGroup[];
      if (role.type === RoleType.Root) {
        permissionGroups = getPermissionsGroup(getAllPermissionsGroups());
      } else {
        permissionGroups = getPermissionsGroup(getAllPermissionsGroupsExceptRoot());
      }
      setPermissionsSettings({ options: permissionGroups, existing: role.permissions });
      setSelectedPermissions(role.permissions);
    } else {
      //create mode
      setOrgRole(undefined);
      const permissionGroups = getPermissionsGroup(props.canWriteStandard ? getAllPermissionsGroupsExceptRoot() : getAllPermissionsGroups());
      setPermissionsSettings({ options: permissionGroups, existing: undefined });
      setSelectedPermissions([]);
    }
  }, [props.affiliateCode, props.roleId, props.canWriteStandard, resetForm, setName, setType, getPermissionsGroup]);
  useEffectAsync(async () => {
    if (props.open) {
      onOpen();
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [props.open]);

  //submission
  formState.handleSubmit(async () => {
    let role: RoleDto = {
      name: nameField.value.trim(),
      affiliateCode: props.affiliateCode,
      type: typeField.value,
      clientCode: props.clientCode,
      permissions: selectedPermissions,
    };
    if (props.save) {
      if (props.roleId) {
        role = (await api.role.update(props.roleId, role)).data;
      } else {
        role = (await api.role.add(role)).data;
      }
    }
    props.onSubmit(role);
    props.close();
  });

  return (
    <EditPoup
      open={showPopup}
      className={classNames(styles.popup)}
      onClose={props.close}
      onSave={async () => await formState.submit()}
      additionalFooterContentClassName={styles.submitError}
      additionalFooterContent={formState.submitError}
      actionsClassName={styles.actions}
      title={props.roleId ? _t('Rolle Editieren') : props.clientCode ? _t('Individuelle Rolle Erstellen') : _t('Rolle Erstellen')}
      saveText={props.roleId ? _t('Speichern') : _t('Erstellen')}
      dataCy={{ root: props.dataCy, buttonSave: 'btnCreate' }}
    >
      <div className={styles.nameAndClientContainer}>
        <EditPopupRow label={nameField.label} dataCy={{ label: 'txaRoleName' }}>
          <Input {...nameField} className={styles.nameInput} label='' onBlur={() => nameField.validate()} dataCy={{ label: 'inpRoleName', error: 'ermRoleName' }} />
        </EditPopupRow>
        {(props.clientTitle || orgRole?.clientTitle) && (
          <div className={styles.clientTitle} data-cy={'txaClientName'}>
            <div>{props.clientTitle || orgRole?.clientTitle}</div>
          </div>
        )}
      </div>

      {!props.clientCode && (
        <EditPopupRowUsingDiv
          label={
            <div>
              {_t('Rollentyp')}
              <Info
                content={
                  <span className={styles.typeInfo}>
                    <b>{_t('Standard')}</b>: {_t('Dieser Rollentyp kann an Clients vergeben werden.')} <br />
                    <b>{_t('Root')}</b>: {_t('Ist für die Administration von Clients bestimmt und kann nur an Mitarbeiter und Service-Partner von TotalEnergies vergeben werden.')}
                  </span>
                }
                dataCy='infRoleType'
              />
            </div>
          }
          dataCy={{ label: 'txaRoleType', content: 'txaRoleTypeValue' }}
        >
          {!props.roleId && (
            <div className={styles.typeOptions} data-cy='rdoRoleType'>
              <RadioButton
                disabled={!props.canWriteStandard}
                label={getRoleTypeText(RoleType.Standard)}
                isSelected={typeField.value === RoleType.Standard}
                onChange={() => typeField.onChange(RoleType.Standard)}
              />
              <RadioButton
                disabled={!props.canWriteRoot}
                label={getRoleTypeText(RoleType.Root)}
                isSelected={typeField.value === RoleType.Root}
                onChange={() => typeField.onChange(RoleType.Root)}
              />
            </div>
          )}
          {orgRole && <div>{getRoleTypeText(orgRole.type)}</div>}
        </EditPopupRowUsingDiv>
      )}

      <PermissionGroupsComponent
        permissionGroupsOptions={permissionsSettings.options}
        existingPermissions={permissionsSettings.existing}
        value={selectedPermissions}
        formState={formState}
        label={_t('Rollenberechtigung')}
        labelInfo={_t('Wählen Sie die Berechtigungen, die diese Rolle haben soll.')}
        noSelectedPermissionError={_t('Um eine Rolle zu erstellen, wählen Sie bitte mindestens eine Berechtigung aus.')}
        removePermissionsWarning={_t('Diese Berechtigung wird allen Benutzern entzogen, die diese Rolle besitzen.')}
        dataCy=''
        onChange={(permissions: Permission[]) => setSelectedPermissions(permissions)}
      />
    </EditPoup>
  );
}
