import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import styles from './navigatable-categories.module.scss';
import { ReactComponent as ArrowRightIco } from '../assets/scroll-arrow-right.svg';
import { ReactComponent as ArrowLeftIco } from '../assets/scroll-arrow-left.svg';
import React from 'react';
import classNames from 'classnames';

export interface NavigatableCategory {
  id: string;
  title: string;
  items: { id: string; title: string }[];
}

export default function NavigatableCategories(
  props: PropsWithChildren<{
    className?: string;
    categories: NavigatableCategory[];
  }>
) {
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(props.categories.length > 0 ? 0 : NaN);
  const isLeftButtonVisible = selectedCategoryIndex > 0;
  const isRightButtonVisible = selectedCategoryIndex < props.categories.length - 1;

  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    const ele = containerRef.current;
    if (!ele) {
      return;
    }
    const startPos = {
      left: ele.scrollLeft,
      top: ele.scrollTop,
      x: e.clientX,
      y: e.clientY,
    };

    const handleMouseMove = (e: MouseEvent) => {
      e.stopPropagation();
      const dx = e.clientX - startPos.x;
      const dy = e.clientY - startPos.y;
      ele.scrollTop = startPos.top - dy;
      ele.scrollLeft = startPos.left - dx;
      updateCursor(ele);
    };

    const handleMouseUp = (e: MouseEvent) => {
      e.stopPropagation();
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      resetCursor(ele);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, []);

  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    e.stopPropagation();
    const ele = containerRef.current;
    if (!ele) {
      return;
    }
    const touch = e.touches[0];
    const startPos = {
      left: ele.scrollLeft,
      top: ele.scrollTop,
      x: touch.clientX,
      y: touch.clientY,
    };

    const handleTouchMove = (e: TouchEvent) => {
      e.stopPropagation();
      const touch = e.touches[0];
      const dx = touch.clientX - startPos.x;
      const dy = touch.clientY - startPos.y;
      ele.scrollTop = startPos.top - dy;
      ele.scrollLeft = startPos.left - dx;
      updateCursor(ele);
    };

    const handleTouchEnd = (e: TouchEvent) => {
      e.stopPropagation();
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      resetCursor(ele);
    };

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  }, []);

  const updateCursor = (ele: any) => {
    ele.style.cursor = 'grabbing';
    //ele.style.userSelect = 'none';
  };

  const resetCursor = (ele: any) => {
    ele.style.cursor = 'grab';
    //ele.style.removeProperty('user-select');
  };

  const [isGrabbable, setIsGrabbable] = useState<boolean>(false);
  useEffect(() => {
    const totalWidth = containerRef.current?.scrollWidth || 0 - (isLeftButtonVisible ? 24 : 0) + (isRightButtonVisible ? 24 : 0);
    const visibleAreaWidth = containerRef.current?.clientWidth || 0;
    setIsGrabbable(totalWidth > visibleAreaWidth);
  }, [isLeftButtonVisible, isRightButtonVisible]);

  const visibleAreaWidth = containerRef.current?.clientWidth || 0;
  const totalWidth = containerRef.current?.scrollWidth || 0 - (isLeftButtonVisible ? 24 : 0) + (isRightButtonVisible ? 24 : 0);

  // Try to ensure, that a newly selected category is always in the visible area
  // Since it is working with average values, it might fail, if category name lengths variance is very high
  const checkAutoScroll = useCallback(() => {
    if (props.categories.length <= 0) return;
    const scrolledWidth = containerRef.current?.scrollLeft || 0;
    const averageItemWidth = totalWidth / props.categories.length;

    const selectedCategoryEstimatedStart = selectedCategoryIndex * averageItemWidth;
    const selectedCategoryEstimatedEnd = selectedCategoryEstimatedStart + averageItemWidth;

    const visibleAreaStart = scrolledWidth;
    const visibleAreaEnd = scrolledWidth + visibleAreaWidth;

    if (selectedCategoryEstimatedStart < visibleAreaStart || selectedCategoryEstimatedEnd > visibleAreaEnd) {
      if (containerRef.current) {
        // Try to place selected category in the middle of the visible area
        containerRef.current.scrollLeft = selectedCategoryEstimatedStart - (visibleAreaWidth - averageItemWidth) / 2;
      }
    }
  }, [props.categories, selectedCategoryIndex, totalWidth, visibleAreaWidth]);

  useEffect(() => {
    checkAutoScroll();
  }, [checkAutoScroll, selectedCategoryIndex]);

  const selectPrevious = () => {
    setSelectedCategoryIndex(Math.max(0, selectedCategoryIndex - 1));
  };

  const selectNext = () => {
    setSelectedCategoryIndex(Math.min(props.categories.length - 1, selectedCategoryIndex + 1));
  };

  return (
    <div className={classNames(styles.categoriesContainer, props.className ? props.className : undefined)}>
      <div className={styles.categories}>
        {isLeftButtonVisible && (
          <div className={classNames(styles.scrollArrow, styles.arrowLeft)}>
            <ArrowLeftIco onClick={selectPrevious} className={styles.scrollArrow}></ArrowLeftIco>
          </div>
        )}
        <div
          className={classNames(styles.scrollList, isGrabbable ? styles.grabbable : undefined)}
          ref={containerRef}
          onMouseDown={isGrabbable ? handleMouseDown : undefined}
          onTouchStart={isGrabbable ? handleTouchStart : undefined}
        >
          {props.categories.map((cat, i) => (
            <div key={cat.id} className={classNames(i === selectedCategoryIndex ? styles.selectedCategory : undefined)} onClick={() => setSelectedCategoryIndex(i)}>
              <span>
                {cat.title} ({cat.items.length})
              </span>
            </div>
          ))}
        </div>
        {isRightButtonVisible && (
          <div className={classNames(styles.scrollArrow, styles.arrowRight)}>
            <ArrowRightIco onClick={selectNext}></ArrowRightIco>
          </div>
        )}
      </div>
      <div className={styles.itemList}>
        {props.categories[selectedCategoryIndex]?.items.map((i) => (
          <div className={classNames(styles.item, props.categories[selectedCategoryIndex].items.length > 5 ? styles.twoColumns : styles.oneColumn)} key={i.id} title={i.title}>
            {i.title}
          </div>
        ))}
      </div>
    </div>
  );
}
