import EditPoup from '../../../../components/edit-popup';
import { ClientHierarchyNodeTypeDto, ClientStructureDto, ClientStructureNodeDto } from '../../../../services/api-client/csp-api';
import { ButtonPrimary } from '../../../../components/button';
import OrgHierarchyDiagramm, { OrgHierarchyDiagrammSettings, useOrgHiearchyDiagramm } from '../../../../components/org-hierarchy/org-hierarchy-diagramm';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import useBetterTranslate from '../../../../utils/translation-utils';
import { mapClientStructureNode, mapClientStructureNodes } from '../../../../components/org-hierarchy/org-diagramm-utils';
import { ReactComponent as EditNodeIco } from '../../../../assets/node-edit.svg';
import { ReactComponent as AddNodeIco } from '../../../../assets/node-add.svg';
import styles from './structure-edit-hierarchy-popup.module.scss';
import StructureEditNodePopup from '../structure-edit-node-popup/structure-edit-node-popup';
import StructureDeleteNodePopup from '../structure-delete-node-popup/structure-delete-node-popup';
import { Item, Menu, TriggerEvent, useContextMenu } from 'react-contexify';
import { translate } from '../../../../i18n';

type NodeAction = {
  action: (parent: NodeInfo) => void;
  title: string;
};

const _t = translate('structure-edit-popup');

interface ActionHandler {
  onAddNodeClick: (parent: NodeInfo, nodeType: ClientHierarchyNodeTypeDto) => void;
  onEditNodeClick: (node: NodeInfo) => void;
  onDeleteNodeClick: (node: NodeInfo) => Promise<void>;
  onShowMoreAddActions: (node: NodeInfo, addActionItems: NodeAction[], ev: TriggerEvent) => void;
}

function buildAddActionItems(node: ClientStructureNodeDto, handlers: ActionHandler): NodeAction[] {
  const actions = [];
  if (node.type === ClientHierarchyNodeTypeDto.Location && (node.can.manageArea || node.can.manageNode)) {
    actions.push({ action: (node: NodeInfo) => handlers.onAddNodeClick(node, ClientHierarchyNodeTypeDto.Area), title: _t('Bereich hinzufügen') });
  } else if (node.type === ClientHierarchyNodeTypeDto.Region && node.can.manageNode) {
    actions.push({ action: (node: NodeInfo) => handlers.onAddNodeClick(node, ClientHierarchyNodeTypeDto.Location), title: _t('Standort hinzufügen') });
    actions.push({ action: (node: NodeInfo) => handlers.onAddNodeClick(node, ClientHierarchyNodeTypeDto.Area), title: _t('Bereich hinzufügen') });
  } else if (node.type === ClientHierarchyNodeTypeDto.ClientRoot && node.can.manageNode) {
    actions.push({ action: (node: NodeInfo) => handlers.onAddNodeClick(node, ClientHierarchyNodeTypeDto.Region), title: _t('Gebiet hinzufügen') });
    actions.push({ action: (node: NodeInfo) => handlers.onAddNodeClick(node, ClientHierarchyNodeTypeDto.Location), title: _t('Standort hinzufügen') });
    actions.push({ action: (node: NodeInfo) => handlers.onAddNodeClick(node, ClientHierarchyNodeTypeDto.Area), title: _t('Bereich hinzufügen') });
  }
  return actions;
}

const buildEditActionItems = (node: ClientStructureNodeDto, handlers: ActionHandler): NodeAction[] => {
  const editNodeAction = { action: (node: NodeInfo) => handlers.onEditNodeClick(node), title: '' };
  const canEditNode =
    ([ClientHierarchyNodeTypeDto.Region, ClientHierarchyNodeTypeDto.Location, ClientHierarchyNodeTypeDto.Area].includes(node.type as ClientHierarchyNodeTypeDto) &&
      node.can.manageNode) ||
    (node.type === ClientHierarchyNodeTypeDto.Area && node.can.manageArea);
  return canEditNode ? [editNodeAction] : [];
};

function getNodeActions(node: ClientStructureNodeDto, handlers: ActionHandler) {
  type ActionGroup = { onClick: (node: NodeInfo, ev: TriggerEvent) => void; icon: ReactElement };
  const actionGroups: ActionGroup[] = [];

  const addActionItems = node.autoCreated ? [] : buildAddActionItems(node, handlers);
  if (addActionItems.length === 1) {
    actionGroups.push({ icon: <AddNodeIco />, onClick: addActionItems[0].action });
  } else if (addActionItems.length > 1) {
    actionGroups.push({
      icon: <AddNodeIco />,
      onClick: (node, ev) => handlers.onShowMoreAddActions(node, addActionItems, ev),
    });
  }

  const editActionItems = buildEditActionItems(node, handlers);
  if (editActionItems.length === 1) {
    actionGroups.push({ icon: <EditNodeIco />, onClick: editActionItems[0].action });
  }
  return actionGroups;
}

export interface NodeInfo {
  type: ClientHierarchyNodeTypeDto;
  code?: string;
  title: string;
  parentCode?: string;
  cpmsId?: string;
  autoCreated?: boolean;
}
export default function StructureEditHierarchyPopup(props: {
  open: boolean;
  org?: ClientStructureDto;
  onClose: () => void;
  onEditNode: (node: NodeInfo) => Promise<void>;
  onAddNode: (node: NodeInfo) => Promise<void>;
  onDeleteNode: (node: NodeInfo) => Promise<void>;
  getUsersCount: (nodeCode: NodeInfo) => Promise<number>;
}) {
  type NodeContextItem = {
    parent: NodeInfo;
    //data: any;
    actions: NodeAction[];
  };

  const { _t } = useBetterTranslate('structure-edit-popup');

  const [addNodeContextItemCurrentNode, setAddNodeContextItemCurrentNode] = useState<NodeContextItem | null>(null);
  const [showEditNodePopup, setShowEditNodePopup] = useState(false);
  const [showDeleteNodePopup, setShowDeleteNodePopup] = useState(false);
  const [currentNode, setCurrentNode] = useState<NodeInfo>();

  const diagProps = useMemo(() => {
    const settings: OrgHierarchyDiagrammSettings = {
      diagramProps: {
        readonly: true,
        className: styles.diagram,
        nodesSelectable: false,
      },
      clientRootChangable: false,
    };

    return settings;
  }, []);

  let [diagrammProps, diagContext] = useOrgHiearchyDiagramm(diagProps);

  const { show: showAddNodeContextMenuUnFrozen, hideAll: hideAddContextMenu } = useContextMenu({
    id: 'add-node',
  });
  // eslint-disable-next-line
  const showAddNodeContextMenu = useMemo(() => showAddNodeContextMenuUnFrozen, []);

  const actionHandler: ActionHandler = useMemo(() => {
    const result: ActionHandler = {
      onShowMoreAddActions: (node: NodeInfo, addActionItems: NodeAction[], ev: TriggerEvent) => {
        setAddNodeContextItemCurrentNode({ parent: node, actions: addActionItems });
        showAddNodeContextMenu(ev);
      },
      onAddNodeClick: (parent: NodeInfo, nodeType: ClientHierarchyNodeTypeDto) => {
        const node: NodeInfo = {
          type: nodeType,
          code: undefined,
          title: '',
          parentCode: parent.code,
          cpmsId: '',
          autoCreated: false,
        };
        setCurrentNode(node);
        setShowEditNodePopup(true);
      },

      onEditNodeClick: (node: NodeInfo) => {
        setCurrentNode(node);
        setShowEditNodePopup(true);
      },

      onDeleteNodeClick: async (node: NodeInfo) => {
        if (!node.code) return;
        setCurrentNode(node);
        setShowDeleteNodePopup(true);
      },
    };
    return result;
  }, [showAddNodeContextMenu]);

  const editNode = async (node: NodeInfo): Promise<void> => {
    if (node.code) {
      await props.onEditNode(node);
    } else {
      await props.onAddNode(node);
    }
    setShowEditNodePopup(false);
  };

  useEffect(() => {
    if (!props.open) return;
    diagContext.fitToView();
  }, [props.open, diagContext]);

  useEffect(() => {
    if (!props.open) return;
    if (props.org) {
      const clientCode = props.org?.code;
      const accessableMapped = props.org.accessableNodes.map((node) => {
        return mapClientStructureNode(node, clientCode, true, getNodeActions(node, actionHandler));
      });

      const forbiddenMapped = mapClientStructureNodes(props.org.forbiddenParents, clientCode, false);

      const mapped = [...accessableMapped, ...forbiddenMapped];
      diagContext.setItems(mapped);
      diagContext.rebuildNodeTooltip();
    }
  }, [props.open, props.org, diagContext, actionHandler]);

  return (
    <>
      <EditPoup
        open={props.open}
        className={styles.popup}
        onClose={props.onClose}
        bodyClassName={styles.popupBody}
        title={_t('Struktur bearbeiten')}
        titleClassName={styles.title}
        headerClassName={styles.header}
        showFooter={false}
        onClickInside={() => hideAddContextMenu()}
        closeIcon={
          <ButtonPrimary ralign={true} onClick={props.onClose}>
            {_t('Bearbeiten beenden')}
          </ButtonPrimary>
        }
      >
        <OrgHierarchyDiagramm {...diagrammProps} />

        <StructureEditNodePopup
          open={showEditNodePopup}
          org={props.org}
          node={currentNode}
          onCancel={() => setShowEditNodePopup(false)}
          onSubmit={editNode}
          onDelete={() => {
            setShowEditNodePopup(false);
            if (currentNode) actionHandler.onDeleteNodeClick(currentNode);
          }}
          autoCreated={!!currentNode?.autoCreated}
        />

        <StructureDeleteNodePopup
          open={showDeleteNodePopup}
          node={currentNode}
          getUsersCount={props.getUsersCount}
          close={() => setShowDeleteNodePopup(false)}
          deleteNode={props.onDeleteNode}
        />

        {/* <StructureUnassignUsersPopup
          open={showUnassignUsersPopup}
          node={currentNode}
          usersCount={nodeUsersCount}
          onCancel={() => setShowUnassignUsersPopup(false)}
          onSubmit={deleteNode}
        /> */}
      </EditPoup>
      <Menu id={`add-node`} className={styles.contextMenu}>
        {addNodeContextItemCurrentNode &&
          addNodeContextItemCurrentNode.parent &&
          // TODO: add types here
          addNodeContextItemCurrentNode.actions.map((item: any, idx: any) => {
            return (
              <Item key={idx} onClick={() => item.action(addNodeContextItemCurrentNode.parent)}>
                {item.title}
              </Item>
            );
          })}
      </Menu>
    </>
  );
}
