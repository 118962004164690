import DatePicker, { Day, DayRange } from '@amir04lm26/react-modern-calendar-date-picker';
import '@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css';
import { default as classNames, default as classnames } from 'classnames';
import moment, { Moment } from 'moment';
import { PropsWithChildren, ReactNode, useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { ReactComponent as ArrowRightIco } from '../assets/arrow-right.svg';
import useBetterTranslate from '../utils/translation-utils';
import styles from './chart-filter.module.scss';
import { FRENCH_LOCALE, GERMAN_LOCALE } from './date-range-picker';
import { useAuth } from '../utils/AuthProvider';
import { formatter } from '../utils/localized-types';

export const TIME_FILTER_KEYS = ['1M', '14d', '7d', '24h', '12h', '6h', '1h', 'custom'];
export type TimeFilterKey = (typeof TIME_FILTER_KEYS)[number];
interface TimeFilterModel {
  label: string;
  key: TimeFilterKey;
}

export interface ChartFilter {
  from?: string;
  to?: string;
  selectedTimeFilter?: TimeFilterKey;
}

export function ChartTimeFilterItem(props: {
  className?: string;
  onChange?: (props: ChartFilter) => void;
  maxDate?: Moment;
  minDate?: Moment;
  selectedTimeFilter?: TimeFilterKey;
}) {
  const { _t } = useBetterTranslate('chart-filter');
  const { user } = useAuth();
  const languageCode = user?.preferences.languageCode || 'en';

  const getLang = () => {
    return languageCode.toUpperCase();
  };

  const availableFilter: TimeFilterModel[] = [
    { label: _t('Letzten Monat'), key: '1M' },
    { label: _t('Letzte 14 Tage'), key: '14d' },
    { label: _t('Letzte 7 Tage'), key: '7d' },
    { label: _t('Letzte 24 Stunden'), key: '24h' },
    { label: _t('Letzte 12 Stunden'), key: '12h' },
    { label: _t('Letzte 6 Stunden'), key: '6h' },
    { label: _t('Letzte Stunde'), key: '1h' },
    { label: _t('Datum wählen'), key: 'custom' },
  ];

  const MAX_DATE: Day | undefined = props.maxDate ? { year: props.maxDate.year(), month: props.maxDate.month() + 1, day: props.maxDate.date() } : undefined;
  const MIN_DATE: Day | undefined = props.minDate ? { year: props.minDate.year(), month: props.minDate.month() + 1, day: props.minDate.date() } : undefined;

  const onFilterItemChanged = (selected: TimeFilterModel, customRange: DayRange) => {
    if (selected.key === 'custom' && customRange.from && customRange.to) {
      let from = moment({ year: customRange.from.year, month: customRange.from.month - 1, day: customRange.from.day, hour: 12, minute: 0, second: 0 }).startOf('day');
      let to = moment({ year: customRange.to.year, month: customRange.to.month - 1, day: customRange.to.day, hour: 12, minute: 0, second: 0 }).endOf('day');
      props.onChange?.({ from: from.toISOString(), to: to.toISOString(), selectedTimeFilter: selected.key });
    } else {
      props.onChange?.({ from: undefined, to: undefined, selectedTimeFilter: selected.key });
    }
  };

  const [filterVisible, setFilterVisible] = useState(false);
  const [selectedFilterItem, setSelectedFilterItem] = useState(availableFilter.find((item) => item.key === (props.selectedTimeFilter || '24h'))!);
  const [customRangeSelected, setCustomRangeSelected] = useState<DayRange>({
    from: null,
    to: null,
  });

  const toggleVisible = () => setFilterVisible(!filterVisible);

  const formatCustomDate = () => {
    const from = customRangeSelected.from;
    const to = customRangeSelected.to;
    if (!from || !to) return _t(`Datum wählen`);
    const fromDate = formatter.formatDate(new Date(from.year, from.month - 1, from.day), languageCode);
    const toDate = formatter.formatDate(new Date(to.year, to.month - 1, to.day), languageCode);
    return `${_t('von')} ${fromDate} ${_t('bis')} ${toDate}`;
  };

  return (
    <ChartFilterItem
      visible={filterVisible}
      toggleVisible={toggleVisible}
      popOverContent={
        <div className={styles.timeFilterButtonGroup}>
          {availableFilter.map((item) => {
            if (item.key === 'custom') {
              return (
                <DatePicker
                  key={item.key}
                  locale={getLang() === 'DE' ? GERMAN_LOCALE : getLang() === 'FR' ? FRENCH_LOCALE : undefined}
                  value={customRangeSelected}
                  maximumDate={MAX_DATE}
                  minimumDate={MIN_DATE}
                  formatInputText={() => {
                    return formatCustomDate();
                  }}
                  onChange={(newVal) => {
                    if (newVal.from && newVal.to) {
                      setSelectedFilterItem(item);
                      toggleVisible();
                      onFilterItemChanged(item, newVal);
                    }
                    setCustomRangeSelected(newVal);
                  }}
                  calendarRangeStartClassName={styles.dateRangePickerStart}
                  calendarRangeEndClassName={styles.dateRangePickerEnd}
                  calendarRangeBetweenClassName={styles.dateRangePickerBetween}
                  wrapperClassName={styles.dateRangePickerWrapper}
                  inputClassName={classnames(styles.timeFilterButton, selectedFilterItem.key === item.key ? styles.selectedTimeFilter : undefined)}
                  calendarClassName={styles.dateRangePickerRoot}
                />
              );
            } else {
              return (
                <div
                  key={item.key}
                  className={classnames(styles.timeFilterButton, selectedFilterItem.key === item.key ? styles.selectedTimeFilter : undefined)}
                  onClick={() => {
                    if (selectedFilterItem.key !== item.key) {
                      setSelectedFilterItem(item);
                      setCustomRangeSelected({ from: null, to: null });
                      onFilterItemChanged(item, { from: null, to: null });
                      toggleVisible();
                    }
                  }}
                >
                  {item.label}
                </div>
              );
            }
          })}
        </div>
      }
    >
      {selectedFilterItem.key === 'custom' ? formatCustomDate() : selectedFilterItem.label}
    </ChartFilterItem>
  );
}

export function ChartFilterItem(props: PropsWithChildren<{ className?: string; popOverContent?: ReactNode; visible: boolean; toggleVisible: () => void }>) {
  if (props.popOverContent) {
    return (
      <Popover
        isOpen={props.visible}
        onClickOutside={() => props.toggleVisible()}
        positions={['bottom', 'left', 'right']} // preferred positions by priority
        align={'end'}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={'transparent'}
            arrowSize={10}
            arrowStyle={{ opacity: 0.7 }}
            arrowClassName={classNames(styles.popoverArrow)}
          >
            <div className={styles.popoverContent}>{props.popOverContent}</div>
          </ArrowContainer>
        )}
      >
        <div onClick={() => props.toggleVisible()} className={classNames(styles.filterItem, props.visible ? styles.expanded : '')}>
          <span>{props.children}</span>
          <ArrowRightIco />
        </div>
      </Popover>
    );
  } else {
    return (
      <div className={classNames(styles.filterItem)}>
        <span>{props.children}</span>
      </div>
    );
  }
}

export default function ChartFilterBar(props: PropsWithChildren<{ className?: string }>) {
  return <div className={classNames(styles.root, props.className)}>{props.children}</div>;
}
