import classNames from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import ReactJson from 'react-json-view';
import { useDebounce } from 'react-use';
import DataTable, { DataTableRow } from '../../../components/data-table/data-table';
import DateRangePicker from '../../../components/date-range-picker';
import { FormCard, FormCardBody } from '../../../components/form-card';
import Page, { ListingCardPlaceholder } from '../../../components/page';
import Paging from '../../../components/paging';
import SearchInput from '../../../components/search-input';
import SingleSelect from '../../../components/select/single-select';
import { useApi } from '../../../hooks/useApi';
import { usePageParams } from '../../../hooks/usePageParams';
import api, { isHttpResponse } from '../../../services/api';
import { InteractionLogsSortableColumn, InteractionsLogsStatus, Permission, SharedClientDto } from '../../../services/api-client/csp-api';
import { useAuth } from '../../../utils/AuthProvider';
import { formatter } from '../../../utils/localized-types';
import { pagingValidator, validateString } from '../../../utils/queryParamValidators';
import useBetterTranslate from '../../../utils/translation-utils';
import OperationTabPanel from '../shared/operation-tab-panel';
import { InteractionLogPage, allActions, getAction, getPage, parseStringParameterValue } from './interactions-logs-page-tools';
import styles from './interactions-logs-page.module.scss';

type FilterParameters = {
  skip?: number | null;
  limit?: number | null;
  sortBy?: InteractionLogsSortableColumn;
  sortDesc?: string;
  search?: string;
  userMail?: string;
  page?: string;
  action?: string;
  client?: string;
  affiliate?: string;
  fullClient?: string;
  fullClientTitle?: string;
  from?: string;
  to?: string;
};
const jobValidators = {
  // serviceType: validateArrayOfString(Object.values(InteractionsLogsStatus)),
  sortBy: validateString(Object.values(InteractionLogsSortableColumn)),
};

const debounceTime = 800;

type SelectOpts = { name: string; id: string };

const MAX_DATE = moment();
const MIN_DATE = moment().subtract(12, 'months');
const DATE_FORMAT = 'YYYY-MM-DD';

export default function InteractionsLogsPage() {
  const { _t } = useBetterTranslate('ev-interactions-logs-page');

  /**
   * KEEP IT UPDATED with all page you can find on InteractionType first part (see the regex in interactions-logs-page-tools.ts)
   * @param page the code of the page
   * @returns the translated page name
   */
  const getPageText = (page: InteractionLogPage) => {
    // const page = extrafctPageFormType(page);

    const translations: Record<InteractionLogPage, string> = {
      admin: _t('Administration'),
      org: _t('Organisation'),
      charge: _t('Charging Infrastructure'),
      cluster: _t('Load Managment'),
      badge: _t('Charging Badges'),
    };

    return translations[page] || page;
  };

  const { user } = useAuth();
  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>(
    {
      sortBy: InteractionLogsSortableColumn.CreatedAt,
    },
    { ...pagingValidator, ...jobValidators }
  );
  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  const [jobsRes, jobsFetching, jobsErr] = useApi(
    {
      call: async (
        skip?: number | null,
        limit?: number | null,
        sortBy?: string,
        sortDesc?: string,
        search?: string,
        userMail?: string,
        page?: string,
        action?: string,
        client?: string,
        affiliate?: string,
        fullClient?: string,
        from?: string,
        to?: string
      ) => {
        const res = await api.interactionsLogs.getInteractionsLogs({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          sortBy: sortBy as InteractionLogsSortableColumn,
          sortDesc: sortDesc === '1' ? true : undefined,
          search,
          userMail,
          page,
          action,
          client,
          affiliate,
          fullClient,
          from,
          to,
        });

        return res;
      },
      map: (data) => {
        if (!data) return data;
        return data;
      },
    },
    filterParams.skip,
    filterParams.limit,
    filterParams.sortBy,
    filterParams.sortDesc,
    filterParams.search,
    filterParams.userMail,
    filterParams.page,
    filterParams.action,
    filterParams.client,
    filterParams.affiliate,
    filterParams.fullClient,
    filterParams.from,
    filterParams.to
  );

  const getStatusText = (status: InteractionsLogsStatus) => {
    if (status === InteractionsLogsStatus.Unknown) return _t('Unknown');
    if (status === InteractionsLogsStatus.Success) return _t('Success');
    if (status === InteractionsLogsStatus.Failed) return _t('Failed');
    if (status === InteractionsLogsStatus.Partial) return _t('Partial Success');
  };

  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const [userMailSearch, setUserMailSearch] = useState(filterParams.userMail);
  const [clientSearch, setClientSearch] = useState(filterParams.client);
  const [affiliateSearch, setAffiliateSearch] = useState(filterParams.affiliate);
  const [fullClientSearch, setFullClientSearch] = useState<SharedClientDto | null | undefined>(
    filterParams.fullClient && filterParams.fullClientTitle ? { code: filterParams.fullClient, title: filterParams.fullClientTitle, hierarchy: [] } : null
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: parseStringParameterValue(searchTerm) });
    },
    debounceTime,
    [searchTerm, filterParams.search]
  );

  useDebounce(
    () => {
      if (userMailSearch === filterParams.userMail) return;
      setFilterParams({ userMail: parseStringParameterValue(userMailSearch) });
    },
    debounceTime,
    [userMailSearch, filterParams.userMail]
  );

  useDebounce(
    () => {
      if (clientSearch === filterParams.client) return;
      setFilterParams({ client: parseStringParameterValue(clientSearch) });
    },
    debounceTime,
    [clientSearch, filterParams.client]
  );

  useDebounce(
    () => {
      if (affiliateSearch === filterParams.affiliate) return;
      setFilterParams({ affiliate: parseStringParameterValue(affiliateSearch) });
    },
    debounceTime,
    [affiliateSearch, filterParams.affiliate]
  );

  const allPageSelectOptions = Object.values(InteractionLogPage).map((item: InteractionLogPage) => ({ name: getPageText(item), id: item }));
  const [selectedPage, setSelectedPage] = useState<SelectOpts | null>(
    filterParams.page ? allPageSelectOptions.find((item: { id: string | undefined }) => item.id === filterParams.page) ?? null : null
  );

  const allActionSelectOptions = allActions.map((item) => ({ name: item, id: item }));
  const [selectedAction, setSelectedAction] = useState<SelectOpts | null>(
    filterParams.action ? allActionSelectOptions.find((item) => item.id === filterParams.action) ?? null : null
  );

  return (
    <Page
      fetching={jobsFetching}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={jobsErr}
      outOfPlaceHolder={
        <>
          <OperationTabPanel selectedTab='interactions' />
          <FormCard tabletSize='full' className={styles.filterCard}>
            <FormCardBody className={classNames(styles.filters)}>
              <div className={styles.filterArea}>
                <SearchInput placeholder={_t('Search')} maxLength={120} onChange={setSearchTerm} value={searchTerm} dataCy='interaction-global-search' />
              </div>
              <div className={styles.filterArea}>
                <SearchInput placeholder={_t('Search User Mail')} maxLength={120} onChange={setUserMailSearch} value={userMailSearch} dataCy='interaction-user-search' />
              </div>
              <SingleSelect<SelectOpts>
                placeholder={_t('Pages')}
                fetchOptions={async (opt) => {
                  return allPageSelectOptions.filter(
                    (item: { id: string | string[]; name: string }) => item.id.includes(opt.toLowerCase()) || item.name.toLowerCase().includes(opt.toLowerCase())
                  );
                }}
                classNames={styles.selectPages}
                options={allPageSelectOptions}
                createOption={(opt) => <span>{opt.name}</span>}
                renderDisplayValue={(val) => val.name}
                selectedValue={selectedPage}
                onChanged={(selected) => {
                  setSelectedPage(selected ?? null);
                  setFilterParams({ page: selected?.id });
                }}
                isClearable={true}
                dataCy='interaction-dropdown-pages'
              />
              <SingleSelect<SelectOpts>
                placeholder={_t('Actions')}
                fetchOptions={async (opt) => {
                  return allActionSelectOptions.filter((item) => item.id.includes(opt.toLowerCase()) || item.name.toLowerCase().includes(opt.toLowerCase()));
                }}
                classNames={styles.selectActions}
                options={allActionSelectOptions}
                createOption={(opt) => <span>{opt.name}</span>}
                renderDisplayValue={(val) => val.name}
                selectedValue={selectedAction}
                onChanged={(selected) => {
                  setSelectedAction(selected ?? null);
                  setFilterParams({ action: selected?.id });
                }}
                isClearable={true}
                dataCy='interaction-dropdown-actions'
              />
              <div className={styles.filterArea}>
                <SingleSelect<{ id: string; dto: SharedClientDto }>
                  placeholder={_t('Search Client...')}
                  className={styles.select}
                  isClearable={true}
                  createOption={(opt) => {
                    return (
                      <div className={styles.clientOpt}>
                        <b>{opt.dto.title}</b>
                        <i>{opt.id}</i>
                      </div>
                    );
                  }}
                  maxMenuHeight={250}
                  fetchOptions={async (search) => {
                    const res = await api.profile.accessibleClientNodes({ search: search, permissions: Object.values(Permission).map((k) => k as Permission) });
                    if (isHttpResponse(res)) {
                      return res.data.map((r) => ({ id: r.code, dto: r }));
                    }
                    return [];
                  }}
                  options={true}
                  renderDisplayValue={(v) => <>{v.dto.title}</>}
                  selectedValue={fullClientSearch ? { id: fullClientSearch.code, dto: fullClientSearch } : null}
                  onChanged={(val) => {
                    setFullClientSearch(val?.dto ?? null);
                    setFilterParams({ fullClient: val?.id, fullClientTitle: val?.dto?.title });
                  }}
                />
                <SearchInput
                  placeholder={_t('Search affiliate')}
                  maxLength={120}
                  onChange={setAffiliateSearch}
                  value={affiliateSearch}
                  dataCy='interaction-affiliate-search'
                  disabled={!!fullClientSearch}
                />
                <SearchInput
                  placeholder={_t('Search client')}
                  maxLength={120}
                  onChange={setClientSearch}
                  value={clientSearch}
                  dataCy='interaction-client-search'
                  disabled={!!fullClientSearch}
                />
              </div>
              <DateRangePicker
                className={styles.datePicker}
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
                selected={filterParams}
                onChange={(range) => setFilterParams({ ...range })}
                dateFormat={DATE_FORMAT}
                addOneDayToEndDate={true}
                clearable={true}
              />
            </FormCardBody>
          </FormCard>
        </>
      }
    >
      <FormCard tabletSize='full' className=''>
        <FormCardBody>
          <div className={styles.tableArea}>
            <DataTable
              sticky={true}
              records={jobsRes?.intercationLog || []}
              sorting={{
                handler: (col, desc) => setFilterParams({ sortBy: col as InteractionLogsSortableColumn, sortDesc: desc ? '1' : undefined }),
                col: filterParams.sortBy,
                desc: filterParams.sortDesc === '1',
              }}
              renderer={{
                row: (record, cols) => (
                  <DataTableRow classNames={styles.row} key={record._id}>
                    {cols}
                  </DataTableRow>
                ),
                cols: [
                  {
                    headerCol: () => <div>{_t('Created At')}</div>,
                    name: InteractionLogsSortableColumn.CreatedAt,
                    sortable: true,
                    col: (record) => (
                      <div>
                        {formatter.formatDateTimeFromIsoString(record.createdAt, user?.preferences.languageCode, {
                          day: 'numeric',
                          month: 'numeric',
                          year: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </div>
                    ),
                    width: '10%',
                  },
                  {
                    headerCol: () => <div>{_t('Client')}</div>,
                    name: InteractionLogsSortableColumn.Client,
                    sortable: true,
                    col: (record) => <div>{record.client}</div>,
                    width: '10%',
                  },
                  {
                    headerCol: () => <div>{_t('User')}</div>,
                    name: InteractionLogsSortableColumn.UserMail,
                    sortable: true,
                    col: (record) => <div>{record.userMail}</div>,
                    width: '20%',
                  },
                  {
                    headerCol: () => <div>{_t('Page')}</div>,
                    name: 'page',
                    sortable: false,
                    col: (record) => <div>{getPageText(getPage(record.page))}</div>,
                    width: '10%',
                  },
                  {
                    headerCol: () => <div>{_t('Action')}</div>,
                    name: 'action',
                    sortable: false,
                    col: (record) => <div>{getAction(record.interactionType)}</div>,
                    width: '10%',
                  },
                  {
                    headerCol: () => <div>{_t('Data')}</div>,
                    name: 'data',
                    sortable: false,
                    col: (record) => (
                      <div>
                        <ReactJson src={record.data} indentWidth={2} collapsed={true} />
                      </div>
                    ),
                    width: '30%',
                  },
                  {
                    headerCol: () => <div>{_t('Status')}</div>,
                    name: 'status',
                    sortable: false,
                    col: (record) => <div>{getStatusText(record.status ?? InteractionsLogsStatus.Unknown)}</div>,
                    width: '10%',
                  },
                ],
              }}
              dataCy='interaction-log-table'
            />
          </div>
        </FormCardBody>
      </FormCard>

      {jobsRes && (
        <Paging
          skip={jobsRes.skip}
          limit={jobsRes.limit}
          total={jobsRes.total}
          onChange={(arg) => {
            setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
          }}
        />
      )}
    </Page>
  );
}
