import { InteractionPage, InteractionType } from '../../../services/api-client/csp-api';
import _ from 'lodash';

export const regexForInteractionType = /([^-]*)-(.*)/i; // string.match(regexForInteractionType)[1 for the page, 2 for the action]

export const getAction = (item: string) => RegExp(regexForInteractionType).exec(item)?.[2] ?? '';
export const allActions = _.uniq(Object.values(InteractionType).map((item) => getAction(item)));
export const parseStringParameterValue = (item?: string) => (item === '' ? undefined : item);

export enum InteractionLogPage {
  Admin = 'admin',
  Charge = 'charge',
  Org = 'org',
  Cluster = 'cluster',
  Badge = 'badge',
}

export function getPage(interactionPage: InteractionPage): InteractionLogPage {
  return interactionPage === InteractionPage.Admin
    ? InteractionLogPage.Admin
    : interactionPage === InteractionPage.Badge
    ? InteractionLogPage.Badge
    : interactionPage === InteractionPage.Charge
    ? InteractionLogPage.Charge
    : interactionPage === InteractionPage.Cluster
    ? InteractionLogPage.Cluster
    : interactionPage === InteractionPage.Org
    ? InteractionLogPage.Org
    : InteractionLogPage.Org;
}
