import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import Pagingation from '../../components-v2/pagination/pagination';
import { ButtonAccent } from '../../components/button';
import ClusterWorkLoadBox from '../../components/cluster/cluster-workload-box';
import DataTable from '../../components/data-table/data-table';
import { FormCard, FormCardBody } from '../../components/form-card';
import NodeHierarchyLabel from '../../components/org-hierarchy/node-hierarchy-label';
import OrgHierarchyPicker from '../../components/org-hierarchy/org-hierarchy-picker';
import Page, { ListingCardPlaceholder } from '../../components/page';
import Pill from '../../components/pill';
import SearchInput from '../../components/search-input';
import SingleSelect from '../../components/select/single-select';
import colorStyles from '../../components/style-utils';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import { ClusterColumnFrontEnd, ClusterStatus, LoadManagementStrategyGroup, WorkloadLevel } from '../../services/api-client/csp-api';
import { createClientSearchProps } from '../../utils/node-picker-client-search-props';
import { hierarchyValidator, pagingValidator, validateString } from '../../utils/queryParamValidators';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './clusters-list-page.module.scss';

export default function ClusterListPage() {
  const { _t } = useBetterTranslate('clusters-list-page');

  const navigate = useBetterNavigate<FilterParameters>();

  const SORT_BY_VALUES = ['postalCode', 'strategy', 'status', 'workload', 'connectorsCount'];

  type FilterParameters = {
    skip?: number | null;
    limit?: number | null;
    sortBy?: string;
    sortDesc?: string;
    clientCode?: string;
    nodes?: string[];
    search?: string;
    status?: ClusterStatus[];
    strategy?: LoadManagementStrategyGroup[];
    workload?: WorkloadLevel[];
  };

  const sortingValidator = { sortBy: validateString(SORT_BY_VALUES) };
  const validators = { ...pagingValidator, ...hierarchyValidator, ...sortingValidator };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, validators);

  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  const [searchTerm, setSearchTerm] = useState(filterParams.search);

  const [clustersResp, clustersFetching, clustersErr] = useApi(
    {
      call: async (
        clientCode?: string,
        hierarchyNodeCodes?: string[],
        skip?: number | null,
        limit?: number | null,
        status?: ClusterStatus[],
        strategy?: LoadManagementStrategyGroup[],
        workload?: WorkloadLevel[],
        query?: string,
        sortBy?: string,
        sortDesc?: string
      ) => {
        if (!clientCode) return undefined;

        return await api.clusterList.getClusterList(
          {
            limit: Number(limit || 20),
            skip: Number(skip || 0),
            clientCode,
            hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
            status,
            strategy,
            workload,
            searchText: query || undefined,
            sortBy: sortBy as ClusterColumnFrontEnd,
            sortDesc: sortDesc === '1',
          },
          {}
        );
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.nodes,
    filterParams.skip,
    filterParams.limit,
    filterParams.status,
    filterParams.strategy,
    filterParams.workload,
    filterParams.search,
    filterParams.sortBy,
    filterParams.sortDesc
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const clusterStatusOptions = [
    { name: 'active', title: _t('aktiviert'), id: 'active' },
    { name: 'inactive', title: _t('deaktiviert'), id: 'inactive' },
  ];

  const clusterStrategyOptions = [
    { name: 'dynamic', title: _t('dynamisch'), id: 'dynamic' },
    { name: 'static', title: _t('statisch'), id: 'static' },
  ];

  const hierarchy = [...(clustersResp?.clientContext.accessableNodes || []), ...(clustersResp?.clientContext?.forbiddenParents || [])];
  const getNodeCode = (nodePath: string) => {
    const path = !nodePath.endsWith('/') ? nodePath : nodePath.substring(0, nodePath.length - 1);
    return path.substring(path.lastIndexOf('/') + 1);
  };

  const navigateToClusterDetailStationsPage = (clusterId: string) => {
    const link = `/loadmanagement/${filterParams.clientCode}/cluster-stations/${clusterId}`;
    navigate(link);
  };

  return (
    <Page
      breadCrumb={[{ title: 'LASTMANAGEMENT', href: '/loadmanagement', active: true }]}
      fetching={clustersFetching}
      placeHolder={<ListingCardPlaceholder />}
      error={clustersErr}
      className={styles.root}
      outOfPlaceHolder={
        <>
          <OrgHierarchyPicker
            data-id='hierarchy-picker'
            selectMode='node'
            clientContext={clustersResp?.clientContext}
            onNodeSelected={(clientCode, selectedCodes) => {
              if (clientCode !== clustersResp?.clientContext?.code) {
                navigate(`/loadmanagement/${clientCode}`, { nodes: selectedCodes });
                window.location.reload();
              } else {
                setFilterParams({ nodes: selectedCodes });
              }
            }}
            {...createClientSearchProps(clustersResp?.clientContext)}
            selectedNodes={filterParams.nodes}
          />

          <FormCard phoneSize='full' className={classNames(styles.filterArea)}>
            <SearchInput data-id='search-input' maxLength={120} onChange={setSearchTerm} value={searchTerm} className={styles.searchInput} placeholder={_t('Suchen')} />
            <div className={styles.strategySelect} data-id='strategy-select'>
              <SingleSelect<{ name: string; title: string; id: string }>
                className=''
                placeholder={_t('Strategie')}
                options={clusterStrategyOptions}
                createOption={(opt) => <div className={styles.outletNumber}>{opt.title}</div>}
                renderDisplayValue={(opt) => opt.name}
                selectedValue={null}
                fetchOptions={async (txt) => {
                  if (!txt) return clusterStrategyOptions || [];
                  const results = (clusterStrategyOptions || []).filter((item) => item.name.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                  return results;
                }}
                onChanged={(selected) => {
                  if (selected) {
                    if (!filterParams.strategy?.map((strategy) => strategy.toString()).includes(selected?.name)) {
                      const allValues = filterParams.strategy
                        ? [...filterParams.strategy, selected.name as LoadManagementStrategyGroup]
                        : [selected.name as LoadManagementStrategyGroup];
                      setFilterParams({ strategy: allValues });
                    }
                  }
                }}
                isClearable={true}
              />
            </div>
            <div className={styles.statusSelect} data-id='status-select'>
              <SingleSelect<{ name: string; title: string; id: string }>
                className=''
                placeholder={_t('Status')}
                options={clusterStatusOptions}
                createOption={(opt) => <div className={styles.outletNumber}>{opt.title}</div>}
                renderDisplayValue={(val) => val.name}
                selectedValue={null}
                fetchOptions={async (txt) => {
                  if (!txt) return clusterStatusOptions || [];
                  const results = (clusterStatusOptions || []).filter((item) => item.name.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                  return results;
                }}
                onChanged={(selected) => {
                  if (selected) {
                    if (!filterParams.status?.map((status) => status.toString()).includes(selected?.name)) {
                      const allValues = filterParams.status ? [...filterParams.status, selected.name as ClusterStatus] : [selected.name as ClusterStatus];
                      setFilterParams({ status: allValues });
                    }
                  }
                }}
                isClearable={true}
              />
            </div>

            <div className={styles.workloadFilters}>
              <div data-id='medium-workload-filter'>
                <Pill
                  className={styles.pill}
                  selectedClass={colorStyles.components.workloadStatus.medium.active}
                  unselectedClass={colorStyles.components.workloadStatus.medium.inactive}
                  selected={filterParams.workload?.includes(WorkloadLevel.Medium)}
                  onClick={() => {
                    const currentFilters = filterParams.workload || [];
                    const currentFiltersWithoutMedium = [...currentFilters].filter((value) => value !== WorkloadLevel.Medium);
                    currentFilters.includes(WorkloadLevel.Medium)
                      ? setFilterParams({ workload: currentFiltersWithoutMedium })
                      : setFilterParams({ workload: [...currentFilters, WorkloadLevel.Medium] });
                  }}
                >
                  {_t('medium')}
                </Pill>
              </div>
              <div data-id='low-workload-filter'>
                <Pill
                  className={styles.pill}
                  selectedClass={colorStyles.components.workloadStatus.low.active}
                  unselectedClass={colorStyles.components.workloadStatus.low.inactive}
                  selected={filterParams.workload?.includes(WorkloadLevel.Low)}
                  onClick={() => {
                    const currentFilters = filterParams.workload || [];
                    const currentFiltersWithoutLow = [...currentFilters].filter((value) => value !== WorkloadLevel.Low);
                    currentFilters.includes(WorkloadLevel.Low)
                      ? setFilterParams({ workload: currentFiltersWithoutLow })
                      : setFilterParams({ workload: [...currentFilters, WorkloadLevel.Low] });
                  }}
                >
                  {_t('low')}
                </Pill>
              </div>
              <div data-id='high-workload-filter'>
                <Pill
                  className={styles.pill}
                  selectedClass={colorStyles.components.workloadStatus.high.active}
                  unselectedClass={colorStyles.components.workloadStatus.high.inactive}
                  selected={filterParams.workload?.includes(WorkloadLevel.High)}
                  onClick={() => {
                    const currentFilters = filterParams.workload || [];
                    const currentFiltersWithoutHigh = [...currentFilters].filter((value) => value !== WorkloadLevel.High);
                    currentFilters.includes(WorkloadLevel.High)
                      ? setFilterParams({ workload: currentFiltersWithoutHigh })
                      : setFilterParams({ workload: [...currentFilters, WorkloadLevel.High] });
                  }}
                >
                  {_t('high')}
                </Pill>
              </div>
            </div>
          </FormCard>
        </>
      }
    >
      <FormCard phoneSize='full' className={classNames(styles.selectedTags)}>
        {filterParams.status &&
          filterParams.status.length > 0 &&
          filterParams.status.map((item) => {
            const dataId = `${item}-filter-selected`;
            return (
              <div data-id={dataId}>
                <Pill
                  key={item}
                  hasClose={true}
                  className={classNames(styles.appliedTag)}
                  onClick={() => {
                    const remainingValues = filterParams.status?.filter((val) => val !== item);
                    setFilterParams({
                      status: remainingValues,
                    });
                  }}
                >
                  {item === 'active' ? _t('aktiviert') : _t('deaktiviert')}
                </Pill>
              </div>
            );
          })}

        {filterParams.strategy &&
          filterParams.strategy.length > 0 &&
          filterParams.strategy.map((item) => {
            const dataId = `${item}-filter-selected`;
            return (
              <div data-id={dataId}>
                <Pill
                  key={item}
                  hasClose={true}
                  className={classNames(styles.appliedTag)}
                  onClick={() => {
                    const remainingValues = filterParams.strategy?.filter((val) => val !== item);
                    setFilterParams({
                      strategy: remainingValues,
                    });
                  }}
                >
                  {item === 'dynamic' ? _t('dynamisch') : _t('statisch')}
                </Pill>
              </div>
            );
          })}
      </FormCard>

      <FormCard phoneSize='full'>
        <FormCardBody className={styles.gridCardBody}>
          <DataTable
            sticky={true}
            records={clustersResp?.entries || []}
            sorting={{
              handler: (col, desc) => setFilterParams({ sortBy: col, sortDesc: desc ? '1' : undefined }),
              col: filterParams.sortBy,
              desc: filterParams.sortDesc === '1',
            }}
            renderer={{
              cols: [
                {
                  headerCol: () => <div>{_t('Standort')}</div>,
                  name: 'location',
                  sortable: false,
                  col: (record) => (
                    <div data-id='location'>
                      <NodeHierarchyLabel
                        className={styles.wrapLabel}
                        allNodes={hierarchy}
                        code={getNodeCode(record.nodePath)}
                        hideClientRoot={true}
                        hideRegion={true}
                        hideArea={true}
                      />
                    </div>
                  ),
                  headerClass: styles.vcenter,
                  width: '19%',
                },
                {
                  headerCol: () => <div>{_t('Gesellschaft')}</div>,
                  name: 'subsidiary',
                  sortable: false,
                  col: (record) => (
                    <div data-id='subsidiary'>
                      <NodeHierarchyLabel
                        className={styles.wrapLabel}
                        allNodes={hierarchy}
                        code={getNodeCode(record.nodePath)}
                        hideClientRoot={true}
                        hideLocation={true}
                        hideArea={true}
                      />
                    </div>
                  ),
                  headerClass: styles.vcenter,
                  width: '19%',
                },
                {
                  headerCol: () => <div>{_t('PLZ')}</div>,
                  name: 'postalCode',
                  sortable: true,
                  col: (record) => <div data-id='postal-code'>{record.postalCode}</div>,
                  width: '8%',
                },
                {
                  headerCol: () => <div>{_t('Strategie')}</div>,
                  name: 'strategy',
                  sortable: true,
                  col: (record) => <div data-id='load-mgmt-strategy'>{record.loadManagementStrategyGroup === 'dynamic' ? _t('dynamisch') : _t('statisch')}</div>,
                  width: '10%',
                },
                {
                  headerCol: () => <div>{_t('Status')}</div>,
                  name: 'status',
                  sortable: true,
                  col: (record) => <div data-id='load-mgmt-status'>{record.loadManagementStatus === 'active' ? _t('aktiviert') : _t('deaktiviert')}</div>,
                  width: '10%',
                },
                {
                  headerCol: () => <div>{_t('Auslastung')}</div>,
                  name: 'workload',
                  sortable: true,
                  col: (record) => <ClusterWorkLoadBox workloadLevel={record.workload as WorkloadLevel} className={styles.tooltip} />,
                  width: '8%',
                },
                {
                  headerCol: () => <div>{_t('Anzahl Ladepunkte')}</div>,
                  name: 'connectorsCount',
                  sortable: true,
                  col: (record) => <div data-id='connectors-count'>{record.chargePointsTotal}</div>,
                  ralign: true,
                  width: '10%',
                },
                {
                  headerCol: () => <div></div>,
                  name: 'details',
                  sortable: false,
                  col: (record) => (
                    <div data-id='details-btn'>
                      <ButtonAccent className={styles.btnDetails} onClick={() => navigateToClusterDetailStationsPage(record.id)}>
                        {_t('Details')}
                      </ButtonAccent>
                    </div>
                  ),
                  headerClass: styles.vcenter,
                  width: '16%',
                },
              ],
            }}
          />
        </FormCardBody>
      </FormCard>

      {clustersResp && (
        <Pagingation
          skip={clustersResp.skip}
          limit={clustersResp.limit}
          total={clustersResp.total}
          onChange={(arg) => {
            setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
          }}
        />
      )}
    </Page>
  );
}
