import { ReactComponent as StationsIco } from '../../../assets/charge.svg';
// add these when pages implemented
// import { ReactComponent as CardsIco } from '../../../assets/color-card.svg';
// import { ReactComponent as ClustersIco } from '../../../assets/gauge-display.svg';
import { ReactComponent as StructureIco } from '../../../assets/hierarchy-picker.svg';
import { ReactComponent as UsersIco } from '../../../assets/users.svg';
import { FormCard, FormCardBody } from '../../../components/form-card';
import TabPanel, { Tab, Tabs } from '../../../components/tab-panel';
import { useBetterNavigate } from '../../../hooks/useBetterNavigate';
// import { OrganisationViewDto } from '../../../services/api-client/csp-api';
import useBetterTranslate from '../../../utils/translation-utils';
import styles from './operation-tab-panel.module.scss';

export type SelectedTab = 'interactions' | 'ocpp-message' | 'move-stations-to-evp';
export type OperationTabPanelProps = { selectedTab: SelectedTab };
export default function OperationTabPanel(props: OperationTabPanelProps) {
  const navigate = useBetterNavigate<any>();
  const { _t } = useBetterTranslate('operation-tab-panel');
  return (
    <FormCard phoneSize='full' className={styles.operationTab}>
      <FormCardBody>
        <TabPanel>
          <Tabs>
            <Tab active={props.selectedTab === 'ocpp-message'} fillIco={false} txt={_t('Send OCPP message')} ico={<StructureIco />} onClick={() => navigate(`/operations`)}></Tab>

            <Tab
              active={props.selectedTab === 'move-stations-to-evp'}
              fillIco={true}
              txt={_t('Move Stations to EVP')}
              ico={<StationsIco />}
              onClick={() => navigate('/operations/ev-migration')}
            ></Tab>

            <Tab
              active={props.selectedTab === 'interactions'}
              strokeIco={true}
              txt={_t('Interactions logs')}
              ico={<UsersIco />}
              onClick={() => navigate(`/operations/interactions-logs`)}
            ></Tab>
          </Tabs>
        </TabPanel>
      </FormCardBody>
    </FormCard>
  );
}
