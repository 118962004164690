import classNames from 'classnames';
import { Dispatch, SetStateAction, useEffect } from 'react';
import Accordion from '../../../../components/accordion/accordion';
import { EditPopupRow } from '../../../../components/edit-popup';
import { FormCard, FormCardBody } from '../../../../components/form-card';
import { ErrorLabel } from '../../../../components/input';
import CheckBox from '../../../../components/input-check-box';
import { FormField, FormFieldParam } from '../../../../hooks/useFormState';
import { Permission } from '../../../../services/api-client/csp-api';
import styles from './permissions-group-component.module.scss';
import { PermissionItem, PermissionsGroup, getPermissionItem } from './permissions-groups';

export default function PermissionGroupsComponent(props: {
  permissionGroupsOptions: PermissionsGroup[];
  existingPermissions?: Permission[];
  value: Permission[];
  formState: { useFieldState<T>(props: FormFieldParam<T>): [FormField<T>, Dispatch<SetStateAction<T>>] };
  label: string;
  labelInfo: string;
  noSelectedPermissionError: string;
  removePermissionsWarning: string;
  onChange: (permissions: Permission[]) => void;
  dataCy: string;
}) {
  const [permissionsField, setPermissions] = props.formState.useFieldState<PermissionsGroup[]>({
    label: props.label,
    labelInfo: props.labelInfo,
    dataCy: props.dataCy,
    initialValue: [],
    validate: async (val) => {
      if (!val.find((group) => group.items.find((item) => item.selected))) {
        return props.noSelectedPermissionError;
      }
    },
  });
  const [expandedPermissionGroupField, setExpandedPermissionGroup] = props.formState.useFieldState<string>({
    label: '',
    initialValue: '',
  });

  const [removedPermissionsField, setRemovedPermissions] = props.formState.useFieldState<Permission[]>({
    label: '',
    initialValue: [],
  });

  const togglePermissionItem = (permission: PermissionItem) => {
    permission.selected = !permission.selected;
    handleRemovedPermissions(permission.selected, [permission.value]);
    const group = permissionsField.value.find((group) => group.items.find((p) => p.value === permission.value));
    if (group) {
      if (permission.selected) {
        group.selectedCount++;
        group.selected = group.selectedCount === group.items.length;
      } else {
        group.selectedCount--;
        group.selected = false;
      }
    }
    permissionsField.onChange([...permissionsField.value]);
    callOnChange(permissionsField.value);
  };

  const togglePermissionsGroup = (permissionGroup: PermissionsGroup) => {
    permissionGroup.selected = !permissionGroup.selected;
    permissionGroup.items.forEach((item) => (item.selected = permissionGroup.selected));
    permissionGroup.selectedCount = permissionGroup.selected ? permissionGroup.items.length : 0;
    handleRemovedPermissions(
      permissionGroup.selected,
      permissionGroup.items.map((i) => i.value)
    );
    permissionsField.onChange([...permissionsField.value]);
    callOnChange(permissionsField.value);
  };

  const handleRemovedPermissions = (selected: boolean, permissions: Permission[]) => {
    if (props.existingPermissions) {
      if (selected) {
        setRemovedPermissions(removedPermissionsField.value.filter((item) => !permissions.includes(item)));
      } else {
        const removed = props.existingPermissions.filter((p) => permissions.includes(p));
        setRemovedPermissions([...removedPermissionsField.value, ...removed]);
      }
    }
  };

  const callOnChange = (groups: PermissionsGroup[]) => {
    const permissions: Permission[] = [];
    groups.forEach((group) =>
      group.items.forEach((item) => {
        if (item.selected) {
          permissions.push(item.value);
        }
      })
    );
    props.onChange(permissions);
  };

  useEffect(() => {
    if (props.value) {
      props.value.forEach((permission) => {
        const item = getPermissionItem(permission, props.permissionGroupsOptions);
        if (item) item.selected = true;
      });
      props.permissionGroupsOptions.forEach((g) => {
        g.selectedCount = g.items.filter((i) => i.selected).length;
        g.selected = g.selectedCount === g.items.length;
      });
      setPermissions(props.permissionGroupsOptions);
    }
  }, [props.permissionGroupsOptions, props.value, setExpandedPermissionGroup, setPermissions]);

  return (
    <>
      <EditPopupRow label={permissionsField.label} className={styles.permissionsLbl} dataCy={{ label: 'txaRolePermission' }}></EditPopupRow>
      <div className={styles.permissionsGroupList}>
        {permissionsField.value.map((permissionsGroup, idx) => {
          return (
            <FormCard phoneSize='full' key={idx}>
              <FormCardBody>
                <Accordion
                  expanded={expandedPermissionGroupField.value === permissionsGroup.code}
                  headline={
                    <div className={classNames(styles.accordionHeadline)}>
                      <CheckBox
                        autoFocus={true}
                        className={styles.checkbox}
                        label={permissionsGroup.title}
                        isSelected={permissionsGroup.selected}
                        onChange={() => {
                          togglePermissionsGroup(permissionsGroup);
                        }}
                      />
                      <span>
                        {permissionsGroup.selectedCount}/{permissionsGroup.items.length}
                      </span>
                    </div>
                  }
                  onExpand={(expand) => {
                    expandedPermissionGroupField.onChange(expand ? permissionsGroup.code : '');
                  }}
                  dataCy={permissionsGroup.dataCy}
                >
                  <div className={classNames(styles.permissionsGroup, permissionsField.validationErr ? styles.hasValidationError : '')}>
                    {permissionsGroup.items.map((permissionItem, nestedIdx) => {
                      return (
                        <CheckBox
                          key={nestedIdx}
                          className={removedPermissionsField.value.includes(permissionItem.value) ? styles.warning : ''}
                          label={permissionItem.title}
                          subLbl={permissionItem.description}
                          isSelected={permissionItem.selected}
                          onChange={() => {
                            togglePermissionItem(permissionItem);
                          }}
                        />
                      );
                    })}
                  </div>
                </Accordion>
              </FormCardBody>
            </FormCard>
          );
        })}
      </div>
      <div className={styles.permissionMessages}>
        <div className={styles.warning} data-cy='wamAffiliatePermission'>
          {removedPermissionsField.value.length > 0 ? '\u26A0 ' + props.removePermissionsWarning : ' '}
        </div>
        <ErrorLabel dataCy='ermAffiliatePermission'>{permissionsField.validationErr}</ErrorLabel>
      </div>
    </>
  );
}
