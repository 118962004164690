import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../components/button';
import { ApiErrorPanel, LoadingSpinner, PageDesc, PageLayout, PageTitle, SuccessPanel } from '../../../../components/page-layout';
import api from '../../../../services/api';
import { ApiError, ImportClientResourcesResponse } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import { useOnMountUnsafeAsync } from '../../../../utils/useEffectAsync';
import styles from './process-client-import-ressources.page.module.scss';

export interface ProcessClientImportRessourcesPageProps {
  className?: string;
}

export default function ProcessClientImportRessourcesPage(props: ProcessClientImportRessourcesPageProps) {
  const { _t } = useBetterTranslate(`process-client-import-ressources`);
  const { affiliateCode, clientCode } = useParams();
  const [importingError, setImportingError] = useState<ApiError>();
  const navigate = useNavigate();
  const [importResult, setImportResult] = useState<ImportClientResourcesResponse>();

  useOnMountUnsafeAsync(async () => {
    if (!affiliateCode || !clientCode) {
      console.error(`unexpected missing client from state`, { affiliateCode, clientCode });
      return;
    }

    try {
      const resp = await api.client.importClientResources(affiliateCode, clientCode);
      setImportResult(resp.data);
    } catch (e) {
      console.error(`resource import failed`, e);
      setImportingError(e as ApiError);
    }
  });

  const goToClientList = () => {
    // if (!createdClient) return;
    navigate(`/administration/clients/${affiliateCode}`);
  };

  const showSpinner = !importResult && !importingError;
  const showError = importingError;
  const showSuccess = !!importResult && !importingError;

  return (
    <PageLayout className={classNames(styles.root, props.className)}>
      <PageTitle>{_t(`Assign resources from CPMS`)}</PageTitle>
      <PageDesc>{_t(`The resources from the CPMS get assigned to the client.`)}</PageDesc>

      {showSpinner && (
        <LoadingSpinner>
          <span>{_t(`Importing Resources`)}</span>
        </LoadingSpinner>
      )}
      {showError && <ApiErrorPanel apiError={importingError} />}

      {showSuccess && (
        <SuccessPanel>
          <span>{_t(`Assignment of resources successful.`)}</span>
          <span>
            {importResult?.stations.assignedCount || 0} {_t(`stations assigned.`)}
          </span>
          {importResult?.stations.unmodifiedCount > 0 && (
            <span>
              {importResult?.stations.unmodifiedCount} {_t(`stations already assigned.`)}
            </span>
          )}
          {importResult?.stations.unassignedCount > 0 && (
            <span>
              {importResult?.stations.unassignedCount} {_t(`stations unassigned.`)}
            </span>
          )}
          {importResult?.stations.notFoundCount > 0 && (
            <span>
              {importResult?.stations.notFoundCount} {_t(`stations found in the CPMS but not in ECS.`)}
            </span>
          )}
          {importResult?.stations.failedCount > 0 && (
            <span>
              {importResult?.stations.failedCount} {_t(`stations failed.`)}
            </span>
          )}
          {importResult.stations.createdAreaCount !== undefined && importResult.stations.createdAreaCount > 0 && (
            <span>
              {importResult?.stations.createdAreaCount} {_t(`area created.`)}
            </span>
          )}
          {importResult?.stations.deletedAreaCount !== undefined && importResult?.stations.deletedAreaCount > 0 && (
            <span>
              {importResult?.stations.deletedAreaCount} {_t(`area deleted.`)}
            </span>
          )}

          {importResult?.cards && (
            <>
              <span>
                {importResult.cards.assignedCount || 0} {_t(`badges assigned.`)}
              </span>
              {importResult.cards.unassignedCount > 0 && (
                <span>
                  {importResult?.cards.unassignedCount || 0} {_t(`badges unassigned.`)}
                </span>
              )}
              {importResult?.cards.failedCount > 0 && (
                <span>
                  {importResult?.cards.failedCount} {_t(`badges failed.`)}
                </span>
              )}
            </>
          )}
          <Button onClick={() => goToClientList()} kind='primary'>
            Go to client list
          </Button>
        </SuccessPanel>
      )}
    </PageLayout>
  );
}
