import classNames from 'classnames';
import { useCallback, useState } from 'react';

import { ReactComponent as IndividualIco } from '../../../../assets/create-individual-role.svg';
import EditPoup, { EditPopupRow, EditPopupRowUsingDiv } from '../../../../components/edit-popup';
import Input, { ErrorLabel } from '../../../../components/input';
import CheckBox from '../../../../components/input-check-box';
import useFormState from '../../../../hooks/useFormState';
import api from '../../../../services/api';
import { ClientCpmsIdCheck, ClientDto, ClientIndividualRoleDto, Permission, RoleDto, RoleType } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import useEffectAsync from '../../../../utils/useEffectAsync';
import RoleCreatePopup from '../../roles/role-create-popup/role-create-popup';
import styles from './client-create-popup.module.scss';
import { ReactComponent as InfoIco } from '../../../../assets/info.svg';
import { PermissionsGroupsOfRole, getPermissionGroupStructure } from '../../roles/role-create-popup/permissions-groups';
import RolePermissionsPopup from '../../../../components/role-permissions-popup';

interface RoleItem {
  id: string;
  name: string;
  selected: boolean;
  type: RoleType;
}

interface ClientCreatePopupDataCy {
  root?: string;
  title?: string;
  buttonClose?: string;
  buttonSave?: string;
  buttonCancel?: string;
}

export default function ClientCreatePopup(props: {
  open: boolean;
  affiliateCode: string;
  clientCode?: string;
  canWriteIndividual: boolean;
  close: () => void;
  onSubmit: () => void;
  dataCy?: ClientCreatePopupDataCy;
  getPermissionsOfRole: (roleId: string) => Promise<Permission[]>;
}) {
  // Client
  const [orgClient, setOrgClient] = useState<ClientDto>();

  const { _t } = useBetterTranslate('client-create-popup');

  // Form state
  const formState = useFormState();
  const resetForm = formState.reset;

  //title
  const [titleField, setTitle] = formState.useInputState({
    label: _t('Kundenname'),
    placeholder: _t('Firmennamen hinzufügen'),
    maxLength: 100,

    initialValue: '',
    validate: async (val) => {
      if (!val) return _t('Dieses Feld darf nicht leer sein.');
      val = val.trim();
      if (val.length === 0) {
        return _t('Dieses Feld darf nicht leer sein.');
      }
      if (val.length > 100) {
        return _t(`Kundenname darf nicht länger als 100 Zeichen sein.`);
      }
      if (orgClient && orgClient.title === val) {
        return;
      }
      const exist = await api.client.titleExists(props.affiliateCode, { title: val });
      if (exist.data) return _t(`Kundenname ist bereits vergeben.`);
    },
  });

  //code
  const [codeField, setCode] = formState.useInputState({
    label: _t('Code'),
    labelInfo: _t('Der Code wird als eindeutige Kennung im System verwendet.'),
    dataCy: 'infCode',
    placeholder: _t('Code'),
    maxLength: 10,

    initialValue: '',
    validate: async (val) => {
      if (orgClient) {
        return;
      }
      if (!val) return _t('Dieses Feld darf nicht leer sein.');

      if (val.length > 10) {
        return _t(`Code darf nicht länger als 10 Zeichen sein.`);
      }

      if (val === 'ROOT') {
        return _t(`Code ist bereits vergeben.`); //reserved word
      }
      const code = `${props.affiliateCode}-${val}`;
      const exist = await api.client.codeExists(props.affiliateCode, { code });
      if (exist.data) return _t(`Code ist bereits vergeben.`);
    },
    transform: (val) => {
      if (!val) return val;
      return val.replace(/[^a-z0-9]/gi, '').toUpperCase();
    },
  });

  //cpms
  const [cpmsField, setCpms] = formState.useInputState({
    label: _t('Unternehmen-ID'),
    labelInfo: _t('Unternehmen-ID muss die korrekte Firmen-ID von HTB oder die Org-Id von EVP sein, um die richtige Hierarchie zu übertragen.'),
    dataCy: 'infCompanyId',
    placeholder: _t('Unternehmen-ID'),
    maxLength: 50,

    initialValue: '',
    validate: async (val) => {
      if (!val) return undefined;

      if (orgClient) {
        return;
      }
      const response = await api.client.cpmsIdValidate(props.affiliateCode, { cpmsId: val });
      if (response.data.result === ClientCpmsIdCheck.ALREADY_USED_BY_ANOTHER_CLIENT) {
        return _t(`Unternehmen-ID ist bereits vergeben.`);
      } else if (response.data.result === ClientCpmsIdCheck.NOT_FOUND_AT_ANY_CPMS) {
        return _t(`Unternehmen-ID ist weder in HTB noch in EVP vorhanden.`);
      }
    },
    transform: (val) => {
      if (!val) return val;
      return val.trim();
    },
  });

  //roles
  const [stdRolesField, setStdRoles] = formState.useFieldState<RoleItem[]>({
    label: _t('Rollen'),
    labelInfo: _t('Wählen Sie die Rollen, die Sie diesem Unternehmen hinzufügen möchten.'),
    dataCy: 'infRoles',
    initialValue: [],
    validate: async (val) => {
      if (indRoles.length === 0 && !val.find((item) => item.selected)) {
        if (orgClient) {
          return _t('Um die Änderung am Unternehmen zu speichern, müssen Sie mindestens eine Rolle auswählen.');
        }
        return _t('Um das Unternehmen zu erstellen, müssen Sie mindestens eine Rolle auswählen.');
      }
    },
  });
  const [unselectedStdRolesField, setUnselectedStdRolesField] = formState.useFieldState<string[]>({
    label: '',
    initialValue: [],
  });

  const [indRoles, setIndRoles] = useState<ClientIndividualRoleDto[]>([]);

  const toggleRoleItem = (role: RoleItem) => {
    role.selected = !role.selected;
    if (role.selected) {
      setUnselectedStdRolesField(unselectedStdRolesField.value.filter((item) => item !== role.id));
    } else if (orgClient) {
      if (orgClient.standardRoleIds.find((r) => r === role.id)) {
        setUnselectedStdRolesField([...unselectedStdRolesField.value, role.id]);
      }
    }
    stdRolesField.onChange([...stdRolesField.value]);
  };

  const [showPopup, setShowPopup] = useState(props.open);

  const onOpen = useCallback(async () => {
    resetForm();
    const res = await api.client.listRoles({
      type: RoleType.Standard,
      affiliateCode: props.affiliateCode,
    });
    const roleItems: RoleItem[] = res.data.map((role) => {
      return { id: role.id || '', name: role.name, selected: false, type: role.type };
    });
    if (props.clientCode) {
      //edit mode
      const client = (await api.client.get(props.affiliateCode, props.clientCode)).data;
      setOrgClient(client);
      setTitle(client.title);
      setCode(client.code);
      setCpms(client.cpmsId || '');
      for (const roleItem of roleItems) {
        if (client.standardRoleIds.includes(roleItem.id)) {
          roleItem.selected = true;
        }
      }
      setIndRoles(client.individualRoles || []);
    } else {
      setOrgClient(undefined);
      setIndRoles([]);
    }
    setStdRoles(roleItems);
  }, [props.affiliateCode, props.clientCode, resetForm, setStdRoles, setOrgClient, setTitle, setCode, setCpms, setIndRoles]);

  useEffectAsync(async () => {
    if (props.open) {
      formState.reset();
      await onOpen();
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [props.open]);

  const [showAddRolePopup, setShowAddRolePopup] = useState(false);
  const onAddRoleClick = () => {
    setShowAddRolePopup(true);
  };

  const [currentPermissionGroupOfRole, setCurrentPermissionGroupOfRole] = useState<PermissionsGroupsOfRole>();
  const onRolesPermissionsClick = async (role: RoleItem) => {
    const permissions = await props.getPermissionsOfRole(role.id);
    const permissionGroups = getPermissionGroupStructure(permissions);
    setCurrentPermissionGroupOfRole({ roleId: role.id || '', roleName: role.name || '', roleType: role.type as RoleType, permissionsGroups: permissionGroups });
  };

  //submission
  formState.handleSubmit(async () => {
    const standardRoleIds: string[] = [];
    stdRolesField.value?.forEach((item) => {
      if (item.selected) {
        standardRoleIds.push(item.id);
      }
    });
    const code = props.clientCode || `${props.affiliateCode}-${codeField.value}`;
    let client = {
      title: titleField.value.trim(),
      code,
      affiliateCode: props.affiliateCode,
      cpmsId: cpmsField.value,
      standardRoleIds,
      individualRoles: indRoles,
    };
    if (props.clientCode) {
      await api.client.update(client);
    } else {
      await api.client.add(client);
    }
    props.onSubmit();
    props.close();
  });

  return (
    <EditPoup
      open={showPopup}
      className={styles.popup}
      skipAutoFocusOnOpen={false}
      onClose={props.close}
      onSave={async () => await formState.submit()}
      additionalFooterContentClassName={styles.submitError}
      additionalFooterContent={formState.submitError}
      bodyClassName={styles.popupBody}
      title={props.clientCode ? _t('Kunde Editieren') : _t('Kunde Erstellen')}
      saveText={props.clientCode ? _t('Speichern') : _t('Erstellen')}
      dataCy={{
        root: props.dataCy?.root,
        title: props.dataCy?.title || 'popTitle',
        buttonClose: props.dataCy?.buttonClose || 'btnClose',
        buttonSave: props.dataCy?.buttonSave,
        buttonCancel: props.dataCy?.buttonCancel || 'btnCancel',
      }}
    >
      <EditPopupRow label={titleField.label} dataCy={{ label: 'txaClientName' }}>
        <Input {...titleField} label='' onBlur={() => titleField.validate()} dataCy={{ label: 'inpClientName', error: 'ermClientName' }} />
      </EditPopupRow>

      <EditPopupRow label={codeField.label} dataCy={{ label: 'txaCode', content: 'txaClientCodeValue' }}>
        {!props.clientCode && (
          <div>
            <span>{props.affiliateCode}-</span>
            <Input {...codeField} label='' onBlur={() => codeField.validate()} dataCy={{ label: 'inpCode', error: 'ermCode' }} />
          </div>
        )}
        {orgClient && <div>{orgClient.code}</div>}
      </EditPopupRow>

      {!props.clientCode && (
        <EditPopupRow label={cpmsField.label} dataCy={{ label: 'txaCompanyId' }}>
          <Input {...cpmsField} label='' onBlur={() => cpmsField.validate()} dataCy={{ label: 'inpCompanyId', error: 'ermCompanyId' }} />
        </EditPopupRow>
      )}

      <EditPopupRowUsingDiv label={stdRolesField.label} controlCassName={styles.rolesRow} dataCy={{ label: 'txaRoles' }}>
        <div className={styles.rolesWrapper} data-cy={'lstRoles'}>
          <div className={classNames(styles.roles, styles.standard, stdRolesField.validationErr ? styles.hasValidationError : '')}>
            {stdRolesField.value.map((role, idx) => {
              return (
                <CheckBox
                  key={idx}
                  className={unselectedStdRolesField.value.includes(role.id) ? styles.warning : ''}
                  label={
                    <>
                      <span>{role.name}</span>
                      <span className={styles.infoIco}>
                        <InfoIco
                          onClick={async () => {
                            await onRolesPermissionsClick(role);
                          }}
                        ></InfoIco>
                      </span>
                    </>
                  }
                  isSelected={role.selected}
                  onChange={() => {
                    toggleRoleItem(role);
                  }}
                  preventDefaultToClickLabel={true}
                />
              );
            })}
          </div>
          <div className={styles.rolesMessages} data-cy='ermRoles'>
            <div className={styles.warning} data-cy='wamRolePermission'>
              {unselectedStdRolesField.value.length > 0 ? '\u26A0 ' + _t('Die Rolle wird allen Usern entzogen, die diese Rolle besitzen.') : ' '}
            </div>

            <ErrorLabel showWarnSign={true}>{stdRolesField.validationErr}</ErrorLabel>
          </div>
        </div>
      </EditPopupRowUsingDiv>

      {props.clientCode && (
        <>
          <EditPopupRowUsingDiv label={_t('Individuelle Rollen')} controlCassName={styles.rolesRow} dataCy={{ label: 'txaIndividualRoles' }}>
            <div className={styles.rolesWrapper}>
              <div className={classNames(styles.roles, styles.individual)} data-cy={'lstIndividualRoles'}>
                {indRoles.map((role, idx) => {
                  return <div key={idx}>{role.name}</div>;
                })}
              </div>
              {props.canWriteIndividual && (
                <div className={classNames(styles.IndividualHint)} onClick={onAddRoleClick} data-cy={'btnCreateIndRole'}>
                  <div>
                    <IndividualIco />
                    <span>{_t('Individuelle Rolle für den Kunden erstellen')}</span>
                  </div>
                </div>
              )}
            </div>
          </EditPopupRowUsingDiv>
        </>
      )}

      <RoleCreatePopup
        affiliateCode={props.affiliateCode}
        open={showAddRolePopup}
        save={false}
        canWriteIndividual={true}
        canWriteRoot={true}
        canWriteStandard={true}
        clientCode={codeField.value}
        clientTitle={titleField.value || orgClient?.title}
        exitingRoleNames={indRoles.map((role) => role.name)}
        close={() => setShowAddRolePopup(false)}
        onSubmit={(role: RoleDto) => indRoles.push({ name: role.name, description: role.description, permissions: role.permissions })}
        dataCy='popCreateIndividualRole'
      />
      <RolePermissionsPopup
        open={!!currentPermissionGroupOfRole?.roleId}
        permissionsGroups={currentPermissionGroupOfRole?.permissionsGroups || []}
        onClose={() => {
          setCurrentPermissionGroupOfRole(undefined);
        }}
        roleType={currentPermissionGroupOfRole?.roleType}
        roleName={currentPermissionGroupOfRole?.roleName}
      />
    </EditPoup>
  );
}
