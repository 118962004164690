import { FormCard, FormCardBody } from '../../components/form-card';
import Page from '../../components/page';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './operations-page.module.scss';
import Button from '../../components/button';
import Input from '../../components/input';
import { useState } from 'react';
import api from '../../services/api';
import useFormState from '../../hooks/useFormState';
import SingleSelect from '../../components/select/single-select';
import { msgTemplates } from './ocpp-message-templates/templates';
import OperationTabPanel from './shared/operation-tab-panel';

export default function OperationsPage() {
  const { _t } = useBetterTranslate('operations-page');

  /*********** Send OCPP messages ***********************/

  const MAX_LINES_TEMPLATE_TEXTAREA = 7;
  const sendOcppMessageForm = useFormState({
    submitHandler: async () => {
      try {
        const response = await api.operationsStations.sendOcppMessage(chargeBoxId.value, { operation: ocppAction.value || '', message: ocppMessage.value }, {});
        alert(`Station response: ${response.data.status}`);
      } catch (e: any) {
        if (e.error && e.error.statusCode === 400) alert('Bad request');
        else if (e.error && e.error.statusCode === 404) alert('Station is offline');
        else alert('Unknown error');
      }
    },
  });

  const [chargeBoxId] = sendOcppMessageForm.useInputState({
    label: 'chargeBoxId',
    initialValue: '',
    validate: async (val) => {
      if (!val) return _t('This field should not be empty.');
    },
    maxLength: 100,
  });
  const [ocppMessage, setOcppMessage] = sendOcppMessageForm.useInputState({ label: 'OCPP message', initialValue: '', maxLength: 1000 });
  const [ocppAction, setOcppAction] = sendOcppMessageForm.useInputState({ label: 'OCPP action', initialValue: '', maxLength: 100 });
  const [selectedTemplate, setSelectedTemplate] = useState<{ name: string; id: string } | undefined>(undefined);

  const ocppMsgTemplateOptions = [
    { name: 'Set charging profile', id: 'SetChargingProfile' },
    { name: 'Clear charging profile', id: 'ClearChargingProfile' },
    { name: 'Start remote transaction', id: 'RemoteStartTransaction' },
    { name: 'Stop remote transaction', id: 'RemoteStopTransaction' },
    { name: 'Reserve a chargepoint', id: 'ReserveNow' },
    { name: 'Cancel reservation', id: 'CancelReservation' },
    { name: 'Reset station', id: 'Reset' },
    { name: 'Unlock connector', id: 'UnlockConnector' },
    { name: 'Change availability', id: 'ChangeAvailability' },
    { name: 'Change configuration', id: 'ChangeConfiguration' },
    { name: 'Trigger status notification', id: 'TriggerStatusNotification' },
    { name: 'Trigger boot notification', id: 'TriggerBootNotification' },
    { name: 'Trigger heartbeat', id: 'TriggerHeartbeat' },
    { name: 'Trigger meter values', id: 'TriggerMeterValues' },
    { name: 'Trigger diagnostics status notification', id: 'TriggerDiagnosticsStatusNotification' },
    { name: 'Trigger firmware status notification', id: 'TriggerFirmwareStatusNotification' },
  ];

  const setMsgTemplate = (msgId: string) => {
    const msg = msgTemplates.find((msg) => msg.id === msgId);
    const option = ocppMsgTemplateOptions.find((opt) => opt.id === msgId);
    setSelectedTemplate(option);
    setOcppAction(msg?.ocppAction || '');
    setOcppMessage(msg?.template || '');
  };

  const calcHeight = () => {
    let numLines = ocppMessage.value.split('\n').length;
    if (numLines > MAX_LINES_TEMPLATE_TEXTAREA) numLines = MAX_LINES_TEMPLATE_TEXTAREA;
    return `${numLines * 1.6}em`;
  };

  /*********** Send OCPP messages end ********************/

  return (
    <Page breadCrumb={[{ title: _t('Operations'), href: '/operations', active: true }]} className={styles.root} outOfPlaceHolder={<OperationTabPanel selectedTab='ocpp-message' />}>
      <FormCard tabletSize='full'>
        <FormCardBody>
          <h4>{_t('Send OCPP message to a station')}</h4>
          <Input className={styles.chBoxIdInput} {...chargeBoxId} onBlur={() => chargeBoxId.validate()} />

          <div className={styles.selectOcppTemplate}>
            <SingleSelect<{ name: string; id: string }>
              placeholder={_t('Select OCPP message template')}
              options={ocppMsgTemplateOptions}
              createOption={(opt) => <div className={styles.option}>{opt.name}</div>}
              renderDisplayValue={(opt) => opt.name}
              selectedValue={selectedTemplate ? selectedTemplate : null}
              fetchOptions={async (txt) => {
                if (!txt) return ocppMsgTemplateOptions || [];
                const results = (ocppMsgTemplateOptions || []).filter((item) => item.name.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                return results;
              }}
              onChanged={(selected) => {
                if (selected) {
                  setMsgTemplate(selected.id);
                }
              }}
              isClearable={false}
            />
          </div>
          <Input className={styles.ocppAction} {...ocppAction} />

          <label className={styles.ocppMsgLbl}>{ocppMessage.label}</label>
          <textarea style={{ height: calcHeight() }} className={styles.ocppMsgInput} {...ocppMessage} onChange={(e) => ocppMessage.onChange(e.currentTarget.value)} />

          <Button className={styles.btnS} onClick={() => sendOcppMessageForm.submit()}>
            {_t('Send OCPP message')}
          </Button>
        </FormCardBody>
      </FormCard>
    </Page>
  );
}
