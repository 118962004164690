import classNames from 'classnames';
import { useRef, useState } from 'react';
import EditPoup from '../../../../components/edit-popup';
import CheckBox from '../../../../components/input-check-box';
import { OrgUserRoleOptionDto, Permission, RoleType, SharedClientContext } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import useEffectAsync from '../../../../utils/useEffectAsync';
import OrgUserHierarchyAssignor from '../user-hierarchy-assignor/org-user-hierarchy-assignor';
import styles from './org-user-add-roles-popup.module.scss';
import { ReactComponent as InfoIco } from '../../../../assets/info.svg';
import RolePermissionsPopup from '../../../../components/role-permissions-popup';
import { PermissionsGroupsOfRole, getPermissionGroupStructure } from '../../../administration/roles/role-create-popup/permissions-groups';

interface RoleItem {
  id: string;
  name: string;
  selected: boolean;
  disabled: boolean;
  type: RoleType;
}

export default function OrgUserAddRolesPopup(props: {
  open: boolean;
  fullName: string;
  clientContext?: SharedClientContext;
  clientRoles?: OrgUserRoleOptionDto[];
  disabledRoleIds?: string[];
  onCancel: () => void;
  onSubmit: (roleIds: string[], nodeIds: string[]) => Promise<void>;
  getPermissionsOfRole: (roleId: string) => Promise<Permission[]>;
}) {
  const { _t } = useBetterTranslate('org-user-add-roles-popup');
  const [roles, setRoles] = useState<RoleItem[]>([]);
  const toggleRoleItem = (role: RoleItem) => {
    role.selected = !role.selected;
    setRoles([...roles]);
    validateRoles();
  };

  const [isRolesValid, setIsRolesValid] = useState<boolean>(true);
  const [nodesError, setNodesError] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();
  const hierarchySelectorRef = useRef(null);

  useEffectAsync(async () => {
    if (props.open) {
      if (props.clientRoles) {
        setRoles(
          props.clientRoles?.map((role) => {
            return { id: role.id || '', name: role.name, selected: false, disabled: props.disabledRoleIds?.includes(role.id) || false, type: role.type };
          })
        );
      }
      setIsRolesValid(true);
      setNodesError(undefined);
      setSubmitError(undefined);
    }
  }, [props.open]);
  //submission

  const validateRoles = (): boolean => {
    const isValid = !!roles.find((item) => item.selected);
    setIsRolesValid(isValid);
    return isValid;
  };

  const validateNodes = (): boolean => {
    const nodeCodes = (hierarchySelectorRef.current as any).getSelectedNodes();
    const isValid = nodeCodes.length > 0;
    setNodesError(isValid ? undefined : '\u26A0 ' + _t('Es muss mindestens ein Knoten ausgewählt werden.'));
    return isValid;
  };

  const submit = async () => {
    const isRolesValid = validateRoles();
    const isNodesValid = validateNodes();
    if (!isRolesValid || !isNodesValid) {
      return;
    }
    const roleIds = roles.filter((item) => item.selected).map((item) => item.id);
    const nodeCodes = (hierarchySelectorRef.current as any).getSelectedNodes();
    try {
      await props.onSubmit(roleIds, nodeCodes);
    } catch (error) {
      setSubmitError(_t('Ein unerwarteter Fehler ist aufgetreten.'));
    }
  };

  const [currentPermissionGroupOfRole, setCurrentPermissionGroupOfRole] = useState<PermissionsGroupsOfRole>();
  const onRolesPermissionsClick = async (role: RoleItem) => {
    const permissions = await props.getPermissionsOfRole(role.id);
    const permissionGroups = getPermissionGroupStructure(permissions);
    setCurrentPermissionGroupOfRole({ roleId: role.id || '', roleName: role.name || '', roleType: role.type as RoleType, permissionsGroups: permissionGroups });
  };

  return (
    <EditPoup
      open={props.open}
      className={styles.popup}
      onClose={props.onCancel}
      onSave={async () => await submit()}
      additionalFooterContentClassName={styles.submitError}
      additionalFooterContent={submitError}
      bodyClassName={styles.popupBody}
      footerClassName={styles.footer}
      actionsClassName={styles.actions}
      title={_t('Rollenberechtigung hinzufügen')}
      saveText={_t('Speichern')}
    >
      <div className={styles.rolesSection}>
        <div className={styles.fullName}>{props.fullName}</div>
        <div className={styles.roles}>
          <div className={styles.label}>
            <b>{_t('Rollen Berechtigung')}</b>

            <span className={!isRolesValid ? styles.hasError : ''}>
              {!isRolesValid ? '\u26A0 ' : ''}
              {_t('Es muss mindestens ein Rolle ausgewählt werden.')}
            </span>

            <span>{_t('Alle ausgewählten Rollen werden auf die unten gewählten Knoten angewendet!')}</span>
          </div>
          <div className={classNames(styles.ctrl, !isRolesValid ? styles.hasValidationError : '')}>
            {roles.map((role, idx) => {
              return (
                <CheckBox
                  key={idx}
                  label={
                    <>
                      <span>{role.name}</span>
                      <span className={styles.infoIco}>
                        <InfoIco
                          onClick={async () => {
                            await onRolesPermissionsClick(role);
                          }}
                        ></InfoIco>
                      </span>
                    </>
                  }
                  isSelected={role.selected}
                  disabled={role.disabled}
                  onChange={() => {
                    if (!role.disabled) toggleRoleItem(role);
                  }}
                  preventDefaultToClickLabel={true}
                />
              );
            })}
          </div>
        </div>
      </div>
      <OrgUserHierarchyAssignor
        ref={hierarchySelectorRef}
        open={props.open}
        nodesSelectable={true}
        clientContext={props.clientContext}
        errorMsg={nodesError}
        onChange={validateNodes}
      />
      <RolePermissionsPopup
        open={!!currentPermissionGroupOfRole?.roleId}
        permissionsGroups={currentPermissionGroupOfRole?.permissionsGroups || []}
        onClose={() => {
          setCurrentPermissionGroupOfRole(undefined);
        }}
        roleType={currentPermissionGroupOfRole?.roleType}
        roleName={currentPermissionGroupOfRole?.roleName}
      />
    </EditPoup>
  );
}
