import classNames from 'classnames';
import { ReactComponent as CreateTicketIcon } from '../../assets/file-plus.svg';
import Button from '../../components/button';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './create-ticket.page.module.scss';

export function CreateServiceTicketAction() {
  const { _t } = useBetterTranslate(`create-ticket-action`);
  return (
    <Button
      onClick={() => {
        window.open(process.env.SERVICE_REQUEST_URL || 'https://totalenergies.de/erneuerbare-energien/elektromobilitaet/kunden-service-formular', '_blank');
      }}
      dataCy='createServiceTicket'
      className={classNames(styles.createTicketBtn)}
    >
      <CreateTicketIcon className={classNames(styles.ico)} />
      <span>{_t(`Create Service Ticket`)}</span>
    </Button>
  );
}
