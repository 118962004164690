import classNames from 'classnames';
import * as _ from 'lodash';
import { PropsWithChildren } from 'react';
import ifTrue from '../../utils/class-name';
// import ChangeAnimator from './change-animator';
import styles from './donut-chart.module.scss';

export default function DonutChart(props: PropsWithChildren<{ className?: string; counterClockWise?: boolean; items: { value: number; color: string }[] }>) {
  let prev = 0;
  let gradients: { color: string; start: number; end: number }[] = [];

  const total = _.sumBy(props.items, (v) => v.value);
  for (let item of props.items) {
    let start = prev;
    let end = 0;
    let color = item.color;
    if (item.value === 0) {
      end = start + 0;
    } else {
      end = start + (item.value * 360) / total;
    }

    prev = end;
    gradients.push({ start, color, end });
  }

  let gradientStrings = gradients.map((g, i) => {
    return `${g.color} ${Math.floor(g.start)}deg ${Math.floor(g.end)}deg`;
  });
  if (total === 0) {
    gradientStrings = ['transparent 0deg 360deg'];
  }

  const bgStr = `conic-gradient(${gradientStrings.join(', ')})`;

  return (
    <div style={{ background: bgStr, borderRadius: '50%' }} className={classNames(styles.root, props.className, ifTrue(styles.counterClockWise, props.counterClockWise))}>
      <div className={styles.holeOuter}>
        <div className={styles.hole}>{props.children}</div>
      </div>
    </div>
  );
}
