import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as InformationIco } from '../../../../assets/information.svg';
import EditPoup, { EditPopupRow, EditPopupRowUsingDiv } from '../../../../components/edit-popup';
import Input, { ErrorLabel } from '../../../../components/input';
import CheckBox from '../../../../components/input-check-box';
import SingleSelect from '../../../../components/select/single-select';
import useFormState from '../../../../hooks/useFormState';
import api from '../../../../services/api';
import { Permission, RoleType, RootUserDto, UserType } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import useEffectAsync from '../../../../utils/useEffectAsync';
import styles from './user-create-popup.module.scss';
import { ReactComponent as InfoIco } from '../../../../assets/info.svg';
import { PermissionsGroupsOfRole, getPermissionGroupStructure } from '../../roles/role-create-popup/permissions-groups';
import RolePermissionsPopup from '../../../../components/role-permissions-popup';

interface RoleItem {
  id: string;
  name: string;
  selected: boolean;
  system: boolean;
  type: RoleType;
}

export default function UserCreatePopup(props: {
  affiliateCode: string;
  defaultLang: string;
  open: boolean;
  mail?: string;
  close: () => void;
  onSubmit: () => void;
  dataCy?: { root?: string; buttonSave?: string };
  getPermissionsOfRole: (roleId: string) => Promise<Permission[]>;
}) {
  const { _t } = useBetterTranslate('user-create-popup');

  // Form state
  const formState = useFormState();
  const resetForm = formState.reset;

  // User
  const [orgUser, setOrgUser] = useState<RootUserDto>();
  const [isExistingUser, setIsExistingUser] = useState<boolean>(false); // User that are not assigned to this affiliate

  //first name
  const [firstNameField, setFirstName] = formState.useInputState({
    label: _t('Vorname'),
    placeholder: 'Max',
    maxLength: 128,

    initialValue: '',
    validate: async (val) => {
      if (!val) return _t('Dieses Feld darf nicht leer sein.');
      val = val.trim();
      if (val.length === 0) {
        return _t('Dieses Feld darf nicht leer sein.');
      }
      if (val.length > 128) {
        return _t('Vorname darf nicht länger als 128 Zeichen sein.');
      }
    },
  });

  //last name
  const [lastNameField, setLastName] = formState.useInputState({
    label: _t('Nachname'),
    placeholder: 'Mustermann',
    maxLength: 128,

    initialValue: '',
    validate: async (val) => {
      if (!val) return _t('Dieses Feld darf nicht leer sein.');
      val = val.trim();
      if (val.length === 0) {
        return _t('Dieses Feld darf nicht leer sein.');
      }
      if (val.length > 128) {
        return _t('Nachname darf nicht länger als 128 Zeichen sein.');
      }
    },
  });

  const languages = useMemo(
    () => [
      { name: _t('English'), id: 'en' },
      { name: _t('German'), id: 'de' },
      { name: _t('French'), id: 'fr' },
    ],
    [_t]
  );
  const [langField, setLangField] = formState.useFieldState({
    initialValue: languages.find((l) => l.id === props.defaultLang) || languages[0],
    label: _t('Language'),
    dataCy: 'language',
  });

  //mail
  const [mailField, setMail] = formState.useInputState({
    label: _t('E-Mail'),
    placeholder: 'Max.Mustermann@beispielmail.com',
    type: 'email',
    maxLength: 254,

    initialValue: '',
    validate: async (val) => {
      if (!val) {
        if (isExistingUser) {
          setIsExistingUser(false);
          resetForm();
        } else {
          return _t('Dieses Feld darf nicht leer sein.');
        }
      }

      val = val.trim();
      if (val.length > 254) {
        return _t('E-Mail darf nicht länger als 254 Zeichen sein.');
      }

      if (!/^[\S._%+-]+@[\S.-]+\.[A-Za-z]{2,}$/i.test(val)) {
        return _t('Ungültige E-Mail-Adresse.');
      }

      if (orgUser && orgUser.mail === val) {
        return;
      }

      const userExists = await api.users.getMultiAffiliateUserIfExists(props.affiliateCode, mailField.value);
      if (userExists.data.userType === UserType.AFFILIATE_ROOT) return _t('E-Mail ist bereits vergeben.');
      else if (userExists.data.userType === UserType.CLIENT_OR_OTHER_AFFILIATE) {
        if (userExists.data.user) {
          setIsExistingUser(true);
          setFirstName(userExists.data.user.firstName);
          setLastName(userExists.data.user.lastName);
          setMail(userExists.data.user.mail);
          setLangField(languages.find((l) => l.id === userExists.data.user!.defaultLanguage) || languages[0]);
        }
      }
    },
  });

  //roles
  const [rolesField, setRolesField] = formState.useFieldState<RoleItem[]>({
    label: _t('Rollen'),
    labelInfo: _t('Nur Rollen vom Typ Root werden in der Liste angezeigt.'),
    initialValue: [],
    validate: async (val) => {
      if (!val.find((item) => item.selected)) {
        return _t('Um einen Benutzer zu erstellen, wählen Sie bitte mindestens eine Rolle aus.');
      }
    },
    dataCy: 'infRoles',
  });

  const toggleRoleItem = (role: RoleItem) => {
    role.selected = !role.selected;
    rolesField.onChange([...rolesField.value]);
  };

  //load data before open
  const [showPopup, setShowPopup] = useState(props.open);
  const onOpen = useCallback(async () => {
    resetForm();
    setIsExistingUser(false);
    const res = await api.users.listRoles(props.affiliateCode);
    let roleItems: RoleItem[] = res.data.map((role) => {
      return { id: role.id || '', name: role.name, selected: false, system: false, type: role.type };
    });

    if (props.mail) {
      //edit mode
      const user = (await api.users.getUser(props.affiliateCode, props.mail)).data;
      setOrgUser(user);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setMail(user.mail);
      setLangField(languages.find((l) => l.id === user.defaultLanguage) || languages[0]);
      for (const roleItem of roleItems) {
        if (user.roleIds.includes(roleItem.id)) {
          roleItem.selected = true;
        }
      }
    } else {
      setOrgUser(undefined);
    }
    setRolesField(roleItems);
  }, [resetForm, setFirstName, setLastName, setMail, setLangField, languages, setRolesField, props.mail, props.affiliateCode]);

  useEffectAsync(async () => {
    if (props.open) {
      await onOpen();
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [props.open]);

  const [currentPermissionGroupOfRole, setCurrentPermissionGroupOfRole] = useState<PermissionsGroupsOfRole>();
  const onRolesPermissionsClick = async (role: RoleItem) => {
    const permissions = await props.getPermissionsOfRole(role.id);
    const permissionGroups = getPermissionGroupStructure(permissions);
    setCurrentPermissionGroupOfRole({ roleId: role.id || '', roleName: role.name || '', roleType: role.type as RoleType, permissionsGroups: permissionGroups });
  };

  //submission
  formState.handleSubmit(async () => {
    const roleIds: string[] = [];
    rolesField.value?.forEach((item) => {
      if (item.selected) {
        roleIds.push(item.id);
      }
    });
    const user = {
      firstName: firstNameField.value.trim(),
      lastName: lastNameField.value.trim(),
      mail: mailField.value.trim(),
      roleIds: roleIds,
      affiliateCode: props.affiliateCode,
      defaultLanguage: langField.value.id,
    };
    if (props.mail || isExistingUser) {
      await api.users.updateRootUser(user);
    } else {
      await api.users.addRootUser(user);
    }
    props.onSubmit();
    props.close();
  });

  return (
    <EditPoup
      open={showPopup}
      className={styles.popup}
      onClose={props.close}
      onSave={async () => await formState.submit()}
      additionalFooterContentClassName={styles.submitError}
      additionalFooterContent={formState.submitError}
      bodyClassName={styles.popupBody}
      title={props.mail ? _t('Benutzer Editieren') : _t('Benutzer Erstellen')}
      saveText={props.mail ? _t('Speichern') : _t('Erstellen')}
      dataCy={{ root: props.dataCy?.root, buttonSave: props.dataCy?.buttonSave }}
    >
      <EditPopupRow label={mailField.label} controlCassName={props.mail ? styles.editEmail : ''} dataCy={{ label: 'txaEmail', content: 'inpEmail' }}>
        {!props.mail && <Input {...mailField} label='' onBlur={() => mailField.validate()} dataCy={{ error: 'ermEmail' }} />}
        {props.mail && <div>{props.mail}</div>}
      </EditPopupRow>

      <EditPopupRow label={firstNameField.label} dataCy={{ label: 'txaFirstName', content: 'inpFirstName' }}>
        {!isExistingUser && <Input {...firstNameField} label='' onBlur={() => firstNameField.validate()} dataCy={{ error: 'ermFirstName' }} />}
        {isExistingUser && <div>{firstNameField.value}</div>}
      </EditPopupRow>

      <EditPopupRow label={lastNameField.label} dataCy={{ label: 'txaLastName', content: 'inpLastName' }}>
        {!isExistingUser && <Input {...lastNameField} label='' onBlur={() => lastNameField.validate()} dataCy={{ error: 'ermLastName' }} />}
        {isExistingUser && <div>{lastNameField.value}</div>}
      </EditPopupRow>

      <EditPopupRow label=' ' className={styles.passwordRow}>
        {!(props.mail || isExistingUser) && (
          <div className={styles.info} data-cy='txaEmailInfo'>
            <InformationIco />
            <span>{_t('Nach dem Erstellen, erhält der Benutzer eine automatisierte E-mail zur Passwortvergabe.')}</span>
          </div>
        )}
        {isExistingUser && (
          <div className={styles.info} data-cy='txaEmailInfo'>
            <InformationIco />
            <span>{_t('Der Benutzer existiert bereits und erhält Zugriff auf dieses Affiliate. Um den Namen des Benutzers zu ändern, nutzen Sie die Editierfunktion.')}</span>
          </div>
        )}
      </EditPopupRow>

      <EditPopupRow label={langField.label} className={styles.langRow} validationErr={langField.validationErr}>
        {!isExistingUser && (
          <SingleSelect
            className={styles.inputField}
            options={languages}
            fetchOptions={async (v) => {
              return languages.filter((l) => l.name.toLowerCase().includes(v.toLowerCase()));
            }}
            createOption={(opt) => <div>{opt.name}</div>}
            renderDisplayValue={(v) => v.name}
            selectedValue={langField.value}
            onChanged={(v) => setLangField(v || languages[0])}

            // createOption={() => }
          />
        )}
        {isExistingUser && <div>{langField.value.id}</div>}
      </EditPopupRow>

      <EditPopupRowUsingDiv label={rolesField.label} controlCassName={styles.rolesRow} dataCy={{ label: 'txaRoles' }}>
        <div className={styles.rolesWrapper} data-cy='lstRoles'>
          <div className={classNames(styles.roles, rolesField.validationErr ? styles.hasValidationError : '')}>
            {rolesField.value.map((role, idx) => {
              return (
                <CheckBox
                  key={idx}
                  label={
                    <>
                      <span>{role.name}</span>
                      <span className={styles.infoIco}>
                        <InfoIco
                          onClick={async () => {
                            await onRolesPermissionsClick(role);
                          }}
                        ></InfoIco>
                      </span>
                    </>
                  }
                  isSelected={role.selected}
                  onChange={() => {
                    toggleRoleItem(role);
                  }}
                  disabled={role.system}
                  preventDefaultToClickLabel={true}
                />
              );
            })}
          </div>
          <ErrorLabel dataCy='ermRoles'>{rolesField.validationErr}</ErrorLabel>
        </div>
      </EditPopupRowUsingDiv>
      <RolePermissionsPopup
        open={!!currentPermissionGroupOfRole?.roleId}
        permissionsGroups={currentPermissionGroupOfRole?.permissionsGroups || []}
        onClose={() => {
          setCurrentPermissionGroupOfRole(undefined);
        }}
        roleType={currentPermissionGroupOfRole?.roleType}
        roleName={currentPermissionGroupOfRole?.roleName}
      />
    </EditPoup>
  );
}
