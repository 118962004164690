type CheckIfOneElementIsEqualOptions = {
  separator?: string;
  caseSensitive?: boolean;
};

/**
 * Used to check if one element of a string is equal to a search string
 * @param value full string to check
 * @param search element to search
 * @param options
 * @returns true if one element is equal to the search string
 */
export const checkIfOneElementIsEqual = (value: string, search: string, options: CheckIfOneElementIsEqualOptions = {}) => {
  const { separator = '_', caseSensitive = false } = options;
  if (!caseSensitive) {
    value = value.toLowerCase();
    search = search.toLowerCase();
  }
  const splittedValue = value.split(separator);

  // console.log(
  //   value,
  //   search,
  //   splittedValue.some((element) => element === search)
  // );

  return splittedValue.some((element) => element === search);
};
