import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { ReactComponent as CloseIco } from '../assets/close.svg';
import { classIf } from '../utils/class-name';
import styles from './pill.module.scss';
// import CloseIcon from './pill.module.scss';

export default function Pill(
  props: PropsWithChildren<{
    hasClose?: boolean;
    selected?: boolean;
    className?: string;
    onClick?: () => void;
    selectedClass?: string;
    unselectedClass?: string;
    dataCy?: string;
    tooltip?: string;
  }>
) {
  const cls = props.selected ? styles.selected : '';
  return (
    <button
      type='button'
      onClick={props.onClick}
      className={classNames(props.className, styles.root, cls, classIf(!!props.selected, props.selectedClass || '', props.unselectedClass))}
      data-cy={props.dataCy}
      title={props.tooltip}
    >
      <span>{props.children}</span>
      {props.hasClose && <CloseIco />}
    </button>
  );
}
