import { useEffect, useState } from 'react';
import EditPoup, { EditPopupRowUsingDiv } from '../../../components/edit-popup';
import TagSelector from '../../../components/tag-selector';
import useBetterTranslate from '../../../utils/translation-utils';
import styles from './ev-migration-new-job-popup.module.scss';
import api from '../../../services/api';
import Pill from '../../../components/pill';
import { ErrorLabel } from '../../../components/input';

export type StringTagSelectorWrapper = {
  id: string;
};
export default function EvMigrationNewJobPopup(props: { open: boolean; close: () => void; onSubmit: (chargeBoxIds: string[]) => Promise<void>; dataCy: string }) {
  const { _t } = useBetterTranslate('ev-migration-new-job-popup');
  const [stations, setStations] = useState<string[]>([]);
  const [error, setError] = useState<string>('');

  const searchStations = async (val: string) => {
    const result = await api.evMigration.searchStation({ q: val });
    return result.data.map((s) => {
      return { id: s };
    });
  };

  const submit = async () => {
    try {
      await props.onSubmit(stations);
    } catch (error) {
      setError(_t('An unexpected error occurred.'));
    }
  };

  useEffect(() => {
    if (props.open) {
      setStations([]);
      setError('');
    }
  }, [props.open]);

  return (
    <EditPoup
      open={props.open}
      className={styles.popup}
      onClose={props.close}
      onSave={async () => await submit()}
      additionalFooterContentClassName={styles.submitError}
      actionsClassName={styles.actions}
      title={_t('New EV Migration Job')}
      saveText={_t('Migrate')}
      dataCy={{ root: props.dataCy, buttonSave: 'btnMigrate' }}
    >
      <EditPopupRowUsingDiv>
        <div className={styles.tagSearchContainer}>
          <TagSelector<StringTagSelectorWrapper>
            fetchOptions={async (val): Promise<StringTagSelectorWrapper[]> => {
              const findings = await searchStations(val);
              return findings;
            }}
            classNames={styles.tagSearchInput}
            placeholder={_t('Search ChargeBoxId')}
            displayStyle={'search'}
            createOption={(val) => {
              return (
                <div className={styles.stationOpt}>
                  <span>{val.id}</span>
                  <span className={styles.type}>station</span>
                </div>
              );
            }}
            createTag={(val) => {
              return <div className={styles.stationTag}>{val.id}</div>;
            }}
            // set always to empty list
            // we will grab the values and show them below and not inside of the input
            selectedValues={[]}
            onChanged={(values) => {
              const invalidStations = values.filter((x) => !x.id.startsWith('DE*DES*E'));
              if (invalidStations.length > 0) {
                setError(`${invalidStations[0].id} can't be migrated, the chargeBoxId must starts with DE*DES*E.`);
                return;
              }
              setError('');
              //check for duplicated
              const uniqueValues = values.filter((x) => stations.find((y) => y === x.id) === undefined).map((y) => y.id);
              if (uniqueValues.length > 0) {
                const allValues = [...uniqueValues, ...stations];
                setStations(allValues);
              }
            }}
            removeBtnClass={() => styles.tagRmBtn}
          />
        </div>
      </EditPopupRowUsingDiv>

      {stations.length > 0 && (
        <EditPopupRowUsingDiv>
          <div className={styles.tagSearchDisplay}>
            {stations.map((chargeBoxId) => {
              return (
                <Pill
                  key={chargeBoxId}
                  className={styles.stationAppliedTag}
                  onClick={() => {
                    setStations(stations.filter((x) => x !== chargeBoxId));
                  }}
                >
                  {chargeBoxId}
                </Pill>
              );
            })}
          </div>
        </EditPopupRowUsingDiv>
      )}

      <ErrorLabel dataCy='erSelectStations'>{error}</ErrorLabel>
    </EditPoup>
  );
}
