import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './card-grid.module.scss';

export function CardHeader(props: PropsWithChildren<{ className?: string }>) {
  return <h3 className={classNames(styles.header, props.className)}>{props.children}</h3>;
}

export function CardBody(props: PropsWithChildren<{ className?: string }>) {
  return <div className={classNames(styles.body, props.className)}>{props.children}</div>;
}

export function Card(props: PropsWithChildren<{ className?: string; onClick?: () => void }>) {
  return (
    <div className={classNames(styles.card, props.className)} onClick={props.onClick}>
      {props.children}
    </div>
  );
}

export default function CardGrid(props: PropsWithChildren<{ className?: string }>) {
  return <div className={classNames(styles.root, props.className)}>{props.children}</div>;
}
