export const msgTemplates = [
  {
    id: 'SetChargingProfile',
    ocppAction: 'SetChargingProfile',
    template: `{
        "connectorId": "1", 
        "csChargingProfiles": { 
          "chargingProfileId": "1", 
          "stackLevel": "1", 
          "chargingProfilePurpose": "TxDefaultProfile", 
          "chargingProfileKind": "Relative", 
          "chargingSchedule": { 
            "chargingRateUnit": "A", 
            "chargingSchedulePeriod": [{ 
              "startPeriod": "0", 
              "limit": "32" 
            }] 
          } 
        } 
      }`,
  },
  {
    id: 'ClearChargingProfile',
    ocppAction: 'ClearChargingProfile',
    template: `{ 
    "connectorId": "1" 
  }`,
  },
  {
    id: 'RemoteStartTransaction',
    ocppAction: 'RemoteStartTransaction',
    template: `{ 
    "connectorId": "1", 
    "idTag": "1" 
  }`,
  },
  {
    id: 'RemoteStopTransaction',
    ocppAction: 'RemoteStopTransaction',
    template: `{ 
    "transactionId": "0" 
  }`,
  },
  {
    id: 'ReserveNow',
    ocppAction: 'ReserveNow',
    template: `{ 
    "connectorId": "1", 
    "idTag": "1", 
    "minutes": "10"
  }`,
  },
  {
    id: 'CancelReservation',
    ocppAction: 'CancelReservation',
    template: `{ 
  }`,
  },
  {
    id: 'Reset',
    ocppAction: 'Reset',
    template: `{ 
    "type": "type" 
  }`,
  },
  {
    id: 'UnlockConnector',
    ocppAction: 'UnlockConnector',
    template: `{ 
    "connectorId": "1" 
  }`,
  },
  {
    id: 'ChangeAvailability',
    ocppAction: 'ChangeAvailability',
    template: `{ 
    "connectorId": "1", 
    "type": "type" 
  }`,
  },
  {
    id: 'ChangeConfiguration',
    ocppAction: 'ChangeConfiguration',
    template: `{ 
    "key": "key", 
    "value": "value" 
  }`,
  },
  {
    id: 'TriggerStatusNotification',
    ocppAction: 'TriggerMessage',
    template: `{ 
    "requestedMessage": "StatusNotification", 
    "connectorId": "1" 
  }`,
  },
  {
    id: 'TriggerBootNotification',
    ocppAction: 'TriggerMessage',
    template: `{ 
    "requestedMessage": "BootNotification" 
  }`,
  },
  {
    id: 'TriggerHeartbeat',
    ocppAction: 'TriggerMessage',
    template: `{ 
    "requestedMessage": "Heartbeat" 
  }`,
  },
  {
    id: 'TriggerMeterValues',
    ocppAction: 'TriggerMessage',
    template: `{ 
    "requestedMessage": "MeterValues", 
    "connectorId": "1" 
  }`,
  },
  {
    id: 'TriggerDiagnosticsStatusNotification',
    ocppAction: 'TriggerMessage',
    template: `{ 
    "requestedMessage": "DiagnosticsStatusNotification" 
  }`,
  },
  {
    id: 'TriggerFirmwareStatusNotification',
    ocppAction: 'TriggerMessage',
    template: `{ 
    "requestedMessage": "FirmwareStatusNotification" 
  }`,
  },
];
