import React, { createContext, useContext, useState } from 'react';

interface UserPreferences {
  clientCode?: string;
  setClientCode: (val?: string) => void;

  affiliateCode?: string;
  setAffiliateCode: (val?: string) => void;
}

export const UserPreferencesCtx = createContext<UserPreferences>({
  setClientCode: () => {},
  setAffiliateCode: () => {},
});

export default function UserPreferencesProvider(props: React.PropsWithChildren<{}>) {
  const [clientCode, setClientCode] = useState<string>();
  const [affiliateCode, setAffiliateCode] = useState<string>();

  const contextValue: UserPreferences = { clientCode, setClientCode, affiliateCode, setAffiliateCode };

  return <UserPreferencesCtx.Provider value={contextValue}>{props.children}</UserPreferencesCtx.Provider>;
}

export function useUserPreferences() {
  return useContext(UserPreferencesCtx);
}
